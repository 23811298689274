"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcPaymentHeader = class UcPaymentHeader extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['messages', 'ux'];
    }
    beforeCreate() {
    }
    onPayment(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("payment");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            messages: {
                cash: {
                    in: this.$fn.pgettext("Payment", "Cash-In"),
                    out: this.$fn.pgettext("Payment", "Cash-Out"),
                },
                bank_account: this.$fn.pgettext("Payment", "Bank transaction"),
                bad_debt_journal: this.$fn.pgettext("Payment", "Bad debt"),
                reconciliation_of_invoices: this.$fn.pgettext("Payment", "Invoice reconciliation"),
                newTransaction: this.$fn.pgettext("Payment", "New settlement"),
            },
            ux: null,
        });
    }
    get isNew() {
        if (this.loaded) {
            return !this.payment.id;
        }
        return null;
    }
    get isPettyCash() {
        if (this.loaded) {
            return this.payment.payment_method === 'cash';
        }
        return null;
    }
    get resourceTitle() {
        if (this.loaded) {
            return this.isPettyCash ? this.messages.cash[this.payment.direction] : this.messages[this.payment.account.account_type];
        }
        return null;
    }
    get resourceKey() {
        if (this.loaded) {
            return this.isNew && !this.isPettyCash ? this.messages.newTransaction : this.payment.snapshot('payment_number');
        }
        return null;
    }
    act_1040167445267876_emit_5812_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_1040167445267876_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_4942415580156499_emit_5814_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "deleted",
            };
        });
    }
    evh_4942415580156499_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4942415580156499_success.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_1040167445267876_emit_5812 = new core_1.actions.EmitAction({
            actionArgs: this.act_1040167445267876_emit_5812_getActionArgs,
            displayName: "reload",
            events: [],
        });
        const evh_1040167445267876_clickPreventStop_5811 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_emit_5812,
            event: "click.prevent.stop",
            displayName: "reload",
        });
        const evh_1040167445267876_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_clickPreventStop_5811],
        });
        const act_4942415580156499_emit_5814 = new core_1.actions.EmitAction({
            actionArgs: this.act_4942415580156499_emit_5814_getActionArgs,
            events: [],
        });
        const evh_4942415580156499_success_5813 = new core_1.actions.EventHandlerImpl({
            action: act_4942415580156499_emit_5814,
            event: "success",
            displayName: "emit",
        });
        const evh_4942415580156499_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4942415580156499_success_5813],
        });
        return {
            act_1040167445267876_emit_5812,
            evh_1040167445267876_clickPreventStop_5811,
            evh_1040167445267876_clickPreventStop,
            act_4942415580156499_emit_5814,
            evh_4942415580156499_success_5813,
            evh_4942415580156499_success,
            reload: act_1040167445267876_emit_5812,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcPaymentHeader.prototype, "payment", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('payment'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcPaymentHeader.prototype, "onPayment", null);
UcPaymentHeader = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcPaymentHeader);
exports.default = UcPaymentHeader;
vue_property_decorator_1.Vue.component("UcPaymentHeader", UcPaymentHeader);
