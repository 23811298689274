"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "settings.statData",
    path: "external-stat-data",
    component: "StatData"
});
let StatData = class StatData extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['statDataTypes', 'items', 'ux', 'b_7315092382398562_modalBindings', 'b_8564662037462133_columns'];
    }
    beforeCreate() {
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_stat_data').then(p => p.data);
        });
    }
    $$load_b_8564662037462133_columns() {
        return __awaiter(this, void 0, void 0, function* () {
            return [{
                    name: "data_type",
                    type: "select",
                    header: this.$fn.gettext("Data type"),
                    width: "280px",
                    required: true,
                    options: this.statDataTypes,
                },
                {
                    name: "year",
                    type: "number",
                    header: this.$fn.gettext("Year"),
                    width: "120px",
                    required: true,
                },
                {
                    name: "month",
                    type: "number",
                    header: this.$fn.gettext("Month"),
                    width: "120px",
                    required: true,
                },
                {
                    name: "value",
                    type: "number",
                    header: this.$fn.gettext("Value"),
                    width: "120px",
                    required: true,
                },
                {
                    name: "unit",
                    header: this.$fn.gettext("Unit"),
                    width: "120px",
                    required: true,
                },
            ];
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            statDataTypes: [{
                    id: "ksh_domestic_industrial_sales_price_index",
                    text: "P_(\"StatDataType\", \"ksh_domestic_industrial_sales_price_index\", \"Sentence\")",
                },
            ],
            items: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_8564662037462133_columns: null,
        });
    }
    get document() {
        if (this.loaded) {
            return;
        }
        return null;
    }
    act_7315092382398562_closeModal_4184_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_8564662037462133_script_4186_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('stat_data').upsert(Object.assign(Object.assign({}, $event.data.row), { start: $event.data.row.lastvalue })));
        });
    }
    act_8564662037462133_script_4186_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_8564662037462133_script_4186_getActionArgs_value($event),
            };
        });
    }
    evh_8564662037462133_save(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_save.executeFromDOM(this, event, scope);
        });
    }
    act_8564662037462133_script_4188_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('stat_data').delete($event.data.row));
        });
    }
    act_8564662037462133_script_4188_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_8564662037462133_script_4188_getActionArgs_value($event),
            };
        });
    }
    evh_8564662037462133_delete(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_delete.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_4184 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_4184_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_4183 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_4184,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_4183],
        });
        const act_8564662037462133_script_4186 = new core_1.actions.ScriptAction({
            actionArgs: this.act_8564662037462133_script_4186_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_save_4185 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_script_4186,
            event: "save",
            displayName: "script",
        });
        const evh_8564662037462133_save = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_save_4185],
        });
        const act_8564662037462133_script_4188 = new core_1.actions.ScriptAction({
            actionArgs: this.act_8564662037462133_script_4188_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_delete_4187 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_script_4188,
            event: "delete",
            displayName: "script",
        });
        const evh_8564662037462133_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_delete_4187],
        });
        return {
            act_7315092382398562_closeModal_4184,
            evh_7315092382398562_close_4183,
            evh_7315092382398562_close,
            act_8564662037462133_script_4186,
            evh_8564662037462133_save_4185,
            evh_8564662037462133_save,
            act_8564662037462133_script_4188,
            evh_8564662037462133_delete_4187,
            evh_8564662037462133_delete,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], StatData.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], StatData.prototype, "onSize", null);
StatData = __decorate([
    (0, vue_property_decorator_1.Component)()
], StatData);
exports.default = StatData;
vue_property_decorator_1.Vue.component("StatData", StatData);
