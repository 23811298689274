"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let CreateContractRevision = class CreateContractRevision extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['formName', 'formId', 'labelAlign', 'item', 'statuses', 'options', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
        this.$$cache_options = new core_1.ComponentValueCache(this, "options", "CreateContractRevision.createRevisionOptions");
    }
    onContract(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contract");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                id: this.contract.id,
                base_revision_id: this.contract.revision_id,
                revision_number: this.contract.revision_number + 1,
                revision_date: this.$fn.today(),
                valid_from: this.contract.lifecycle.valid_to ? this.$fn.dateAdd(this.contract.lifecycle.valid_to, { days: 1 }) : this.$fn.today(),
                valid_to: null,
                status: 'draft'
            };
        });
    }
    $$load_statuses() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.getEnumValuesFromSchema('ContractStatus');
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            formName: "contract",
            formId: null,
            labelAlign: "right",
            item: null,
            statuses: null,
            options: {
                copy_services: true,
                copy_service_fees: true,
                skip_outdated_services: true,
                skip_outdated_service_fees: true,
            },
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "md",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.gettext("Create Contract Revision"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_2828_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1548630417156826_reloadSlickgrid_2834_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "contracts",
            };
        });
    }
    act_1548630417156826_closeComponent_2836_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    close: true,
                    value: $event.data.response.data,
                },
            };
        });
    }
    act_1548630417156826_navigate_2838_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                location: {
                    name: "app.contract",
                    params: {
                        id: $event.data.response.data.id,
                        rev: $event.data.response.data.revision_number,
                    },
                },
            };
        });
    }
    act_1548630417156826_request_2832_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "create_contract_revision",
                data: Object.assign(Object.assign({}, this.item), this.options),
            };
        });
    }
    evh_1548630417156826_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2828 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2828_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2827 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2828,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2827],
        });
        const act_2248226175642056_closeComponent_2830 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_2829 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_2830,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_2829],
        });
        const act_1548630417156826_reloadSlickgrid_2834 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1548630417156826_reloadSlickgrid_2834_getActionArgs,
            events: [],
        });
        const evh_1548630417156826_success_2833 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_reloadSlickgrid_2834,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_1548630417156826_closeComponent_2836 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1548630417156826_closeComponent_2836_getActionArgs,
            events: [],
        });
        const evh_1548630417156826_success_2835 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeComponent_2836,
            event: "success",
            displayName: "closeComponent",
        });
        const act_1548630417156826_navigate_2838 = new core_1.actions.NavigateAction({
            actionArgs: this.act_1548630417156826_navigate_2838_getActionArgs,
            events: [],
        });
        const evh_1548630417156826_success_2837 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_navigate_2838,
            event: "success",
            displayName: "navigate",
        });
        const act_1548630417156826_request_2832 = new core_1.actions.RequestAction({
            actionArgs: this.act_1548630417156826_request_2832_getActionArgs,
            displayName: "save",
            events: [evh_1548630417156826_success_2833, evh_1548630417156826_success_2835, evh_1548630417156826_success_2837],
        });
        const evh_1548630417156826_clickPreventStop_2831 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_request_2832,
            event: "click.prevent.stop",
            displayName: "save",
        });
        const evh_1548630417156826_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_clickPreventStop_2831],
        });
        const act_1419464017721962_closeModal_2840 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_2839 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeModal_2840,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_2839],
        });
        return {
            act_7315092382398562_closeModal_2828,
            evh_7315092382398562_close_2827,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_2830,
            evh_2248226175642056_close_2829,
            evh_2248226175642056_close,
            act_1548630417156826_reloadSlickgrid_2834,
            evh_1548630417156826_success_2833,
            act_1548630417156826_closeComponent_2836,
            evh_1548630417156826_success_2835,
            act_1548630417156826_navigate_2838,
            evh_1548630417156826_success_2837,
            act_1548630417156826_request_2832,
            evh_1548630417156826_clickPreventStop_2831,
            evh_1548630417156826_clickPreventStop,
            act_1419464017721962_closeModal_2840,
            evh_1419464017721962_clickPreventStop_2839,
            evh_1419464017721962_clickPreventStop,
            save: act_1548630417156826_request_2832,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], CreateContractRevision.prototype, "contract", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('contract'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], CreateContractRevision.prototype, "onContract", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], CreateContractRevision.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], CreateContractRevision.prototype, "onSize", null);
CreateContractRevision = __decorate([
    (0, vue_property_decorator_1.Component)()
], CreateContractRevision);
exports.default = CreateContractRevision;
vue_property_decorator_1.Vue.component("CreateContractRevision", CreateContractRevision);
