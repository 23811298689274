<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="NAVOnlineInvoiceInterfaceSettings"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :name="formName"
      :readonly="resource.locked"
      :labelAlign="$config.forms.defaultLabelAlign"
      :id="formId"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpRow>
        <ItpCol>
          <ItpFormGroup
            labelColsMd="3"
            contentColsMd="9"
            name="base_url"
            :label="'Base URL'|pgettext('NAVOnlineInvoiceInterfaceSettings')"
            required
          >
            <ItpFormInput
              name="base_url"
              v-model="editor.base_url"
              required
              :placeholder="'The root URL of the service.'|pgettext('NAVOnlineInvoiceInterfaceSettings')"
            >
            </ItpFormInput>
          </ItpFormGroup>
          <ItpFormGroup
            labelColsMd="3"
            contentColsMd="9"
            name="tax_number"
            :label="'Tax number'|pgettext('NAVOnlineInvoiceInterfaceSettings')"
            required
          >
            <ItpFormInput
              name="tax_number"
              v-model="editor.tax_number"
              required
              :placeholder="'The first 8 digits of the tax number.'|pgettext('NAVOnlineInvoiceInterfaceSettings')"
            >
            </ItpFormInput>
          </ItpFormGroup>
          <ItpFormGroup
            labelColsMd="3"
            contentColsMd="9"
            name="login"
            :label="'Login'|pgettext('NAVOnlineInvoiceInterfaceSettings')"
            required
          >
            <ItpFormInput
              name="login"
              v-model="editor.login"
              required
              :placeholder="'The technical user\'s login name.'|pgettext('NAVOnlineInvoiceInterfaceSettings')"
            >
            </ItpFormInput>
          </ItpFormGroup>
          <HR>
          </HR>
          <ItpFormGroup
            labelColsMd="3"
            contentColsMd="9"
            name="password_hash"
            :label="'Password hash'|pgettext('NAVOnlineInvoiceInterfaceSettings')"
          >
            <ItpFormTextarea
              name="password_hash"
              v-model="resource.password"
              :placeholder="'The generated password\'s SHA-512 hash.'|pgettext('NAVOnlineInvoiceInterfaceSettings')"
              readonly
            >
            </ItpFormTextarea>
          </ItpFormGroup>
          <ItpFormGroup
            labelColsMd="3"
            contentColsMd="9"
            name="password"
            :label="'Password'|pgettext('NAVOnlineInvoiceInterfaceSettings')"
          >
            <ItpFormInput
              type="password"
              name="password"
              v-model="change_password.password"
              :placeholder="'The generated password.'|pgettext('NAVOnlineInvoiceInterfaceSettings')"
            >
            </ItpFormInput>
          </ItpFormGroup>
          <ItpFormGroup
            labelColsMd="3"
            contentColsMd="9"
            name="password"
            :label="'Confirm password'|pgettext('NAVOnlineInvoiceInterfaceSettings')"
          >
            <ItpFormInput
              type="password"
              name="verify_password"
              v-model="change_password.verify_password"
              :placeholder="'The generated password.'|pgettext('NAVOnlineInvoiceInterfaceSettings')"
            >
            </ItpFormInput>
          </ItpFormGroup>
          <HR>
          </HR>
          <ItpFormGroup
            labelColsMd="3"
            contentColsMd="9"
            name="xml_sign_key"
            :label="'XML sign key'|pgettext('NAVOnlineInvoiceInterfaceSettings')"
            required
          >
            <ItpFormInput
              name="xml_sign_key"
              v-model="editor.xml_sign_key"
              required
              :placeholder="'The technical user\'s XML sign key.'|pgettext('NAVOnlineInvoiceInterfaceSettings')"
            >
            </ItpFormInput>
          </ItpFormGroup>
          <ItpFormGroup
            labelColsMd="3"
            contentColsMd="9"
            name="exchange_key"
            :label="'XML exchange key'|pgettext('NAVOnlineInvoiceInterfaceSettings')"
            required
          >
            <ItpFormInput
              name="exchange_key"
              v-model="editor.exchange_key"
              required
              :placeholder="'The technical user\'s XML exchange key.'|pgettext('NAVOnlineInvoiceInterfaceSettings')"
            >
            </ItpFormInput>
          </ItpFormGroup>
          <ItpFormGroup
            labelColsMd="3"
            contentColsMd="9"
            name="add_unknown_clients"
            :label="'Add unknown clients'|pgettext('NAVOnlineInvoiceInterfaceSettings')"
          >
            <ItpFormCheckbox
              name="add_unknown_clients"
              v-model="editor.add_unknown_clients"
            >
            </ItpFormCheckbox>
          </ItpFormGroup>
          <ItpFormGroup
            labelColsMd="3"
            contentColsMd="2"
            name="lookback_period"
            :label="'Lookback period (days)'|pgettext('NAVOnlineInvoiceInterfaceSettings')"
          >
            <ItpFormInput
              type="number"
              name="lookback_period"
              required
              v-model="editor.lookback_period"
            >
            </ItpFormInput>
          </ItpFormGroup>
        </ItpCol>
      </ItpRow>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :form="forms[formName] && forms[formName].id"
      :text="'Save'|pgettext('Button')"
      type="submit"
      variant="primary"
      default
      :spinning="actions.submit && actions.submit.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|pgettext('Button')"
      variant="default"
      :disabled="actions.submit && actions.submit.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class NAVOnlineInvoiceInterfaceSettings extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  resource!: any;

  async $$load_resource() {
    return this.$fn.fetch('read_settings', { parameters: { key: 'nav-online-interface' }, asResource: true })
  }

  editor!: any;

  async $$load_editor() {
    return this.resource.editor()
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  formName!: any;
  change_password!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['resource', 'editor', 'formId', 'formName', 'change_password', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        resource: null,
        editor: null,
        formId: null,
        formName: "NAVOnlineInvoiceInterfaceSettings",
        change_password: {
          password: null,
          verify_password: null,
        }
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "lg",
        }
        ,
      },
    }
  }

  get payload() {
    if (this.loaded) {

      return _.extend(
        {},
        this.editor.getData(),
        this.change_password.password && this.change_password.verify_password ? this.change_password : null
      )
    }
    return null;
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("NAVOnlineInvoiceInterfaceSettings|Title", "NAV online invoice interface configuration"),
        icon: this.$config.settings.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_4132_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_4132, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeComponent_4138_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1040167445267876_closeComponent_4138, alias=undefined
    return {
      result: {
        ok: true,
        value: $event.data.response.data,
      }
      ,
    }
  }

  async act_1040167445267876_request_4136_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1040167445267876_request_4136, alias=submit
    return {
      operation: "update_settings",
      data: this.payload,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeModal_4140_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1419464017721962_closeModal_4140, alias=undefined
    return {
      name: this.modalName,
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_4132: actions.CloseModalAction;
    evh_7315092382398562_close_4131: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_4134: actions.CloseComponentAction;
    evh_2248226175642056_close_4133: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeComponent_4138: actions.CloseComponentAction;
    evh_1040167445267876_saved_4137: actions.EventHandlerImpl;
    act_1040167445267876_request_4136: actions.RequestAction;
    evh_1040167445267876_submit_4135: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeModal_4140: actions.CloseModalAction;
    evh_1419464017721962_clickPreventStop_4139: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_4132 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_4132_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_4131 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_4132,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_4131],
      }
    );
    const act_2248226175642056_closeComponent_4134 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_4133 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_4134,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_4133],
      }
    );
    const act_1040167445267876_closeComponent_4138 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1040167445267876_closeComponent_4138_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_saved_4137 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeComponent_4138,
        event: "saved",
        displayName: "closeComponent",
      }
    );
    const act_1040167445267876_request_4136 = new actions.RequestAction(
      {
        actionArgs: this.act_1040167445267876_request_4136_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_saved_4137],
      }
    );
    const evh_1040167445267876_submit_4135 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_request_4136,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_4135],
      }
    );
    const act_1419464017721962_closeModal_4140 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1419464017721962_closeModal_4140_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_4139 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeModal_4140,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_4139],
      }
    );
    return {
      act_7315092382398562_closeModal_4132,
      evh_7315092382398562_close_4131,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_4134,
      evh_2248226175642056_close_4133,
      evh_2248226175642056_close,
      act_1040167445267876_closeComponent_4138,
      evh_1040167445267876_saved_4137,
      act_1040167445267876_request_4136,
      evh_1040167445267876_submit_4135,
      evh_1040167445267876_submit,
      act_1419464017721962_closeModal_4140,
      evh_1419464017721962_clickPreventStop_4139,
      evh_1419464017721962_clickPreventStop,
      submit: act_1040167445267876_request_4136,
    }
  }
}

Vue.component("NAVOnlineInvoiceInterfaceSettings", NAVOnlineInvoiceInterfaceSettings);

</script>