"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let MeterRelations = class MeterRelations extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['relationTypes', 'relationTypesMap', 'items', 'ux', 'b_2248226175642056_columns'];
    }
    beforeCreate() {
    }
    relationItemToPayload(...args) {
        const p = args[0];
        const meter = this.meter;
        if (p.relation_type === 'sub') {
            p.parent_meter_id = meter.id;
            p.child_meter_id = p.related_meter.id;
        }
        else if (p.relation_type === 'data') {
            p.parent_meter_id = p.related_meter.id;
            p.child_meter_id = meter.id;
        }
        else if (p.relation_type === 'parent') {
            p.parent_meter_id = p.related_meter.id;
            p.child_meter_id = meter.id;
        }
        return p;
    }
    mapRelationItem(...args) {
        const p = args[0];
        if (!p) {
            return p;
        }
        const meter = this.meter;
        const isRelatedChild = p.child_meter_id === meter.id;
        if (isRelatedChild) {
            p.related_meter = p.parent_meter;
            p.relation_type = p.related_meter.meter_type == 'pulse' ? 'data' : 'parent';
            return p;
        }
        p.related_meter = p.child_meter;
        p.relation_type = 'sub';
        return p;
    }
    onMeter(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("meter");
    }
    $$load_relationTypesMap() {
        return __awaiter(this, void 0, void 0, function* () {
            return _.zipObject(_.map(this.relationTypes, p => p.id), _.map(this.relationTypes, p => p.text));
        });
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_meter_relationship', { parameters: { meter_id: this.meter.id } })
                .then(items => items.map(p => this.mapRelationItem(p)));
        });
    }
    $$load_b_2248226175642056_columns() {
        return __awaiter(this, void 0, void 0, function* () {
            return [{
                    name: "inactive",
                    header: this.$fn.gettext("Inactive"),
                    width: "30px",
                    textAlign: "center",
                    formatter: {
                        name: "checkbox",
                    },
                    editor: {
                        name: "checkbox",
                    },
                },
                {
                    name: "relation_type",
                    type: "select",
                    header: this.$fn.gettext("Relation type"),
                    width: "170px",
                    options: this.relationTypes,
                },
                {
                    name: "related_meter",
                    type: "select2",
                    header: this.$fn.gettext("Device"),
                    width: "270px",
                    formatter: {
                        name: "template",
                        value: "<div v-if=\"value\" class=\"text-left\">\n\
  <ItpLink :to=\"{ name: 'app.meter', params: row.related_meter}\">\n\
    {{ value.serial_number }}\n\
  </ItpLink>\n\
  <div v-if=\"value.deveui\">{{ value.deveui }}</div>\n\
  <div v-if=\"value.tag_id\">{{ value.tag_id }}</div>\n\
  <div v-if=\"value.description\">{{ value.description }}</div>\n\
</div>\n\
",
                    },
                    options: this.$fn.searchDataSource('suggest_related_meter', {
                        parent: this.meter.id
                    }),
                    optionValueField: "id",
                    valueField: "id",
                    textField: "serial_number",
                    bindObject: true,
                    optionTemplate: "<div class=\"text-left\">\n\
  {{ option.serial_number }}\n\
  <div v-if=\"option.deveui\">{{ option.deveui }}</div>\n\
  <div v-if=\"option.tag_id\">{{ option.tag_id }}</div>\n\
  <div v-if=\"option.description\">{{ option.description }}</div>\n\
</div>\n\
",
                    validator: ["required",
                    ],
                },
                {
                    name: "slot_number",
                    type: "number",
                    header: this.$fn.gettext("Slot Number"),
                    width: "140px",
                },
                {
                    name: "offset",
                    type: "number",
                    header: this.$fn.gettext("Offset"),
                    width: "140px",
                    required: true,
                },
                {
                    name: "notes",
                    type: "textarea",
                    width: "200px",
                    header: this.$fn.gettext("Notes"),
                },
            ];
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            relationTypes: [{
                    id: "parent",
                    text: this.$fn.pgettext("MeterRelationTypes", "Parent"),
                },
                {
                    id: "sub",
                    text: this.$fn.pgettext("MeterRelationTypes", "Submeter"),
                },
                {
                    id: "data",
                    text: this.$fn.pgettext("MeterRelationTypes", "Data Provider"),
                },
            ],
            relationTypesMap: null,
            items: null,
            ux: {
                errorHandling: "local",
                initialLoadSpinner: true,
            },
            b_2248226175642056_columns: null,
        });
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_script_6884_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('meter_relationship')
                .upsert(this.relationItemToPayload($event.data.row))
                .then(p => this.mapRelationItem(p)));
        });
    }
    act_2248226175642056_script_6884_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_2248226175642056_script_6884_getActionArgs_value($event),
            };
        });
    }
    evh_2248226175642056_save(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_save.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_script_6886_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('meter_relationship').delete($event.data.row));
        });
    }
    act_2248226175642056_script_6886_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_2248226175642056_script_6886_getActionArgs_value($event),
            };
        });
    }
    evh_2248226175642056_delete(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_delete.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_6882 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_6881 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_6882,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_6881],
        });
        const act_2248226175642056_script_6884 = new core_1.actions.ScriptAction({
            actionArgs: this.act_2248226175642056_script_6884_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_save_6883 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_script_6884,
            event: "save",
            displayName: "script",
        });
        const evh_2248226175642056_save = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_save_6883],
        });
        const act_2248226175642056_script_6886 = new core_1.actions.ScriptAction({
            actionArgs: this.act_2248226175642056_script_6886_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_delete_6885 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_script_6886,
            event: "delete",
            displayName: "script",
        });
        const evh_2248226175642056_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_delete_6885],
        });
        return {
            act_7315092382398562_reloadComponentData_6882,
            evh_7315092382398562_reload_6881,
            evh_7315092382398562_reload,
            act_2248226175642056_script_6884,
            evh_2248226175642056_save_6883,
            evh_2248226175642056_save,
            act_2248226175642056_script_6886,
            evh_2248226175642056_delete_6885,
            evh_2248226175642056_delete,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], MeterRelations.prototype, "meter", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('meter'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MeterRelations.prototype, "onMeter", null);
MeterRelations = __decorate([
    (0, vue_property_decorator_1.Component)()
], MeterRelations);
exports.default = MeterRelations;
vue_property_decorator_1.Vue.component("MeterRelations", MeterRelations);
