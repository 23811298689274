<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <legend
    class="text-muted"
  >
    {{ 'General'|pgettext('Contract') }}
  </legend>
  <ItpFormGroup
    name="category"
    :label="'Category'|gettext"
    v-if="filteredCategories && filteredCategories.length"
  >
    <ItpFormSelect2
      name="category"
      v-model="contract.category"
      bind-object
      textField="name"
      :options="filteredCategories"
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    name="revision_number"
    :label="'Revision number'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="2"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormInput
      type="number"
      name="revision_number"
      v-model="contract.revision_number"
      required
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="status"
    :label="'Status'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormSelect2
      name="status"
      v-model="contract.status"
      valueField="value"
      textField="text"
      :options="statuses"
      required
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    name="external_key"
    :label="'External key'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="5"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="external_key"
      v-model="contract.external_key"
    >
    </ItpFormInput>
  </ItpFormGroup>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetContractGeneralData extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  labelAlign!: any;
  categories!: any;

  async $$load_categories() {
    return this.$fn.fetch('list_contract_category')
  }

  statuses!: any;

  async $$load_statuses() {
    return this.$fn.getEnumValuesFromSchema('ContractStatus')
  }

  ux!: any;
  dataMembers = ['labelAlign', 'categories', 'statuses', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        labelAlign: "right",
        categories: null,
        statuses: null,
        ux: null,
      },
    }
  }

  get filteredCategories() {
    if (this.loaded) {

      return this.categories.filter(p => p.contract_type === this.contract.contract_type)
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6578: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6577: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6578 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6577 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6578,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6577],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6578,
      evh_7315092382398562_reload_6577,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("FieldsetContractGeneralData", FieldsetContractGeneralData);

</script>