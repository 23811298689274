<template>
<ItpBox
  alignItems="center"
  class="items-spacing w-100"
  v-if="loaded"
>
  <ItpBox
    direction="column"
  >
    <ItpText
      muted
      class="mb-0"
    >
      {{ resourceTitle }}
    </ItpText>
    <ItpText
      tag="h4"
      :text="resourceKey"
      class="mb-0"
    >
    </ItpText>
  </ItpBox>
  <UcInvoiceTypeBadge
    :value="invoice.invoice_type"
  >
  </UcInvoiceTypeBadge>
  <UcInvoiceStatusBadge
    :value="invoice.status"
  >
  </UcInvoiceStatusBadge>
  <DIV
    v-if="invoice.tags && invoice.tags.length"
  >
    <span
      class="badge badge-secondary mx-1 shadow-none"
      v-for="tag in invoice.tags"
    >
      {{ tag }}
    </span>
  </DIV>
  <DIV
    :title="'Closed'|gettext"
    v-if="invoice.isReadOnly"
  >
    <ItpIcon
      :icon="$config.lockIcon"
    >
    </ItpIcon>
  </DIV>
  <ItpBox
    direction="column"
    v-if="invoice.reference_invoice"
  >
    <ItpText
      muted
      class="mb-0"
    >
      {{ 'Reference invoice'|pgettext('Invoice') }}
    </ItpText>
    <UcInvoiceMiniCard
      :item="invoice.reference_invoice"
      no-icon
    >
    </UcInvoiceMiniCard>
  </ItpBox>
  <ItpBox
    direction="column"
    v-if="invoice.referencing_invoices && invoice.referencing_invoices.length"
  >
    <ItpText
      muted
      class="mb-0"
    >
      {{ 'Referencing invoices'|pgettext('Invoice') }}
    </ItpText>
    <ItpBox>
      <UcInvoiceMiniCard
        :key="obj.id"
        :item="obj"
        no-icon
        class="mr-2"
        v-for="obj in invoice.referencing_invoices"
      >
      </UcInvoiceMiniCard>
    </ItpBox>
  </ItpBox>
  <Icon
    :icon="'flagpack:' + invoice.language"
    :title="'Invoice Language'|pgettext('Invoice')"
    v-if="!invoice.isNew"
  >
  </Icon>
  <DIV
    class="btn-toolbar ml-auto"
  >
    <BtnSyncInvoice
      :invoice="invoice"
      class="ml-auto mr-2"
      v-if="!invoice.isNew"
      @success="evh_8680084583475136_success($event, {})"
    >
    </BtnSyncInvoice>
    <BtnSyncResource
      :resource="invoice"
      target="irent"
      confirmMessage="A számlát csak egyszer lehet felölteni a régi rendszerbe,
feltöltés után a számlát már nem lehet módosítani."
      class="ml-auto mr-2"
      v-if="!invoice.isNew"
      @success="evh_3282321863127091_success($event, {})"
    >
    </BtnSyncResource>
    <ItpButton
      :icon="$config.editIcon"
      :tooltip="'Edit'|gettext"
      variant="light"
      class="ml-auto mr-2"
      v-if="!invoice.isNew && !invoice.isReadOnly"
      @click.prevent.stop="evh_2386378572842701_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      icon="fa-sync-alt"
      :tooltip="'Reload'|gettext"
      :spinning="actions.reload.isRunning"
      variant="light"
      class="ml-auto mr-2"
      v-if="!invoice.isNew"
      @click.prevent.stop="evh_6305342467955186_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      variant="light"
      icon="icon-park-outline:printer-one"
      :href="invoice.print_url"
      :tooltip="'Open print document'|pgettext('Invoice')"
      target="_blank"
      use-auth
      show-spinner
      class="ml-auto mr-2"
      v-if="invoice.print_url"
    >
    </ItpButton>
    <ItpButton
      variant="light"
      icon="icon-park-outline:preview-open"
      :href="invoice.preview_url"
      :tooltip="'Open preview'|pgettext('Invoice')"
      target="_blank"
      use-auth
      show-spinner
      class="ml-auto mr-2"
      v-if="invoice.preview_url"
    >
    </ItpButton>
    <ItpButton
      variant="light"
      icon="icon-park-outline:log"
      :tooltip="'Show log messages'|pgettext('Invoice')"
      class="ml-auto mr-2"
      v-if="invoice.log_messages"
      @click.prevent.stop="evh_6493473528837603_clickPreventStop($event, {})"
    >
    </ItpButton>
    <b-dropdown
      right
      variant="light"
      no-caret
      class="border"
      v-if="!invoice.isNew"
    >
      <ItpIcon
        fa="bars"
        class="mx-1"
        slot="button-content"
      >
      </ItpIcon>
      <ItpButton
        dropdown-item
        :text="'Register invoice'|pgettext('Invoice')"
        :tooltip="'Add this invoice to the invoice register'|pgettext('Invoice')"
        variant="default"
        v-if="invoiceIsRegistrable"
        @click="evh_5796712150329752_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        dropdown-item
        :text="'Copy'|pgettext('BtnCopyResource')"
        :tooltip="'Create new invoice from this one'|pgettext('Invoice')"
        variant="default"
        @click="evh_4125470329773403_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        dropdown-item
        :text="'Create storno'|pgettext('Invoice')"
        :tooltip="createStornoTooltip"
        variant="default"
        :disabled="!invoiceIsReversible"
        v-if="!invoice.isNew"
        @click="evh_3785918277944093_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        dropdown-item
        variant="default"
        :text="'Create corrector'|pgettext('Invoice')"
        :tooltip="createCorrectorTooltip"
        :disabled="!invoiceIsModifiable"
        v-if="!invoice.isNew"
        @click="evh_2191919750581405_click($event, {})"
      >
      </ItpButton>
      <BtnLockResource
        no-icon
        dropdown-item
        :resource="invoice"
        :tooltip="'Lock invoice'|pgettext('Invoice')"
        v-if="!invoice.isNew && !invoice.isReadOnly"
        @success="evh_3699289115122175_success($event, {})"
      >
      </BtnLockResource>
      <BtnDeleteResource
        no-icon
        dropdown-item
        :resource="invoice"
        :tooltip="'Delete invoice'|pgettext('Invoice')"
        v-if="!invoice.isNew && !invoice.isReadOnly"
        @success="evh_5417874158095965_success($event, {})"
      >
      </BtnDeleteResource>
      <hr>
      </hr>
      <ItpButton
        dropdown-item
        :text="'History'|pgettext('BtnResourceHistory')"
        :tooltip="'Invoice history'|pgettext('Invoice')"
        variant="default"
        @click.prevent.stop="evh_8518465037810560_clickPreventStop($event, {})"
      >
      </ItpButton>
    </b-dropdown>
  </DIV>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceHeader extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  @Prop({
    type: String,
    default: function() {
      return this.$fn.randomElementId()
    },
  })
  formId!: string;

  @Watch('formId')
  onForm_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("formId")
  }

  @Prop({
    type: String,
  })
  invoiceNumber!: string;

  @Watch('invoiceNumber')
  onInvoice_number(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoiceNumber")
  }

  @Prop({
    required: true,
    type: Object,
  })
  formContext!: object;

  @Watch('formContext')
  onForm_context(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("formContext")
  }

  messages!: any;
  ux!: any;
  dataMembers = ['messages', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          newTransaction: this.$fn.pgettext("Invoice", "New Invoice"),
          invoiceKind: {
            incoming: this.$fn.pgettext("InvoiceKind", "Incoming Invoice"),
            outgoing: this.$fn.pgettext("InvoiceKind", "Outgoing Invoice"),
          }
          ,
          createCorrectorTooltip: this.$fn.pgettext("Invoice", "Create corrector invoice"),
          createStornoTooltip: this.$fn.pgettext("Invoice", "Create storno invoice"),
        }
        ,
        ux: null,
      },
    }
  }

  get resourceTitle() {
    if (this.loaded) {

      return this.messages.invoiceKind[this.invoice.invoice_kind]
    }
    return null;
  }

  get resourceKey() {
    if (this.loaded) {

      return this.invoiceNumber || (this.invoice.isNew ? this.messages.newTransaction : this.invoice.invoice_number)
    }
    return null;
  }

  get createCorrectorTooltip() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.modifiable.message') || this.messages.createCorrectorTooltip
    }
    return null;
  }

  get createStornoTooltip() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.reversible.message') || this.messages.createStornoTooltip
    }
    return null;
  }

  get invoiceIsReversible() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.reversible.result')
    }
    return null;
  }

  get invoiceIsModifiable() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.modifiable.result')
    }
    return null;
  }

  get invoiceIsRegistrable() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.registrable.result')
    }
    return null;
  }

  async act_8680084583475136_emit_5352_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8680084583475136_emit_5352, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8680084583475136_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8680084583475136_success.executeFromDOM(this, event, scope);
  }

  async act_3282321863127091_emit_5354_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3282321863127091_emit_5354, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_3282321863127091_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3282321863127091_success.executeFromDOM(this, event, scope);
  }

  async act_2386378572842701_emit_5358_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_2386378572842701_emit_5358, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_2386378572842701_close_5357_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2386378572842701_close_5357, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_2386378572842701_showModal_5356_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2386378572842701_showModal_5356, alias=undefined
    return {
      name: "InvoiceEditor",
      props: {
        invoice: _.cloneDeep(this.invoice),
      }
      ,
    }
  }

  async evh_2386378572842701_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2386378572842701_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_6305342467955186_emit_5360_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_6305342467955186_emit_5360, alias=reload
    return {
      event: "reload",
    }
  }

  async evh_6305342467955186_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6305342467955186_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_6493473528837603_showModal_5362_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6493473528837603_showModal_5362, alias=undefined
    return {
      name: "InvoiceLogMessageViewer",
      props: {
        invoiceId: this.invoice.id,
      }
      ,
    }
  }

  async evh_6493473528837603_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6493473528837603_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_5796712150329752_emit_5366_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5796712150329752_emit_5366, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_5796712150329752_close_5365_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_5796712150329752_close_5365, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_5796712150329752_showModal_5364_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_5796712150329752_showModal_5364, alias=registerInvoice
    return {
      name: "InvoiceRegisterEntry",
      props: {
        invoice: this.invoice,
      }
      ,
    }
  }

  async evh_5796712150329752_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5796712150329752_click.executeFromDOM(this, event, scope);
  }

  async act_4125470329773403_emit_5370_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_4125470329773403_emit_5370, alias=undefined
    return {
      event: "created",
      value: $event.data.response.data,
    }
  }

  async act_4125470329773403_request_5368_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_4125470329773403_request_5368, alias=createInvoicecopy
    return {
      operation: "create_invoice",
      data: { invoice_id: this.invoice.id, invoice_type: 'copy' },
    }
  }

  async evh_4125470329773403_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4125470329773403_click.executeFromDOM(this, event, scope);
  }

  async act_3785918277944093_emit_5374_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3785918277944093_emit_5374, alias=undefined
    return {
      event: "created",
      value: $event.data.response.data,
    }
  }

  async act_3785918277944093_request_5372_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_3785918277944093_request_5372, alias=createStornoInvoice
    return {
      operation: "create_invoice",
      data: { invoice_id: this.invoice.id, invoice_type: 'storno' },
    }
  }

  async evh_3785918277944093_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3785918277944093_click.executeFromDOM(this, event, scope);
  }

  async act_2191919750581405_emit_5378_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_2191919750581405_emit_5378, alias=undefined
    return {
      event: "created",
      value: $event.data.response.data,
    }
  }

  async act_2191919750581405_request_5376_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_2191919750581405_request_5376, alias=createCorrectorIvoice
    return {
      operation: "create_invoice",
      data: {
        invoice_id: this.invoice.id,
        invoice_type: 'correction',
        invoice_lines: _.map(this.formContext.selectedInvoiceLines, p => p.id).join(',')
      },
    }
  }

  async evh_2191919750581405_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2191919750581405_click.executeFromDOM(this, event, scope);
  }

  async act_3699289115122175_emit_5380_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3699289115122175_emit_5380, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_3699289115122175_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3699289115122175_success.executeFromDOM(this, event, scope);
  }

  async act_5417874158095965_emit_5382_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5417874158095965_emit_5382, alias=undefined
    return {
      event: "deleted",
    }
  }

  async evh_5417874158095965_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5417874158095965_success.executeFromDOM(this, event, scope);
  }

  async act_8518465037810560_showModal_5384_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8518465037810560_showModal_5384, alias=undefined
    return {
      name: "entityEvents",
      props: {
        entityId: this.invoice.id,
      }
      ,
    }
  }

  async evh_8518465037810560_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8518465037810560_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_8680084583475136_emit_5352: actions.EmitAction;
    evh_8680084583475136_success_5351: actions.EventHandlerImpl;
    evh_8680084583475136_success: actions.EventHandlerGroup;
    act_3282321863127091_emit_5354: actions.EmitAction;
    evh_3282321863127091_success_5353: actions.EventHandlerImpl;
    evh_3282321863127091_success: actions.EventHandlerGroup;
    act_2386378572842701_emit_5358: actions.EmitAction;
    evh_2386378572842701_close_5357: actions.EventHandlerImpl;
    act_2386378572842701_showModal_5356: actions.ShowModalAction;
    evh_2386378572842701_clickPreventStop_5355: actions.EventHandlerImpl;
    evh_2386378572842701_clickPreventStop: actions.EventHandlerGroup;
    act_6305342467955186_emit_5360: actions.EmitAction;
    evh_6305342467955186_clickPreventStop_5359: actions.EventHandlerImpl;
    evh_6305342467955186_clickPreventStop: actions.EventHandlerGroup;
    act_6493473528837603_showModal_5362: actions.ShowModalAction;
    evh_6493473528837603_clickPreventStop_5361: actions.EventHandlerImpl;
    evh_6493473528837603_clickPreventStop: actions.EventHandlerGroup;
    act_5796712150329752_emit_5366: actions.EmitAction;
    evh_5796712150329752_close_5365: actions.EventHandlerImpl;
    act_5796712150329752_showModal_5364: actions.ShowModalAction;
    evh_5796712150329752_click_5363: actions.EventHandlerImpl;
    evh_5796712150329752_click: actions.EventHandlerGroup;
    act_4125470329773403_emit_5370: actions.EmitAction;
    evh_4125470329773403_success_5369: actions.EventHandlerImpl;
    act_4125470329773403_request_5368: actions.RequestAction;
    evh_4125470329773403_click_5367: actions.EventHandlerImpl;
    evh_4125470329773403_click: actions.EventHandlerGroup;
    act_3785918277944093_emit_5374: actions.EmitAction;
    evh_3785918277944093_success_5373: actions.EventHandlerImpl;
    act_3785918277944093_request_5372: actions.RequestAction;
    evh_3785918277944093_click_5371: actions.EventHandlerImpl;
    evh_3785918277944093_click: actions.EventHandlerGroup;
    act_2191919750581405_emit_5378: actions.EmitAction;
    evh_2191919750581405_success_5377: actions.EventHandlerImpl;
    act_2191919750581405_request_5376: actions.RequestAction;
    evh_2191919750581405_click_5375: actions.EventHandlerImpl;
    evh_2191919750581405_click: actions.EventHandlerGroup;
    act_3699289115122175_emit_5380: actions.EmitAction;
    evh_3699289115122175_success_5379: actions.EventHandlerImpl;
    evh_3699289115122175_success: actions.EventHandlerGroup;
    act_5417874158095965_emit_5382: actions.EmitAction;
    evh_5417874158095965_success_5381: actions.EventHandlerImpl;
    evh_5417874158095965_success: actions.EventHandlerGroup;
    act_8518465037810560_showModal_5384: actions.ShowModalAction;
    evh_8518465037810560_clickPreventStop_5383: actions.EventHandlerImpl;
    evh_8518465037810560_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_8680084583475136_emit_5352 = new actions.EmitAction(
      {
        actionArgs: this.act_8680084583475136_emit_5352_getActionArgs,
        events: [],
      }
    );
    const evh_8680084583475136_success_5351 = new actions.EventHandlerImpl(
      {
        action: act_8680084583475136_emit_5352,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_8680084583475136_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_8680084583475136_success_5351],
      }
    );
    const act_3282321863127091_emit_5354 = new actions.EmitAction(
      {
        actionArgs: this.act_3282321863127091_emit_5354_getActionArgs,
        events: [],
      }
    );
    const evh_3282321863127091_success_5353 = new actions.EventHandlerImpl(
      {
        action: act_3282321863127091_emit_5354,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_3282321863127091_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_3282321863127091_success_5353],
      }
    );
    const act_2386378572842701_emit_5358 = new actions.EmitAction(
      {
        actionArgs: this.act_2386378572842701_emit_5358_getActionArgs,
        events: [],
      }
    );
    const evh_2386378572842701_close_5357 = new actions.EventHandlerImpl(
      {
        action: act_2386378572842701_emit_5358,
        event: "close",
        when: this.evh_2386378572842701_close_5357_getWhen,
        displayName: "emit",
      }
    );
    const act_2386378572842701_showModal_5356 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2386378572842701_showModal_5356_getActionArgs,
        events: [evh_2386378572842701_close_5357],
      }
    );
    const evh_2386378572842701_clickPreventStop_5355 = new actions.EventHandlerImpl(
      {
        action: act_2386378572842701_showModal_5356,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_2386378572842701_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2386378572842701_clickPreventStop_5355],
      }
    );
    const act_6305342467955186_emit_5360 = new actions.EmitAction(
      {
        actionArgs: this.act_6305342467955186_emit_5360_getActionArgs,
        displayName: "reload",
        events: [],
      }
    );
    const evh_6305342467955186_clickPreventStop_5359 = new actions.EventHandlerImpl(
      {
        action: act_6305342467955186_emit_5360,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_6305342467955186_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6305342467955186_clickPreventStop_5359],
      }
    );
    const act_6493473528837603_showModal_5362 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6493473528837603_showModal_5362_getActionArgs,
        events: [],
      }
    );
    const evh_6493473528837603_clickPreventStop_5361 = new actions.EventHandlerImpl(
      {
        action: act_6493473528837603_showModal_5362,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_6493473528837603_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6493473528837603_clickPreventStop_5361],
      }
    );
    const act_5796712150329752_emit_5366 = new actions.EmitAction(
      {
        actionArgs: this.act_5796712150329752_emit_5366_getActionArgs,
        events: [],
      }
    );
    const evh_5796712150329752_close_5365 = new actions.EventHandlerImpl(
      {
        action: act_5796712150329752_emit_5366,
        event: "close",
        when: this.evh_5796712150329752_close_5365_getWhen,
        displayName: "emit",
      }
    );
    const act_5796712150329752_showModal_5364 = new actions.ShowModalAction(
      {
        actionArgs: this.act_5796712150329752_showModal_5364_getActionArgs,
        displayName: "registerInvoice",
        events: [evh_5796712150329752_close_5365],
      }
    );
    const evh_5796712150329752_click_5363 = new actions.EventHandlerImpl(
      {
        action: act_5796712150329752_showModal_5364,
        event: "click",
        displayName: "registerInvoice",
      }
    );
    const evh_5796712150329752_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_5796712150329752_click_5363],
      }
    );
    const act_4125470329773403_emit_5370 = new actions.EmitAction(
      {
        actionArgs: this.act_4125470329773403_emit_5370_getActionArgs,
        events: [],
      }
    );
    const evh_4125470329773403_success_5369 = new actions.EventHandlerImpl(
      {
        action: act_4125470329773403_emit_5370,
        event: "success",
        displayName: "emit",
      }
    );
    const act_4125470329773403_request_5368 = new actions.RequestAction(
      {
        actionArgs: this.act_4125470329773403_request_5368_getActionArgs,
        displayName: "createInvoicecopy",
        events: [evh_4125470329773403_success_5369],
      }
    );
    const evh_4125470329773403_click_5367 = new actions.EventHandlerImpl(
      {
        action: act_4125470329773403_request_5368,
        event: "click",
        displayName: "createInvoicecopy",
      }
    );
    const evh_4125470329773403_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_4125470329773403_click_5367],
      }
    );
    const act_3785918277944093_emit_5374 = new actions.EmitAction(
      {
        actionArgs: this.act_3785918277944093_emit_5374_getActionArgs,
        events: [],
      }
    );
    const evh_3785918277944093_success_5373 = new actions.EventHandlerImpl(
      {
        action: act_3785918277944093_emit_5374,
        event: "success",
        displayName: "emit",
      }
    );
    const act_3785918277944093_request_5372 = new actions.RequestAction(
      {
        actionArgs: this.act_3785918277944093_request_5372_getActionArgs,
        displayName: "createStornoInvoice",
        events: [evh_3785918277944093_success_5373],
      }
    );
    const evh_3785918277944093_click_5371 = new actions.EventHandlerImpl(
      {
        action: act_3785918277944093_request_5372,
        event: "click",
        displayName: "createStornoInvoice",
      }
    );
    const evh_3785918277944093_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_3785918277944093_click_5371],
      }
    );
    const act_2191919750581405_emit_5378 = new actions.EmitAction(
      {
        actionArgs: this.act_2191919750581405_emit_5378_getActionArgs,
        events: [],
      }
    );
    const evh_2191919750581405_success_5377 = new actions.EventHandlerImpl(
      {
        action: act_2191919750581405_emit_5378,
        event: "success",
        displayName: "emit",
      }
    );
    const act_2191919750581405_request_5376 = new actions.RequestAction(
      {
        actionArgs: this.act_2191919750581405_request_5376_getActionArgs,
        displayName: "createCorrectorIvoice",
        events: [evh_2191919750581405_success_5377],
      }
    );
    const evh_2191919750581405_click_5375 = new actions.EventHandlerImpl(
      {
        action: act_2191919750581405_request_5376,
        event: "click",
        displayName: "createCorrectorIvoice",
      }
    );
    const evh_2191919750581405_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_2191919750581405_click_5375],
      }
    );
    const act_3699289115122175_emit_5380 = new actions.EmitAction(
      {
        actionArgs: this.act_3699289115122175_emit_5380_getActionArgs,
        events: [],
      }
    );
    const evh_3699289115122175_success_5379 = new actions.EventHandlerImpl(
      {
        action: act_3699289115122175_emit_5380,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_3699289115122175_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_3699289115122175_success_5379],
      }
    );
    const act_5417874158095965_emit_5382 = new actions.EmitAction(
      {
        actionArgs: this.act_5417874158095965_emit_5382_getActionArgs,
        events: [],
      }
    );
    const evh_5417874158095965_success_5381 = new actions.EventHandlerImpl(
      {
        action: act_5417874158095965_emit_5382,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_5417874158095965_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_5417874158095965_success_5381],
      }
    );
    const act_8518465037810560_showModal_5384 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8518465037810560_showModal_5384_getActionArgs,
        events: [],
      }
    );
    const evh_8518465037810560_clickPreventStop_5383 = new actions.EventHandlerImpl(
      {
        action: act_8518465037810560_showModal_5384,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8518465037810560_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8518465037810560_clickPreventStop_5383],
      }
    );
    return {
      act_8680084583475136_emit_5352,
      evh_8680084583475136_success_5351,
      evh_8680084583475136_success,
      act_3282321863127091_emit_5354,
      evh_3282321863127091_success_5353,
      evh_3282321863127091_success,
      act_2386378572842701_emit_5358,
      evh_2386378572842701_close_5357,
      act_2386378572842701_showModal_5356,
      evh_2386378572842701_clickPreventStop_5355,
      evh_2386378572842701_clickPreventStop,
      act_6305342467955186_emit_5360,
      evh_6305342467955186_clickPreventStop_5359,
      evh_6305342467955186_clickPreventStop,
      act_6493473528837603_showModal_5362,
      evh_6493473528837603_clickPreventStop_5361,
      evh_6493473528837603_clickPreventStop,
      act_5796712150329752_emit_5366,
      evh_5796712150329752_close_5365,
      act_5796712150329752_showModal_5364,
      evh_5796712150329752_click_5363,
      evh_5796712150329752_click,
      act_4125470329773403_emit_5370,
      evh_4125470329773403_success_5369,
      act_4125470329773403_request_5368,
      evh_4125470329773403_click_5367,
      evh_4125470329773403_click,
      act_3785918277944093_emit_5374,
      evh_3785918277944093_success_5373,
      act_3785918277944093_request_5372,
      evh_3785918277944093_click_5371,
      evh_3785918277944093_click,
      act_2191919750581405_emit_5378,
      evh_2191919750581405_success_5377,
      act_2191919750581405_request_5376,
      evh_2191919750581405_click_5375,
      evh_2191919750581405_click,
      act_3699289115122175_emit_5380,
      evh_3699289115122175_success_5379,
      evh_3699289115122175_success,
      act_5417874158095965_emit_5382,
      evh_5417874158095965_success_5381,
      evh_5417874158095965_success,
      act_8518465037810560_showModal_5384,
      evh_8518465037810560_clickPreventStop_5383,
      evh_8518465037810560_clickPreventStop,
      reload: act_6305342467955186_emit_5360,
      registerInvoice: act_5796712150329752_showModal_5364,
      createInvoicecopy: act_4125470329773403_request_5368,
      createStornoInvoice: act_3785918277944093_request_5372,
      createCorrectorIvoice: act_2191919750581405_request_5376,
    }
  }
}

Vue.component("UcInvoiceHeader", UcInvoiceHeader);

</script>