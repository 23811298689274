"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "payments",
    path: "/payments",
    component: "PaymentsView"
});
let PaymentsView = class PaymentsView extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['selectedItems', 'params', 'totals', 'paymentMethods', 'ux', 'b_7315092382398562_modalBindings', 'b_6466705138206980_headerShowColumnSelectorButton', 'b_6466705138206980_headerShowReloadButton', 'b_6466705138206980_columnDefaults', 'b_6466705138206980_columns'];
    }
    beforeCreate() {
    }
    getItems(...args) {
        const parameters = this.params;
        return parameters && parameters.account && parameters.date_from
            ? this.$fn.fetch('list_payment', { parameters })
            : [];
    }
    getInvoiceBadgeClass(...args) {
        const data = args[0];
        if (_.isEmpty(data)) {
            return;
        }
        const payment_balance = data.payment_balance || 0;
        const invoice_gross_amount = data.invoice_gross_amount;
        if (payment_balance == 0) {
            return 'bg-green-200';
        }
        if (payment_balance < 0) {
            return 'bg-yellow-200';
        }
        if (payment_balance < invoice_gross_amount) {
            return 'bg-blue-200';
        }
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_paymentMethods() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_payment_method')
                .then(data => _.reduce(data, (m, v, k) => _.set(m, v.id, this.$fn.pgettext('PaymentMethod', v.name))));
        });
    }
    $$load_b_6466705138206980_columns() {
        return __awaiter(this, void 0, void 0, function* () {
            return [{
                    field: "payment_method",
                    name: this.$fn.pgettext("PaymentBrowserView", "Method"),
                    formatter: {
                        name: "mapvalue",
                        mapping: this.paymentMethods,
                    },
                },
                {
                    field: "payment_number",
                    name: this.$fn.pgettext("PaymentBrowserView", "Number"),
                    type: "command",
                    formatter: [{
                            name: "chain",
                            formatters: [{
                                    name: "link",
                                    href: "#",
                                },
                                {
                                    name: "cellclass",
                                    value: (row) => ({
                                        'font-weight-bold': true,
                                        'bg-yellow-200': !row.locked
                                    }),
                                },
                            ],
                        },
                    ],
                },
                {
                    field: "payment_date",
                    name: this.$fn.pgettext("PaymentBrowserView", "Date"),
                    type: "date",
                },
                {
                    field: "payment_category",
                    name: this.$fn.pgettext("PaymentBrowserView", "Title"),
                },
                {
                    field: "description",
                    name: this.$fn.pgettext("PaymentBrowserView", "Description"),
                },
                {
                    field: "notes",
                    name: this.$fn.pgettext("PaymentBrowserView", "Notes"),
                },
                {
                    field: "opening_balance",
                    name: this.$fn.pgettext("PaymentBrowserView", "Opening balance"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                    noFooterTotals: true,
                },
                {
                    field: "amount_in",
                    name: this.$fn.pgettext("PaymentBrowserView", "In"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "amount_out",
                    name: this.$fn.pgettext("PaymentBrowserView", "Out"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "closing_balance",
                    name: this.$fn.pgettext("PaymentBrowserView", "Closing balance"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                    noFooterTotals: true,
                },
                {
                    field: "currency",
                    name: this.$fn.pgettext("PaymentBrowserView", "Currency"),
                },
                {
                    field: "exchange_rate",
                    name: this.$fn.pgettext("PaymentBrowserView", "Exchange Rate"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                    noFooterTotals: true,
                },
                {
                    field: "reference_number",
                    name: this.$fn.pgettext("PaymentBrowserView", "Ref Nr."),
                },
                {
                    field: "file_number",
                    name: this.$fn.pgettext("PaymentBrowserView", "File Nr."),
                },
                {
                    field: "client_name",
                    name: this.$fn.pgettext("PaymentBrowserView", "Client"),
                    formatter: {
                        name: "routeLink",
                        to: "client",
                        navigateOnIconClick: true,
                        params: {
                            id: "client_id",
                        },
                    },
                },
                {
                    field: "invoices",
                    name: this.$fn.pgettext("PaymentBrowserView", "Invoices"),
                    formatter: [{
                            name: "chain",
                            mode: "mapReduce",
                            separator: ", ",
                            formatterContext: "value",
                            formatters: [{
                                    name: "getvalue",
                                    opts: {
                                        field: "invoice_number",
                                        source: "value",
                                    },
                                },
                                {
                                    name: "routeLink",
                                    to: "invoice",
                                    navigateOnIconClick: true,
                                    params: {
                                        id: "id",
                                    },
                                },
                                {
                                    name: "badge",
                                    badgeClass: this.getInvoiceBadgeClass,
                                },
                            ],
                        },
                    ],
                },
            ];
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            selectedItems: [],
            params: {},
            totals: [{
                    id: 0,
                    amount_in: 0,
                    amount_out: 0,
                },
            ],
            paymentMethods: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_6466705138206980_headerShowColumnSelectorButton: false,
            b_6466705138206980_headerShowReloadButton: false,
            b_6466705138206980_columnDefaults: {
                sortable: false,
                filterable: true,
                resizable: true,
            },
            b_6466705138206980_columns: null,
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("PaymentsView", "Payments"),
                icon: this.$config.payment.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_5886_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_6466705138206980_setData_5888_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: this.$refs.grid.getSelectedItems(),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_6466705138206980_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6466705138206980_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    act_6466705138206980_showModal_5890_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    modal: true,
                    oid: $event.data.data.id,
                },
            };
        });
    }
    evh_6466705138206980_command_5889_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.column.id === 'payment_number';
        });
    }
    evh_6466705138206980_command(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6466705138206980_command.executeFromDOM(this, event, scope);
        });
    }
    act_2285343489561581_setData_5892_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "params",
                value: $event.data,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_2285343489561581_script_5894_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.$refs.grid.reload();
        });
    }
    act_2285343489561581_script_5894_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_2285343489561581_script_5894_getActionArgs_value($event),
            };
        });
    }
    evh_2285343489561581_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2285343489561581_submit.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_5886 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_5886_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_5885 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_5886,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_5885],
        });
        const act_6466705138206980_setData_5888 = new core_1.actions.SetDataAction({
            actionArgs: this.act_6466705138206980_setData_5888_getActionArgs,
            events: [],
        });
        const evh_6466705138206980_selectedRowsChanged_5887 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_setData_5888,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        const evh_6466705138206980_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6466705138206980_selectedRowsChanged_5887],
        });
        const act_6466705138206980_showModal_5890 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6466705138206980_showModal_5890_getActionArgs,
            events: [],
        });
        const evh_6466705138206980_command_5889 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_showModal_5890,
            event: "command",
            when: this.evh_6466705138206980_command_5889_getWhen,
            displayName: "showModal",
        });
        const evh_6466705138206980_command = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6466705138206980_command_5889],
        });
        const act_2285343489561581_setData_5892 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2285343489561581_setData_5892_getActionArgs,
            events: [],
        });
        const evh_2285343489561581_submit_5891 = new core_1.actions.EventHandlerImpl({
            action: act_2285343489561581_setData_5892,
            event: "submit",
            displayName: "setData",
        });
        const act_2285343489561581_script_5894 = new core_1.actions.ScriptAction({
            actionArgs: this.act_2285343489561581_script_5894_getActionArgs,
            events: [],
        });
        const evh_2285343489561581_submit_5893 = new core_1.actions.EventHandlerImpl({
            action: act_2285343489561581_script_5894,
            event: "submit",
            displayName: "script",
        });
        const evh_2285343489561581_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2285343489561581_submit_5891, evh_2285343489561581_submit_5893],
        });
        return {
            act_7315092382398562_closeModal_5886,
            evh_7315092382398562_close_5885,
            evh_7315092382398562_close,
            act_6466705138206980_setData_5888,
            evh_6466705138206980_selectedRowsChanged_5887,
            evh_6466705138206980_selectedRowsChanged,
            act_6466705138206980_showModal_5890,
            evh_6466705138206980_command_5889,
            evh_6466705138206980_command,
            act_2285343489561581_setData_5892,
            evh_2285343489561581_submit_5891,
            act_2285343489561581_script_5894,
            evh_2285343489561581_submit_5893,
            evh_2285343489561581_submit,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], PaymentsView.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], PaymentsView.prototype, "onSize", null);
PaymentsView = __decorate([
    (0, vue_property_decorator_1.Component)()
], PaymentsView);
exports.default = PaymentsView;
vue_property_decorator_1.Vue.component("PaymentsView", PaymentsView);
