<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'Units'|pgettext('Property') }}
      </strong>
      <ItpButton
        :text="'Add unit...'|gettext"
        variant="light"
        size="sm"
        class="ml-auto"
        v-if="!parent.readonly"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup>
      <BListGroupItem
        v-if="!parent.units || !parent.units.length"
      >
        <ItpText>
          {{ 'No units have been added yet.'|pgettext('Property') }}
        </ItpText>
      </BListGroupItem>
      <BListGroupItem
        :key="item.id"
        v-for="item in _.orderBy(parent.units, ['sort_order', 'name'])"
      >
        <ItpBox
          alignItems="center"
        >
          <ItpText
            :text="item.name"
            class="font-weight-bold"
          >
          </ItpText>
          <ItpIcon
            :title="'Locked'|gettext"
            name="fa-lock"
            class="px-3"
            v-if="item.locked"
          >
          </ItpIcon>
          <ItpButton
            icon="fa-pen"
            :tooltip="'Edit'|gettext"
            variant="light"
            size="sm"
            class="ml-auto"
            v-if="!parent.readonly"
            @click.prevent.stop="evh_2585509888553580_clickPreventStop($event, {item})"
          >
          </ItpButton>
        </ItpBox>
        <ItpBox>
          <ItpText
            :text="item.description"
            pre
          >
          </ItpText>
        </ItpBox>
      </BListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcPropertyUnits extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  parent!: object;

  @Watch('parent')
  onParent(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("parent")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_showModal_5594_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_5594, alias=undefined
    return {
      name: "PropertyUnitEditor",
      props: {
        mode: "add",
        parent: this.parent,
      }
      ,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_2585509888553580_emit_5598_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_2585509888553580_emit_5598, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_2585509888553580_close_5597_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2585509888553580_close_5597, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_2585509888553580_showModal_5596_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2585509888553580_showModal_5596, alias=undefined
    return {
      name: "PropertyUnitEditor",
      props: {
        parent: this.parent,
        item: $event.scope.item,
      }
      ,
    }
  }

  async evh_2585509888553580_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2585509888553580_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5592: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5591: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8320016629450276_showModal_5594: actions.ShowModalAction;
    evh_8320016629450276_clickPreventStop_5593: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
    act_2585509888553580_emit_5598: actions.EmitAction;
    evh_2585509888553580_close_5597: actions.EventHandlerImpl;
    act_2585509888553580_showModal_5596: actions.ShowModalAction;
    evh_2585509888553580_clickPreventStop_5595: actions.EventHandlerImpl;
    evh_2585509888553580_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5592 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5591 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5592,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5591],
      }
    );
    const act_8320016629450276_showModal_5594 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_5594_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_clickPreventStop_5593 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_5594,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_5593],
      }
    );
    const act_2585509888553580_emit_5598 = new actions.EmitAction(
      {
        actionArgs: this.act_2585509888553580_emit_5598_getActionArgs,
        events: [],
      }
    );
    const evh_2585509888553580_close_5597 = new actions.EventHandlerImpl(
      {
        action: act_2585509888553580_emit_5598,
        event: "close",
        when: this.evh_2585509888553580_close_5597_getWhen,
        displayName: "emit",
      }
    );
    const act_2585509888553580_showModal_5596 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2585509888553580_showModal_5596_getActionArgs,
        events: [evh_2585509888553580_close_5597],
      }
    );
    const evh_2585509888553580_clickPreventStop_5595 = new actions.EventHandlerImpl(
      {
        action: act_2585509888553580_showModal_5596,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_2585509888553580_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2585509888553580_clickPreventStop_5595],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5592,
      evh_7315092382398562_reload_5591,
      evh_7315092382398562_reload,
      act_8320016629450276_showModal_5594,
      evh_8320016629450276_clickPreventStop_5593,
      evh_8320016629450276_clickPreventStop,
      act_2585509888553580_emit_5598,
      evh_2585509888553580_close_5597,
      act_2585509888553580_showModal_5596,
      evh_2585509888553580_clickPreventStop_5595,
      evh_2585509888553580_clickPreventStop,
    }
  }
}

Vue.component("UcPropertyUnits", UcPropertyUnits);

</script>