"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "invoices",
    path: "/invoices",
    component: "InvoicesView",
    params: [{
            name: "kind",
            prop: "kind",
        },
    ]
});
let InvoicesView = class InvoicesView extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['titles', 'selectedItems', 'params', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_headerShowReloadButton', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];
    }
    beforeCreate() {
    }
    getItems(...args) {
        const parameters = this.params;
        return parameters ? this.$fn.fetch('list_invoice', { parameters }) : [];
    }
    invoiceTypeBadgeClass(...args) {
        const [row] = args;
        const cc = this.$config.invoice.types.colorCodes[row.invoice_type];
        return `bg-${cc.bg} text-${cc.text}`;
    }
    onPrintInvoicesPayload(...args) {
        return this.$imodal
            .show({ name: 'CompleteInvoicesAlt' })
            .then(result => {
            if (!result || result.cancel || !result.value) {
                return;
            }
            return this.selectedItems.map(p => (Object.assign(Object.assign({}, result.value), { invoice_id: p.id })));
        });
    }
    onKind(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("kind");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_b_2248226175642056_columns() {
        return __awaiter(this, void 0, void 0, function* () {
            return [{
                    field: "invoice_number",
                    name: this.$fn.pgettext("InvoicesView", "Invoice nr"),
                    type: "command",
                    formatter: [{
                            name: "chain",
                            formatters: [{
                                    name: "routeLink",
                                    to: "invoice",
                                    params: {
                                        id: "id",
                                    },
                                },
                                {
                                    name: "cellclass",
                                    value: (row) => ({
                                        'font-weight-bold': true,
                                        'bg-yellow-200': !row.locked,
                                        'bg-yellow-500 text-yellow-800': row.is_corrected,
                                        'bg-red-300 text-red-800': row.is_reversed,
                                    }),
                                },
                            ],
                        },
                    ],
                },
                {
                    field: "invoice_type",
                    name: this.$fn.pgettext("InvoicesView", "Type"),
                    formatter: {
                        name: "chain",
                        formatters: [{
                                name: "gettext",
                                context: "InvoiceType",
                                conversion: "StartCase",
                                filterInput: true,
                            },
                            {
                                name: "badge",
                                dot: true,
                                pill: true,
                                badgeClass: this.invoiceTypeBadgeClass,
                            },
                        ],
                    },
                },
                {
                    field: "invoice_status",
                    name: this.$fn.pgettext("InvoicesView", "Status"),
                    formatter: {
                        name: "chain",
                        formatters: [{
                                name: "gettext",
                                context: "Status",
                                conversion: "StartCase",
                                filterInput: true,
                            },
                            {
                                name: "badge",
                                variantmap: this.$config.invoice.invoiceStatusVariants,
                                variantMapSource: "fieldValue",
                            },
                        ],
                    },
                },
                {
                    field: "invoice_register_entry",
                    name: this.$fn.pgettext("InvoicesView", "Register entry"),
                    type: "command",
                    formatter: {
                        name: "chain",
                        mode: "mapReduce",
                        separator: ", ",
                        formatterContext: "item",
                        formatters: [{
                                name: "getvalue",
                                opts: {
                                    field: "entry_number",
                                    source: "value",
                                },
                            },
                            {
                                name: "link",
                                href: "#",
                            },
                            {
                                name: "cellclass",
                                value: (value) => ({
                                    'font-weight-bold': true,
                                    'bg-yellow-200': value && !value.locked
                                }),
                            },
                        ],
                    },
                },
                {
                    field: "invoice_date",
                    name: this.$fn.pgettext("InvoicesView", "Date"),
                },
                {
                    field: "fulfillment_date",
                    name: this.$fn.pgettext("InvoicesView", "Fulfillment date"),
                    type: "date",
                },
                {
                    field: "due_date",
                    name: this.$fn.pgettext("InvoicesView", "Due date"),
                    type: "date",
                },
                {
                    field: "client_name",
                    name: this.$fn.pgettext("InvoicesView", "Client"),
                    formatter: {
                        name: "routeLink",
                        to: "client",
                        navigateOnIconClick: true,
                        params: {
                            id: "client_id",
                        },
                    },
                },
                {
                    field: "description",
                    name: this.$fn.pgettext("InvoicesView", "Description"),
                },
                {
                    field: "net_total",
                    name: this.$fn.pgettext("InvoicesView", "Net"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "vat_total",
                    name: this.$fn.pgettext("InvoicesView", "VAT"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "total",
                    name: this.$fn.pgettext("InvoicesView", "Total"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "paid",
                    name: this.$fn.pgettext("InvoicesView", "Paid"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "unpaid",
                    name: this.$fn.pgettext("InvoicesView", "Claim"),
                    type: "number",
                    formatter: {
                        name: "chain",
                        formatters: [{
                                name: "localestring",
                                locale: "hu-HU",
                            },
                            {
                                name: "cellclass",
                                value: (row) => ({
                                    'text-red-500': row.unpaid != null && row.unpaid > 0,
                                    'text-green-500': row.unpaid != null && row.unpaid <= 0,
                                    'font-weight-bold': row.unpaid != null
                                }),
                            },
                        ],
                    },
                },
                {
                    field: "currency",
                    name: this.$fn.pgettext("InvoicesView", "Currency"),
                },
                {
                    field: "tags",
                    name: this.$fn.pgettext("InvoicesView", "Tags"),
                },
                {
                    field: "rentals",
                    name: this.$fn.pgettext("InvoicesView", "Rentals"),
                },
                {
                    field: "contracts",
                    name: this.$fn.pgettext("InvoicesView", "Contracts"),
                },
            ];
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            titles: {
                incoming: this.$fn.pgettext("InvoicesView", "Vendor invoices"),
                outgoing: this.$fn.pgettext("InvoicesView", "Customer invoices"),
            },
            selectedItems: [],
            params: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_2248226175642056_headerShowReloadButton: false,
            b_2248226175642056_columnDefaults: {
                sortable: true,
                filterable: true,
                resizable: true,
            },
            b_2248226175642056_columns: null,
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.titles[this.kind],
                icon: this.$config.invoice.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_5394_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_setData_5396_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: this.$refs.grid.getSelectedItems(),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2248226175642056_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_script_5400_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.$refs.grid.reload();
        });
    }
    act_2248226175642056_script_5400_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_2248226175642056_script_5400_getActionArgs_value($event),
            };
        });
    }
    act_2248226175642056_showModal_5398_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "InvoiceRegisterEntry",
                props: {
                    oid: $event.data.data.invoice_register_entry.id,
                },
            };
        });
    }
    evh_2248226175642056_command_5397_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.column.id === 'invoice_register_entry' && $event.data.data.invoice_register_entry != null;
        });
    }
    evh_2248226175642056_command(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
        });
    }
    act_6466705138206980_showModal_5402_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateInvoice",
                props: {
                    invoiceKind: this.kind,
                },
            };
        });
    }
    evh_6466705138206980_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6466705138206980_click.executeFromDOM(this, event, scope);
        });
    }
    act_8320016629450276_showModal_5404_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ImportInvoices",
            };
        });
    }
    evh_8320016629450276_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_6806515264385235_setData_5406_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "params",
                value: Object.assign(Object.assign({}, $event.data), { kind: this.kind }),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_6806515264385235_script_5408_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.$refs.grid.reload();
        });
    }
    act_6806515264385235_script_5408_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_6806515264385235_script_5408_getActionArgs_value($event),
            };
        });
    }
    evh_6806515264385235_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6806515264385235_submit.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_5394 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_5394_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_5393 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_5394,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_5393],
        });
        const act_2248226175642056_setData_5396 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_5396_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_selectedRowsChanged_5395 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_5396,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        const evh_2248226175642056_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_selectedRowsChanged_5395],
        });
        const act_2248226175642056_script_5400 = new core_1.actions.ScriptAction({
            actionArgs: this.act_2248226175642056_script_5400_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_saved_5399 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_script_5400,
            event: "saved",
            displayName: "script",
        });
        const act_2248226175642056_showModal_5398 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2248226175642056_showModal_5398_getActionArgs,
            events: [evh_2248226175642056_saved_5399],
        });
        const evh_2248226175642056_command_5397 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_showModal_5398,
            event: "command",
            when: this.evh_2248226175642056_command_5397_getWhen,
            displayName: "showModal",
        });
        const evh_2248226175642056_command = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_command_5397],
        });
        const act_6466705138206980_showModal_5402 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6466705138206980_showModal_5402_getActionArgs,
            events: [],
        });
        const evh_6466705138206980_click_5401 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_showModal_5402,
            event: "click",
            displayName: "showModal",
        });
        const evh_6466705138206980_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6466705138206980_click_5401],
        });
        const act_8320016629450276_showModal_5404 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8320016629450276_showModal_5404_getActionArgs,
            events: [],
        });
        const evh_8320016629450276_clickPrevent_5403 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_showModal_5404,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_8320016629450276_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_clickPrevent_5403],
        });
        const act_6806515264385235_setData_5406 = new core_1.actions.SetDataAction({
            actionArgs: this.act_6806515264385235_setData_5406_getActionArgs,
            events: [],
        });
        const evh_6806515264385235_submit_5405 = new core_1.actions.EventHandlerImpl({
            action: act_6806515264385235_setData_5406,
            event: "submit",
            displayName: "setData",
        });
        const act_6806515264385235_script_5408 = new core_1.actions.ScriptAction({
            actionArgs: this.act_6806515264385235_script_5408_getActionArgs,
            events: [],
        });
        const evh_6806515264385235_submit_5407 = new core_1.actions.EventHandlerImpl({
            action: act_6806515264385235_script_5408,
            event: "submit",
            displayName: "script",
        });
        const evh_6806515264385235_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6806515264385235_submit_5405, evh_6806515264385235_submit_5407],
        });
        return {
            act_7315092382398562_closeModal_5394,
            evh_7315092382398562_close_5393,
            evh_7315092382398562_close,
            act_2248226175642056_setData_5396,
            evh_2248226175642056_selectedRowsChanged_5395,
            evh_2248226175642056_selectedRowsChanged,
            act_2248226175642056_script_5400,
            evh_2248226175642056_saved_5399,
            act_2248226175642056_showModal_5398,
            evh_2248226175642056_command_5397,
            evh_2248226175642056_command,
            act_6466705138206980_showModal_5402,
            evh_6466705138206980_click_5401,
            evh_6466705138206980_click,
            act_8320016629450276_showModal_5404,
            evh_8320016629450276_clickPrevent_5403,
            evh_8320016629450276_clickPrevent,
            act_6806515264385235_setData_5406,
            evh_6806515264385235_submit_5405,
            act_6806515264385235_script_5408,
            evh_6806515264385235_submit_5407,
            evh_6806515264385235_submit,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], InvoicesView.prototype, "kind", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('kind'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoicesView.prototype, "onKind", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], InvoicesView.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoicesView.prototype, "onSize", null);
InvoicesView = __decorate([
    (0, vue_property_decorator_1.Component)()
], InvoicesView);
exports.default = InvoicesView;
vue_property_decorator_1.Vue.component("InvoicesView", InvoicesView);
