<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpButton
    icon="fa-list-alt"
    variant="default"
    :id="triggerButtonID"
  >
  </ItpButton>
  <b-popover
    :target="triggerButtonID"
    triggers="click blur"
    :title="currentTitle"
  >
    <ItpFormGroup
      name="currency_to"
      labelColsMd="4"
      contentColsMd="8"
      :label="'Currency'|gettext"
      :labelAlign="labelAlign"
    >
      <ItpFormSelect2
        name="currency_to"
        valueField="code"
        textField="code"
        required
        v-model="item.currency_to"
        :options="currencies"
      >
      </ItpFormSelect2>
    </ItpFormGroup>
    <ItpFormGroup
      labelColsMd="4"
      contentColsMd="8"
      name="value"
      :label="'Amount'|gettext"
    >
      <ItpFormInput
        type="number"
        name="value"
        v-model="item.value"
      >
      </ItpFormInput>
    </ItpFormGroup>
    <ItpFormGroup
      labelColsMd="4"
      contentColsMd="8"
      name="date"
      :label="'Date'|gettext"
    >
      <ItpFormDatetime
        name="date"
        v-model="item.date"
      >
      </ItpFormDatetime>
    </ItpFormGroup>
    <ItpFormGroup
      labelColsMd="4"
      contentColsMd="8"
      name="bank"
      :label="'Bank'|gettext"
    >
      <ItpFormInput
        name="bank"
        v-model="item.bank"
      >
      </ItpFormInput>
    </ItpFormGroup>
  </b-popover>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceExchangeRateEditor extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: String,
  })
  currency!: string;

  @Watch('currency')
  onCurrency(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("currency")
  }

  @Prop({
    type: Object,
  })
  value!: object;

  @Watch('value')
  onValue(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("value")
  }

  labelAlign!: any;
  item!: any;

  async $$load_item() {
    return _.extend({
      currency_to: null,
      value: 1,
      date: this.$fn.today(),
      bank: null
    }, this.value, { currency_from: this.currency })
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  currencies!: any;

  async $$load_currencies() {
    return this.$fn.fetch('list_currency')
  }

  triggerButtonID!: any;

  async $$load_triggerButtonID() {
    return this.$fn.randomElementId()
  }

  currentTitle!: any;

  async $$load_currentTitle() {
    return this.$fn.gettext('Exchange rate') + ' - ' + this.currency
  }

  ux!: any;
  dataMembers = ['labelAlign', 'item', 'formId', 'currencies', 'triggerButtonID', 'currentTitle', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        labelAlign: "right",
        item: null,
        formId: null,
        currencies: null,
        triggerButtonID: null,
        currentTitle: null,
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5350: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5349: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5350 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5349 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5350,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5349],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5350,
      evh_7315092382398562_reload_5349,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcInvoiceExchangeRateEditor", UcInvoiceExchangeRateEditor);

</script>