"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let ClientDataImportModal = class ClientDataImportModal extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "modal",
        };
        this.dataMembers = ['file', 'headered', 'colnum_number', 'colnum_name', 'colnum_email', 'ux'];
    }
    beforeCreate() {
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            file: null,
            headered: true,
            colnum_number: 1,
            colnum_name: 2,
            colnum_email: 3,
            ux: null,
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.gettext("Import client data"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_1742_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_5534025912102772_register_1748_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "resultLength",
                value: $event.data.response.data ? $event.data.response.data.updated_records : 0,
            };
        });
    }
    act_5534025912102772_notification_1750_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                message: this.$fn.ngettext("One record imported.", "${n} records imported.", $event.data.resultLength),
            };
        });
    }
    act_5534025912102772_notification_1750_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.resultLength > 0;
        });
    }
    act_5534025912102772_notification_1752_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                message: this.$fn.gettext("Nothing has been imported."),
            };
        });
    }
    act_5534025912102772_notification_1752_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !$event.data.resultLength;
        });
    }
    act_5534025912102772_closeModal_1754_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    value: $event.data.response.data,
                },
            };
        });
    }
    act_5534025912102772_reloadSlickgrid_1756_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "all",
            };
        });
    }
    act_5534025912102772_request_1746_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "import_clients",
                data: { header: this.headered, file: this.file, colnum_number: this.colnum_number, colnum_name: this.colnum_name, colnum_email: this.colnum_email },
            };
        });
    }
    evh_5534025912102772_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5534025912102772_submit.executeFromDOM(this, event, scope);
        });
    }
    act_1419464017721962_closeModal_1758_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                },
            };
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_1742 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_1742_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_1741 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_1742,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_1741],
        });
        const act_2248226175642056_closeComponent_1744 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_1743 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_1744,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_1743],
        });
        const act_5534025912102772_register_1748 = new core_1.actions.RegisterAction({
            actionArgs: this.act_5534025912102772_register_1748_getActionArgs,
            events: [],
        });
        const evh_5534025912102772_success_1747 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_register_1748,
            event: "success",
            displayName: "register",
        });
        const act_5534025912102772_notification_1750 = new core_1.actions.NotificationAction({
            actionArgs: this.act_5534025912102772_notification_1750_getActionArgs,
            when: this.act_5534025912102772_notification_1750_getWhen,
            events: [],
        });
        const evh_5534025912102772_success_1749 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_notification_1750,
            event: "success",
            displayName: "notification",
        });
        const act_5534025912102772_notification_1752 = new core_1.actions.NotificationAction({
            actionArgs: this.act_5534025912102772_notification_1752_getActionArgs,
            when: this.act_5534025912102772_notification_1752_getWhen,
            events: [],
        });
        const evh_5534025912102772_success_1751 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_notification_1752,
            event: "success",
            displayName: "notification",
        });
        const act_5534025912102772_closeModal_1754 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_5534025912102772_closeModal_1754_getActionArgs,
            events: [],
        });
        const evh_5534025912102772_success_1753 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_closeModal_1754,
            event: "success",
            displayName: "closeModal",
        });
        const act_5534025912102772_reloadSlickgrid_1756 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_5534025912102772_reloadSlickgrid_1756_getActionArgs,
            events: [],
        });
        const evh_5534025912102772_success_1755 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_reloadSlickgrid_1756,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_5534025912102772_request_1746 = new core_1.actions.RequestAction({
            actionArgs: this.act_5534025912102772_request_1746_getActionArgs,
            displayName: "submit",
            events: [evh_5534025912102772_success_1747, evh_5534025912102772_success_1749, evh_5534025912102772_success_1751, evh_5534025912102772_success_1753, evh_5534025912102772_success_1755],
        });
        const evh_5534025912102772_submit_1745 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_request_1746,
            event: "submit",
            displayName: "submit",
        });
        const evh_5534025912102772_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5534025912102772_submit_1745],
        });
        const act_1419464017721962_closeModal_1758 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1419464017721962_closeModal_1758_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_1757 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeModal_1758,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_1757],
        });
        return {
            act_7315092382398562_closeModal_1742,
            evh_7315092382398562_close_1741,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_1744,
            evh_2248226175642056_close_1743,
            evh_2248226175642056_close,
            act_5534025912102772_register_1748,
            evh_5534025912102772_success_1747,
            act_5534025912102772_notification_1750,
            evh_5534025912102772_success_1749,
            act_5534025912102772_notification_1752,
            evh_5534025912102772_success_1751,
            act_5534025912102772_closeModal_1754,
            evh_5534025912102772_success_1753,
            act_5534025912102772_reloadSlickgrid_1756,
            evh_5534025912102772_success_1755,
            act_5534025912102772_request_1746,
            evh_5534025912102772_submit_1745,
            evh_5534025912102772_submit,
            act_1419464017721962_closeModal_1758,
            evh_1419464017721962_clickPreventStop_1757,
            evh_1419464017721962_clickPreventStop,
            submit: act_5534025912102772_request_1746,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], ClientDataImportModal.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ClientDataImportModal.prototype, "onSize", null);
ClientDataImportModal = __decorate([
    (0, vue_property_decorator_1.Component)()
], ClientDataImportModal);
exports.default = ClientDataImportModal;
vue_property_decorator_1.Vue.component("ClientDataImportModal", ClientDataImportModal);
