<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="InvoiceBrowser"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :color="$config.invoice.color"
    :title="documentTitle"
    slot="header"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    full-height
    v-if="loaded"
  >
    <ItpBox
      direction="column"
      class="h-100 w-100 overflow-hidden"
    >
      <ItpForm
        name="invoiceBrowser"
        :id="formId"
        v-if="loaded"
        @submit="evh_5534025912102772_submit($event, {})"
      >
        <DIV
          class="row align-items-center mb-3"
        >
          <ItpFormSelect2
            name="company"
            v-model="filters.company_id"
            :dataSource="b_5731695935601903_dataSource"
            valueField="id"
            textField="name"
            :placeholder="'Company'|pgettext('InvoiceBrowser')"
            class="col-4"
          >
          </ItpFormSelect2>
          <ItpFormSelect2
            name="kind"
            v-model="filters.kind"
            :options="invoiceKinds"
            valueField="id"
            textField="text"
            clearable
            :placeholder="'Invoice kind'|pgettext('InvoiceBrowser')"
            class="col-3"
          >
          </ItpFormSelect2>
        </DIV>
        <DIV
          class="row align-items-center mb-3"
        >
          <ItpFormSelect2
            name="client"
            v-model="filters.client_id"
            :dataSource="b_3054425420812800_dataSource"
            valueField="id"
            textField="name"
            :placeholder="'Select client'|pgettext('InvoiceBrowser')"
            clearable
            optionTemplate="<UcSuggestClientOption :option=option></UcSuggestClientOption>"
            use-option-template-for-selected-option
            class="col-4"
          >
          </ItpFormSelect2>
          <DIV
            class="col-3 form-row"
          >
            <ItpFormDatetime
              name="date_from"
              v-model="filters.date_from"
              :placeholder="'Date From'|pgettext('InvoiceBrowser')"
              required
              class="col"
            >
            </ItpFormDatetime>
            <ItpFormDatetime
              name="date_to"
              v-model="filters.date_to"
              :placeholder="'Date To'|pgettext('InvoiceBrowser')"
              clearable
              class="col"
            >
            </ItpFormDatetime>
          </DIV>
          <DIV
            class="col form-row align-items-center"
          >
            <ItpFormCheckbox
              name="unpaid"
              v-model="filters.unpaid"
              :label="'Unpaid'|pgettext('InvoiceBrowser')"
              inline
            >
            </ItpFormCheckbox>
            <ItpButton
              type="submit"
              icon="fa-search"
              :text="'Search'|gettext"
              size="md"
              variant="light"
              :spinning="actions.search.isRunning"
              :disabled="!forms.invoiceBrowser || !forms.invoiceBrowser.flags.valid"
            >
            </ItpButton>
          </DIV>
        </DIV>
      </ItpForm>
      <DIV
        class="h-100"
      >
        <VueSlickgrid
          name="invoiceBrowserInvoices"
          :items="invoices"
          checkbox-row-selection
          no-header
          :editable="b_2070266589801014_editable"
          :columnDefaults="b_2070266589801014_columnDefaults"
          :columns="b_2070266589801014_columns"
          class="h-100"
          @selectedRowsChanged="evh_2070266589801014_selectedRowsChanged($event, {})"
        >
        </VueSlickgrid>
      </DIV>
    </ItpBox>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
  >
    <ItpButton
      icon="fa-check-square"
      :text="'Add Selection'|gettext"
      size="md"
      variant="primary"
      :disabled="!selectedItems || !selectedItems.length"
      class="ml-auto"
      @click="evh_1548630417156826_click($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class InvoiceBrowser extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  invoiceTypeBadgeClass(...args: any[]) {
    const [row] = args;
    const cc = this.$config.invoice.types.colorCodes[row.invoice_type];
    return `bg-${cc.bg} text-${cc.text}`
  }

  @Prop({
    type: Boolean,
  })
  multiselect!: boolean;

  @Watch('multiselect')
  onMultiselect(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("multiselect")
  }

  @Prop({
    type: String,
  })
  kind!: string;

  @Watch('kind')
  onKind(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("kind")
  }

  @Prop({
    type: String,
  })
  company_id!: string;

  @Watch('company_id')
  onCompany_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("company_id")
  }

  @Prop({
    type: String,
  })
  client_id!: string;

  @Watch('client_id')
  onClient_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("client_id")
  }

  @Prop({
    type: String,
  })
  invoice_number!: string;

  @Watch('invoice_number')
  onInvoice_number(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice_number")
  }

  @Prop({
  })
  invoice_date!: date;

  @Watch('invoice_date')
  onInvoice_date(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice_date")
  }

  @Prop({
    type: String,
    default: "full-screen",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  selectedItems!: any;
  invoices!: any;
  selectedInvoices!: any;
  filters!: any;

  async $$load_filters() {
    return {
      date_from: this.invoice_date,
      date_to: null,
      kind: this.kind ? [this.kind] : [],
      company_id: this.company_id,
      client_id: this.client_id,
      unpaid: true
    }
  }

  invoiceKinds!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_5731695935601903_dataSource!: any;
  b_3054425420812800_dataSource!: any;
  b_2070266589801014_editable!: any;
  b_2070266589801014_columnDefaults!: any;
  b_2070266589801014_columns!: any;

  async $$load_b_2070266589801014_columns() {
    return [{
      field: "invoice_kind",
      name: this.$fn.pgettext("InvoicesView", "Kind"),
      formatter: {
        name: "mapvalue",
        mapping: {
          incoming: this.$fn.pgettext("InvoiceKind", "In"),
          outgoing: this.$fn.pgettext("InvoiceKind", "Out"),
        }
        ,
      }
      ,
    }
      ,
    {
      field: "invoice_number",
      name: this.$fn.pgettext("InvoicesView", "Invoice nr"),
      formatter: {
        name: "cellclass",
        value: (row) => ({
          'font-weight-bold': true,
          'bg-yellow-200': !row.locked,
          'bg-yellow-500 text-yellow-800': row.is_corrected,
          'bg-red-300 text-red-800': row.is_reversed,
        }),
      }
      ,
    }
      ,
    {
      field: "invoice_type",
      name: this.$fn.pgettext("InvoicesView", "Type"),
      formatter: {
        name: "chain",
        formatters: [{
          name: "gettext",
          context: "InvoiceType",
          conversion: "StartCase",
          filterInput: true,
        }
          ,
        {
          name: "badge",
          dot: true,
          pill: true,
          badgeClass: this.invoiceTypeBadgeClass,
        }
          ,
        ]
        ,
      }
      ,
    }
      ,
    {
      field: "invoice_date",
      name: this.$fn.pgettext("InvoicesView", "Date"),
    }
      ,
    {
      field: "fulfillment_date",
      name: this.$fn.pgettext("InvoicesView", "Fulfillment date"),
      type: "date",
    }
      ,
    {
      field: "due_date",
      name: this.$fn.pgettext("InvoicesView", "Due date"),
      type: "date",
    }
      ,
    {
      field: "invoice_status",
      name: this.$fn.pgettext("InvoicesView", "Status"),
      formatter: {
        name: "gettext",
        context: "Status",
        conversion: "StartCase",
        filterInput: true,
      }
      ,
    }
      ,
    {
      field: "invoice_register_entry",
      name: this.$fn.pgettext("InvoicesView", "Register entry"),
      formatter: {
        name: "getvalue",
        field: "entry_number",
        source: "value",
      }
      ,
    }
      ,
    {
      field: "client_name",
      name: this.$fn.pgettext("InvoicesView", "Client"),
      formatter: {
        name: "routeLink",
        to: "client",
        navigateOnIconClick: true,
        params: {
          id: "client_id",
        }
        ,
      }
      ,
    }
      ,
    {
      field: "description",
      name: this.$fn.pgettext("InvoicesView", "Description"),
    }
      ,
    {
      field: "net_total",
      name: this.$fn.pgettext("InvoicesView", "Net"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "total",
      name: this.$fn.pgettext("InvoicesView", "Amount"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "paid",
      name: this.$fn.pgettext("InvoicesView", "Paid"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "unpaid",
      name: this.$fn.pgettext("InvoicesView", "Claim"),
      type: "number",
      formatter: {
        name: "chain",
        formatters: [{
          name: "localestring",
          locale: "hu-HU",
        }
          ,
        {
          name: "cellclass",
          value: (row) => ({
            'text-red-500': row.unpaid != null && row.unpaid > 0,
            'text-green-500': row.unpaid != null && row.unpaid <= 0,
            'font-weight-bold': row.unpaid != null
          }),
        }
          ,
        ]
        ,
      }
      ,
    }
      ,
    {
      field: "currency",
      name: this.$fn.pgettext("InvoicesView", "Currency"),
    }
      ,
    {
      field: "tags",
      name: this.$fn.pgettext("InvoicesView", "Tags"),
    }
      ,
    {
      field: "rentals",
      name: this.$fn.pgettext("InvoicesView", "Rentals"),
    }
      ,
    {
      field: "contracts",
      name: this.$fn.pgettext("InvoicesView", "Contracts"),
    }
      ,
    ]
  }

  dataMembers = ['formId', 'selectedItems', 'invoices', 'selectedInvoices', 'filters', 'invoiceKinds', 'ux', 'b_7315092382398562_modalBindings', 'b_5731695935601903_dataSource', 'b_3054425420812800_dataSource', 'b_2070266589801014_editable', 'b_2070266589801014_columnDefaults', 'b_2070266589801014_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        selectedItems: null,
        invoices: [
        ]
        ,
        selectedInvoices: [
        ]
        ,
        filters: null,
        invoiceKinds: [{
          id: "incoming",
          text: this.$fn.pgettext("InvoiceKind", "Incoming"),
        }
          ,
        {
          id: "outgoing",
          text: this.$fn.pgettext("InvoiceKind", "Outgoing"),
        }
          ,
        ]
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "full-screen",
        }
        ,
        b_5731695935601903_dataSource: {
          name: "suggest_company",
          parameters: {
            owned: true,
          }
          ,
        }
        ,
        b_3054425420812800_dataSource: {
          name: "suggest_client",
        }
        ,
        b_2070266589801014_editable: false,
        b_2070266589801014_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_2070266589801014_columns: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("InvoiceBrowser", "Browse Invoices"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3096_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3096, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_5534025912102772_setData_3102_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_5534025912102772_setData_3102, alias=undefined
    return {
      path: "invoices",
      value: $event.data.response.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_5534025912102772_cache_3104_getActionArgs($event: actions.ActionEvent): Promise<actions.CacheActionArgs> {
    // parameterResolver name=act_5534025912102772_cache_3104, alias=undefined
    return {
      path: "filters",
    }
  }

  async act_5534025912102772_request_3100_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_5534025912102772_request_3100, alias=search
    return {
      operation: "list_invoice",
      parameters: _.pickBy(this.filters, v => v || !_.isEmpty(v)),
    }
  }

  async evh_5534025912102772_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5534025912102772_submit.executeFromDOM(this, event, scope);
  }

  async act_2070266589801014_setData_3106_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2070266589801014_setData_3106, alias=undefined
    return {
      path: "selectedItems",
      value: $event.data.sender.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2070266589801014_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2070266589801014_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_1548630417156826_closeComponent_3108_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1548630417156826_closeComponent_3108, alias=undefined
    return {
      result: {
        ok: true,
        value: this.multiselect ? this.selectedItems : this.selectedItems[0],
      }
      ,
    }
  }

  async evh_1548630417156826_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3096: actions.CloseModalAction;
    evh_7315092382398562_close_3095: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_3098: actions.CloseComponentAction;
    evh_2248226175642056_close_3097: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_5534025912102772_setData_3102: actions.SetDataAction;
    evh_5534025912102772_success_3101: actions.EventHandlerImpl;
    act_5534025912102772_cache_3104: actions.CacheAction;
    evh_5534025912102772_success_3103: actions.EventHandlerImpl;
    act_5534025912102772_request_3100: actions.RequestAction;
    evh_5534025912102772_submit_3099: actions.EventHandlerImpl;
    evh_5534025912102772_submit: actions.EventHandlerGroup;
    act_2070266589801014_setData_3106: actions.SetDataAction;
    evh_2070266589801014_selectedRowsChanged_3105: actions.EventHandlerImpl;
    evh_2070266589801014_selectedRowsChanged: actions.EventHandlerGroup;
    act_1548630417156826_closeComponent_3108: actions.CloseComponentAction;
    evh_1548630417156826_click_3107: actions.EventHandlerImpl;
    evh_1548630417156826_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3096 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3096_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3095 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3096,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3095],
      }
    );
    const act_2248226175642056_closeComponent_3098 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_3097 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_3098,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_3097],
      }
    );
    const act_5534025912102772_setData_3102 = new actions.SetDataAction(
      {
        actionArgs: this.act_5534025912102772_setData_3102_getActionArgs,
        events: [],
      }
    );
    const evh_5534025912102772_success_3101 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_setData_3102,
        event: "success",
        displayName: "setData",
      }
    );
    const act_5534025912102772_cache_3104 = new actions.CacheAction(
      {
        actionArgs: this.act_5534025912102772_cache_3104_getActionArgs,
        events: [],
      }
    );
    const evh_5534025912102772_success_3103 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_cache_3104,
        event: "success",
        displayName: "cache",
      }
    );
    const act_5534025912102772_request_3100 = new actions.RequestAction(
      {
        actionArgs: this.act_5534025912102772_request_3100_getActionArgs,
        displayName: "search",
        events: [evh_5534025912102772_success_3101, evh_5534025912102772_success_3103],
      }
    );
    const evh_5534025912102772_submit_3099 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_request_3100,
        event: "submit",
        displayName: "search",
      }
    );
    const evh_5534025912102772_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_5534025912102772_submit_3099],
      }
    );
    const act_2070266589801014_setData_3106 = new actions.SetDataAction(
      {
        actionArgs: this.act_2070266589801014_setData_3106_getActionArgs,
        events: [],
      }
    );
    const evh_2070266589801014_selectedRowsChanged_3105 = new actions.EventHandlerImpl(
      {
        action: act_2070266589801014_setData_3106,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2070266589801014_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2070266589801014_selectedRowsChanged_3105],
      }
    );
    const act_1548630417156826_closeComponent_3108 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1548630417156826_closeComponent_3108_getActionArgs,
        events: [],
      }
    );
    const evh_1548630417156826_click_3107 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_closeComponent_3108,
        event: "click",
        displayName: "closeComponent",
      }
    );
    const evh_1548630417156826_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_click_3107],
      }
    );
    return {
      act_7315092382398562_closeModal_3096,
      evh_7315092382398562_close_3095,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_3098,
      evh_2248226175642056_close_3097,
      evh_2248226175642056_close,
      act_5534025912102772_setData_3102,
      evh_5534025912102772_success_3101,
      act_5534025912102772_cache_3104,
      evh_5534025912102772_success_3103,
      act_5534025912102772_request_3100,
      evh_5534025912102772_submit_3099,
      evh_5534025912102772_submit,
      act_2070266589801014_setData_3106,
      evh_2070266589801014_selectedRowsChanged_3105,
      evh_2070266589801014_selectedRowsChanged,
      act_1548630417156826_closeComponent_3108,
      evh_1548630417156826_click_3107,
      evh_1548630417156826_click,
      search: act_5534025912102772_request_3100,
    }
  }
}

Vue.component("InvoiceBrowser", InvoiceBrowser);

</script>