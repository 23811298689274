<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Dashboard"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <Notifications>
    </Notifications>
  </UcBody>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "dashboard",
    path: "/",
    component: "Dashboard",
    default: true
  }
)

@Component()
export default class Dashboard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Dashboard"),
        icon: this.$config.dashboard.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2880_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2880, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2880: actions.CloseModalAction;
    evh_7315092382398562_close_2879: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2880 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2880_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2879 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2880,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2879],
      }
    );
    return {
      act_7315092382398562_closeModal_2880,
      evh_7315092382398562_close_2879,
      evh_7315092382398562_close,
    }
  }
}

Vue.component("Dashboard", Dashboard);

</script>