"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let CreateMeter = class CreateMeter extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['item', 'formName', 'formId', 'submitAndClose', 'container', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.schemaDefaults('CreateMeter', { multiplier: 1 });
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_container() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                self: this.$fn.urlFor('create_meter'),
                object: 'list',
                data: []
            };
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            formName: "meter",
            formId: null,
            submitAndClose: true,
            container: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                noPrimaryButton: true,
                closeButtonText: this.$fn.gettext("Close"),
                size: "md",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.gettext("Create Meter"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_3486_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeModal_3492_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    act_1040167445267876_navigate_3494_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                location: {
                    name: "app.meter",
                    params: {
                        id: $event.data.response.data.id,
                    },
                },
            };
        });
    }
    act_1040167445267876_reloadSlickgrid_3496_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "meters",
            };
        });
    }
    act_1040167445267876_rest_3490_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "save",
                container: this.container,
                resource: this.item,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    act_1419464017721962_script_3500_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            (this.setData('item', this.item.clone({
                id: null,
                rev: null,
                serial_number: null,
                links: null,
                etag: null,
                locked: null,
            })),
                this.setData('editor', this.item.editor()));
        });
    }
    act_1419464017721962_script_3500_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_1419464017721962_script_3500_getActionArgs_value($event),
            };
        });
    }
    act_1419464017721962_reloadSlickgrid_3502_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "meters",
            };
        });
    }
    act_1419464017721962_rest_3498_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "save",
                container: this.container,
                resource: this.item,
            };
        });
    }
    evh_1419464017721962_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_click.executeFromDOM(this, event, scope);
        });
    }
    evh_2186679009640457_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_3486 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_3486_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_3485 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_3486,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_3485],
        });
        const act_2248226175642056_closeComponent_3488 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_3487 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_3488,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_3487],
        });
        const act_1040167445267876_closeModal_3492 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_3492_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_saved_3491 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_3492,
            event: "saved",
            displayName: "closeModal",
        });
        const act_1040167445267876_navigate_3494 = new core_1.actions.NavigateAction({
            actionArgs: this.act_1040167445267876_navigate_3494_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_saved_3493 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_navigate_3494,
            event: "saved",
            displayName: "navigate",
        });
        const act_1040167445267876_reloadSlickgrid_3496 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1040167445267876_reloadSlickgrid_3496_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_saved_3495 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_reloadSlickgrid_3496,
            event: "saved",
            displayName: "reloadSlickgrid",
        });
        const act_1040167445267876_rest_3490 = new core_1.actions.RestAction({
            actionArgs: this.act_1040167445267876_rest_3490_getActionArgs,
            displayName: "create",
            events: [evh_1040167445267876_saved_3491, evh_1040167445267876_saved_3493, evh_1040167445267876_saved_3495],
        });
        const evh_1040167445267876_submit_3489 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_rest_3490,
            event: "submit",
            displayName: "create",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_3489],
        });
        const act_1419464017721962_script_3500 = new core_1.actions.ScriptAction({
            actionArgs: this.act_1419464017721962_script_3500_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_saved_3499 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_script_3500,
            event: "saved",
            displayName: "script",
        });
        const act_1419464017721962_reloadSlickgrid_3502 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1419464017721962_reloadSlickgrid_3502_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_saved_3501 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_reloadSlickgrid_3502,
            event: "saved",
            displayName: "reloadSlickgrid",
        });
        const act_1419464017721962_rest_3498 = new core_1.actions.RestAction({
            actionArgs: this.act_1419464017721962_rest_3498_getActionArgs,
            displayName: "create2",
            events: [evh_1419464017721962_saved_3499, evh_1419464017721962_saved_3501],
        });
        const evh_1419464017721962_click_3497 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_rest_3498,
            event: "click",
            displayName: "create2",
        });
        const evh_1419464017721962_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_click_3497],
        });
        const act_2186679009640457_closeComponent_3504 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2186679009640457_clickPreventStop_3503 = new core_1.actions.EventHandlerImpl({
            action: act_2186679009640457_closeComponent_3504,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_2186679009640457_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2186679009640457_clickPreventStop_3503],
        });
        return {
            act_7315092382398562_closeModal_3486,
            evh_7315092382398562_close_3485,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_3488,
            evh_2248226175642056_close_3487,
            evh_2248226175642056_close,
            act_1040167445267876_closeModal_3492,
            evh_1040167445267876_saved_3491,
            act_1040167445267876_navigate_3494,
            evh_1040167445267876_saved_3493,
            act_1040167445267876_reloadSlickgrid_3496,
            evh_1040167445267876_saved_3495,
            act_1040167445267876_rest_3490,
            evh_1040167445267876_submit_3489,
            evh_1040167445267876_submit,
            act_1419464017721962_script_3500,
            evh_1419464017721962_saved_3499,
            act_1419464017721962_reloadSlickgrid_3502,
            evh_1419464017721962_saved_3501,
            act_1419464017721962_rest_3498,
            evh_1419464017721962_click_3497,
            evh_1419464017721962_click,
            act_2186679009640457_closeComponent_3504,
            evh_2186679009640457_clickPreventStop_3503,
            evh_2186679009640457_clickPreventStop,
            create: act_1040167445267876_rest_3490,
            create2: act_1419464017721962_rest_3498,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], CreateMeter.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], CreateMeter.prototype, "onSize", null);
CreateMeter = __decorate([
    (0, vue_property_decorator_1.Component)()
], CreateMeter);
exports.default = CreateMeter;
vue_property_decorator_1.Vue.component("CreateMeter", CreateMeter);
