<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="ContractViewsOverlappingRevisions"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="ContractViewsOverlappingRevisions"
    :headerTitle="'Overlapping Revisions'|pgettext('Contracts')"
    :items="$fn.slickDataSource('view_contracts_overlapping_revisions', {assignUniqueId: true})"
    assign-unique-id
    checkbox-row-selection
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
  >
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "overlappingRevisions",
    path: "/overlapping-revisions",
    component: "ContractViewsOverlappingRevisions"
  }
)

@Component()
export default class ContractViewsOverlappingRevisions extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;
  dataMembers = ['ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: [{
          field: "contract_a",
          name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "Contract 'A'"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "contract",
            params: {
              id: "contract_a_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "a_status",
          name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "'A' Status"),
          formatter: {
            name: "chain",
            mode: "mapReduce",
            formatters: [{
              name: "gettext",
              context: "ContractStatus",
              conversion: "StartCase",
              filterInput: true,
            }
              ,
            {
              name: "badge",
              variantMapSource: "fieldValue",
              variantmap: {
                draft: "light",
                approved: "success",
                signed: "success",
                active: "primary",
                ended: "warning",
                terminated: "warning",
                rescinded: "danger",
                archived: "light",
              }
              ,
            }
              ,
            ]
            ,
          }
          ,
        }
          ,
        {
          field: "client_a",
          name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "Client 'A'"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "client",
            params: {
              id: "client_a_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "a_valid_from",
          name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "'A' Valid From"),
          formatter: {
            name: "date",
          }
          ,
        }
          ,
        {
          field: "a_valid_to",
          name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "'A' Valid To"),
          formatter: {
            name: "date",
          }
          ,
        }
          ,
        {
          field: "contract_b",
          name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "Contract 'B'"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "contract",
            params: {
              id: "contract_b_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "b_status",
          name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "'B' Status"),
          formatter: {
            name: "chain",
            mode: "mapReduce",
            formatters: [{
              name: "gettext",
              context: "ContractStatus",
              conversion: "StartCase",
              filterInput: true,
            }
              ,
            {
              name: "badge",
              variantMapSource: "fieldValue",
              variantmap: {
                draft: "light",
                approved: "success",
                signed: "success",
                active: "primary",
                ended: "warning",
                terminated: "warning",
                rescinded: "danger",
                archived: "light",
              }
              ,
            }
              ,
            ]
            ,
          }
          ,
        }
          ,
        {
          field: "client_b",
          name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "Client 'B'"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "client",
            params: {
              id: "client_b_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "b_valid_from",
          name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "'B' Valid From"),
          formatter: {
            name: "date",
          }
          ,
        }
          ,
        {
          field: "b_valid_to",
          name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "'B' Valid To"),
          formatter: {
            name: "date",
          }
          ,
        }
          ,
        {
          field: "rental",
          name: this.$fn.pgettext("ContractViewsOverlappingRevisions", "Rental"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "rental",
            params: {
              id: "rental_id",
            }
            ,
          }
          ,
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("Contracts", "Overlapping Revisions"),
        icon: this.$config.contract.views.overlappingRevisions.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_5138_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_5138, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_5138: actions.CloseModalAction;
    evh_7315092382398562_close_5137: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_5138 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_5138_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_5137 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_5138,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_5137],
      }
    );
    return {
      act_7315092382398562_closeModal_5138,
      evh_7315092382398562_close_5137,
      evh_7315092382398562_close,
    }
  }
}

Vue.component("ContractViewsOverlappingRevisions", ContractViewsOverlappingRevisions);

</script>