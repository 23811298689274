"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const big_js_1 = require("big.js");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcInvoiceLines = class UcInvoiceLines extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['expenseTypes', 'taxCodes', 'units', 'displayAddInvoiceLine', 'messages', 'ux', 'b_5962862420439144_rowHeader', 'b_5962862420439144_paging', 'b_5962862420439144_sortable', 'b_5962862420439144_rowTemplate', 'b_5962862420439144_messages', 'b_5962862420439144_columns', 'b_1270943406604881_dataSource', 'b_1803307708030275_paging', 'b_1803307708030275_sortable', 'b_1803307708030275_rowHeader', 'b_1803307708030275_fillEmptyRowSpace', 'b_1803307708030275_messages', 'b_1803307708030275_columns', 'b_7700024326226090_paging', 'b_7700024326226090_sortable', 'b_7700024326226090_rowHeader', 'b_7700024326226090_fillEmptyRowSpace', 'b_7700024326226090_messages', 'b_7700024326226090_columns'];
    }
    beforeCreate() {
    }
    onProductSelected(...args) {
        let [row, product] = args;
        if (!product) {
            product = row.product;
        }
        if (_.isObject(product) && product.id == null) {
            product = product.name;
            row.product = product;
        }
        if (_.isString(product)) {
            row.description = product;
            row.tax_code = this.defaultTaxCode;
            const nearestRow = row.$$table.nearestRow(row);
            console.log('PR', nearestRow);
            if (nearestRow) {
                row.unit = nearestRow.unit;
                row.discount = nearestRow.discount;
            }
        }
        else {
            row.product = product;
            row.unit = product.unit;
            row.description = product.name;
            row.tax_code = this.defaultTaxCode || (this.invoice.invoice_kind === "outgoing"
                ? product.sales_vat_code
                : product.purchase_vat_code);
            row.intermediated_service = product.intermediated_service || false;
        }
        row.setFocus('quantity');
    }
    onProductChanged(...args) {
        console.log('onProductChanged', args);
    }
    calculateUnitPrice(...args) {
        const [row, tax, decimals] = args;
        row.price = (0, big_js_1.default)((0, big_js_1.default)(row.net_amount) / (0, big_js_1.default)(row.quantity) / (1 - (0, big_js_1.default)(row.discount) / 100)).round(decimals).toNumber();
    }
    calculateNetAmount(...args) {
        const [row, tax, decimals] = args;
        row.net_amount = (0, big_js_1.default)((0, big_js_1.default)(row.quantity) * (0, big_js_1.default)(row.price) * (1 - (0, big_js_1.default)(row.discount) / 100)).round(decimals).toNumber();
    }
    calculateNetAmountFromAmount(...args) {
        const [row, tax, decimals] = args;
        row.net_amount = (0, big_js_1.default)((0, big_js_1.default)(row.amount) / (1 + (0, big_js_1.default)(tax.rate) / 100)).round(decimals).toNumber();
        console.log('calculateNetAmountFromAmount', row, row.net_amount);
    }
    calculateAmount(...args) {
        const [row, tax, decimals] = args;
        row.amount = (0, big_js_1.default)((0, big_js_1.default)(row.net_amount) * (1 + (0, big_js_1.default)(tax.rate) / 100)).round(decimals).toNumber();
    }
    recalculateLineItem(...args) {
        const data = args[0].data;
        console.log('recalculateLineItem', data);
        const row = data.row;
        const decimals = this.currency.digits;
        const isIncomingInvoice = this.isIncomingInvoice;
        const tx = _.find(this.taxCodes, { id: row.tax_code });
        if (row.quantity === 0) {
            return;
        }
        switch (data.field) {
            case 'quantity':
            case 'price':
            case 'discount': {
                this.calculateNetAmount(row, tx, decimals);
                this.calculateAmount(row, tx, decimals);
                break;
            }
            case 'net_amount': {
                this.calculateUnitPrice(row, tx, this.currency.price_digits);
                this.calculateAmount(row, tx, decimals);
                break;
            }
            case 'tax_code': {
                this.calculateAmount(row, tx, decimals);
                break;
            }
            case 'amount': {
                this.calculateNetAmountFromAmount(row, tx, decimals);
                this.calculateUnitPrice(row, tx, this.currency.price_digits);
                break;
            }
        }
    }
    hasQuantityMismatch(...args) {
        const row = args[0];
        return row.accountings
            && row.accountings.data
            && row.accountings.data.length
            && _.sumBy(row.accountings.data, p => p.quantity) != row.quantity;
    }
    getInvoiceLineEditorIsDisabled(...args) {
        const [row] = args;
        return !row.tax_code;
    }
    onRentalOrPropertySelected(...args) {
        let [row, selection] = args;
        console.log(row, selection);
        if (!selection) {
            row.rental = null;
            row.property = null;
            return;
        }
        if (_.isObject(selection) && selection.object == 'rental') {
            row.rental = selection;
            row.property = null;
            return;
        }
        if (_.isObject(selection) && ['property', 'property_unit'].indexOf(selection.object) > -1) {
            row.rental = null;
            row.property = selection;
            return;
        }
    }
    onInvoice(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice");
    }
    onCurrency(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("currency");
    }
    onDefault_discount(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("defaultDiscount");
    }
    onDefault_tax_code(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("defaultTaxCode");
    }
    onForm_context(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("formContext");
    }
    $$load_expenseTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_object_by_type', { parameters: { object_type: 'expense-type' } });
        });
    }
    $$load_taxCodes() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_tax_code');
        });
    }
    $$load_units() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_unit');
        });
    }
    $$load_b_5962862420439144_rowTemplate() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                description: null,
                discount: this.defaultDiscount || 0,
            };
        });
    }
    $$load_b_5962862420439144_columns() {
        return __awaiter(this, void 0, void 0, function* () {
            return [{
                    name: "product",
                    header: this.$fn.gettext("Product"),
                    width: "250px",
                    required: true,
                    textAlign: "left",
                    editor: {
                        name: "template",
                        value: "<ItpSelect2\n\
  class=\"w-100\"\n\
  v-model=\"currentValue\"\n\
  bindObject\n\
  valueField=\"id\"\n\
  textField=\"name\"\n\
  required\n\
  :placeholder=\"messages.msgSearchProduct\"\n\
  :options=\"globs.searchDataSource('suggest_product', {invoice_line_products: 'include'})\"\n\
  :autofocus=autofocus\n\
  :form=\"formId\"\n\
  :inputId=\"col.field\"\n\
  :size=\"col.size\"\n\
  :state=\"validationState\"\n\
  :disabled=\"isDisabled\"\n\
  :tabindex=\"col.tabindex\"\n\
  taggable\n\
  @optionSelected=\"col.emit('product:selected', {col, row, ...$event})\"\n\
  @change=\"onChange($event)\"\n\
>\n\
  <template #search=\"{attributes, events}\">\n\
    <input\n\
      class=\"vs__search\"\n\
      v-bind=\"attributes\"\n\
      v-on=\"events\"\n\
    />\n\
  </template>\n\
\n\
  <template #option=\"option\">\n\
    <UcSuggestProductOption :option=\"option\" v-if=\"option.object=='product'\">\n\
    </UcSuggestProductOption>\n\
    <span v-else>{{ option.name }}</span>\n\
  </template>\n\
\n\
  <template #selected-option=\"option\">\n\
    <UcSuggestProductOption :option=\"option\" v-if=\"option.object=='product'\">\n\
    </UcSuggestProductOption>\n\
    <span v-else>{{ option.name }}</span>\n\
  </template>\n\
\n\
  <template #list-footer>\n\
    <hr />\n\
    <ItpButton\n\
      class=\"ml-2\"\n\
      size=sm\n\
      variant=light\n\
      @click=\"col.emitCellEvent('createProduct', row)\">\n\
      {{ messages.msgCreateProduct }}\n\
    </ItpButton>\n\
  </template>\n\
\n\
  <template #footer>\n\
    <b-form-input\n\
      class=\"mt-1\"\n\
      type=text\n\
      v-model=row.description\n\
      id=\"description\"\n\
      name=\"description\"\n\
      autocomplete=\"off\"\n\
      :form=\"formId\"\n\
      :size=col.size\n\
      :disabled=\"isDisabled\"\n\
      :placeholder=\"globs.messages.invoiceLineDescriptionPlaceholder\"\n\
      @change=\"onFieldChange('description', row.description)\"\n\
    ></b-form-input>\n\
  </template>\n\
</ItpSelect2>\n\
",
                    },
                    formatter: {
                        name: "template",
                        value: "<div>\n\
  <b-link v-if=\"row.product\" :to=\"{\n\
    name: 'app.product',\n\
    params: { id: row.product.id }\n\
  }\">{{ row.description }}</b-link>\n\
  <span v-if=\"!row.product\">{{ row.description }}</span>\n\
</div>\n\
",
                    },
                },
                {
                    name: "advance",
                    header: this.$fn.pgettext("InvoiceLine|ColHeader", "Adv."),
                    headerTooltip: this.$fn.pgettext("invoiceLine|ColHeader|Tooltip", "Advance"),
                    headerTextAlign: "center",
                    width: "30px",
                    type: "checkbox",
                    editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
                },
                {
                    name: "intermediated_service",
                    header: this.$fn.pgettext("AbbrIntermediatedService", "IMS"),
                    headerTooltip: this.$fn.gettext("Intermediated Service"),
                    headerTextAlign: "center",
                    width: "30px",
                    type: "checkbox",
                    editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
                },
                {
                    name: "quantity",
                    type: "number",
                    maxAllowedDecimals: this.isIncomingInvoice ? null : this.currency.quantity_digits,
                    default: 0,
                    header: this.$fn.pgettext("Quantity Abbr", "Qty"),
                    headerTooltip: this.$fn.gettext("Quantity"),
                    headerTextAlign: "center",
                    width: "60px",
                    textAlign: "right",
                    required: true,
                    editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
                },
                {
                    name: "unit",
                    header: this.$fn.pgettext("Unit Abbr", "Unit"),
                    headerTooltip: this.$fn.gettext("Unit"),
                    width: "70px",
                    textAlign: "center",
                    required: true,
                    editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
                },
                {
                    name: "price",
                    type: "number",
                    maxAllowedDecimals: this.isIncomingInvoice ? null : this.currency.price_digits,
                    default: 0,
                    header: this.$fn.gettext("Price"),
                    width: "70px",
                    textAlign: "right",
                    required: true,
                    editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
                    footerTotals: rows => _.meanBy(rows, p => p.quantity * p.price),
                },
                {
                    name: "discount",
                    type: "number",
                    maxAllowedDecimals: this.isIncomingInvoice ? null : this.currency.digits,
                    default: this.defaultDiscount || 0,
                    header: this.$fn.pgettext("AbbrDiscount", "Disc"),
                    headerTooltip: this.$fn.gettext("Discount"),
                    noFooterTotals: true,
                    width: "50px",
                    textAlign: "right",
                    required: true,
                    editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
                },
                {
                    name: "net_amount",
                    type: "number",
                    maxAllowedDecimals: this.currency.digits,
                    default: 0,
                    header: this.$fn.gettext("Net"),
                    width: "100px",
                    textAlign: "right",
                    required: true,
                    editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
                },
                {
                    name: "amount",
                    type: "number",
                    maxAllowedDecimals: this.currency.digits,
                    default: 0,
                    header: this.$fn.gettext("Amount"),
                    width: "100px",
                    textAlign: "right",
                    required: true,
                    editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
                },
                {
                    name: "tax_code",
                    header: this.$fn.gettext("VAT"),
                    headerTooltip: this.$fn.gettext("VAT Code"),
                    width: "100px",
                    type: "select",
                    options: this.taxCodes,
                    valueField: "id",
                    textField: "id",
                    textAlign: "right",
                    editorIsDisabled: this.getInvoiceLineEditorIsDisabled,
                },
                {
                    name: "expense_type",
                    type: "select",
                    header: this.$fn.pgettext("InvoiceLine|ColHeader", "Expense Type"),
                    width: "100px",
                    options: this.expenseTypes,
                    valueField: "name",
                    textField: "name",
                },
                {
                    name: "rental_or_property",
                    header: this.$fn.gettext("Cost Center"),
                    textAlign: "left",
                    width: "100px",
                    editor: {
                        name: "template",
                        value: "<ItpSelect2\n\
  class=\"w-100\"\n\
  v-model=\"currentValue\"\n\
  bindObject\n\
  valueField=\"id\"\n\
  textField=\"name\"\n\
  required\n\
  :placeholder=\"messages.msgSearchCostCenter\"\n\
  :options=\"globs.searchDataSource('suggest_cost_center')\"\n\
  :initialOptions=\"[row.rental || row.property]\"\n\
  :form=\"formId\"\n\
  :inputId=\"col.field\"\n\
  :size=\"col.size\"\n\
  :state=\"validationState\"\n\
  :disabled=\"isDisabled\"\n\
  :tabindex=\"col.tabindex\"\n\
  @optionSelected=\"col.emit('rental_or_property:selected', {col, row, ...$event})\"\n\
  @change=\"onChange($event)\"\n\
>\n\
  <template #search=\"{attributes, events}\">\n\
    <input\n\
      class=\"vs__search\"\n\
      v-bind=\"attributes\"\n\
      v-on=\"events\"\n\
    />\n\
  </template>\n\
\n\
  <template #option=\"option\">\n\
    <UcSuggestRentalOption :option=\"option\" v-if=\"option.object=='rental'\">\n\
    </UcSuggestRentalOption>\n\
    <UcSuggestPropertyOption :option=\"option\" v-if=\"option.object=='property' || option.object == 'property_unit'\">\n\
    </UcSuggestPropertyOption>\n\
  </template>\n\
\n\
  <template #selected-option=\"option\">\n\
    <UcSuggestRentalOption :option=\"option\" v-if=\"option.object=='rental'\">\n\
    </UcSuggestRentalOption>\n\
    <UcSuggestPropertyOption :option=\"option\" v-if=\"option.object=='property' || option.object == 'property_unit'\">\n\
    </UcSuggestPropertyOption>\n\
  </template>\n\
</ItpSelect2>\n\
",
                    },
                    formatter: {
                        name: "template",
                        value: "<div>\n\
  <UcSuggestRentalOption :option=\"row.rental\" v-if=\"row.rental\">\n\
  </UcSuggestRentalOption>\n\
  <UcSuggestPropertyOption :option=\"row.property\" v-if=\"row.property\" display-code>\n\
  </UcSuggestPropertyOption>\n\
</div>\n\
",
                    },
                },
            ];
        });
    }
    $$load_b_1270943406604881_dataSource() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "suggest_invoice",
                parameters: {
                    invoice_kind: this.invoice.invoice_kind,
                    client_id: this.invoice.client.id,
                    company_id: this.invoice.company.id,
                    exclude_invoice: this.invoice.id,
                },
            };
        });
    }
    $$load_b_1803307708030275_columns() {
        return __awaiter(this, void 0, void 0, function* () {
            return [{
                    name: "meter",
                    header: this.$fn.gettext("Meter"),
                    width: "160px",
                    required: true,
                    type: "select2",
                    options: this.$fn.searchDataSource('suggest_meter'),
                    bindObject: true,
                    valueField: "id",
                    textField: "serial_number",
                    formatter: {
                        name: "template",
                        value: "<UcMeterMiniCard :item=\"row.meter\" link-style-underline no-strong></UcMeterMiniCard>\n\
",
                    },
                },
                {
                    name: "from",
                    header: this.$fn.gettext("Previous reading"),
                    width: "160px",
                    formatter: {
                        name: "template",
                        value: "<div>{{ row.from_date|date }} {{ row.from_amount|number }} {{ row.from_unit }}</div>\n\
",
                    },
                    editor: {
                        name: "template",
                        value: "<div class=\"input-group\">\n\
  <ItpFormDatetime\n\
    v-model=\"row.from_date\"\n\
    name=\"from_date\"\n\
    placeholder=\"date\"\n\
    size=\"sm\"\n\
    style=\"min-width:100px;\"\n\
    required\n\
  ></ItpFormDatetime>\n\
\n\
  <ItpFormInput\n\
    type=\"number\"\n\
    name=\"from_amount\"\n\
    v-model=\"row.from_amount\"\n\
    placeholder=\"reading\"\n\
    size=\"sm\"\n\
    style=\"min-width:60px;\"\n\
    required\n\
  ></ItpFormInput>\n\
\n\
  <ItpFormSelect\n\
    v-model=\"row.from_unit\"\n\
    name=\"from_unit\"\n\
    placeholder=\"unit\"\n\
    size=\"sm\"\n\
    :options=\"globs.units\"\n\
    style=\"min-width:60px;\"\n\
    valueField=\"symbol\"\n\
    textField=\"symbol\"\n\
    required\n\
  ></ItpFormSelect>\n\
</div>\n\
",
                    },
                },
                {
                    name: "to",
                    header: this.$fn.gettext("Current reading"),
                    width: "240px",
                    formatter: {
                        name: "template",
                        value: "<div>{{ row.to_date|date }} {{ row.to_amount|number }} {{ row.to_unit }}</div>\n\
",
                    },
                    editor: {
                        name: "template",
                        value: "<div class=\"input-group\">\n\
  <ItpFormDatetime\n\
    v-model=\"row.to_date\"\n\
    name=\"to_date\"\n\
    placeholder=\"date\"\n\
    size=\"sm\"\n\
    style=\"min-width:100px;\"\n\
    required\n\
  ></ItpFormDatetime>\n\
\n\
  <ItpFormInput\n\
    type=\"number\"\n\
    name=\"to_amount\"\n\
    v-model=\"row.to_amount\"\n\
    placeholder=\"reading\"\n\
    size=\"sm\"\n\
    style=\"min-width:60px;\"\n\
    required\n\
  ></ItpFormInput>\n\
\n\
  <ItpFormSelect\n\
    v-model=\"row.to_unit\"\n\
    name=\"to_unit\"\n\
    placeholder=\"unit\"\n\
    size=\"sm\"\n\
    :options=\"globs.units\"\n\
    style=\"min-width:60px;\"\n\
    valueField=\"symbol\"\n\
    textField=\"symbol\"\n\
    required\n\
  ></ItpFormSelect>\n\
</div>\n\
",
                    },
                },
                {
                    name: "consumption",
                    header: this.$fn.gettext("Consumption"),
                    readonly: true,
                    formatter: {
                        name: "template",
                        value: "<div>{{ row.consumption_amount|number }} {{ row.consumption_unit }}</div>\n\
",
                    },
                },
                {
                    name: "billing_multiplier",
                    header: this.$fn.gettext("Multiplier"),
                    default: 1,
                    width: "70px",
                    type: "number",
                    required: true,
                },
            ];
        });
    }
    $$load_b_7700024326226090_columns() {
        return __awaiter(this, void 0, void 0, function* () {
            return [{
                    name: "expense_type",
                    type: "select",
                    header: this.$fn.gettext("Expense Type"),
                    width: "100px",
                    options: this.expenseTypes,
                    valueField: "name",
                    textField: "name",
                },
                {
                    name: "rental",
                    type: "select2",
                    header: this.$fn.gettext("Rental"),
                    width: "100px",
                    path: "name",
                    bindObject: true,
                    textField: "name",
                    options: this.$fn.searchDataSource('suggest_rental'),
                    useOptionTemplateForSelectedOption: true,
                    optionTemplate: "<UcSuggestRentalOption :option=\"option\"></UcSuggestRentalOption>",
                },
                {
                    name: "property",
                    type: "select2",
                    header: this.$fn.gettext("Property"),
                    width: "100px",
                    path: "name",
                    bindObject: true,
                    textField: "name",
                    options: this.$fn.searchDataSource('suggest_property'),
                    useOptionTemplateForSelectedOption: true,
                    optionTemplate: "<UcSuggestPropertyOption :option=\"option\"></UcSuggestPropertyOption>",
                },
                {
                    name: "contractor",
                    type: "select2",
                    header: this.$fn.gettext("Contractor"),
                    width: "100px",
                    path: "name",
                    bindObject: true,
                    textField: "name",
                    options: this.$fn.searchDataSource('suggest_client', { 'is-contractor': true }),
                },
                {
                    name: "quantity",
                    type: "number",
                    header: this.$fn.gettext("Quantity"),
                    width: "100px",
                },
            ];
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            expenseTypes: null,
            taxCodes: null,
            units: null,
            displayAddInvoiceLine: null,
            messages: {
                invoiceLineProductSearchPlaceholder: this.$fn.pgettext("InvoiceLines", "Search product/service..."),
                invoiceLineCostCenterSearchPlaceholder: this.$fn.pgettext("invoiceLines", "search..."),
                invoiceLineDescriptionPlaceholder: this.$fn.pgettext("InvoiceLines", "Description"),
            },
            ux: null,
            b_5962862420439144_rowHeader: false,
            b_5962862420439144_paging: false,
            b_5962862420439144_sortable: false,
            b_5962862420439144_rowTemplate: null,
            b_5962862420439144_messages: {
                msgCreateRow: this.$fn.pgettext("InvoiceLines", "Add line"),
                msgCreateProduct: this.$fn.pgettext("InvoiceLines", "Add new product..."),
                msgSearchProduct: this.$fn.pgettext("InvoiceLines", "Search for product/service..."),
                msgSearchCostCenter: this.$fn.pgettext("InvoiceLines", "search..."),
            },
            b_5962862420439144_columns: null,
            b_1270943406604881_dataSource: null,
            b_1803307708030275_paging: false,
            b_1803307708030275_sortable: false,
            b_1803307708030275_rowHeader: false,
            b_1803307708030275_fillEmptyRowSpace: false,
            b_1803307708030275_messages: {
                msgCreateRow: this.$fn.pgettext("invoiceLines", "New meter row"),
            },
            b_1803307708030275_columns: null,
            b_7700024326226090_paging: false,
            b_7700024326226090_sortable: false,
            b_7700024326226090_rowHeader: false,
            b_7700024326226090_fillEmptyRowSpace: false,
            b_7700024326226090_messages: {
                msgCreateRow: this.$fn.pgettext("invoiceLines", "New accounting row"),
            },
            b_7700024326226090_columns: null,
        });
    }
    get invoiceLines() {
        if (this.loaded) {
            return this.invoice.lines.data;
        }
        return null;
    }
    get invoiceLineGroups() {
        if (this.loaded) {
            return this.invoice.line_groups.data;
        }
        return null;
    }
    get isIncomingInvoice() {
        if (this.loaded) {
            return this.invoice.invoice_kind == 'incoming';
        }
        return null;
    }
    get invoiceRentalNumber() {
        if (this.loaded) {
            return _.chain(this.invoice.references.data).find({ object: 'rental' }).get('name').value();
        }
        return null;
    }
    act_1889980785424776_showModal_6704_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "UploadInvoiceLines",
                props: {
                    invoice: this.invoice,
                },
            };
        });
    }
    evh_1889980785424776_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1889980785424776_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_emit_6706_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_5962862420439144_saveCompleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_saveCompleted.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_6708_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.invoice.id
                ? $event.data.waitFor(this.$fn.crud('invoice_line').upsert(Object.assign(Object.assign({}, $event.data.row), { invoice_id: this.invoice.id })))
                : this.invoiceLines.splice(this.invoiceLines.length - 1, 0, $event.data.row);
        });
    }
    act_5962862420439144_script_6708_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_6708_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_save(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_save.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_6712_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.onProductSelected($event.parent.data.row, $event.data.modalResult.value);
        });
    }
    act_5962862420439144_script_6712_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_6712_getActionArgs_value($event),
            };
        });
    }
    act_5962862420439144_showModal_6710_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateProduct",
                props: {
                    preventDefaultOnClose: true,
                },
            };
        });
    }
    evh_5962862420439144_createProduct(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_createProduct.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_6714_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('invoice_line').delete($event.data.row));
        });
    }
    act_5962862420439144_script_6714_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_6714_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_delete(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_delete.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_6716_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.onProductSelected($event.data.row, $event.data.option);
        });
    }
    act_5962862420439144_script_6716_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_6716_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_productSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_productSelected.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_6718_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.onProductChanged($event);
        });
    }
    act_5962862420439144_script_6718_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_6718_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_productChange(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_productChange.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_6720_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.recalculateLineItem($event);
        });
    }
    act_5962862420439144_script_6720_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_6720_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_quantityChange(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_quantityChange.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_6722_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.recalculateLineItem($event);
        });
    }
    act_5962862420439144_script_6722_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_6722_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_priceChange(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_priceChange.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_6724_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.recalculateLineItem($event);
        });
    }
    act_5962862420439144_script_6724_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_6724_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_discountChange(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_discountChange.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_6726_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.recalculateLineItem($event);
        });
    }
    act_5962862420439144_script_6726_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_6726_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_netAmountChange(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_netAmountChange.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_6728_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.recalculateLineItem($event);
        });
    }
    act_5962862420439144_script_6728_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_6728_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_amountChange(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_amountChange.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_6730_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.recalculateLineItem($event);
        });
    }
    act_5962862420439144_script_6730_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_6730_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_taxCodeChange(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_taxCodeChange.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_6732_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.onRentalOrPropertySelected($event.data.row, $event.data.option);
        });
    }
    act_5962862420439144_script_6732_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_6732_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_rentalOrPropertySelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_rentalOrPropertySelected.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_setData_6734_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "formContext.selectedInvoiceLines",
                value: $event.data.selection,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_5962862420439144_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    act_7789867021953041_emit_6736_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_7789867021953041_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7789867021953041_reload.executeFromDOM(this, event, scope);
        });
    }
    act_309515315410323_emit_6740_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_309515315410323_close_6739_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.value;
        });
    }
    act_309515315410323_showModal_6738_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "InvoiceLineGroup",
                props: {
                    invoice: this.invoice,
                },
            };
        });
    }
    evh_309515315410323_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_309515315410323_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_880971572885910_script_6744_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.$fn.crud('invoice_line')
                .upsert({
                id: $event.scope.row.id,
                etag: $event.scope.row.etag,
                meter_accounting: Object.assign({ invoice_id: this.invoice.id, invoice_line_id: $event.scope.row.id }, $event.data.modalResult.value)
            }).then(() => this.$emit('reload'));
        });
    }
    act_880971572885910_script_6744_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_880971572885910_script_6744_getActionArgs_value($event),
            };
        });
    }
    evh_880971572885910_close_6743_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.value;
        });
    }
    act_880971572885910_showModal_6742_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "InvoiceLineMeterReadingSelector",
                props: {
                    rental: this.invoiceRentalNumber,
                    multiple: false,
                    serviceId: $event.scope.row.product ? $event.scope.row.product.id : null,
                },
            };
        });
    }
    evh_880971572885910_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_880971572885910_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_1803307708030275_script_6746_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('invoice_line')
                .upsert({
                id: $event.scope.row.id,
                etag: $event.scope.row.etag,
                meter_accounting: $event.data.row,
            }));
        });
    }
    act_1803307708030275_script_6746_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_1803307708030275_script_6746_getActionArgs_value($event),
            };
        });
    }
    evh_1803307708030275_save(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1803307708030275_save.executeFromDOM(this, event, scope);
        });
    }
    act_1803307708030275_emit_6748_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_1803307708030275_saveCompleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1803307708030275_saveCompleted.executeFromDOM(this, event, scope);
        });
    }
    act_1803307708030275_script_6750_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('invoice_line_meter_accounting').delete($event.data.row));
        });
    }
    act_1803307708030275_script_6750_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_1803307708030275_script_6750_getActionArgs_value($event),
            };
        });
    }
    evh_1803307708030275_delete(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1803307708030275_delete.executeFromDOM(this, event, scope);
        });
    }
    act_1803307708030275_emit_6752_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_1803307708030275_deleteCompleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1803307708030275_deleteCompleted.executeFromDOM(this, event, scope);
        });
    }
    act_7700024326226090_script_6754_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('invoice_line_accounting').upsert($event.data.row)
                .then(p => (this.$emit('reload'), p)));
        });
    }
    act_7700024326226090_script_6754_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_7700024326226090_script_6754_getActionArgs_value($event),
            };
        });
    }
    evh_7700024326226090_save(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7700024326226090_save.executeFromDOM(this, event, scope);
        });
    }
    act_7700024326226090_script_6756_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('invoice_line_accounting').delete($event.data.row));
        });
    }
    act_7700024326226090_script_6756_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_7700024326226090_script_6756_getActionArgs_value($event),
            };
        });
    }
    evh_7700024326226090_delete(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7700024326226090_delete.executeFromDOM(this, event, scope);
        });
    }
    act_7700024326226090_emit_6758_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_7700024326226090_saveCompleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7700024326226090_saveCompleted.executeFromDOM(this, event, scope);
        });
    }
    act_7700024326226090_emit_6760_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_7700024326226090_deleteCompleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7700024326226090_deleteCompleted.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_1889980785424776_showModal_6704 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_1889980785424776_showModal_6704_getActionArgs,
            events: [],
        });
        const evh_1889980785424776_clickPreventStop_6703 = new core_1.actions.EventHandlerImpl({
            action: act_1889980785424776_showModal_6704,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_1889980785424776_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1889980785424776_clickPreventStop_6703],
        });
        const act_5962862420439144_emit_6706 = new core_1.actions.EmitAction({
            actionArgs: this.act_5962862420439144_emit_6706_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_saveCompleted_6705 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_emit_6706,
            event: "save-completed",
            displayName: "emit",
        });
        const evh_5962862420439144_saveCompleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_saveCompleted_6705],
        });
        const act_5962862420439144_script_6708 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_6708_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_save_6707 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_6708,
            event: "save",
            displayName: "script",
        });
        const evh_5962862420439144_save = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_save_6707],
        });
        const act_5962862420439144_script_6712 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_6712_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_close_6711 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_6712,
            event: "close",
            displayName: "script",
        });
        const act_5962862420439144_showModal_6710 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5962862420439144_showModal_6710_getActionArgs,
            events: [evh_5962862420439144_close_6711],
        });
        const evh_5962862420439144_createProduct_6709 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_showModal_6710,
            event: "createProduct",
            displayName: "showModal",
        });
        const evh_5962862420439144_createProduct = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_createProduct_6709],
        });
        const act_5962862420439144_script_6714 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_6714_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_delete_6713 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_6714,
            event: "delete",
            displayName: "script",
        });
        const evh_5962862420439144_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_delete_6713],
        });
        const act_5962862420439144_script_6716 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_6716_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_productSelected_6715 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_6716,
            event: "product:selected",
            displayName: "script",
        });
        const evh_5962862420439144_productSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_productSelected_6715],
        });
        const act_5962862420439144_script_6718 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_6718_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_productChange_6717 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_6718,
            event: "product:change",
            displayName: "script",
        });
        const evh_5962862420439144_productChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_productChange_6717],
        });
        const act_5962862420439144_script_6720 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_6720_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_quantityChange_6719 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_6720,
            event: "quantity:change",
            displayName: "script",
        });
        const evh_5962862420439144_quantityChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_quantityChange_6719],
        });
        const act_5962862420439144_script_6722 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_6722_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_priceChange_6721 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_6722,
            event: "price:change",
            displayName: "script",
        });
        const evh_5962862420439144_priceChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_priceChange_6721],
        });
        const act_5962862420439144_script_6724 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_6724_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_discountChange_6723 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_6724,
            event: "discount:change",
            displayName: "script",
        });
        const evh_5962862420439144_discountChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_discountChange_6723],
        });
        const act_5962862420439144_script_6726 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_6726_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_netAmountChange_6725 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_6726,
            event: "net_amount:change",
            displayName: "script",
        });
        const evh_5962862420439144_netAmountChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_netAmountChange_6725],
        });
        const act_5962862420439144_script_6728 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_6728_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_amountChange_6727 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_6728,
            event: "amount:change",
            displayName: "script",
        });
        const evh_5962862420439144_amountChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_amountChange_6727],
        });
        const act_5962862420439144_script_6730 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_6730_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_taxCodeChange_6729 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_6730,
            event: "tax_code:change",
            displayName: "script",
        });
        const evh_5962862420439144_taxCodeChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_taxCodeChange_6729],
        });
        const act_5962862420439144_script_6732 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_6732_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_rentalOrPropertySelected_6731 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_6732,
            event: "rental_or_property:selected",
            displayName: "script",
        });
        const evh_5962862420439144_rentalOrPropertySelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_rentalOrPropertySelected_6731],
        });
        const act_5962862420439144_setData_6734 = new core_1.actions.SetDataAction({
            actionArgs: this.act_5962862420439144_setData_6734_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_selectedRowsChanged_6733 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_setData_6734,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        const evh_5962862420439144_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_selectedRowsChanged_6733],
        });
        const act_7789867021953041_emit_6736 = new core_1.actions.EmitAction({
            actionArgs: this.act_7789867021953041_emit_6736_getActionArgs,
            events: [],
        });
        const evh_7789867021953041_reload_6735 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_emit_6736,
            event: "reload",
            displayName: "emit",
        });
        const evh_7789867021953041_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7789867021953041_reload_6735],
        });
        const act_309515315410323_emit_6740 = new core_1.actions.EmitAction({
            actionArgs: this.act_309515315410323_emit_6740_getActionArgs,
            events: [],
        });
        const evh_309515315410323_close_6739 = new core_1.actions.EventHandlerImpl({
            action: act_309515315410323_emit_6740,
            event: "close",
            when: this.evh_309515315410323_close_6739_getWhen,
            displayName: "emit",
        });
        const act_309515315410323_showModal_6738 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_309515315410323_showModal_6738_getActionArgs,
            events: [evh_309515315410323_close_6739],
        });
        const evh_309515315410323_clickPreventStop_6737 = new core_1.actions.EventHandlerImpl({
            action: act_309515315410323_showModal_6738,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_309515315410323_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_309515315410323_clickPreventStop_6737],
        });
        const act_880971572885910_script_6744 = new core_1.actions.ScriptAction({
            actionArgs: this.act_880971572885910_script_6744_getActionArgs,
            events: [],
        });
        const evh_880971572885910_close_6743 = new core_1.actions.EventHandlerImpl({
            action: act_880971572885910_script_6744,
            event: "close",
            when: this.evh_880971572885910_close_6743_getWhen,
            displayName: "script",
        });
        const act_880971572885910_showModal_6742 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_880971572885910_showModal_6742_getActionArgs,
            events: [evh_880971572885910_close_6743],
        });
        const evh_880971572885910_clickPreventStop_6741 = new core_1.actions.EventHandlerImpl({
            action: act_880971572885910_showModal_6742,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_880971572885910_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_880971572885910_clickPreventStop_6741],
        });
        const act_1803307708030275_script_6746 = new core_1.actions.ScriptAction({
            actionArgs: this.act_1803307708030275_script_6746_getActionArgs,
            events: [],
        });
        const evh_1803307708030275_save_6745 = new core_1.actions.EventHandlerImpl({
            action: act_1803307708030275_script_6746,
            event: "save",
            displayName: "script",
        });
        const evh_1803307708030275_save = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1803307708030275_save_6745],
        });
        const act_1803307708030275_emit_6748 = new core_1.actions.EmitAction({
            actionArgs: this.act_1803307708030275_emit_6748_getActionArgs,
            events: [],
        });
        const evh_1803307708030275_saveCompleted_6747 = new core_1.actions.EventHandlerImpl({
            action: act_1803307708030275_emit_6748,
            event: "save-completed",
            displayName: "emit",
        });
        const evh_1803307708030275_saveCompleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1803307708030275_saveCompleted_6747],
        });
        const act_1803307708030275_script_6750 = new core_1.actions.ScriptAction({
            actionArgs: this.act_1803307708030275_script_6750_getActionArgs,
            events: [],
        });
        const evh_1803307708030275_delete_6749 = new core_1.actions.EventHandlerImpl({
            action: act_1803307708030275_script_6750,
            event: "delete",
            displayName: "script",
        });
        const evh_1803307708030275_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1803307708030275_delete_6749],
        });
        const act_1803307708030275_emit_6752 = new core_1.actions.EmitAction({
            actionArgs: this.act_1803307708030275_emit_6752_getActionArgs,
            events: [],
        });
        const evh_1803307708030275_deleteCompleted_6751 = new core_1.actions.EventHandlerImpl({
            action: act_1803307708030275_emit_6752,
            event: "delete-completed",
            displayName: "emit",
        });
        const evh_1803307708030275_deleteCompleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1803307708030275_deleteCompleted_6751],
        });
        const act_7700024326226090_script_6754 = new core_1.actions.ScriptAction({
            actionArgs: this.act_7700024326226090_script_6754_getActionArgs,
            events: [],
        });
        const evh_7700024326226090_save_6753 = new core_1.actions.EventHandlerImpl({
            action: act_7700024326226090_script_6754,
            event: "save",
            displayName: "script",
        });
        const evh_7700024326226090_save = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7700024326226090_save_6753],
        });
        const act_7700024326226090_script_6756 = new core_1.actions.ScriptAction({
            actionArgs: this.act_7700024326226090_script_6756_getActionArgs,
            events: [],
        });
        const evh_7700024326226090_delete_6755 = new core_1.actions.EventHandlerImpl({
            action: act_7700024326226090_script_6756,
            event: "delete",
            displayName: "script",
        });
        const evh_7700024326226090_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7700024326226090_delete_6755],
        });
        const act_7700024326226090_emit_6758 = new core_1.actions.EmitAction({
            actionArgs: this.act_7700024326226090_emit_6758_getActionArgs,
            events: [],
        });
        const evh_7700024326226090_saveCompleted_6757 = new core_1.actions.EventHandlerImpl({
            action: act_7700024326226090_emit_6758,
            event: "save-completed",
            displayName: "emit",
        });
        const evh_7700024326226090_saveCompleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7700024326226090_saveCompleted_6757],
        });
        const act_7700024326226090_emit_6760 = new core_1.actions.EmitAction({
            actionArgs: this.act_7700024326226090_emit_6760_getActionArgs,
            events: [],
        });
        const evh_7700024326226090_deleteCompleted_6759 = new core_1.actions.EventHandlerImpl({
            action: act_7700024326226090_emit_6760,
            event: "delete-completed",
            displayName: "emit",
        });
        const evh_7700024326226090_deleteCompleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7700024326226090_deleteCompleted_6759],
        });
        return {
            act_1889980785424776_showModal_6704,
            evh_1889980785424776_clickPreventStop_6703,
            evh_1889980785424776_clickPreventStop,
            act_5962862420439144_emit_6706,
            evh_5962862420439144_saveCompleted_6705,
            evh_5962862420439144_saveCompleted,
            act_5962862420439144_script_6708,
            evh_5962862420439144_save_6707,
            evh_5962862420439144_save,
            act_5962862420439144_script_6712,
            evh_5962862420439144_close_6711,
            act_5962862420439144_showModal_6710,
            evh_5962862420439144_createProduct_6709,
            evh_5962862420439144_createProduct,
            act_5962862420439144_script_6714,
            evh_5962862420439144_delete_6713,
            evh_5962862420439144_delete,
            act_5962862420439144_script_6716,
            evh_5962862420439144_productSelected_6715,
            evh_5962862420439144_productSelected,
            act_5962862420439144_script_6718,
            evh_5962862420439144_productChange_6717,
            evh_5962862420439144_productChange,
            act_5962862420439144_script_6720,
            evh_5962862420439144_quantityChange_6719,
            evh_5962862420439144_quantityChange,
            act_5962862420439144_script_6722,
            evh_5962862420439144_priceChange_6721,
            evh_5962862420439144_priceChange,
            act_5962862420439144_script_6724,
            evh_5962862420439144_discountChange_6723,
            evh_5962862420439144_discountChange,
            act_5962862420439144_script_6726,
            evh_5962862420439144_netAmountChange_6725,
            evh_5962862420439144_netAmountChange,
            act_5962862420439144_script_6728,
            evh_5962862420439144_amountChange_6727,
            evh_5962862420439144_amountChange,
            act_5962862420439144_script_6730,
            evh_5962862420439144_taxCodeChange_6729,
            evh_5962862420439144_taxCodeChange,
            act_5962862420439144_script_6732,
            evh_5962862420439144_rentalOrPropertySelected_6731,
            evh_5962862420439144_rentalOrPropertySelected,
            act_5962862420439144_setData_6734,
            evh_5962862420439144_selectedRowsChanged_6733,
            evh_5962862420439144_selectedRowsChanged,
            act_7789867021953041_emit_6736,
            evh_7789867021953041_reload_6735,
            evh_7789867021953041_reload,
            act_309515315410323_emit_6740,
            evh_309515315410323_close_6739,
            act_309515315410323_showModal_6738,
            evh_309515315410323_clickPreventStop_6737,
            evh_309515315410323_clickPreventStop,
            act_880971572885910_script_6744,
            evh_880971572885910_close_6743,
            act_880971572885910_showModal_6742,
            evh_880971572885910_clickPreventStop_6741,
            evh_880971572885910_clickPreventStop,
            act_1803307708030275_script_6746,
            evh_1803307708030275_save_6745,
            evh_1803307708030275_save,
            act_1803307708030275_emit_6748,
            evh_1803307708030275_saveCompleted_6747,
            evh_1803307708030275_saveCompleted,
            act_1803307708030275_script_6750,
            evh_1803307708030275_delete_6749,
            evh_1803307708030275_delete,
            act_1803307708030275_emit_6752,
            evh_1803307708030275_deleteCompleted_6751,
            evh_1803307708030275_deleteCompleted,
            act_7700024326226090_script_6754,
            evh_7700024326226090_save_6753,
            evh_7700024326226090_save,
            act_7700024326226090_script_6756,
            evh_7700024326226090_delete_6755,
            evh_7700024326226090_delete,
            act_7700024326226090_emit_6758,
            evh_7700024326226090_saveCompleted_6757,
            evh_7700024326226090_saveCompleted,
            act_7700024326226090_emit_6760,
            evh_7700024326226090_deleteCompleted_6759,
            evh_7700024326226090_deleteCompleted,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcInvoiceLines.prototype, "invoice", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoice'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoiceLines.prototype, "onInvoice", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcInvoiceLines.prototype, "currency", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('currency'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoiceLines.prototype, "onCurrency", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Number,
    }),
    __metadata("design:type", Number)
], UcInvoiceLines.prototype, "defaultDiscount", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('defaultDiscount'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoiceLines.prototype, "onDefault_discount", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], UcInvoiceLines.prototype, "defaultTaxCode", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('defaultTaxCode'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoiceLines.prototype, "onDefault_tax_code", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcInvoiceLines.prototype, "formContext", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('formContext'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoiceLines.prototype, "onForm_context", null);
UcInvoiceLines = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcInvoiceLines);
exports.default = UcInvoiceLines;
vue_property_decorator_1.Vue.component("UcInvoiceLines", UcInvoiceLines);
