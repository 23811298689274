"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let FileSelectorModal = class FileSelectorModal extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "modal",
        };
        this.dataMembers = ['selectedFiles', 'ux'];
    }
    beforeCreate() {
    }
    onMultiple(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("multiple");
    }
    onOperation(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("operation");
    }
    onPayload(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("payload");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            selectedFiles: null,
            ux: null,
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.gettext("Select files"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_2432_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_7786837829063213_closeModal_2436_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    value: $event.data.response.data,
                },
            };
        });
    }
    act_7786837829063213_request_2434_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: this.operation,
                data: _.extend(this.payload, { file: this.selectedFiles }),
                contentType: "multipart/form-data",
            };
        });
    }
    evh_7786837829063213_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7786837829063213_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2432 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2432_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2431 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2432,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2431],
        });
        const act_7786837829063213_closeModal_2436 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7786837829063213_closeModal_2436_getActionArgs,
            events: [],
        });
        const evh_7786837829063213_success_2435 = new core_1.actions.EventHandlerImpl({
            action: act_7786837829063213_closeModal_2436,
            event: "success",
            displayName: "closeModal",
        });
        const act_7786837829063213_request_2434 = new core_1.actions.RequestAction({
            actionArgs: this.act_7786837829063213_request_2434_getActionArgs,
            displayName: "submit",
            events: [evh_7786837829063213_success_2435],
        });
        const evh_7786837829063213_click_2433 = new core_1.actions.EventHandlerImpl({
            action: act_7786837829063213_request_2434,
            event: "click",
            displayName: "submit",
        });
        const evh_7786837829063213_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7786837829063213_click_2433],
        });
        return {
            act_7315092382398562_closeModal_2432,
            evh_7315092382398562_close_2431,
            evh_7315092382398562_close,
            act_7786837829063213_closeModal_2436,
            evh_7786837829063213_success_2435,
            act_7786837829063213_request_2434,
            evh_7786837829063213_click_2433,
            evh_7786837829063213_click,
            submit: act_7786837829063213_request_2434,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
        default: false,
    }),
    __metadata("design:type", Boolean)
], FileSelectorModal.prototype, "multiple", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('multiple'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FileSelectorModal.prototype, "onMultiple", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], FileSelectorModal.prototype, "operation", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('operation'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FileSelectorModal.prototype, "onOperation", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
        default: {},
    }),
    __metadata("design:type", Object)
], FileSelectorModal.prototype, "payload", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('payload'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FileSelectorModal.prototype, "onPayload", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], FileSelectorModal.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FileSelectorModal.prototype, "onSize", null);
FileSelectorModal = __decorate([
    (0, vue_property_decorator_1.Component)()
], FileSelectorModal);
exports.default = FileSelectorModal;
vue_property_decorator_1.Vue.component("FileSelectorModal", FileSelectorModal);
