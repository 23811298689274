<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <legend
    v-if="!!noLegend"
  >
    {{ 'Tenant'|pgettext('Contract') }}
  </legend>
  <ItpFormGroup
    name="client"
    :label="'Client'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormSelect2
      name="client"
      v-model="client"
      bind-object
      :dataSource="b_1040167445267876_dataSource"
      textField="name"
      required
      optionTemplate="<UcSuggestClientOption :option=option></UcSuggestClientOption>"
      use-option-template-for-selected-option
      @optionSelected="evh_1040167445267876_optionSelected($event, {})"
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    name="head_office"
    :label="'Head office'|pgettext('Client')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    v-if="contract.counterparty"
  >
    <ItpFormInput
      name="head_office"
      v-model="contract.counterparty.head_office"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="branch_office"
    :label="'Branch office'|pgettext('Client')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    v-if="contract.counterparty"
  >
    <ItpFormInput
      name="branch_office"
      v-model="contract.counterparty.branch_office"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="statistical_code"
    :label="'Statistical code'|pgettext('Client')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    v-if="contract.counterparty"
  >
    <ItpFormInput
      name="statistical_code"
      v-model="contract.counterparty.statistical_code"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="registration_number"
    :label="'Registration number'|pgettext('Client')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    v-if="contract.counterparty"
  >
    <ItpFormInput
      name="registration_number"
      v-model="contract.counterparty.registration_number"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="account_number"
    :label="'Account number'|pgettext('Client')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    v-if="contract.counterparty"
  >
    <ItpFormInput
      name="account_number"
      v-model="contract.counterparty.account_number"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="representative"
    :label="'Representative'|pgettext('Client')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    v-if="contract.counterparty"
  >
    <ItpFormTextarea
      name="representative"
      v-model="contract.counterparty.representative"
    >
    </ItpFormTextarea>
  </ItpFormGroup>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetContractClientData extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  @Prop({
    type: Boolean,
  })
  noLegend!: boolean;

  @Watch('noLegend')
  onNo_legend(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noLegend")
  }

  client!: any;

  async $$load_client() {
    return this.contract.counterparty
  }

  ux!: any;
  b_1040167445267876_dataSource!: any;
  dataMembers = ['client', 'ux', 'b_1040167445267876_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        client: null,
        ux: null,
        b_1040167445267876_dataSource: {
          name: "suggest_client",
        }
        ,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_setData_6574_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1040167445267876_setData_6574, alias=undefined
    return {
      path: "contract.counterparty",
      value: $event.data.response.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_1040167445267876_request_6572_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1040167445267876_request_6572, alias=undefined
    return {
      operation: "read_client",
      parameters: {
        id: $event.data.value.id,
      }
      ,
      headers: {
        accept: "application/vnd.itp.contractparty+json",
      }
      ,
    }
  }

  async evh_1040167445267876_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_optionSelected.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6570: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6569: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_1040167445267876_setData_6574: actions.SetDataAction;
    evh_1040167445267876_success_6573: actions.EventHandlerImpl;
    act_1040167445267876_request_6572: actions.RequestAction;
    evh_1040167445267876_optionSelected_6571: actions.EventHandlerImpl;
    evh_1040167445267876_optionSelected: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6570 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6569 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6570,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6569],
      }
    );
    const act_1040167445267876_setData_6574 = new actions.SetDataAction(
      {
        actionArgs: this.act_1040167445267876_setData_6574_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_6573 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_setData_6574,
        event: "success",
        displayName: "setData",
      }
    );
    const act_1040167445267876_request_6572 = new actions.RequestAction(
      {
        actionArgs: this.act_1040167445267876_request_6572_getActionArgs,
        events: [evh_1040167445267876_success_6573],
      }
    );
    const evh_1040167445267876_optionSelected_6571 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_request_6572,
        event: "optionSelected",
        displayName: "request",
      }
    );
    const evh_1040167445267876_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_optionSelected_6571],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6570,
      evh_7315092382398562_reload_6569,
      evh_7315092382398562_reload,
      act_1040167445267876_setData_6574,
      evh_1040167445267876_success_6573,
      act_1040167445267876_request_6572,
      evh_1040167445267876_optionSelected_6571,
      evh_1040167445267876_optionSelected,
    }
  }
}

Vue.component("FieldsetContractClientData", FieldsetContractClientData);

</script>