"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "properties",
    path: "/properties",
    component: "PropertyBrowser"
});
let PropertyBrowser = class PropertyBrowser extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['selectedItems', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];
    }
    beforeCreate() {
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            selectedItems: [],
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_2248226175642056_columnDefaults: {
                sortable: true,
                filterable: true,
                resizable: true,
            },
            b_2248226175642056_columns: [{
                    field: "type",
                    name: this.$fn.pgettext("PropertyBrowser", "Type"),
                },
                {
                    field: "name",
                    name: this.$fn.pgettext("PropertyBrowser", "Name"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "property",
                        params: {
                            id: "id",
                        },
                    },
                },
                {
                    field: "description",
                    name: this.$fn.pgettext("PropertyBrowser", "Description"),
                },
                {
                    field: "area",
                    name: this.$fn.pgettext("PropertyBrowser", "Area"),
                },
                {
                    field: "unit_of_area",
                    name: this.$fn.pgettext("PropertyBrowser", "Unit"),
                },
                {
                    field: "location_number",
                    name: this.$fn.pgettext("PropertyBrowser", "Location number"),
                },
                {
                    field: "inactive",
                    name: this.$fn.gettext("Inactive"),
                    textAlign: "center",
                    formatter: {
                        name: "checkbox",
                    },
                },
                {
                    field: "locked",
                    name: this.$fn.gettext("Locked"),
                    textAlign: "center",
                    formatter: {
                        name: "checkbox",
                    },
                },
            ],
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.gettext("Locations"),
                icon: this.$config.realPropertyUnits.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_4012_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_setData_4014_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: this.$refs.grid.getSelectedItems(),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2248226175642056_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    act_6466705138206980_showModal_4016_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateProperty",
            };
        });
    }
    evh_6466705138206980_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6466705138206980_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_4012 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_4012_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_4011 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_4012,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_4011],
        });
        const act_2248226175642056_setData_4014 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_4014_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_selectedRowsChanged_4013 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_4014,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        const evh_2248226175642056_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_selectedRowsChanged_4013],
        });
        const act_6466705138206980_showModal_4016 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6466705138206980_showModal_4016_getActionArgs,
            events: [],
        });
        const evh_6466705138206980_click_4015 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_showModal_4016,
            event: "click",
            displayName: "showModal",
        });
        const evh_6466705138206980_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6466705138206980_click_4015],
        });
        return {
            act_7315092382398562_closeModal_4012,
            evh_7315092382398562_close_4011,
            evh_7315092382398562_close,
            act_2248226175642056_setData_4014,
            evh_2248226175642056_selectedRowsChanged_4013,
            evh_2248226175642056_selectedRowsChanged,
            act_6466705138206980_showModal_4016,
            evh_6466705138206980_click_4015,
            evh_6466705138206980_click,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], PropertyBrowser.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], PropertyBrowser.prototype, "onSize", null);
PropertyBrowser = __decorate([
    (0, vue_property_decorator_1.Component)()
], PropertyBrowser);
exports.default = PropertyBrowser;
vue_property_decorator_1.Vue.component("PropertyBrowser", PropertyBrowser);
