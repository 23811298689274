"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let JobHandlerControl = class JobHandlerControl extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['messages', 'jobData', 'jobPoller', 'status', 'ux'];
    }
    beforeCreate() {
    }
    beforeDestroy(...args) {
        {
            {
                this.jobPoller && this.jobPoller.stop();
            }
        }
    }
    loadJob(...args) {
        return this.$fn
            .fetch('read_a_job', { parameters: { id: this.jobData.id } })
            .then(data => {
            this.setData('jobData', data);
            this.setData('status', data.status);
            this.maybeStopPoll();
        })
            .catch(error => this.handleError(error));
    }
    onStatusReceived(...args) {
        const [{ status }] = args;
        if (status && this.status != status) {
            this.setData('jobData.status', status);
            this.setData('status', status);
            this.$emit(status);
            if (status !== 'running') {
                return this.loadJob();
            }
        }
    }
    onSubmit(...args) {
        const val = _.isFunction(this.payload) ? this.payload() : this.payload;
        if (this.isRunning) {
            return;
        }
        Promise
            .resolve(val)
            .then(payload => {
            if (!payload || payload.cancel) {
                return;
            }
            this.$fn.crud('a_job')
                .create(payload, { parameters: { job_type: this.jobType } })
                .then(resource => {
                this.setData('jobData', resource);
                this.setData('status', resource.status);
            })
                .then(() => this.maybeStartPoll())
                .catch(error => this.handleError(error));
        })
            .catch(error => this.handleError(error));
    }
    checkStatus(...args) {
        if (!this.jobData) {
            return false;
        }
        const status = this.jobData.status;
        if (status && this.status != status) {
            this.$emit(status);
            this.status = status;
        }
        if (status !== 'pending' && status !== 'running') {
            console.log(`JobStatus is ${status}, stop polling.`, this.jobPoller);
            this.jobPoller.stop();
            this.$emit('success');
            return;
        }
        return this.$fn
            .fetch('read_a_job_status', { parameters: { id: this.jobData.id } })
            .then(data => { this.onStatusReceived(data); });
    }
    maybeStartPoll(...args) {
        const status = _.get(this, 'jobData.status');
        if (['completed', 'failed', 'cancelled', 'deleted'].indexOf(status) > -1) {
            return;
        }
        if (!this.jobPoller.isRunning) {
            return this.jobPoller.start();
        }
    }
    maybeStopPoll(...args) {
        const status = _.get(this, 'jobData.status');
        if (['pending', 'running'].indexOf(status) > -1) {
            return;
        }
        if (this.jobPoller.isRunning) {
            return this.jobPoller.stop();
        }
    }
    onBlock(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("block");
    }
    onJob_type(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("jobType");
    }
    onText(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("text");
    }
    onLoad_current(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("loadCurrent");
    }
    onPayload(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("payload");
    }
    onJob_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("jobId");
    }
    onIcon(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("icon");
    }
    onVariant(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("variant");
    }
    onDisabled(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("disabled");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    onTooltip(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("tooltip");
    }
    onPolling_interval(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("pollingInterval");
    }
    $$load_jobData() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.loadCurrent
                ? this.$fn.fetch('read_a_job', { parameters: { id: this.jobID || 'current', job_type: this.jobType }, asResource: true })
                : null;
        });
    }
    $$load_jobPoller() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.poller({
                id: 'Job',
                callback: this.checkStatus,
                interval: this.pollingInterval,
            });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            messages: {
                showJobTooltip: this.$fn.pgettext("JobHandler|Command", "Open job details"),
                reloadJobData: this.$fn.pgettext("JobHandler|Command", "Reload job status"),
            },
            jobData: null,
            jobPoller: null,
            status: null,
            ux: null,
        });
    }
    get isRunning() {
        if (this.loaded) {
            return this.jobData && this.jobData.status === 'running';
        }
        return null;
    }
    get isPending() {
        if (this.loaded) {
            return this.jobData && this.jobData.status === 'pending';
        }
        return null;
    }
    get spinning() {
        if (this.loaded) {
            return this.isRunning || this.isPending || (this.actions && this.actions.reloadJobData && this.actions.reloadJobData.isRunning);
        }
        return null;
    }
    act_7315092382398562_script_5690_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.onSubmit();
        });
    }
    act_7315092382398562_script_5690_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_7315092382398562_script_5690_getActionArgs_value($event),
            };
        });
    }
    act_7315092382398562_script_5690_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !this.disabled;
        });
    }
    evh_7315092382398562_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_96700093353179_script_5692_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.onSubmit();
        });
    }
    act_96700093353179_script_5692_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_96700093353179_script_5692_getActionArgs_value($event),
            };
        });
    }
    act_96700093353179_script_5692_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !this.disabled;
        });
    }
    evh_96700093353179_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_96700093353179_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_96700093353179_script_5694_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.stop();
        });
    }
    act_96700093353179_script_5694_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_96700093353179_script_5694_getActionArgs_value($event),
            };
        });
    }
    evh_96700093353179_bvDropdownHide(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_96700093353179_bvDropdownHide.executeFromDOM(this, event, scope);
        });
    }
    act_96700093353179_script_5696_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log($event);
        });
    }
    act_96700093353179_script_5696_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_96700093353179_script_5696_getActionArgs_value($event),
            };
        });
    }
    evh_96700093353179_bvDropdownShow(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_96700093353179_bvDropdownShow.executeFromDOM(this, event, scope);
        });
    }
    act_302930856768608_showModal_5698_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Job",
                props: {
                    oid: this.jobData.id,
                },
            };
        });
    }
    evh_302930856768608_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_302930856768608_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_939987670462915_setData_5702_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "jobData",
                value: $event.data.response.data,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_939987670462915_setData_5702_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.response.data;
        });
    }
    act_939987670462915_request_5700_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "read_a_job",
                parameters: {
                    id: this.jobData.id,
                },
            };
        });
    }
    evh_939987670462915_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_939987670462915_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_script_5690 = new core_1.actions.ScriptAction({
            actionArgs: this.act_7315092382398562_script_5690_getActionArgs,
            when: this.act_7315092382398562_script_5690_getWhen,
            displayName: "submit",
            events: [],
        });
        const evh_7315092382398562_clickPreventStop_5689 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_script_5690,
            event: "click.prevent.stop",
            displayName: "submit",
        });
        const evh_7315092382398562_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_clickPreventStop_5689],
        });
        const act_96700093353179_script_5692 = new core_1.actions.ScriptAction({
            actionArgs: this.act_96700093353179_script_5692_getActionArgs,
            when: this.act_96700093353179_script_5692_getWhen,
            displayName: "submit",
            events: [],
        });
        const evh_96700093353179_clickPreventStop_5691 = new core_1.actions.EventHandlerImpl({
            action: act_96700093353179_script_5692,
            event: "click.prevent.stop",
            displayName: "submit",
        });
        const evh_96700093353179_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_96700093353179_clickPreventStop_5691],
        });
        const act_96700093353179_script_5694 = new core_1.actions.ScriptAction({
            actionArgs: this.act_96700093353179_script_5694_getActionArgs,
            events: [],
        });
        const evh_96700093353179_bvDropdownHide_5693 = new core_1.actions.EventHandlerImpl({
            action: act_96700093353179_script_5694,
            event: "bv::dropdown::hide",
            displayName: "script",
        });
        const evh_96700093353179_bvDropdownHide = new core_1.actions.EventHandlerGroup({
            handlers: [evh_96700093353179_bvDropdownHide_5693],
        });
        const act_96700093353179_script_5696 = new core_1.actions.ScriptAction({
            actionArgs: this.act_96700093353179_script_5696_getActionArgs,
            events: [],
        });
        const evh_96700093353179_bvDropdownShow_5695 = new core_1.actions.EventHandlerImpl({
            action: act_96700093353179_script_5696,
            event: "bv::dropdown::show",
            displayName: "script",
        });
        const evh_96700093353179_bvDropdownShow = new core_1.actions.EventHandlerGroup({
            handlers: [evh_96700093353179_bvDropdownShow_5695],
        });
        const act_302930856768608_showModal_5698 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_302930856768608_showModal_5698_getActionArgs,
            displayName: "showJob",
            events: [],
        });
        const evh_302930856768608_clickPreventStop_5697 = new core_1.actions.EventHandlerImpl({
            action: act_302930856768608_showModal_5698,
            event: "click.prevent.stop",
            displayName: "showJob",
        });
        const evh_302930856768608_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_302930856768608_clickPreventStop_5697],
        });
        const act_939987670462915_setData_5702 = new core_1.actions.SetDataAction({
            actionArgs: this.act_939987670462915_setData_5702_getActionArgs,
            when: this.act_939987670462915_setData_5702_getWhen,
            events: [],
        });
        const evh_939987670462915_success_5701 = new core_1.actions.EventHandlerImpl({
            action: act_939987670462915_setData_5702,
            event: "success",
            displayName: "setData",
        });
        const act_939987670462915_request_5700 = new core_1.actions.RequestAction({
            actionArgs: this.act_939987670462915_request_5700_getActionArgs,
            displayName: "reloadJobData",
            events: [evh_939987670462915_success_5701],
        });
        const evh_939987670462915_clickPreventStop_5699 = new core_1.actions.EventHandlerImpl({
            action: act_939987670462915_request_5700,
            event: "click.prevent.stop",
            displayName: "reloadJobData",
        });
        const evh_939987670462915_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_939987670462915_clickPreventStop_5699],
        });
        return {
            act_7315092382398562_script_5690,
            evh_7315092382398562_clickPreventStop_5689,
            evh_7315092382398562_clickPreventStop,
            act_96700093353179_script_5692,
            evh_96700093353179_clickPreventStop_5691,
            evh_96700093353179_clickPreventStop,
            act_96700093353179_script_5694,
            evh_96700093353179_bvDropdownHide_5693,
            evh_96700093353179_bvDropdownHide,
            act_96700093353179_script_5696,
            evh_96700093353179_bvDropdownShow_5695,
            evh_96700093353179_bvDropdownShow,
            act_302930856768608_showModal_5698,
            evh_302930856768608_clickPreventStop_5697,
            evh_302930856768608_clickPreventStop,
            act_939987670462915_setData_5702,
            evh_939987670462915_success_5701,
            act_939987670462915_request_5700,
            evh_939987670462915_clickPreventStop_5699,
            evh_939987670462915_clickPreventStop,
            submit: act_96700093353179_script_5692,
            showJob: act_302930856768608_showModal_5698,
            reloadJobData: act_939987670462915_request_5700,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
        default: false,
    }),
    __metadata("design:type", Boolean)
], JobHandlerControl.prototype, "block", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('block'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], JobHandlerControl.prototype, "onBlock", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], JobHandlerControl.prototype, "jobType", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('jobType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], JobHandlerControl.prototype, "onJob_type", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], JobHandlerControl.prototype, "text", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('text'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], JobHandlerControl.prototype, "onText", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
        default: false,
    }),
    __metadata("design:type", Boolean)
], JobHandlerControl.prototype, "loadCurrent", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('loadCurrent'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], JobHandlerControl.prototype, "onLoad_current", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
    }),
    __metadata("design:type", typeof (_a = typeof Function !== "undefined" && Function) === "function" ? _a : Object)
], JobHandlerControl.prototype, "payload", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('payload'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], JobHandlerControl.prototype, "onPayload", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], JobHandlerControl.prototype, "jobId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('jobId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], JobHandlerControl.prototype, "onJob_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], JobHandlerControl.prototype, "icon", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('icon'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], JobHandlerControl.prototype, "onIcon", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "light",
    }),
    __metadata("design:type", String)
], JobHandlerControl.prototype, "variant", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('variant'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], JobHandlerControl.prototype, "onVariant", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], JobHandlerControl.prototype, "disabled", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('disabled'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], JobHandlerControl.prototype, "onDisabled", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], JobHandlerControl.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], JobHandlerControl.prototype, "onSize", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], JobHandlerControl.prototype, "tooltip", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('tooltip'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], JobHandlerControl.prototype, "onTooltip", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Number,
        default: 1000,
    }),
    __metadata("design:type", Number)
], JobHandlerControl.prototype, "pollingInterval", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('pollingInterval'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], JobHandlerControl.prototype, "onPolling_interval", null);
JobHandlerControl = __decorate([
    (0, vue_property_decorator_1.Component)()
], JobHandlerControl);
exports.default = JobHandlerControl;
vue_property_decorator_1.Vue.component("JobHandlerControl", JobHandlerControl);
