<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    direction="row"
  >
    <div
      class="mr-3"
    >
      <Icon
        icon="pajamas:expire"
        :tooltip="'Expiring contract'|gettext"
      >
      </Icon>
    </div>
    <ItpBox
      direction="column"
    >
      <ItpText
        :text="'Expiring contract'|gettext"
        strong
        tag="h5"
      >
      </ItpText>
      <ItpBox
        direction="row"
        class="mb-2"
      >
        <UcContractMiniCard
          :item="notification.data.contract_data"
          no-icon
        >
        </UcContractMiniCard>
        <span
          class="mx-3"
        >
          &middot;
        </span>
        <UcClientMiniCard
          :item="notification.data.contract_data.counterparty"
          no-icon
        >
        </UcClientMiniCard>
        <span
          class="mx-3"
        >
          &middot;
        </span>
        <ItpText
          class="mr-2"
        >
          {{ 'End date'|gettext }}
        </ItpText>
        <strong>
          {{ notification.data.valid_to | date }}
        </strong>
      </ItpBox>
      <ItpText
        small
        muted
      >
        {{ notification.created_at | datetime }}  {{ notification.created_by | username }}

      </ItpText>
    </ItpBox>
  </ItpBox>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcExpiringContractNotificationCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  notification!: object;

  @Watch('notification')
  onNotification(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("notification")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_2864: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_2863: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_2864 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_2863 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_2864,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_2863],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_2864,
      evh_7315092382398562_reload_2863,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcExpiringContractNotificationCard", UcExpiringContractNotificationCard);

</script>