"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "draftInvoices",
    path: "/draft-invoices",
    component: "DraftInvoices"
});
let DraftInvoices = class DraftInvoices extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.sse = {
            cleanup: true,
        };
        this.dataMembers = ['resizeTrigger', 'selectedItems', 'ts', 'ux', 'b_1040167445267876_columnDefaults', 'b_1040167445267876_columns'];
    }
    beforeCreate() {
    }
    processMessage(...args) {
        const msg = args[0];
        if (msg.event === 'InvoiceGeneratorStatus') {
            this.$refs.panel.updateStatus(msg.data);
        }
        if (msg.event === 'InvoiceCreated') {
            this.$refs.grid.dataView.addItem(msg.data);
        }
        if (msg.event === 'InvoiceUpdated') {
            this.$refs.grid.dataView.updateItem(msg.data.id, msg.data);
        }
        if (msg.event === 'InvoiceDeleted') {
            this.$refs.grid.dataView.removeItem(msg.data.id);
        }
    }
    onCreateInvoicesPayload(...args) {
        return this.$imodal
            .show({ name: 'CompleteInvoicesAlt' })
            .then(result => {
            if (!result || result.cancel || !result.value) {
                return;
            }
            return this.selectedItems.map(p => (Object.assign(Object.assign({}, result.value), { invoice_id: p.id })));
        });
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            resizeTrigger: 0,
            selectedItems: [],
            ts: null,
            ux: null,
            b_1040167445267876_columnDefaults: {
                sortable: true,
                filterable: true,
                resizable: true,
            },
            b_1040167445267876_columns: [{
                    field: "contract_number",
                    name: this.$fn.pgettext("InvoiceBrowserView", "Contract"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "contract",
                        navigateOnIconClick: true,
                        params: {
                            id: "contract_id",
                        },
                    },
                },
                {
                    field: "contract_valid_from",
                    name: this.$fn.pgettext("InvoiceBrowserView", "Contract valid from"),
                    type: "date",
                },
                {
                    field: "contract_valid_to",
                    name: this.$fn.pgettext("InvoiceBrowserView", "Contract valid to"),
                    type: "date",
                },
                {
                    field: "invoice_number",
                    name: this.$fn.pgettext("InvoiceBrowserView", "Invoice Number"),
                    type: "command",
                    formatter: {
                        name: "link",
                        href: "#",
                    },
                },
                {
                    field: "client_name",
                    name: this.$fn.pgettext("InvoiceBrowserView", "Client"),
                    formatter: {
                        name: "routeLink",
                        to: "client",
                        navigateOnIconClick: true,
                        params: {
                            id: "client_id",
                        },
                    },
                },
                {
                    field: "rental_name",
                    name: this.$fn.pgettext("InvoiceBrowserView", "Rental"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "rental",
                        navigateOnIconClick: true,
                        params: {
                            id: "rental_id",
                        },
                    },
                },
                {
                    field: "site_name",
                    name: this.$fn.pgettext("InvoiceBrowserView", "Site"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "property",
                        navigateOnIconClick: true,
                        params: {
                            id: "site_id",
                        },
                    },
                },
                {
                    field: "net_amount",
                    name: this.$fn.pgettext("InvoiceBrowserView", "Net"),
                    type: "number",
                    formatter: {
                        name: "number",
                    },
                },
                {
                    field: "vat_amount",
                    name: this.$fn.pgettext("InvoiceBrowserView", "VAT"),
                    type: "number",
                    formatter: {
                        name: "number",
                    },
                },
                {
                    field: "amount",
                    name: this.$fn.pgettext("InvoiceBrowserView", "Amount"),
                    type: "number",
                    formatter: {
                        name: "number",
                    },
                },
                {
                    field: "currency",
                    name: this.$fn.pgettext("InvoiceBrowserView", "Currency"),
                },
                {
                    field: "invoice_date",
                    name: this.$fn.pgettext("InvoiceBrowserView", "Invoice date"),
                    type: "date",
                },
                {
                    field: "fulfillment_date",
                    name: this.$fn.pgettext("InvoiceBrowserView", "Fulfillment date"),
                    type: "date",
                },
                {
                    field: "due_date",
                    name: this.$fn.pgettext("InvoiceBrowserView", "Due date"),
                    type: "date",
                },
                {
                    field: "invoice_status",
                    name: this.$fn.pgettext("InvoiceBrowserView", "Status"),
                    formatter: {
                        name: "chain",
                        mode: "mapReduce",
                        formatters: [{
                                name: "gettext",
                                context: "InvoiceStatus",
                                conversion: "StartCase",
                                filterInput: true,
                            },
                            {
                                name: "badge",
                                variantMapSource: "fieldValue",
                                variantmap: {
                                    draft: "light",
                                    approved: "success",
                                    error: "danger",
                                    sync_pending: "secondary",
                                    sync_job_created: "primary",
                                    sync_in_progress: "warning",
                                    sync_error: "danger",
                                    completed: "success",
                                },
                            },
                        ],
                    },
                },
            ],
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.gettext("Draft Invoices"),
                icon: this.$config.invoice.draftInvoicesIcon,
            };
        }
        return null;
    }
    act_1040167445267876_setData_5624_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: this.$refs.grid.getSelectedItems(),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_1040167445267876_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_showModal_5626_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Invoice",
                props: {
                    oid: $event.data.data.id,
                    modal: true,
                },
            };
        });
    }
    evh_1040167445267876_command_5625_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.column.id === 'invoice_number';
        });
    }
    evh_1040167445267876_command(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_command.executeFromDOM(this, event, scope);
        });
    }
    act_7789867021953041_reloadSlickgrid_5630_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "draftInvoices",
            };
        });
    }
    act_7789867021953041_request_5628_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "delete_draft_invoices",
                data: {
                    invoices: this.selectedItems.map(p => p.id)
                },
            };
        });
    }
    evh_7789867021953041_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7789867021953041_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_1040167445267876_setData_5624 = new core_1.actions.SetDataAction({
            actionArgs: this.act_1040167445267876_setData_5624_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_selectedRowsChanged_5623 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_setData_5624,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        const evh_1040167445267876_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_selectedRowsChanged_5623],
        });
        const act_1040167445267876_showModal_5626 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_1040167445267876_showModal_5626_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_command_5625 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_showModal_5626,
            event: "command",
            when: this.evh_1040167445267876_command_5625_getWhen,
            displayName: "showModal",
        });
        const evh_1040167445267876_command = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_command_5625],
        });
        const act_7789867021953041_reloadSlickgrid_5630 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_7789867021953041_reloadSlickgrid_5630_getActionArgs,
            events: [],
        });
        const evh_7789867021953041_success_5629 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_reloadSlickgrid_5630,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_7789867021953041_request_5628 = new core_1.actions.RequestAction({
            actionArgs: this.act_7789867021953041_request_5628_getActionArgs,
            displayName: "deleteItems",
            events: [evh_7789867021953041_success_5629],
        });
        const evh_7789867021953041_click_5627 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_request_5628,
            event: "click",
            displayName: "deleteItems",
        });
        const evh_7789867021953041_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7789867021953041_click_5627],
        });
        return {
            act_1040167445267876_setData_5624,
            evh_1040167445267876_selectedRowsChanged_5623,
            evh_1040167445267876_selectedRowsChanged,
            act_1040167445267876_showModal_5626,
            evh_1040167445267876_command_5625,
            evh_1040167445267876_command,
            act_7789867021953041_reloadSlickgrid_5630,
            evh_7789867021953041_success_5629,
            act_7789867021953041_request_5628,
            evh_7789867021953041_click_5627,
            evh_7789867021953041_click,
            deleteItems: act_7789867021953041_request_5628,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], DraftInvoices.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], DraftInvoices.prototype, "onSize", null);
DraftInvoices = __decorate([
    (0, vue_property_decorator_1.Component)()
], DraftInvoices);
exports.default = DraftInvoices;
vue_property_decorator_1.Vue.component("DraftInvoices", DraftInvoices);
