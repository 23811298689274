<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="InvoiceRegister"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="invoiceRegister"
    :headerTitle="'Invoice register'|pgettext('Invoice register')"
    :items="$fn.slickDataSource('list_invoice_register_entries', {parameters: getQueryParameters})"
    export-to-csv
    :exportToCsvFilename="'invoice-register'|pgettext('filename')"
    footer-totals
    checkbox-row-selection
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    v-if="loaded"
    @command="evh_2248226175642056_command($event, {})"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <ItpFormSelect2
        name="register_name"
        v-model="selectedRegister"
        bind-object
        valueField="id"
        textField="name"
        :options="registers"
        class="w-25 mx-3"
        @input="evh_6466705138206980_input($event, {})"
      >
      </ItpFormSelect2>
      <ItpButton
        :icon="$config.invoiceRegister.createEntry"
        :text="'Add entry...'|pgettext('InvoiceRegister')"
        size="sm"
        variant="light"
        class="mx-2"
        @click="evh_8320016629450276_click($event, {})"
      >
      </ItpButton>
      <b-dropdown
        size="sm"
        variant="light"
        toggle-class="itp-button border"
        :tooltip="'Set status to selected items'|pgettext('InvoiceRegister')"
        :disabled="!selectedItems || !selectedItems.length || (
    actions.setStatus && actions.setStatus.isRunning
  )"
        class="mx-2"
        v-if="statuses && statuses.length"
      >
        <span
          slot="button-content"
        >
          <b-spinner
            small
            type="grow"
            class="mr-1"
            v-if="actions.setStatus.isRunning"
          >
          </b-spinner>
          <ItpText
            :text="'Set status'|pgettext('InvoiceRegister')"
            class="mx-1"
          >
          </ItpText>
        </span>
        <b-dropdown-item-button
          :disabled="!selectedItems || !selectedItems.length"
          :key="status"
          v-for="status in statuses"
          @click.prevent.stop="evh_5382094220310827_clickPreventStop($event, {status})"
        >
          <ItpText
            :text="status"
          >
          </ItpText>
        </b-dropdown-item-button>
        <hr>
        </hr>
        <b-dropdown-item-button
          :disabled="!selectedItems || !selectedItems.length"
          @click.prevent.stop="evh_8704926098283402_clickPreventStop($event, {})"
        >
          <ItpText
            :text="'Clear status'|pgettext('InvoiceRegister')"
          >
          </ItpText>
        </b-dropdown-item-button>
      </b-dropdown>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "invoiceRegister",
    path: "/invoice-register",
    component: "InvoiceRegister"
  }
)

@Component()
export default class InvoiceRegister extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_selectedRegisterId = new ComponentValueCache(this, "selectedRegisterId", "InvoiceRegister.selectedRegisterId");
  }

  getQueryParameters(...args: any[]) {
    return {
      sort_mode: 'desc',
      register_name: this.selectedRegister ? this.selectedRegister.id : this.registers[0].id
    }
  }

  invoiceTypeBadgeClass(...args: any[]) {
    const [row] = args;
    const cc = this.$config.invoice.types.colorCodes[row.invoice_type];
    return `bg-${cc.bg} text-${cc.text}`
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  registers!: any;

  async $$load_registers() {
    return this.$fn.fetch('list_register')
      .then(data => _.filter(data, d => _.includes(d.register_type, 'incoming_invoice')))
  }

  selectedRegisterId!: any;
  selectedRegister!: any;

  async $$load_selectedRegister() {
    return _.find(this.registers, { id: this.selectedRegisterId }) || this.registers[0]
  }

  selectedItems!: any;
  statuses!: any;

  async $$load_statuses() {
    return this.$fn.fetch('get_tags', { parameters: { tag_type: 'invoice-register-status' } }).then(data => _.map(data, p => p.name))
  }

  selectedStatus!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;

  async $$load_b_2248226175642056_columns() {
    return [{
      field: "entry_number",
      name: this.$fn.pgettext("Invoice register", "No."),
      type: "command",
      formatter: [{
        name: "chain",
        formatters: [{
          name: "link",
          href: "#",
        }
          ,
        {
          name: "cellclass",
          value: (row) => ({
            'font-weight-bold': true,
            'bg-yellow-200': !row.locked
          }),
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    {
      field: "ref_entry_number",
      name: this.$fn.pgettext("Invoice register", "Ref."),
      type: "command",
      formatter: [{
        name: "chain",
        formatters: [{
          name: "link",
          href: "#",
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    {
      field: "invoice_number",
      name: this.$fn.pgettext("Invoice register", "Invoice number"),
      formatter: [{
        name: "chain",
        formatters: [{
          name: "routeLink",
          to: "invoice",
          navigateOnIconClick: true,
          params: {
            id: "invoice_id",
          }
          ,
        }
          ,
        {
          name: "cellclass",
          value: (row) => ({
            'bg-yellow-200': row.invoice_id != null && row.external_key == null,
            'bg-red-200': row.invoice_id == null,
            'font-weight-bold': row.invoice_id != null
          }),
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    {
      field: "received_date",
      name: this.$fn.pgettext("Invoice register", "Received date"),
      type: "date",
    }
      ,
    {
      field: "invoice_type",
      name: this.$fn.pgettext("Invoice register", "Invoice type"),
      formatter: {
        name: "chain",
        formatters: [{
          name: "gettext",
          context: "InvoiceType",
          conversion: "StartCase",
          filterInput: true,
        }
          ,
        {
          name: "badge",
          dot: true,
          pill: true,
          badgeClass: this.invoiceTypeBadgeClass,
        }
          ,
        ]
        ,
      }
      ,
    }
      ,
    {
      field: "status",
      name: this.$fn.pgettext("Invoice register", "Status"),
    }
      ,
    {
      field: "categories",
      name: this.$fn.pgettext("Invoice register", "Category"),
    }
      ,
    {
      field: "client_name",
      name: this.$fn.pgettext("Invoice register", "Vendor"),
      formatter: {
        name: "routeLink",
        to: "client",
        navigateOnIconClick: true,
        params: {
          id: "client_id",
        }
        ,
      }
      ,
    }
      ,
    {
      field: "description",
      name: this.$fn.pgettext("Invoice register", "Subject"),
    }
      ,
    {
      field: "notes",
      name: this.$fn.pgettext("Invoice register", "Notes"),
    }
      ,
    {
      field: "amount",
      name: this.$fn.pgettext("Invoice register", "Amount"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "currency",
      name: this.$fn.pgettext("Invoice register", "Currency"),
    }
      ,
    {
      field: "fulfillment_date",
      name: this.$fn.pgettext("Invoice register", "Fulfillment date"),
      type: "date",
    }
      ,
    {
      field: "due_date",
      name: this.$fn.pgettext("Invoice register", "Due date"),
      type: "date",
    }
      ,
    ]
  }

  dataMembers = ['registers', 'selectedRegisterId', 'selectedRegister', 'selectedItems', 'statuses', 'selectedStatus', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        registers: null,
        selectedRegisterId: null,
        selectedRegister: null,
        selectedItems: [
        ]
        ,
        statuses: null,
        selectedStatus: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("Invoice register", "Invoice register"),
        icon: this.$config.invoiceRegister.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3212_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3212, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_reloadSlickgrid_3216_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_3216, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_2248226175642056_reloadSlickgrid_3218_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_3218, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async evh_2248226175642056_close_3217_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_close_3217, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_2248226175642056_showModal_3214_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_3214, alias=undefined
    return {
      name: "InvoiceRegisterEntry",
      props: {
        oid: $event.data.data.id,
      }
      ,
    }
  }

  async evh_2248226175642056_command_3213_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_command_3213, alias=undefined
    return $event.data.column.id === 'entry_number'
  }

  async act_2248226175642056_reloadSlickgrid_3222_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_3222, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_2248226175642056_reloadSlickgrid_3224_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_3224, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async evh_2248226175642056_close_3223_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_close_3223, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_2248226175642056_showModal_3220_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_3220, alias=undefined
    return {
      name: "InvoiceRegisterEntry",
      props: {
        oid: $event.data.data.ref_entry_id,
      }
      ,
    }
  }

  async evh_2248226175642056_command_3219_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_command_3219, alias=undefined
    return $event.data.column.id === 'ref_entry_number'
  }

  async evh_2248226175642056_command(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_3226_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_3226, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_reloadSlickgrid_3228_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_6466705138206980_reloadSlickgrid_3228, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_6466705138206980_setData_3230_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_6466705138206980_setData_3230, alias=updateSelectedRegisterId
    return {
      path: "selectedRegisterId",
      value: $event.data.id,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_6466705138206980_input(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_input.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_reloadSlickgrid_3234_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8320016629450276_reloadSlickgrid_3234, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_8320016629450276_reloadSlickgrid_3236_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8320016629450276_reloadSlickgrid_3236, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async evh_8320016629450276_close_3235_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_3235, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_8320016629450276_showModal_3232_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_3232, alias=undefined
    return {
      name: "InvoiceRegisterEntry",
      props: {
        register: this.selectedRegister,
        companyId: this.selectedRegister.company_id,
      }
      ,
    }
  }

  async evh_8320016629450276_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
  }

  async act_5382094220310827_setData_3238_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_5382094220310827_setData_3238, alias=undefined
    return {
      path: "selectedStatus",
      value: $event.scope.status,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_5382094220310827_reloadSlickgrid_3242_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_5382094220310827_reloadSlickgrid_3242, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_5382094220310827_request_3240_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_5382094220310827_request_3240, alias=setStatus
    return {
      operation: "bulk_update_invoice_register_entry",
      data: _.map(this.selectedItems, p => ({ id: p.id, status: this.selectedStatus })),
    }
  }

  async evh_5382094220310827_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5382094220310827_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_8704926098283402_setData_3244_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_8704926098283402_setData_3244, alias=undefined
    return {
      path: "selectedStatus",
      value: null,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_8704926098283402_reloadSlickgrid_3248_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8704926098283402_reloadSlickgrid_3248, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_8704926098283402_request_3246_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_8704926098283402_request_3246, alias=setStatus
    return {
      operation: "bulk_update_invoice_register_entry",
      data: _.map(this.selectedItems, p => ({ id: p.id, status: this.selectedStatus })),
    }
  }

  async evh_8704926098283402_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8704926098283402_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3212: actions.CloseModalAction;
    evh_7315092382398562_close_3211: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_reloadSlickgrid_3216: actions.ReloadSlickgridAction;
    evh_2248226175642056_saved_3215: actions.EventHandlerImpl;
    act_2248226175642056_reloadSlickgrid_3218: actions.ReloadSlickgridAction;
    evh_2248226175642056_close_3217: actions.EventHandlerImpl;
    act_2248226175642056_showModal_3214: actions.ShowModalAction;
    evh_2248226175642056_command_3213: actions.EventHandlerImpl;
    act_2248226175642056_reloadSlickgrid_3222: actions.ReloadSlickgridAction;
    evh_2248226175642056_saved_3221: actions.EventHandlerImpl;
    act_2248226175642056_reloadSlickgrid_3224: actions.ReloadSlickgridAction;
    evh_2248226175642056_close_3223: actions.EventHandlerImpl;
    act_2248226175642056_showModal_3220: actions.ShowModalAction;
    evh_2248226175642056_command_3219: actions.EventHandlerImpl;
    evh_2248226175642056_command: actions.EventHandlerGroup;
    act_2248226175642056_setData_3226: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_3225: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_6466705138206980_reloadSlickgrid_3228: actions.ReloadSlickgridAction;
    evh_6466705138206980_input_3227: actions.EventHandlerImpl;
    act_6466705138206980_setData_3230: actions.SetDataAction;
    evh_6466705138206980_input_3229: actions.EventHandlerImpl;
    evh_6466705138206980_input: actions.EventHandlerGroup;
    act_8320016629450276_reloadSlickgrid_3234: actions.ReloadSlickgridAction;
    evh_8320016629450276_saved_3233: actions.EventHandlerImpl;
    act_8320016629450276_reloadSlickgrid_3236: actions.ReloadSlickgridAction;
    evh_8320016629450276_close_3235: actions.EventHandlerImpl;
    act_8320016629450276_showModal_3232: actions.ShowModalAction;
    evh_8320016629450276_click_3231: actions.EventHandlerImpl;
    evh_8320016629450276_click: actions.EventHandlerGroup;
    act_5382094220310827_setData_3238: actions.SetDataAction;
    evh_5382094220310827_clickPreventStop_3237: actions.EventHandlerImpl;
    act_5382094220310827_reloadSlickgrid_3242: actions.ReloadSlickgridAction;
    evh_5382094220310827_success_3241: actions.EventHandlerImpl;
    act_5382094220310827_request_3240: actions.RequestAction;
    evh_5382094220310827_clickPreventStop_3239: actions.EventHandlerImpl;
    evh_5382094220310827_clickPreventStop: actions.EventHandlerGroup;
    act_8704926098283402_setData_3244: actions.SetDataAction;
    evh_8704926098283402_clickPreventStop_3243: actions.EventHandlerImpl;
    act_8704926098283402_reloadSlickgrid_3248: actions.ReloadSlickgridAction;
    evh_8704926098283402_success_3247: actions.EventHandlerImpl;
    act_8704926098283402_request_3246: actions.RequestAction;
    evh_8704926098283402_clickPreventStop_3245: actions.EventHandlerImpl;
    evh_8704926098283402_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3212 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3212_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3211 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3212,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3211],
      }
    );
    const act_2248226175642056_reloadSlickgrid_3216 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_3216_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_2248226175642056_saved_3215 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_3216,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_reloadSlickgrid_3218 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_3218_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_2248226175642056_close_3217 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_3218,
        event: "close",
        when: this.evh_2248226175642056_close_3217_getWhen,
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_showModal_3214 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_3214_getActionArgs,
        events: [evh_2248226175642056_saved_3215, evh_2248226175642056_close_3217],
      }
    );
    const evh_2248226175642056_command_3213 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_3214,
        event: "command",
        when: this.evh_2248226175642056_command_3213_getWhen,
        displayName: "showModal",
      }
    );
    const act_2248226175642056_reloadSlickgrid_3222 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_3222_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_2248226175642056_saved_3221 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_3222,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_reloadSlickgrid_3224 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_3224_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_2248226175642056_close_3223 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_3224,
        event: "close",
        when: this.evh_2248226175642056_close_3223_getWhen,
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_showModal_3220 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_3220_getActionArgs,
        events: [evh_2248226175642056_saved_3221, evh_2248226175642056_close_3223],
      }
    );
    const evh_2248226175642056_command_3219 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_3220,
        event: "command",
        when: this.evh_2248226175642056_command_3219_getWhen,
        displayName: "showModal",
      }
    );
    const evh_2248226175642056_command = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_command_3213, evh_2248226175642056_command_3219],
      }
    );
    const act_2248226175642056_setData_3226 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_3226_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_3225 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_3226,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_3225],
      }
    );
    const act_6466705138206980_reloadSlickgrid_3228 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_6466705138206980_reloadSlickgrid_3228_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_6466705138206980_input_3227 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_reloadSlickgrid_3228,
        event: "input",
        displayName: "reloadSlickgrid",
      }
    );
    const act_6466705138206980_setData_3230 = new actions.SetDataAction(
      {
        actionArgs: this.act_6466705138206980_setData_3230_getActionArgs,
        displayName: "updateSelectedRegisterId",
        events: [],
      }
    );
    const evh_6466705138206980_input_3229 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_setData_3230,
        event: "input",
        displayName: "updateSelectedRegisterId",
      }
    );
    const evh_6466705138206980_input = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_input_3227, evh_6466705138206980_input_3229],
      }
    );
    const act_8320016629450276_reloadSlickgrid_3234 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8320016629450276_reloadSlickgrid_3234_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_8320016629450276_saved_3233 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_reloadSlickgrid_3234,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_8320016629450276_reloadSlickgrid_3236 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8320016629450276_reloadSlickgrid_3236_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_8320016629450276_close_3235 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_reloadSlickgrid_3236,
        event: "close",
        when: this.evh_8320016629450276_close_3235_getWhen,
        displayName: "reloadSlickgrid",
      }
    );
    const act_8320016629450276_showModal_3232 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_3232_getActionArgs,
        events: [evh_8320016629450276_saved_3233, evh_8320016629450276_close_3235],
      }
    );
    const evh_8320016629450276_click_3231 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_3232,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_click_3231],
      }
    );
    const act_5382094220310827_setData_3238 = new actions.SetDataAction(
      {
        actionArgs: this.act_5382094220310827_setData_3238_getActionArgs,
        events: [],
      }
    );
    const evh_5382094220310827_clickPreventStop_3237 = new actions.EventHandlerImpl(
      {
        action: act_5382094220310827_setData_3238,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const act_5382094220310827_reloadSlickgrid_3242 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_5382094220310827_reloadSlickgrid_3242_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_5382094220310827_success_3241 = new actions.EventHandlerImpl(
      {
        action: act_5382094220310827_reloadSlickgrid_3242,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_5382094220310827_request_3240 = new actions.RequestAction(
      {
        actionArgs: this.act_5382094220310827_request_3240_getActionArgs,
        displayName: "setStatus",
        events: [evh_5382094220310827_success_3241],
      }
    );
    const evh_5382094220310827_clickPreventStop_3239 = new actions.EventHandlerImpl(
      {
        action: act_5382094220310827_request_3240,
        event: "click.prevent.stop",
        displayName: "setStatus",
      }
    );
    const evh_5382094220310827_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_5382094220310827_clickPreventStop_3237, evh_5382094220310827_clickPreventStop_3239],
      }
    );
    const act_8704926098283402_setData_3244 = new actions.SetDataAction(
      {
        actionArgs: this.act_8704926098283402_setData_3244_getActionArgs,
        events: [],
      }
    );
    const evh_8704926098283402_clickPreventStop_3243 = new actions.EventHandlerImpl(
      {
        action: act_8704926098283402_setData_3244,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const act_8704926098283402_reloadSlickgrid_3248 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8704926098283402_reloadSlickgrid_3248_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_8704926098283402_success_3247 = new actions.EventHandlerImpl(
      {
        action: act_8704926098283402_reloadSlickgrid_3248,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_8704926098283402_request_3246 = new actions.RequestAction(
      {
        actionArgs: this.act_8704926098283402_request_3246_getActionArgs,
        displayName: "setStatus",
        events: [evh_8704926098283402_success_3247],
      }
    );
    const evh_8704926098283402_clickPreventStop_3245 = new actions.EventHandlerImpl(
      {
        action: act_8704926098283402_request_3246,
        event: "click.prevent.stop",
        displayName: "setStatus",
      }
    );
    const evh_8704926098283402_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8704926098283402_clickPreventStop_3243, evh_8704926098283402_clickPreventStop_3245],
      }
    );
    return {
      act_7315092382398562_closeModal_3212,
      evh_7315092382398562_close_3211,
      evh_7315092382398562_close,
      act_2248226175642056_reloadSlickgrid_3216,
      evh_2248226175642056_saved_3215,
      act_2248226175642056_reloadSlickgrid_3218,
      evh_2248226175642056_close_3217,
      act_2248226175642056_showModal_3214,
      evh_2248226175642056_command_3213,
      act_2248226175642056_reloadSlickgrid_3222,
      evh_2248226175642056_saved_3221,
      act_2248226175642056_reloadSlickgrid_3224,
      evh_2248226175642056_close_3223,
      act_2248226175642056_showModal_3220,
      evh_2248226175642056_command_3219,
      evh_2248226175642056_command,
      act_2248226175642056_setData_3226,
      evh_2248226175642056_selectedRowsChanged_3225,
      evh_2248226175642056_selectedRowsChanged,
      act_6466705138206980_reloadSlickgrid_3228,
      evh_6466705138206980_input_3227,
      act_6466705138206980_setData_3230,
      evh_6466705138206980_input_3229,
      evh_6466705138206980_input,
      act_8320016629450276_reloadSlickgrid_3234,
      evh_8320016629450276_saved_3233,
      act_8320016629450276_reloadSlickgrid_3236,
      evh_8320016629450276_close_3235,
      act_8320016629450276_showModal_3232,
      evh_8320016629450276_click_3231,
      evh_8320016629450276_click,
      act_5382094220310827_setData_3238,
      evh_5382094220310827_clickPreventStop_3237,
      act_5382094220310827_reloadSlickgrid_3242,
      evh_5382094220310827_success_3241,
      act_5382094220310827_request_3240,
      evh_5382094220310827_clickPreventStop_3239,
      evh_5382094220310827_clickPreventStop,
      act_8704926098283402_setData_3244,
      evh_8704926098283402_clickPreventStop_3243,
      act_8704926098283402_reloadSlickgrid_3248,
      evh_8704926098283402_success_3247,
      act_8704926098283402_request_3246,
      evh_8704926098283402_clickPreventStop_3245,
      evh_8704926098283402_clickPreventStop,
      reloadSlickgrid: act_8704926098283402_reloadSlickgrid_3248,
      updateSelectedRegisterId: act_6466705138206980_setData_3230,
      setStatus: act_8704926098283402_request_3246,
    }
  }
}

Vue.component("InvoiceRegister", InvoiceRegister);

</script>