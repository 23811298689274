"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "templates",
    path: "/templates",
    component: "Templates"
});
let Templates = class Templates extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['items', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_template').then(data => _.sortBy(data, p => p.created_at));
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            items: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("Templates", "Templates"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_4230_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_2095089031469157_reloadComponentData_4234_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {};
        });
    }
    act_2095089031469157_navigate_4236_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                location: {
                    name: "app.templates.template",
                    params: {
                        id: $event.data.modalResult.value.id,
                    },
                },
            };
        });
    }
    evh_2095089031469157_close_4235_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.modalResult.value;
        });
    }
    act_2095089031469157_showModal_4232_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateTemplate",
            };
        });
    }
    evh_2095089031469157_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2095089031469157_click.executeFromDOM(this, event, scope);
        });
    }
    act_6545969026712024_request_4238_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "delete_template",
                parameters: {
                    id: $event.data.item.id,
                },
            };
        });
    }
    act_6545969026712024_request_4238_getConfirm($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                message: this.$fn.pgettext("Templates", "Please confirm deleting the template."),
                btnOk: this.$fn.pgettext("Templates", "Delete"),
                btnCancel: this.$fn.pgettext("Templates", "Cancel"),
            };
        });
    }
    evh_6545969026712024_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6545969026712024_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_4230 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_4230_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_4229 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_4230,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_4229],
        });
        const act_2095089031469157_reloadComponentData_4234 = new core_1.actions.ReloadComponentDataAction({
            actionArgs: this.act_2095089031469157_reloadComponentData_4234_getActionArgs,
            events: [],
        });
        const evh_2095089031469157_close_4233 = new core_1.actions.EventHandlerImpl({
            action: act_2095089031469157_reloadComponentData_4234,
            event: "close",
            displayName: "reloadComponentData",
        });
        const act_2095089031469157_navigate_4236 = new core_1.actions.NavigateAction({
            actionArgs: this.act_2095089031469157_navigate_4236_getActionArgs,
            events: [],
        });
        const evh_2095089031469157_close_4235 = new core_1.actions.EventHandlerImpl({
            action: act_2095089031469157_navigate_4236,
            event: "close",
            when: this.evh_2095089031469157_close_4235_getWhen,
            displayName: "navigate",
        });
        const act_2095089031469157_showModal_4232 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2095089031469157_showModal_4232_getActionArgs,
            events: [evh_2095089031469157_close_4233, evh_2095089031469157_close_4235],
        });
        const evh_2095089031469157_click_4231 = new core_1.actions.EventHandlerImpl({
            action: act_2095089031469157_showModal_4232,
            event: "click",
            displayName: "showModal",
        });
        const evh_2095089031469157_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2095089031469157_click_4231],
        });
        const act_6545969026712024_reloadComponentData_4240 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_6545969026712024_success_4239 = new core_1.actions.EventHandlerImpl({
            action: act_6545969026712024_reloadComponentData_4240,
            event: "success",
            displayName: "reloadComponentData",
        });
        const act_6545969026712024_request_4238 = new core_1.actions.RequestAction({
            actionArgs: this.act_6545969026712024_request_4238_getActionArgs,
            confirm: this.act_6545969026712024_request_4238_getConfirm,
            events: [evh_6545969026712024_success_4239],
        });
        const evh_6545969026712024_click_4237 = new core_1.actions.EventHandlerImpl({
            action: act_6545969026712024_request_4238,
            event: "click",
            displayName: "request",
        });
        const evh_6545969026712024_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6545969026712024_click_4237],
        });
        return {
            act_7315092382398562_closeModal_4230,
            evh_7315092382398562_close_4229,
            evh_7315092382398562_close,
            act_2095089031469157_reloadComponentData_4234,
            evh_2095089031469157_close_4233,
            act_2095089031469157_navigate_4236,
            evh_2095089031469157_close_4235,
            act_2095089031469157_showModal_4232,
            evh_2095089031469157_click_4231,
            evh_2095089031469157_click,
            act_6545969026712024_reloadComponentData_4240,
            evh_6545969026712024_success_4239,
            act_6545969026712024_request_4238,
            evh_6545969026712024_click_4237,
            evh_6545969026712024_click,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], Templates.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Templates.prototype, "onSize", null);
Templates = __decorate([
    (0, vue_property_decorator_1.Component)()
], Templates);
exports.default = Templates;
vue_property_decorator_1.Vue.component("Templates", Templates);
