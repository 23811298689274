<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="CreateInvoice"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :color="$config.invoice.color"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
    @deleted="evh_2248226175642056_deleted($event, {})"
  >
    <UcInvoiceHeader
      :invoice="_.clone(item)"
      :formId="formId"
      :formContext="formContext"
      :invoiceNumber="invoiceRegisterEntry ? item.invoice_number : null"
    >
    </UcInvoiceHeader>
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      name="invoice"
      autofocus="first"
      v-if="loaded"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpCol>
        <UcInvoiceEditorFieldset
          :invoice="item"
          :formId="formId"
        >
        </UcInvoiceEditorFieldset>
      </ItpCol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    :color="$config.invoice.color"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :form="forms.invoice && forms.invoice.id"
      icon="fa-save"
      :text="'Create invoice'|pgettext('CreateInvoice|Submit')"
      type="submit"
      variant="primary"
      :spinning="actions.submit.isRunning"
      :disabled="!forms.invoice || !forms.invoice.submittable"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class CreateInvoice extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "outgoing",
  })
  invoiceKind!: string;

  @Watch('invoiceKind')
  onInvoice_kind(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoiceKind")
  }

  @Prop({
    type: String,
    default: "normal",
  })
  invoiceType!: string;

  @Watch('invoiceType')
  onInvoice_type(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoiceType")
  }

  @Prop({
    type: String,
  })
  invoiceRegisterEntry!: string;

  @Watch('invoiceRegisterEntry')
  onInvoice_register_entry(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoiceRegisterEntry")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  titles!: any;
  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  item!: any;

  async $$load_item() {
    return this.$fn.fetch('get_invoice_template', {
      parameters: {
        invoice_kind: this.invoiceKind,
        invoice_type: this.invoiceType,
        invoice_register_entry: this.invoiceRegisterEntry
      }
    }).then(data => new this.$fn.Resource(data, {
      billing_period: data.billing_period || { start: null, end: null }
    })
    )
  }

  formContext!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['titles', 'formId', 'item', 'formContext', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        titles: {
          incoming: this.$fn.pgettext("Invoice", "Create Incoming Invoice"),
          outgoing: this.$fn.pgettext("Invoice", "Create Outgoing Invoice"),
        }
        ,
        formId: null,
        item: null,
        formContext: {
        }
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "lg",
        }
        ,
      },
    }
  }

  get incomingInvoice() {
    if (this.loaded) {

      return this.invoiceKind === 'incoming'
    }
    return null;
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.titles[this.invoiceKind],
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3066_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3066, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_reloadSlickgrid_3072_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_3072, alias=undefined
    return {
      grid: "invoices",
    }
  }

  async evh_2248226175642056_deleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_deleted.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_3076_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_3076, alias=undefined
    return {
      result: {
        close: true,
      }
      ,
    }
  }

  async act_1040167445267876_navigate_3078_getActionArgs($event: actions.ActionEvent): Promise<actions.NavigateActionArgs> {
    // parameterResolver name=act_1040167445267876_navigate_3078, alias=undefined
    return {
      location: {
        name: "app.invoice",
        params: {
          id: $event.data.id,
        }
        ,
      }
      ,
    }
  }

  async act_1040167445267876_crud_3074_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_3074, alias=submit
    return {
      objectType: "invoice",
      op: "upsert",
      data: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeModal_3080_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1419464017721962_closeModal_3080, alias=undefined
    return {
      result: {
        close: true,
      }
      ,
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3066: actions.CloseModalAction;
    evh_7315092382398562_close_3065: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_3068: actions.CloseModalAction;
    evh_2248226175642056_close_3067: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_3070: actions.CloseModalAction;
    evh_2248226175642056_deleted_3069: actions.EventHandlerImpl;
    act_2248226175642056_reloadSlickgrid_3072: actions.ReloadSlickgridAction;
    evh_2248226175642056_deleted_3071: actions.EventHandlerImpl;
    evh_2248226175642056_deleted: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_3076: actions.CloseModalAction;
    evh_1040167445267876_success_3075: actions.EventHandlerImpl;
    act_1040167445267876_navigate_3078: actions.NavigateAction;
    evh_1040167445267876_success_3077: actions.EventHandlerImpl;
    act_1040167445267876_crud_3074: actions.CRUDAction;
    evh_1040167445267876_submit_3073: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeModal_3080: actions.CloseModalAction;
    evh_1419464017721962_clickPreventStop_3079: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3066 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3066_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3065 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3066,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3065],
      }
    );
    const act_2248226175642056_closeModal_3068 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_3067 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_3068,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_3067],
      }
    );
    const act_2248226175642056_closeModal_3070 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_deleted_3069 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_3070,
        event: "deleted",
        displayName: "closeModal",
      }
    );
    const act_2248226175642056_reloadSlickgrid_3072 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_3072_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_deleted_3071 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_3072,
        event: "deleted",
        displayName: "reloadSlickgrid",
      }
    );
    const evh_2248226175642056_deleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_deleted_3069, evh_2248226175642056_deleted_3071],
      }
    );
    const act_1040167445267876_closeModal_3076 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_3076_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_3075 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_3076,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_navigate_3078 = new actions.NavigateAction(
      {
        actionArgs: this.act_1040167445267876_navigate_3078_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_3077 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_navigate_3078,
        event: "success",
        displayName: "navigate",
      }
    );
    const act_1040167445267876_crud_3074 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_3074_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_success_3075, evh_1040167445267876_success_3077],
      }
    );
    const evh_1040167445267876_submit_3073 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_3074,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_3073],
      }
    );
    const act_1419464017721962_closeModal_3080 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1419464017721962_closeModal_3080_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_3079 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeModal_3080,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_3079],
      }
    );
    return {
      act_7315092382398562_closeModal_3066,
      evh_7315092382398562_close_3065,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_3068,
      evh_2248226175642056_close_3067,
      evh_2248226175642056_close,
      act_2248226175642056_closeModal_3070,
      evh_2248226175642056_deleted_3069,
      act_2248226175642056_reloadSlickgrid_3072,
      evh_2248226175642056_deleted_3071,
      evh_2248226175642056_deleted,
      act_1040167445267876_closeModal_3076,
      evh_1040167445267876_success_3075,
      act_1040167445267876_navigate_3078,
      evh_1040167445267876_success_3077,
      act_1040167445267876_crud_3074,
      evh_1040167445267876_submit_3073,
      evh_1040167445267876_submit,
      act_1419464017721962_closeModal_3080,
      evh_1419464017721962_clickPreventStop_3079,
      evh_1419464017721962_clickPreventStop,
      submit: act_1040167445267876_crud_3074,
    }
  }
}

Vue.component("CreateInvoice", CreateInvoice);

</script>