<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'Works'|pgettext('FixedAssetWorkItems') }}
      </strong>
      <strong
        class="mx-2"
        v-if="items && items.length"
      >
        ({{ items.length }})
      </strong>
    </ItpBox>
    <BListGroup
      v-if="loaded"
    >
      <BListGroupItem
        v-if="!items.length"
      >
        <span>
          {{ 'No related items.'|pgettext('FixedAssetWorkItems') }}
        </span>
      </BListGroupItem>
      <BListGroupItem
        :key="item.id"
        class="d-flex flex-column"
        v-for="item in filterItems(items, displayAll, displayCount)"
      >
        <table
          class="table table-borderless table-sm m-0"
        >
          <tbody>
            <tr>
              <td>
                <ItpLink
                  :text="item.worksheet_number"
                  :to="{
    name: 'app.worksheet',
    params: {id: item.worksheet_id}
  }"
                >
                </ItpLink>
              </td>
              <td>
                <ItpText
                  muted
                  strong
                  spacing="mr-4"
                >
                  {{ item.worksheet_date|date }}
                </ItpText>
              </td>
              <td>
                <ItpLink
                  :text="item.rental_name"
                  :to="{
    name: 'app.rental',
    params: {id: item.rental_id}
  }"
                >
                </ItpLink>
              </td>
              <td>
                <ItpLink
                  :text="item.client_name"
                  :to="{
    name: 'app.client',
    params: {id: item.client_id}
  }"
                >
                </ItpLink>
              </td>
            </tr>
            <tr
              v-if="item.worksheet_description"
            >
              <td
                colspan="4"
              >
                <ItpText
                  :text="item.worksheet_description"
                >
                </ItpText>
              </td>
            </tr>
            <tr>
              <td
                colspan="4"
              >
                <table
                  class="table table-borderless table-sm m-0"
                >
                  <tbody>
                    <tr
                      v-for="work in item.items"
                    >
                      <td>
                        <ItpText
                          muted
                          strong
                          spacing="mr-4"
                        >
                          {{ work.work_item_date|date }}
                        </ItpText>
                      </td>
                      <td>
                        <ItpText
                          strong
                          spacing="mr-3"
                        >
                          {{work.quantity|number }} {{ work.unit}}

                        </ItpText>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </BListGroupItem>
      <BListGroupItem
        v-if="canHideItems || canDisplayMoreItems"
      >
        <ItpBox
          justifyContent="center"
        >
          <ItpLink
            :text="'Show less'|gettext"
            v-if="canHideItems"
            @click.prevent.stop="evh_7736732573189513_clickPreventStop($event, {})"
          >
          </ItpLink>
          <ItpLink
            :text="'Show more'|gettext"
            v-if="canDisplayMoreItems"
            @click.prevent.stop="evh_2912679705172878_clickPreventStop($event, {})"
          >
          </ItpLink>
        </ItpBox>
      </BListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcFixedAssetWorkItemsPanel extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
    this.$$cache_displayAll = new ComponentValueCache(this, "displayAll", "FixedAsset.WorkItems.displayAll");
  }

  filterItems(...args: any[]) {
    const [items, displayAll, displayCount] = args;
    if (displayAll || displayCount == items.length) {
      return items;
    }
    return _.take(items, displayCount)
  }

  @Prop({
    required: true,
    type: String,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_fixed_asset_works', { parameters: { fixed_asset_id: this.oid } })
      .then(p => _
        .chain(p)
        .orderBy(p, ['date', 'id'], ['asc', 'asc'])
        .groupBy('worksheet_id')
        .map((items, worksheet_id) => ({ ...items[0], items }))
        .value()
      )
  }

  displayAll!: any;
  displayCount!: any;
  ux!: any;
  dataMembers = ['items', 'displayAll', 'displayCount', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        displayAll: false,
        displayCount: 5,
        ux: null,
      },
    }
  }

  get canDisplayMoreItems() {
    if (this.loaded) {

      return !this.displayAll && this.items && this.displayCount < this.items.length
    }
    return null;
  }

  get canHideItems() {
    if (this.loaded) {

      return this.displayAll && this.items && this.displayCount < this.items.length
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_7736732573189513_setData_5082_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_7736732573189513_setData_5082, alias=undefined
    return {
      path: "displayAll",
      value: false,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_7736732573189513_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7736732573189513_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_2912679705172878_setData_5084_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2912679705172878_setData_5084, alias=undefined
    return {
      path: "displayAll",
      value: true,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2912679705172878_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2912679705172878_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5080: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5079: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_7736732573189513_setData_5082: actions.SetDataAction;
    evh_7736732573189513_clickPreventStop_5081: actions.EventHandlerImpl;
    evh_7736732573189513_clickPreventStop: actions.EventHandlerGroup;
    act_2912679705172878_setData_5084: actions.SetDataAction;
    evh_2912679705172878_clickPreventStop_5083: actions.EventHandlerImpl;
    evh_2912679705172878_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5080 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5079 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5080,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5079],
      }
    );
    const act_7736732573189513_setData_5082 = new actions.SetDataAction(
      {
        actionArgs: this.act_7736732573189513_setData_5082_getActionArgs,
        events: [],
      }
    );
    const evh_7736732573189513_clickPreventStop_5081 = new actions.EventHandlerImpl(
      {
        action: act_7736732573189513_setData_5082,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const evh_7736732573189513_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_7736732573189513_clickPreventStop_5081],
      }
    );
    const act_2912679705172878_setData_5084 = new actions.SetDataAction(
      {
        actionArgs: this.act_2912679705172878_setData_5084_getActionArgs,
        events: [],
      }
    );
    const evh_2912679705172878_clickPreventStop_5083 = new actions.EventHandlerImpl(
      {
        action: act_2912679705172878_setData_5084,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const evh_2912679705172878_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2912679705172878_clickPreventStop_5083],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5080,
      evh_7315092382398562_reload_5079,
      evh_7315092382398562_reload,
      act_7736732573189513_setData_5082,
      evh_7736732573189513_clickPreventStop_5081,
      evh_7736732573189513_clickPreventStop,
      act_2912679705172878_setData_5084,
      evh_2912679705172878_clickPreventStop_5083,
      evh_2912679705172878_clickPreventStop,
    }
  }
}

Vue.component("UcFixedAssetWorkItemsPanel", UcFixedAssetWorkItemsPanel);

</script>