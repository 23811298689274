"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "property",
    path: "/properties/:id",
    component: "Property",
    params: [{
            name: "id",
            prop: "propertyId",
        },
    ]
});
let Property = class Property extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['item', 'ux', 'b_7315092382398562_modalBindings', 'b_4615685607825208_to'];
    }
    beforeCreate() {
    }
    onProperty_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("propertyId");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('read_property', { asResource: true, parameters: { id: this.propertyId, include_units: true, include_subtree: true } });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            ux: {
                initialLoadSpinner: true,
            },
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_4615685607825208_to: {
                name: "app.property",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: [this.item.snapshot('name'),
                    this.$fn.gettext("Property"),
                ],
                icon: this.$config.property.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_3998_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_8320016629450276_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_5477420070495986_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5477420070495986_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_6337614691287098_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6337614691287098_reload.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_3998 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_3998_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_3997 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_3998,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_3997],
        });
        const act_8320016629450276_reloadComponentData_4000 = new core_1.actions.ReloadComponentDataAction({
            displayName: "reload",
            events: [],
        });
        const evh_8320016629450276_clickPreventStop_3999 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_reloadComponentData_4000,
            event: "click.prevent.stop",
            displayName: "reload",
        });
        const evh_8320016629450276_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_clickPreventStop_3999],
        });
        const act_5477420070495986_reloadComponentData_4002 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_5477420070495986_reload_4001 = new core_1.actions.EventHandlerImpl({
            action: act_5477420070495986_reloadComponentData_4002,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_5477420070495986_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5477420070495986_reload_4001],
        });
        const act_6337614691287098_reloadComponentData_4004 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_6337614691287098_reload_4003 = new core_1.actions.EventHandlerImpl({
            action: act_6337614691287098_reloadComponentData_4004,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_6337614691287098_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6337614691287098_reload_4003],
        });
        return {
            act_7315092382398562_closeModal_3998,
            evh_7315092382398562_close_3997,
            evh_7315092382398562_close,
            act_8320016629450276_reloadComponentData_4000,
            evh_8320016629450276_clickPreventStop_3999,
            evh_8320016629450276_clickPreventStop,
            act_5477420070495986_reloadComponentData_4002,
            evh_5477420070495986_reload_4001,
            evh_5477420070495986_reload,
            act_6337614691287098_reloadComponentData_4004,
            evh_6337614691287098_reload_4003,
            evh_6337614691287098_reload,
            reload: act_8320016629450276_reloadComponentData_4000,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], Property.prototype, "propertyId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('propertyId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Property.prototype, "onProperty_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], Property.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Property.prototype, "onSize", null);
Property = __decorate([
    (0, vue_property_decorator_1.Component)()
], Property);
exports.default = Property;
vue_property_decorator_1.Vue.component("Property", Property);
