<template>
<ItpModal
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="MClientEditor"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      name="client"
      class="row"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpCol>
        <MClientEditorFieldset
          :client="client"
          :formId="formId"
          :target="target"
        >
        </MClientEditorFieldset>
      </ItpCol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Save'|pgettext('Button')"
      :disabled="!forms.client || !forms.client.submittable"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.save.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpModal>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class MClientEditor extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "modal",
  };

  beforeCreate() {
  }

  getPayload(...args: any[]) {
    const [data] = args

    if (!this.target) {
      return data;
    }

    const fieldsToSubmit = this.targetConfig[this.target].fieldsToSubmit;
    if (fieldsToSubmit.indexOf('*') > -1) {
      return data;
    }

    return _.pick(data, fieldsToSubmit);
  }

  @Prop({
    type: Object,
  })
  client!: object;

  @Watch('client')
  onClient(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("client")
  }

  @Prop({
    type: String,
  })
  target!: string;

  @Watch('target')
  onTarget(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("target")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  defaultTitle!: any;
  targetConfig!: any;
  ux!: any;
  dataMembers = ['formId', 'defaultTitle', 'targetConfig', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        defaultTitle: this.$fn.pgettext("Client", "Edit General Information"),
        targetConfig: {
          general: {
            title: this.$fn.pgettext("Client", "Edit general data"),
            fieldsToSubmit: ["*"
              ,
            ]
            ,
          }
          ,
        }
        ,
        ux: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.target ? this.targetConfig[this.target].title : this.defaultTitle,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1714_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1714, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_1720_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_1720, alias=undefined
    return {
      result: {
        ok: true,
        value: $event.data,
      }
      ,
    }
  }

  async act_1040167445267876_crud_1718_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_1718, alias=save
    return {
      objectType: "client",
      op: "upsert",
      data: this.getPayload(this.client),
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeModal_1722_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1419464017721962_closeModal_1722, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
      }
      ,
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1714: actions.CloseModalAction;
    evh_7315092382398562_close_1713: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_1716: actions.CloseModalAction;
    evh_2248226175642056_close_1715: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_1720: actions.CloseModalAction;
    evh_1040167445267876_success_1719: actions.EventHandlerImpl;
    act_1040167445267876_crud_1718: actions.CRUDAction;
    evh_1040167445267876_submit_1717: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeModal_1722: actions.CloseModalAction;
    evh_1419464017721962_clickPreventStop_1721: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1714 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1714_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1713 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1714,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1713],
      }
    );
    const act_2248226175642056_closeModal_1716 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_1715 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_1716,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_1715],
      }
    );
    const act_1040167445267876_closeModal_1720 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_1720_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1719 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_1720,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_crud_1718 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_1718_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_success_1719],
      }
    );
    const evh_1040167445267876_submit_1717 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_1718,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_1717],
      }
    );
    const act_1419464017721962_closeModal_1722 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1419464017721962_closeModal_1722_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_1721 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeModal_1722,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_1721],
      }
    );
    return {
      act_7315092382398562_closeModal_1714,
      evh_7315092382398562_close_1713,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_1716,
      evh_2248226175642056_close_1715,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_1720,
      evh_1040167445267876_success_1719,
      act_1040167445267876_crud_1718,
      evh_1040167445267876_submit_1717,
      evh_1040167445267876_submit,
      act_1419464017721962_closeModal_1722,
      evh_1419464017721962_clickPreventStop_1721,
      evh_1419464017721962_clickPreventStop,
      save: act_1040167445267876_crud_1718,
    }
  }
}

Vue.component("MClientEditor", MClientEditor);

</script>