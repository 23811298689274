"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcFixedAssetWorkItemsPanel = class UcFixedAssetWorkItemsPanel extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['items', 'displayAll', 'displayCount', 'ux'];
    }
    beforeCreate() {
        this.$$cache_displayAll = new core_1.ComponentValueCache(this, "displayAll", "FixedAsset.WorkItems.displayAll");
    }
    filterItems(...args) {
        const [items, displayAll, displayCount] = args;
        if (displayAll || displayCount == items.length) {
            return items;
        }
        return _.take(items, displayCount);
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_fixed_asset_works', { parameters: { fixed_asset_id: this.oid } })
                .then(p => _
                .chain(p)
                .orderBy(p, ['date', 'id'], ['asc', 'asc'])
                .groupBy('worksheet_id')
                .map((items, worksheet_id) => (Object.assign(Object.assign({}, items[0]), { items })))
                .value());
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            items: null,
            displayAll: false,
            displayCount: 5,
            ux: null,
        });
    }
    get canDisplayMoreItems() {
        if (this.loaded) {
            return !this.displayAll && this.items && this.displayCount < this.items.length;
        }
        return null;
    }
    get canHideItems() {
        if (this.loaded) {
            return this.displayAll && this.items && this.displayCount < this.items.length;
        }
        return null;
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_7736732573189513_setData_5082_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "displayAll",
                value: false,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_7736732573189513_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7736732573189513_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_2912679705172878_setData_5084_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "displayAll",
                value: true,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2912679705172878_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2912679705172878_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_5080 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_5079 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_5080,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_5079],
        });
        const act_7736732573189513_setData_5082 = new core_1.actions.SetDataAction({
            actionArgs: this.act_7736732573189513_setData_5082_getActionArgs,
            events: [],
        });
        const evh_7736732573189513_clickPreventStop_5081 = new core_1.actions.EventHandlerImpl({
            action: act_7736732573189513_setData_5082,
            event: "click.prevent.stop",
            displayName: "setData",
        });
        const evh_7736732573189513_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7736732573189513_clickPreventStop_5081],
        });
        const act_2912679705172878_setData_5084 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2912679705172878_setData_5084_getActionArgs,
            events: [],
        });
        const evh_2912679705172878_clickPreventStop_5083 = new core_1.actions.EventHandlerImpl({
            action: act_2912679705172878_setData_5084,
            event: "click.prevent.stop",
            displayName: "setData",
        });
        const evh_2912679705172878_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2912679705172878_clickPreventStop_5083],
        });
        return {
            act_7315092382398562_reloadComponentData_5080,
            evh_7315092382398562_reload_5079,
            evh_7315092382398562_reload,
            act_7736732573189513_setData_5082,
            evh_7736732573189513_clickPreventStop_5081,
            evh_7736732573189513_clickPreventStop,
            act_2912679705172878_setData_5084,
            evh_2912679705172878_clickPreventStop_5083,
            evh_2912679705172878_clickPreventStop,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], UcFixedAssetWorkItemsPanel.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcFixedAssetWorkItemsPanel.prototype, "onOid", null);
UcFixedAssetWorkItemsPanel = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcFixedAssetWorkItemsPanel);
exports.default = UcFixedAssetWorkItemsPanel;
vue_property_decorator_1.Vue.component("UcFixedAssetWorkItemsPanel", UcFixedAssetWorkItemsPanel);
