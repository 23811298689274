<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Templates"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <DIV
    class="overflow-hidden h-100 d-flex"
  >
    <ItpSidebar
      default-width="400"
      class="mr-3"
    >
      <DIV
        class="mb-2 w-100 d-flex align-items-center"
      >
        <DIV
          class="flex-grow-1"
        >
          <strong
            class="text-muted mr-2"
          >
            {{ 'Template'|pgettext('Templates') }}
          </strong>
          <PageHelp
            path="/general/templates.html"
          >
          </PageHelp>
        </DIV>
        <ItpButton
          icon="fa-plus"
          :text="'Create template...'|pgettext('Templates')"
          variant="secondary"
          size="sm"
          @click="evh_2095089031469157_click($event, {})"
        >
        </ItpButton>
      </DIV>
      <b-list-group>
        <router-link
          :key="item.id"
          :to="{
  name: 'app.templates.template',
  params: {
    id: item.id
  }
}"
          v-for="item in items"
          v-slot="{ href, route, navigate, isActive, isExactActive }"
          class="py-2"
        >
          <b-list-group-item
            :class="{'font-weight-bold': isActive, 'overflow-hidden': true}"
          >
            <ItpBox>
              <DIV
                class="w-100 mr-2"
              >
                <ItpBox
                  class="w-100"
                >
                  <b-link
                    :href="href"
                    @click="navigate"
                    class="text-truncate"
                  >
                    {{ item.name }}
                  </b-link>
                </ItpBox>
                <DIV>
                  <ItpText
                    :text="item.object_type"
                  >
                  </ItpText>
                </DIV>
              </DIV>
              <b-badge
                :class="{
    'badge-alt': true,
    'badge-primary': item.template_type === 'html',
    'badge-mjml': item.template_type === 'mjml',
    'ml-auto': true,
    'align-self-center': true,
    'mx-3': true
  }"
              >
                {{ item.template_type }}
              </b-badge>
              <button
                type="button"
                :aria-label="'Delete'|pgettext('Templates')"
                class="close ml-auto"
                @click="evh_6545969026712024_click($event, {href, route, navigate, isActive, isExactActive})"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ItpBox>
          </b-list-group-item>
        </router-link>
      </b-list-group>
    </ItpSidebar>
    <router-view
      class="h-100 w-100 overflow-hidden"
    >
    </router-view>
  </DIV>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "templates",
    path: "/templates",
    component: "Templates"
  }
)

@Component()
export default class Templates extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_template').then(data => _.sortBy(data, p => p.created_at))
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['items', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("Templates", "Templates"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_4230_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_4230, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2095089031469157_reloadComponentData_4234_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadComponentDataActionArgs> {
    // parameterResolver name=act_2095089031469157_reloadComponentData_4234, alias=undefined
    return {
    }
  }

  async act_2095089031469157_navigate_4236_getActionArgs($event: actions.ActionEvent): Promise<actions.NavigateActionArgs> {
    // parameterResolver name=act_2095089031469157_navigate_4236, alias=undefined
    return {
      location: {
        name: "app.templates.template",
        params: {
          id: $event.data.modalResult.value.id,
        }
        ,
      }
      ,
    }
  }

  async evh_2095089031469157_close_4235_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2095089031469157_close_4235, alias=undefined
    return $event.data.modalResult.value
  }

  async act_2095089031469157_showModal_4232_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2095089031469157_showModal_4232, alias=undefined
    return {
      name: "CreateTemplate",
    }
  }

  async evh_2095089031469157_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2095089031469157_click.executeFromDOM(this, event, scope);
  }

  async act_6545969026712024_request_4238_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_6545969026712024_request_4238, alias=undefined
    return {
      operation: "delete_template",
      parameters: {
        id: $event.data.item.id,
      }
      ,
    }
  }

  async act_6545969026712024_request_4238_getConfirm($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_6545969026712024_request_4238, alias=undefined
    return {
      message: this.$fn.pgettext("Templates", "Please confirm deleting the template."),
      btnOk: this.$fn.pgettext("Templates", "Delete"),
      btnCancel: this.$fn.pgettext("Templates", "Cancel"),
    }
  }

  async evh_6545969026712024_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6545969026712024_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_4230: actions.CloseModalAction;
    evh_7315092382398562_close_4229: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2095089031469157_reloadComponentData_4234: actions.ReloadComponentDataAction;
    evh_2095089031469157_close_4233: actions.EventHandlerImpl;
    act_2095089031469157_navigate_4236: actions.NavigateAction;
    evh_2095089031469157_close_4235: actions.EventHandlerImpl;
    act_2095089031469157_showModal_4232: actions.ShowModalAction;
    evh_2095089031469157_click_4231: actions.EventHandlerImpl;
    evh_2095089031469157_click: actions.EventHandlerGroup;
    act_6545969026712024_reloadComponentData_4240: actions.ReloadComponentDataAction;
    evh_6545969026712024_success_4239: actions.EventHandlerImpl;
    act_6545969026712024_request_4238: actions.RequestAction;
    evh_6545969026712024_click_4237: actions.EventHandlerImpl;
    evh_6545969026712024_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_4230 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_4230_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_4229 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_4230,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_4229],
      }
    );
    const act_2095089031469157_reloadComponentData_4234 = new actions.ReloadComponentDataAction(
      {
        actionArgs: this.act_2095089031469157_reloadComponentData_4234_getActionArgs,
        events: [],
      }
    );
    const evh_2095089031469157_close_4233 = new actions.EventHandlerImpl(
      {
        action: act_2095089031469157_reloadComponentData_4234,
        event: "close",
        displayName: "reloadComponentData",
      }
    );
    const act_2095089031469157_navigate_4236 = new actions.NavigateAction(
      {
        actionArgs: this.act_2095089031469157_navigate_4236_getActionArgs,
        events: [],
      }
    );
    const evh_2095089031469157_close_4235 = new actions.EventHandlerImpl(
      {
        action: act_2095089031469157_navigate_4236,
        event: "close",
        when: this.evh_2095089031469157_close_4235_getWhen,
        displayName: "navigate",
      }
    );
    const act_2095089031469157_showModal_4232 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2095089031469157_showModal_4232_getActionArgs,
        events: [evh_2095089031469157_close_4233, evh_2095089031469157_close_4235],
      }
    );
    const evh_2095089031469157_click_4231 = new actions.EventHandlerImpl(
      {
        action: act_2095089031469157_showModal_4232,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_2095089031469157_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_2095089031469157_click_4231],
      }
    );
    const act_6545969026712024_reloadComponentData_4240 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_6545969026712024_success_4239 = new actions.EventHandlerImpl(
      {
        action: act_6545969026712024_reloadComponentData_4240,
        event: "success",
        displayName: "reloadComponentData",
      }
    );
    const act_6545969026712024_request_4238 = new actions.RequestAction(
      {
        actionArgs: this.act_6545969026712024_request_4238_getActionArgs,
        confirm: this.act_6545969026712024_request_4238_getConfirm,
        events: [evh_6545969026712024_success_4239],
      }
    );
    const evh_6545969026712024_click_4237 = new actions.EventHandlerImpl(
      {
        action: act_6545969026712024_request_4238,
        event: "click",
        displayName: "request",
      }
    );
    const evh_6545969026712024_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_6545969026712024_click_4237],
      }
    );
    return {
      act_7315092382398562_closeModal_4230,
      evh_7315092382398562_close_4229,
      evh_7315092382398562_close,
      act_2095089031469157_reloadComponentData_4234,
      evh_2095089031469157_close_4233,
      act_2095089031469157_navigate_4236,
      evh_2095089031469157_close_4235,
      act_2095089031469157_showModal_4232,
      evh_2095089031469157_click_4231,
      evh_2095089031469157_click,
      act_6545969026712024_reloadComponentData_4240,
      evh_6545969026712024_success_4239,
      act_6545969026712024_request_4238,
      evh_6545969026712024_click_4237,
      evh_6545969026712024_click,
    }
  }
}

Vue.component("Templates", Templates);

</script>