<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <DIV
    v-if="rental.address"
  >
    <ItpText muted>{{ 'Address'|pgettext('Rental') }}:</ItpText> {{ rental.address }}

  </DIV>
  <DIV
    v-if="rental.area"
  >
    <ItpText muted>{{ 'Area'|pgettext('Rental') }}:</ItpText> {{ rental.area }}

  </DIV>
  <DIV
    v-if="rental.lot_number"
  >
    <ItpText muted>{{ 'LOT number'|pgettext('Rental') }}:</ItpText> {{ rental.lot_number }}

  </DIV>
  <DIV
    v-if="rental.house_number"
  >
    <ItpText muted>{{ 'House number'|pgettext('Rental') }}:</ItpText> {{ rental.house_number }}

  </DIV>
  <DIV
    v-if="rental.description"
  >
    <ItpText muted>{{ 'Description'|pgettext('Rental') }}:</ItpText> {{ rental.description }}

  </DIV>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractRentalCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  rental!: object;

  @Watch('rental')
  onRental(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("rental")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6612: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6611: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6612 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6611 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6612,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6611],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6612,
      evh_7315092382398562_reload_6611,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcContractRentalCard", UcContractRentalCard);

</script>