<template>
<ItpBox
  direction="column"
  alignItems="stretch"
  v-if="loaded"
>
  <ItpBox
    class="pb-2"
  >
    <strong>
      {{ 'Works'|pgettext('Worksheet') }}
    </strong>
  </ItpBox>
  <ItpDataTable
    rowKey="id"
    tableId="WorksheetWorkItems"
    :rowHeader="b_5962862420439144_rowHeader"
    :paging="b_5962862420439144_paging"
    :sortable="b_5962862420439144_sortable"
    :editable="!isReadonly"
    :value="worksheet.work_items"
    variant="details"
    :rowTemplate="{ worksheet_id: worksheet.id, task: null, service_id: null }"
    :columns="b_5962862420439144_columns"
    class="h-100"
    v-if="loaded"
    @save-completed="evh_5962862420439144_saveCompleted($event, {})"
    @save="evh_5962862420439144_save($event, {})"
    @delete="evh_5962862420439144_delete($event, {})"
    @service:selected="evh_5962862420439144_serviceSelected($event, {})"
    @service:change="evh_5962862420439144_serviceChange($event, {})"
    @quantity:change="evh_5962862420439144_quantityChange($event, {})"
    @unit_price:change="evh_5962862420439144_unitPriceChange($event, {})"
    @discount:change="evh_5962862420439144_discountChange($event, {})"
    @net_amount:change="evh_5962862420439144_netAmountChange($event, {})"
    @amount:change="evh_5962862420439144_amountChange($event, {})"
    @tax_code:change="evh_5962862420439144_taxCodeChange($event, {})"
  >
  </ItpDataTable>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcWorksheetWorkItems extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  onServiceSelected(...args: any[]) {
    const [row, service] = args;

    row.service = service
    row.service_id = service.id
    row.unit = service.unit;
    row.task = service.name
    row.tax_code = service.sales_vat_code;
    row.intermediated_service = service.intermediated_service || false;
    row.setFocus('quantity')
  }

  onServiceChanged(...args: any[]) {
    console.log('onServiceChanged', args)
  }

  calculateUnitPrice(...args: any[]) {
    const [row, tax, decimals] = args;

    row.unit_price = Big(
      Big(row.net_amount) / Big(row.quantity) / (1 - Big(row.discount) / 100)
    ).round(decimals).toNumber()
  }

  calculateNetAmount(...args: any[]) {
    const [row, tax, decimals] = args;

    row.net_amount = Big(
      Big(row.quantity) * Big(row.unit_price) * (1 - Big(row.discount) / 100)
    ).round(decimals).toNumber()
  }

  calculateNetAmountFromAmount(...args: any[]) {
    const [row, tax, decimals] = args;

    row.net_amount = Big(
      Big(row.amount) / (1 + Big(tax.rate) / 100)
    ).round(decimals).toNumber()
  }

  calculateAmount(...args: any[]) {
    const [row, tax, decimals] = args;

    row.amount = Big(
      Big(row.net_amount) * (1 + Big(tax.rate) / 100)
    ).round(decimals).toNumber()
  }

  recalculateLineItem(...args: any[]) {
    const data = args[0].data;
    const row = data.row;
    const decimals = 2;
    const tx = _.find(this.taxCodes, { id: row.tax_code });

    if (row.quantity === 0) {
      return;
    }

    switch (data.field) {
      case 'quantity':
      case 'unit_price':
      case 'discount': {
        this.calculateNetAmount(row, tx, decimals);
        this.calculateAmount(row, tx, decimals);
        break;
      }

      case 'net_amount': {
        this.calculateUnitPrice(row, tx, decimals);
        this.calculateAmount(row, tx, decimals);
        break;
      }

      case 'tax_code': {
        this.calculateAmount(row, tx, decimals);
        break;
      }

      case 'amount': {
        this.calculateNetAmountFromAmount(row, tx, decimals);
        this.calculateUnitPrice(row, tx, decimals);
        break;
      }
    }
  }

  @Prop({
    required: true,
    type: Object,
  })
  worksheet!: object;

  @Watch('worksheet')
  onWorksheet(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("worksheet")
  }

  @Prop({
    type: Boolean,
  })
  isReadonly!: boolean;

  @Watch('isReadonly')
  onIs_readonly(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("isReadonly")
  }

  expenseTypes!: any;

  async $$load_expenseTypes() {
    return this.$fn.fetch('list_object_by_type', { parameters: { object_type: 'expense-type' } })
  }

  taxCodes!: any;

  async $$load_taxCodes() {
    return this.$fn.fetch('list_tax_code')
  }

  units!: any;

  async $$load_units() {
    return this.$fn.fetch('list_unit')
  }

  ux!: any;
  b_5962862420439144_rowHeader!: any;
  b_5962862420439144_paging!: any;
  b_5962862420439144_sortable!: any;
  b_5962862420439144_columns!: any;

  async $$load_b_5962862420439144_columns() {
    return [{
      name: "service",
      type: "select2",
      header: this.$fn.gettext("Task"),
      width: "140px",
      formatter: {
        name: "template",
        value: "<b-link :to=\"{\n\
  name: 'app.product',\n\
  params: { id: row.service_id }\n\
}\">{{ row.task }}</b-link>\n\
",
      }
      ,
      options: this.$fn.searchDataSource('suggest_product', { parameters: { product_types: 'service' } }),
      optionValueField: "name",
      valueField: "id",
      textField: "name",
      bindObject: true,
      validator: ["required"
        ,
      ]
      ,
    }
      ,
    {
      name: "asset",
      type: "select2",
      header: this.$fn.gettext("Asset"),
      width: "140px",
      formatter: {
        name: "template",
        value: "<b-link :to=\"{\n\
  name: 'app.fixedAsset',\n\
  params: { id: row.asset_id }\n\
}\">{{ row.asset ? row.asset.name : '-' }}</b-link>\n\
",
      }
      ,
      options: this.$fn.searchDataSource('suggest_fixed_asset'),
      optionValueField: "name",
      valueField: "id",
      textField: "name",
      bindObject: true,
    }
      ,
    {
      name: "date",
      type: "date",
      header: this.$fn.gettext("Date"),
      width: "140px",
      required: true,
    }
      ,
    {
      name: "notes",
      type: "textarea",
      width: "200px",
      header: this.$fn.gettext("Notes"),
    }
      ,
    {
      name: "quantity",
      type: "number",
      default: 0,
      header: this.$fn.pgettext("Quantity Abbr", "Qty"),
      headerTooltip: this.$fn.gettext("Quantity"),
      width: "60px",
      textAlign: "right",
      required: true,
      editorIsDisabled: row => !row.service || !row.service.id,
    }
      ,
    {
      name: "unit",
      header: this.$fn.pgettext("Unit Abbr", "Unit"),
      headerTooltip: this.$fn.gettext("Unit"),
      width: "70px",
      textAlign: "center",
      required: true,
      editorIsDisabled: row => !row.service || !row.service.id,
    }
      ,
    {
      name: "unit_price",
      type: "number",
      default: 0,
      header: this.$fn.gettext("Price"),
      width: "70px",
      textAlign: "right",
      required: true,
      editorIsDisabled: row => !row.service || !row.service.id,
    }
      ,
    {
      name: "discount",
      type: "number",
      default: 0,
      header: this.$fn.pgettext("AbbrDiscount", "Disc"),
      headerTooltip: this.$fn.gettext("Discount"),
      width: "70px",
      required: true,
      editorIsDisabled: row => !row.service || !row.service.id,
    }
      ,
    {
      name: "net_amount",
      type: "number",
      default: 0,
      header: this.$fn.gettext("Net"),
      width: "100px",
      textAlign: "right",
      required: true,
      editorIsDisabled: row => !row.service || !row.service.id,
    }
      ,
    {
      name: "amount",
      type: "number",
      default: 0,
      header: this.$fn.gettext("Amount"),
      width: "100px",
      textAlign: "right",
      required: true,
      editorIsDisabled: row => !row.service || !row.service.id,
    }
      ,
    {
      name: "tax_code",
      header: this.$fn.gettext("VAT"),
      headerTooltip: this.$fn.gettext("VAT Code"),
      width: "100px",
      type: "select",
      options: this.taxCodes,
      valueField: "id",
      textField: "id",
      editorIsDisabled: row => !row.service || !row.service.id,
    }
      ,
    {
      name: "intermediated_service",
      header: this.$fn.pgettext("AbbrIntermediatedService", "IMS"),
      headerTooltip: this.$fn.gettext("Intermediated Service"),
      width: "60px",
      type: "checkbox",
      editorIsDisabled: row => !row.service || !row.service.id,
    }
      ,
    ]
  }

  dataMembers = ['expenseTypes', 'taxCodes', 'units', 'ux', 'b_5962862420439144_rowHeader', 'b_5962862420439144_paging', 'b_5962862420439144_sortable', 'b_5962862420439144_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        expenseTypes: null,
        taxCodes: null,
        units: null,
        ux: {
          errorHandling: "local",
          initialLoadSpinner: true,
        }
        ,
        b_5962862420439144_rowHeader: false,
        b_5962862420439144_paging: false,
        b_5962862420439144_sortable: false,
        b_5962862420439144_columns: null,
      },
    }
  }

  async act_5962862420439144_emit_6078_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5962862420439144_emit_6078, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_5962862420439144_saveCompleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_saveCompleted.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6080_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6080, alias=undefined
    this.worksheet.id
      ? $event.data.waitFor(this.$fn.crud('work_item').upsert({ ...$event.data.row, worksheet_id: this.worksheet.id }))
      : this.worksheet.work_items.splice(this.worksheet.work_items.length - 1, 0, $event.data.row)
  }

  async act_5962862420439144_script_6080_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6080, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6080_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_save(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_save.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6082_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6082, alias=undefined
    this.worksheet.id
      ? $event.data.waitFor(this.$fn.crud('work_item').delete($event.data.row))
      : _.remove(this.worksheet.work_items, $event.data.row)
  }

  async act_5962862420439144_script_6082_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6082, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6082_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_delete.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6084_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6084, alias=undefined
    this.onServiceSelected($event.data.row, $event.data.option)
  }

  async act_5962862420439144_script_6084_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6084, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6084_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_serviceSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_serviceSelected.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6086_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6086, alias=undefined
    this.onServiceChanged($event)
  }

  async act_5962862420439144_script_6086_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6086, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6086_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_serviceChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_serviceChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6088_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6088, alias=undefined
    this.recalculateLineItem($event)
  }

  async act_5962862420439144_script_6088_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6088, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6088_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_quantityChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_quantityChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6090_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6090, alias=undefined
    this.recalculateLineItem($event)
  }

  async act_5962862420439144_script_6090_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6090, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6090_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_unitPriceChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_unitPriceChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6092_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6092, alias=undefined
    this.recalculateLineItem($event)
  }

  async act_5962862420439144_script_6092_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6092, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6092_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_discountChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_discountChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6094_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6094, alias=undefined
    this.recalculateLineItem($event)
  }

  async act_5962862420439144_script_6094_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6094, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6094_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_netAmountChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_netAmountChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6096_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6096, alias=undefined
    this.recalculateLineItem($event)
  }

  async act_5962862420439144_script_6096_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6096, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6096_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_amountChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_amountChange.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_6098_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_6098, alias=undefined
    this.recalculateLineItem($event)
  }

  async act_5962862420439144_script_6098_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_6098, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_6098_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_taxCodeChange(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_taxCodeChange.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_5962862420439144_emit_6078: actions.EmitAction;
    evh_5962862420439144_saveCompleted_6077: actions.EventHandlerImpl;
    evh_5962862420439144_saveCompleted: actions.EventHandlerGroup;
    act_5962862420439144_script_6080: actions.ScriptAction;
    evh_5962862420439144_save_6079: actions.EventHandlerImpl;
    evh_5962862420439144_save: actions.EventHandlerGroup;
    act_5962862420439144_script_6082: actions.ScriptAction;
    evh_5962862420439144_delete_6081: actions.EventHandlerImpl;
    evh_5962862420439144_delete: actions.EventHandlerGroup;
    act_5962862420439144_script_6084: actions.ScriptAction;
    evh_5962862420439144_serviceSelected_6083: actions.EventHandlerImpl;
    evh_5962862420439144_serviceSelected: actions.EventHandlerGroup;
    act_5962862420439144_script_6086: actions.ScriptAction;
    evh_5962862420439144_serviceChange_6085: actions.EventHandlerImpl;
    evh_5962862420439144_serviceChange: actions.EventHandlerGroup;
    act_5962862420439144_script_6088: actions.ScriptAction;
    evh_5962862420439144_quantityChange_6087: actions.EventHandlerImpl;
    evh_5962862420439144_quantityChange: actions.EventHandlerGroup;
    act_5962862420439144_script_6090: actions.ScriptAction;
    evh_5962862420439144_unitPriceChange_6089: actions.EventHandlerImpl;
    evh_5962862420439144_unitPriceChange: actions.EventHandlerGroup;
    act_5962862420439144_script_6092: actions.ScriptAction;
    evh_5962862420439144_discountChange_6091: actions.EventHandlerImpl;
    evh_5962862420439144_discountChange: actions.EventHandlerGroup;
    act_5962862420439144_script_6094: actions.ScriptAction;
    evh_5962862420439144_netAmountChange_6093: actions.EventHandlerImpl;
    evh_5962862420439144_netAmountChange: actions.EventHandlerGroup;
    act_5962862420439144_script_6096: actions.ScriptAction;
    evh_5962862420439144_amountChange_6095: actions.EventHandlerImpl;
    evh_5962862420439144_amountChange: actions.EventHandlerGroup;
    act_5962862420439144_script_6098: actions.ScriptAction;
    evh_5962862420439144_taxCodeChange_6097: actions.EventHandlerImpl;
    evh_5962862420439144_taxCodeChange: actions.EventHandlerGroup;
  }

  getActions() {
    const act_5962862420439144_emit_6078 = new actions.EmitAction(
      {
        actionArgs: this.act_5962862420439144_emit_6078_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_saveCompleted_6077 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_emit_6078,
        event: "save-completed",
        displayName: "emit",
      }
    );
    const evh_5962862420439144_saveCompleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_saveCompleted_6077],
      }
    );
    const act_5962862420439144_script_6080 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6080_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_save_6079 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6080,
        event: "save",
        displayName: "script",
      }
    );
    const evh_5962862420439144_save = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_save_6079],
      }
    );
    const act_5962862420439144_script_6082 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6082_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_delete_6081 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6082,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_5962862420439144_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_delete_6081],
      }
    );
    const act_5962862420439144_script_6084 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6084_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_serviceSelected_6083 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6084,
        event: "service:selected",
        displayName: "script",
      }
    );
    const evh_5962862420439144_serviceSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_serviceSelected_6083],
      }
    );
    const act_5962862420439144_script_6086 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6086_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_serviceChange_6085 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6086,
        event: "service:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_serviceChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_serviceChange_6085],
      }
    );
    const act_5962862420439144_script_6088 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6088_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_quantityChange_6087 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6088,
        event: "quantity:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_quantityChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_quantityChange_6087],
      }
    );
    const act_5962862420439144_script_6090 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6090_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_unitPriceChange_6089 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6090,
        event: "unit_price:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_unitPriceChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_unitPriceChange_6089],
      }
    );
    const act_5962862420439144_script_6092 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6092_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_discountChange_6091 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6092,
        event: "discount:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_discountChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_discountChange_6091],
      }
    );
    const act_5962862420439144_script_6094 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6094_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_netAmountChange_6093 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6094,
        event: "net_amount:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_netAmountChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_netAmountChange_6093],
      }
    );
    const act_5962862420439144_script_6096 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6096_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_amountChange_6095 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6096,
        event: "amount:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_amountChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_amountChange_6095],
      }
    );
    const act_5962862420439144_script_6098 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_6098_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_taxCodeChange_6097 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_6098,
        event: "tax_code:change",
        displayName: "script",
      }
    );
    const evh_5962862420439144_taxCodeChange = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_taxCodeChange_6097],
      }
    );
    return {
      act_5962862420439144_emit_6078,
      evh_5962862420439144_saveCompleted_6077,
      evh_5962862420439144_saveCompleted,
      act_5962862420439144_script_6080,
      evh_5962862420439144_save_6079,
      evh_5962862420439144_save,
      act_5962862420439144_script_6082,
      evh_5962862420439144_delete_6081,
      evh_5962862420439144_delete,
      act_5962862420439144_script_6084,
      evh_5962862420439144_serviceSelected_6083,
      evh_5962862420439144_serviceSelected,
      act_5962862420439144_script_6086,
      evh_5962862420439144_serviceChange_6085,
      evh_5962862420439144_serviceChange,
      act_5962862420439144_script_6088,
      evh_5962862420439144_quantityChange_6087,
      evh_5962862420439144_quantityChange,
      act_5962862420439144_script_6090,
      evh_5962862420439144_unitPriceChange_6089,
      evh_5962862420439144_unitPriceChange,
      act_5962862420439144_script_6092,
      evh_5962862420439144_discountChange_6091,
      evh_5962862420439144_discountChange,
      act_5962862420439144_script_6094,
      evh_5962862420439144_netAmountChange_6093,
      evh_5962862420439144_netAmountChange,
      act_5962862420439144_script_6096,
      evh_5962862420439144_amountChange_6095,
      evh_5962862420439144_amountChange,
      act_5962862420439144_script_6098,
      evh_5962862420439144_taxCodeChange_6097,
      evh_5962862420439144_taxCodeChange,
    }
  }
}

Vue.component("UcWorksheetWorkItems", UcWorksheetWorkItems);

</script>