"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let ClientAddresses = class ClientAddresses extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['items', 'ux', 'b_7315092382398562_columns'];
    }
    beforeCreate() {
    }
    onOwner(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("owner");
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_client_address', { parameters: { 'client_id': this.owner.id } });
        });
    }
    $$load_b_7315092382398562_columns() {
        return __awaiter(this, void 0, void 0, function* () {
            return [{
                    name: "locked",
                    type: "lock",
                },
                {
                    name: "inactive",
                    header: this.$fn.gettext("Inactive"),
                    width: "30px",
                    textAlign: "center",
                    formatter: {
                        name: "checkbox",
                    },
                    editor: {
                        name: "checkbox",
                    },
                },
                {
                    name: "valid_from",
                    type: "date",
                    header: this.$fn.gettext("Valid From"),
                    required: true,
                    width: "120px",
                },
                {
                    name: "valid_to",
                    type: "date",
                    header: this.$fn.gettext("Valid To"),
                    width: "120px",
                },
                {
                    name: "quantity_from",
                    type: "number",
                    header: this.$fn.gettext("Quantity from"),
                    width: "110px",
                    validator: ["numeric",
                    ],
                },
                {
                    name: "price",
                    type: "number",
                    header: this.$fn.gettext("Price"),
                    width: "110px",
                    validator: ["required",
                        "numeric",
                    ],
                },
                {
                    name: "currency",
                    header: this.$fn.gettext("Currency"),
                    width: "110px",
                    required: true,
                    type: "select",
                    options: this.currencies,
                },
                {
                    name: "is_discount_price",
                    type: "checkbox",
                    header: this.$fn.gettext("Discount price"),
                },
                {
                    name: "is_purchase_price",
                    type: "checkbox",
                    header: this.$fn.gettext("Purchase price"),
                },
                {
                    name: "note",
                    type: "textarea",
                    header: this.$fn.gettext("Note"),
                    width: "200px",
                },
            ];
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            items: null,
            ux: {
                initialLoadSpinner: true,
            },
            b_7315092382398562_columns: null,
        });
    }
    act_7315092382398562_script_5092_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('part_price').upsert($event.data.row));
        });
    }
    act_7315092382398562_script_5092_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_7315092382398562_script_5092_getActionArgs_value($event),
            };
        });
    }
    evh_7315092382398562_save(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_save.executeFromDOM(this, event, scope);
        });
    }
    act_7315092382398562_script_5094_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('part_price').delete($event.data.row));
        });
    }
    act_7315092382398562_script_5094_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_7315092382398562_script_5094_getActionArgs_value($event),
            };
        });
    }
    evh_7315092382398562_delete(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_delete.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_script_5092 = new core_1.actions.ScriptAction({
            actionArgs: this.act_7315092382398562_script_5092_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_save_5091 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_script_5092,
            event: "save",
            displayName: "script",
        });
        const evh_7315092382398562_save = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_save_5091],
        });
        const act_7315092382398562_script_5094 = new core_1.actions.ScriptAction({
            actionArgs: this.act_7315092382398562_script_5094_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_delete_5093 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_script_5094,
            event: "delete",
            displayName: "script",
        });
        const evh_7315092382398562_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_delete_5093],
        });
        return {
            act_7315092382398562_script_5092,
            evh_7315092382398562_save_5091,
            evh_7315092382398562_save,
            act_7315092382398562_script_5094,
            evh_7315092382398562_delete_5093,
            evh_7315092382398562_delete,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], ClientAddresses.prototype, "owner", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('owner'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ClientAddresses.prototype, "onOwner", null);
ClientAddresses = __decorate([
    (0, vue_property_decorator_1.Component)()
], ClientAddresses);
exports.default = ClientAddresses;
vue_property_decorator_1.Vue.component("ClientAddresses", ClientAddresses);
