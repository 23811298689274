<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Contracts"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="contracts"
    :headerTitle="'Contracts'|pgettext('Contracts')"
    :items="$fn.slickDataSource('integro_api/list_contract', {parameters: () => dsParams})"
    checkbox-row-selection
    export-to-csv
    :exportToCsvFilename="'contracts'|pgettext('filename')"
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <DIV
        class="mx-2"
      >
        <ItpFormCheckbox
          name="skip_archived"
          :label="'Skip archived contracts'|pgettext('Contracts')"
          inline
          v-model="dsParams.skip_archived"
        >
        </ItpFormCheckbox>
      </DIV>
      <DIV
        class="border-left px-4"
      >
        <ItpButton
          :icon="$config.contract.createContractIcon"
          :text="'Create contract...'|pgettext('Contracts')"
          size="sm"
          variant="light"
          @click="evh_4440454779651433_click($event, {})"
        >
        </ItpButton>
      </DIV>
      <PageHelp
        path="/contracts/contracts.html"
      >
      </PageHelp>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "contracts",
    path: "/contracts",
    component: "Contracts"
  }
)

@Component()
export default class Contracts extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_dsParams = new ComponentValueCache(this, "dsParams", "Contracts.dsParams");
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedItems!: any;
  dsParams!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;
  dataMembers = ['selectedItems', 'dsParams', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedItems: [
        ]
        ,
        dsParams: {
          skip_archived: true,
        }
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: [{
          field: "contract_number",
          name: this.$fn.pgettext("Contracts", "Contract number"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "contract",
            params: {
              id: "id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "contract_type",
          name: this.$fn.pgettext("Contracts", "Type"),
          formatter: {
            name: "chain",
            mode: "mapReduce",
            formatters: [{
              name: "gettext",
              context: "ContractTypes",
              conversion: "StartCase",
              filterInput: true,
            }
              ,
            {
              name: "badge",
              variantMapSource: "fieldValue",
              variantmap: {
                rental: "contract-type--rental",
                vendor: "contract-type--vendor",
                rental_agreement: "contract-type--rental_agreement",
                service: "contract-type--service",
                parking: "contract-type--parking",
                unknown: "contract-type--unknown",
              }
              ,
            }
              ,
            ]
            ,
          }
          ,
        }
          ,
        {
          field: "status",
          name: this.$fn.pgettext("Contracts", "Status"),
          formatter: {
            name: "chain",
            mode: "mapReduce",
            formatters: [{
              name: "gettext",
              context: "ContractStatus",
              conversion: "StartCase",
              filterInput: true,
            }
              ,
            {
              name: "badge",
              variantMapSource: "fieldValue",
              variantmap: {
                draft: "light",
                approved: "success",
                signed: "success",
                active: "primary",
                ended: "warning",
                terminated: "warning",
                rescinded: "danger",
                archived: "light",
              }
              ,
            }
              ,
            ]
            ,
          }
          ,
        }
          ,
        {
          field: "rental_name",
          name: this.$fn.pgettext("Contracts", "Rental"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "rental",
            navigateOnIconClick: true,
            params: {
              id: "rental_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "company_name",
          name: this.$fn.pgettext("Contracts", "Lessor"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "company",
            navigateOnIconClick: true,
            params: {
              id: "company_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "counterparty_name",
          name: this.$fn.pgettext("Contracts", "Tenant"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "client",
            navigateOnIconClick: true,
            params: {
              id: "counterparty_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "revision_number",
          name: this.$fn.pgettext("Contracts", "Version"),
        }
          ,
        {
          field: "revision_date",
          name: this.$fn.pgettext("Contracts", "Revision date"),
          formatter: {
            name: "date",
          }
          ,
        }
          ,
        {
          field: "contract_date",
          name: this.$fn.pgettext("Contracts", "Contract date"),
          formatter: {
            name: "date",
          }
          ,
        }
          ,
        {
          field: "valid_from",
          name: this.$fn.pgettext("Contracts", "Valid From"),
          formatter: {
            name: "date",
          }
          ,
        }
          ,
        {
          field: "valid_to",
          name: this.$fn.pgettext("Contracts", "Valid To"),
          formatter: {
            name: "date",
          }
          ,
        }
          ,
        {
          field: "next_indexation_date",
          name: this.$fn.pgettext("Contracts", "Next indexation"),
          formatter: {
            name: "date",
          }
          ,
        }
          ,
        {
          field: "termination_date",
          name: this.$fn.pgettext("Contracts", "Termination date"),
          formatter: {
            name: "date",
          }
          ,
        }
          ,
        {
          field: "termination_effective_date",
          name: this.$fn.pgettext("Contracts", "Termination effective date"),
          formatter: {
            name: "date",
          }
          ,
        }
          ,
        {
          field: "folder_number",
          name: this.$fn.pgettext("Contracts", "Folder number"),
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Contracts"),
        icon: this.$config.contract.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2740_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2740, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_2742_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_2742, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_4440454779651433_showModal_2744_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_4440454779651433_showModal_2744, alias=undefined
    return {
      name: "CreateContract",
    }
  }

  async evh_4440454779651433_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4440454779651433_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2740: actions.CloseModalAction;
    evh_7315092382398562_close_2739: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_setData_2742: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_2741: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_4440454779651433_showModal_2744: actions.ShowModalAction;
    evh_4440454779651433_click_2743: actions.EventHandlerImpl;
    evh_4440454779651433_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2740 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2740_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2739 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2740,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2739],
      }
    );
    const act_2248226175642056_setData_2742 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_2742_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_2741 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_2742,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_2741],
      }
    );
    const act_4440454779651433_showModal_2744 = new actions.ShowModalAction(
      {
        actionArgs: this.act_4440454779651433_showModal_2744_getActionArgs,
        events: [],
      }
    );
    const evh_4440454779651433_click_2743 = new actions.EventHandlerImpl(
      {
        action: act_4440454779651433_showModal_2744,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_4440454779651433_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_4440454779651433_click_2743],
      }
    );
    return {
      act_7315092382398562_closeModal_2740,
      evh_7315092382398562_close_2739,
      evh_7315092382398562_close,
      act_2248226175642056_setData_2742,
      evh_2248226175642056_selectedRowsChanged_2741,
      evh_2248226175642056_selectedRowsChanged,
      act_4440454779651433_showModal_2744,
      evh_4440454779651433_click_2743,
      evh_4440454779651433_click,
    }
  }
}

Vue.component("Contracts", Contracts);

</script>