<template>
<ItpModal
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="ClientDataImportModal"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <DIV
      class="container"
    >
      <ItpForm
        name="clientDataImport"
        ref="form"
        v-if="loaded"
        @submit="evh_5534025912102772_submit($event, {})"
      >
        <ItpFormGroup
          row
          name="file"
          :label="'File'|gettext"
          labelFor="file"
          label-cols-md="2"
          content-cols-md="10"
        >
          <ItpFormFile
            v-model="file"
            name="file"
            size="md"
            accept=".xlsx, .csv"
            required
          >
          </ItpFormFile>
        </ItpFormGroup>
        <ItpFormGroup
          row
          name="header"
          label-cols-md="0"
          content-cols-md="12"
        >
          <ItpFormCheckbox
            name="headered"
            :label="'Import file has header'|gettext"
            v-model="headered"
            size="md"
          >
          </ItpFormCheckbox>
        </ItpFormGroup>
        <ItpFormGroup
          row
          name="colnum_number"
          :label="'Number column number'|gettext"
          label-cols-md="5"
          content-cols-md="3"
        >
          <ItpFormInput
            type="number"
            name="colnum_number"
            v-model="colnum_number"
            size="md"
            number
            required
            min="1"
          >
          </ItpFormInput>
        </ItpFormGroup>
        <ItpFormGroup
          row
          name="colnum_name"
          :label="'Name column number'|gettext"
          label-cols-md="5"
          content-cols-md="3"
        >
          <ItpFormInput
            type="number"
            name="colnum_name"
            v-model="colnum_name"
            size="md"
            number
            required
            min="1"
          >
          </ItpFormInput>
        </ItpFormGroup>
        <ItpFormGroup
          row
          name="colnum_email"
          :label="'Email column number'|gettext"
          label-cols-md="5"
          content-cols-md="3"
        >
          <ItpFormInput
            type="number"
            name="colnum_email"
            v-model="colnum_email"
            size="md"
            number
            required
            min="1"
          >
          </ItpFormInput>
        </ItpFormGroup>
      </ItpForm>
    </DIV>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :form="forms.clientDataImport && forms.clientDataImport.id"
      :text="'Import'|gettext"
      type="submit"
      variant="primary"
      icon="fa-file-import"
      :spinning="actions.submit.isRunning"
      :disabled="!forms.clientDataImport || !forms.clientDataImport.submittable"
    >
    </ItpButton>
    <ItpButton
      :text="'Cancel'|gettext"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpModal>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class ClientDataImportModal extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "modal",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  file!: any;
  headered!: any;
  colnum_number!: any;
  colnum_name!: any;
  colnum_email!: any;
  ux!: any;
  dataMembers = ['file', 'headered', 'colnum_number', 'colnum_name', 'colnum_email', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        file: null,
        headered: true,
        colnum_number: 1,
        colnum_name: 2,
        colnum_email: 3,
        ux: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Import client data"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1742_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1742, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_5534025912102772_register_1748_getActionArgs($event: actions.ActionEvent): Promise<actions.RegisterActionArgs> {
    // parameterResolver name=act_5534025912102772_register_1748, alias=undefined
    return {
      name: "resultLength",
      value: $event.data.response.data ? $event.data.response.data.updated_records : 0,
    }
  }

  async act_5534025912102772_notification_1750_getActionArgs($event: actions.ActionEvent): Promise<actions.NotificationActionArgs> {
    // parameterResolver name=act_5534025912102772_notification_1750, alias=undefined
    return {
      message: this.$fn.ngettext("One record imported.", "${n} records imported.", $event.data.resultLength),
    }
  }

  async act_5534025912102772_notification_1750_getWhen($event: actions.ActionEvent): Promise<actions.NotificationActionArgs> {
    // parameterResolver name=act_5534025912102772_notification_1750, alias=undefined
    return $event.data.resultLength > 0
  }

  async act_5534025912102772_notification_1752_getActionArgs($event: actions.ActionEvent): Promise<actions.NotificationActionArgs> {
    // parameterResolver name=act_5534025912102772_notification_1752, alias=undefined
    return {
      message: this.$fn.gettext("Nothing has been imported."),
    }
  }

  async act_5534025912102772_notification_1752_getWhen($event: actions.ActionEvent): Promise<actions.NotificationActionArgs> {
    // parameterResolver name=act_5534025912102772_notification_1752, alias=undefined
    return !$event.data.resultLength
  }

  async act_5534025912102772_closeModal_1754_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_5534025912102772_closeModal_1754, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        value: $event.data.response.data,
      }
      ,
    }
  }

  async act_5534025912102772_reloadSlickgrid_1756_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_5534025912102772_reloadSlickgrid_1756, alias=undefined
    return {
      grid: "all",
    }
  }

  async act_5534025912102772_request_1746_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_5534025912102772_request_1746, alias=submit
    return {
      operation: "import_clients",
      data: { header: this.headered, file: this.file, colnum_number: this.colnum_number, colnum_name: this.colnum_name, colnum_email: this.colnum_email },
    }
  }

  async evh_5534025912102772_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5534025912102772_submit.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeModal_1758_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1419464017721962_closeModal_1758, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
      }
      ,
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1742: actions.CloseModalAction;
    evh_7315092382398562_close_1741: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_1744: actions.CloseComponentAction;
    evh_2248226175642056_close_1743: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_5534025912102772_register_1748: actions.RegisterAction;
    evh_5534025912102772_success_1747: actions.EventHandlerImpl;
    act_5534025912102772_notification_1750: actions.NotificationAction;
    evh_5534025912102772_success_1749: actions.EventHandlerImpl;
    act_5534025912102772_notification_1752: actions.NotificationAction;
    evh_5534025912102772_success_1751: actions.EventHandlerImpl;
    act_5534025912102772_closeModal_1754: actions.CloseModalAction;
    evh_5534025912102772_success_1753: actions.EventHandlerImpl;
    act_5534025912102772_reloadSlickgrid_1756: actions.ReloadSlickgridAction;
    evh_5534025912102772_success_1755: actions.EventHandlerImpl;
    act_5534025912102772_request_1746: actions.RequestAction;
    evh_5534025912102772_submit_1745: actions.EventHandlerImpl;
    evh_5534025912102772_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeModal_1758: actions.CloseModalAction;
    evh_1419464017721962_clickPreventStop_1757: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1742 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1742_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1741 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1742,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1741],
      }
    );
    const act_2248226175642056_closeComponent_1744 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_1743 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_1744,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_1743],
      }
    );
    const act_5534025912102772_register_1748 = new actions.RegisterAction(
      {
        actionArgs: this.act_5534025912102772_register_1748_getActionArgs,
        events: [],
      }
    );
    const evh_5534025912102772_success_1747 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_register_1748,
        event: "success",
        displayName: "register",
      }
    );
    const act_5534025912102772_notification_1750 = new actions.NotificationAction(
      {
        actionArgs: this.act_5534025912102772_notification_1750_getActionArgs,
        when: this.act_5534025912102772_notification_1750_getWhen,
        events: [],
      }
    );
    const evh_5534025912102772_success_1749 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_notification_1750,
        event: "success",
        displayName: "notification",
      }
    );
    const act_5534025912102772_notification_1752 = new actions.NotificationAction(
      {
        actionArgs: this.act_5534025912102772_notification_1752_getActionArgs,
        when: this.act_5534025912102772_notification_1752_getWhen,
        events: [],
      }
    );
    const evh_5534025912102772_success_1751 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_notification_1752,
        event: "success",
        displayName: "notification",
      }
    );
    const act_5534025912102772_closeModal_1754 = new actions.CloseModalAction(
      {
        actionArgs: this.act_5534025912102772_closeModal_1754_getActionArgs,
        events: [],
      }
    );
    const evh_5534025912102772_success_1753 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_closeModal_1754,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_5534025912102772_reloadSlickgrid_1756 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_5534025912102772_reloadSlickgrid_1756_getActionArgs,
        events: [],
      }
    );
    const evh_5534025912102772_success_1755 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_reloadSlickgrid_1756,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_5534025912102772_request_1746 = new actions.RequestAction(
      {
        actionArgs: this.act_5534025912102772_request_1746_getActionArgs,
        displayName: "submit",
        events: [evh_5534025912102772_success_1747, evh_5534025912102772_success_1749, evh_5534025912102772_success_1751, evh_5534025912102772_success_1753, evh_5534025912102772_success_1755],
      }
    );
    const evh_5534025912102772_submit_1745 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_request_1746,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_5534025912102772_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_5534025912102772_submit_1745],
      }
    );
    const act_1419464017721962_closeModal_1758 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1419464017721962_closeModal_1758_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_1757 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeModal_1758,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_1757],
      }
    );
    return {
      act_7315092382398562_closeModal_1742,
      evh_7315092382398562_close_1741,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_1744,
      evh_2248226175642056_close_1743,
      evh_2248226175642056_close,
      act_5534025912102772_register_1748,
      evh_5534025912102772_success_1747,
      act_5534025912102772_notification_1750,
      evh_5534025912102772_success_1749,
      act_5534025912102772_notification_1752,
      evh_5534025912102772_success_1751,
      act_5534025912102772_closeModal_1754,
      evh_5534025912102772_success_1753,
      act_5534025912102772_reloadSlickgrid_1756,
      evh_5534025912102772_success_1755,
      act_5534025912102772_request_1746,
      evh_5534025912102772_submit_1745,
      evh_5534025912102772_submit,
      act_1419464017721962_closeModal_1758,
      evh_1419464017721962_clickPreventStop_1757,
      evh_1419464017721962_clickPreventStop,
      submit: act_5534025912102772_request_1746,
    }
  }
}

Vue.component("ClientDataImportModal", ClientDataImportModal);

</script>