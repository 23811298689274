<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="CreateMeterReadings"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      name="createOptions"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpFormGroup
        name="notes"
        :label="'Notes'|pgettext('CreateMeterReadings')"
      >
        <ItpFormTextarea
          name="notes"
          v-model="notes"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
      <ItpFormGroup
        name="irentWriteback"
        :description="'Save values to the legacy database, too.'|pgettext('CreateMeterReadings')"
        row
        label-cols-md="0"
        content-cols-md="12"
      >
        <ItpFormCheckbox
          name="irentWriteback"
          v-model="irentWriteback"
          :label="'IntegroRent writeback'|pgettext('CreateMeterReadings')"
        >
        </ItpFormCheckbox>
      </ItpFormGroup>
      <ItpFormGroup
        name="irentWriteback"
        :description="'Skip writeback to the legacy database if the serial number is not found in the legacy database.'|pgettext('GenerateMeterReading')"
        row
        label-cols-md="0"
        content-cols-md="12"
      >
        <ItpFormCheckbox
          name="irentWritebackSkipMetersNotFound"
          v-model="irentWritebackSkipMetersNotFound"
          :label="'IntegroRent writeback - skip not found'|pgettext('CreateMeterReadings')"
        >
        </ItpFormCheckbox>
      </ItpFormGroup>
      <ItpFormGroup
        name="skipMetersNotFound"
        :description="'Skip meters not found in the database.'|pgettext('CreateMeterReadings')"
        row
        label-cols-md="0"
        content-cols-md="12"
      >
        <ItpFormCheckbox
          name="skipMetersNotFound"
          v-model="skipMetersNotFound"
          :label="'Skip not found'|pgettext('CreateMeterReadings')"
        >
        </ItpFormCheckbox>
      </ItpFormGroup>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :form="forms.createOptions && forms.createOptions.id"
      :text="'Create Readings'|pgettext('MeterMeasurementGenerateButton')"
      type="submit"
      variant="primary"
      :spinning="actions.submit.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Cancel'|pgettext('CreateMeterReadings')"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class CreateMeterReadings extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    default: null,
  })
  items!: list;

  @Watch('items')
  onItems(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("items")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  notes!: any;
  irentWriteback!: any;
  skipMetersNotFound!: any;
  irentWritebackSkipMetersNotFound!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['notes', 'irentWriteback', 'skipMetersNotFound', 'irentWritebackSkipMetersNotFound', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        notes: null,
        irentWriteback: false,
        skipMetersNotFound: false,
        irentWritebackSkipMetersNotFound: false,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("CreateMeterReadings", "Create Meter Readings"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_5756_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_5756, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_register_5762_getActionArgs($event: actions.ActionEvent): Promise<actions.RegisterActionArgs> {
    // parameterResolver name=act_1040167445267876_register_5762, alias=undefined
    return {
      name: "resultLength",
      value: $event.data.response.data ? $event.data.response.data.length : 0,
    }
  }

  async act_1040167445267876_notification_5764_getActionArgs($event: actions.ActionEvent): Promise<actions.NotificationActionArgs> {
    // parameterResolver name=act_1040167445267876_notification_5764, alias=undefined
    return {
      message: this.$fn.ngettext("One meter reading created.", "${n} meter readings created.", $event.data.resultLength),
    }
  }

  async act_1040167445267876_notification_5764_getWhen($event: actions.ActionEvent): Promise<actions.NotificationActionArgs> {
    // parameterResolver name=act_1040167445267876_notification_5764, alias=undefined
    return $event.data.resultLength > 0
  }

  async act_1040167445267876_closeModal_5766_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_5766, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        value: $event.data.response.data,
      }
      ,
    }
  }

  async act_1040167445267876_reloadSlickgrid_5768_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_5768, alias=undefined
    return {
      grid: "all",
    }
  }

  async act_1040167445267876_request_5760_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1040167445267876_request_5760, alias=submit
    return {
      operation: "create_meter_reading",
      data: this.items
        .filter(p => !!p.meter_id)
        .map(p => ({
          meter_id: p.meter_id,
          value: p.value,
          unit: p.unit,
          notes: this.notes,
          reading_type: 'automated',
          reading_date: this.$fn.formatDate(this.$fn.parseISODate(p.timestamp), 'yyyy-MM-dd'),
          reading_time: this.$fn.formatDate(this.$fn.parseISODate(p.timestamp), 'hh:mm'),
        })),
      parameters: {
        "irent-writeback": this.irentWriteback,
        "skip-meters-not-found": this.skipMetersNotFound,
        "irent-writeback-skip-meters-not-found": this.irentWritebackSkipMetersNotFound,
      }
      ,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_5756: actions.CloseModalAction;
    evh_7315092382398562_close_5755: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_5758: actions.CloseComponentAction;
    evh_2248226175642056_close_5757: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_register_5762: actions.RegisterAction;
    evh_1040167445267876_success_5761: actions.EventHandlerImpl;
    act_1040167445267876_notification_5764: actions.NotificationAction;
    evh_1040167445267876_success_5763: actions.EventHandlerImpl;
    act_1040167445267876_closeModal_5766: actions.CloseModalAction;
    evh_1040167445267876_success_5765: actions.EventHandlerImpl;
    act_1040167445267876_reloadSlickgrid_5768: actions.ReloadSlickgridAction;
    evh_1040167445267876_success_5767: actions.EventHandlerImpl;
    act_1040167445267876_request_5760: actions.RequestAction;
    evh_1040167445267876_submit_5759: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_5770: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_5769: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_5756 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_5756_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_5755 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_5756,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_5755],
      }
    );
    const act_2248226175642056_closeComponent_5758 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_5757 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_5758,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_5757],
      }
    );
    const act_1040167445267876_register_5762 = new actions.RegisterAction(
      {
        actionArgs: this.act_1040167445267876_register_5762_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_5761 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_register_5762,
        event: "success",
        displayName: "register",
      }
    );
    const act_1040167445267876_notification_5764 = new actions.NotificationAction(
      {
        actionArgs: this.act_1040167445267876_notification_5764_getActionArgs,
        when: this.act_1040167445267876_notification_5764_getWhen,
        events: [],
      }
    );
    const evh_1040167445267876_success_5763 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_notification_5764,
        event: "success",
        displayName: "notification",
      }
    );
    const act_1040167445267876_closeModal_5766 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_5766_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_5765 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_5766,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_reloadSlickgrid_5768 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1040167445267876_reloadSlickgrid_5768_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_5767 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadSlickgrid_5768,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_1040167445267876_request_5760 = new actions.RequestAction(
      {
        actionArgs: this.act_1040167445267876_request_5760_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_success_5761, evh_1040167445267876_success_5763, evh_1040167445267876_success_5765, evh_1040167445267876_success_5767],
      }
    );
    const evh_1040167445267876_submit_5759 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_request_5760,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_5759],
      }
    );
    const act_1419464017721962_closeComponent_5770 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_5769 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_5770,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_5769],
      }
    );
    return {
      act_7315092382398562_closeModal_5756,
      evh_7315092382398562_close_5755,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_5758,
      evh_2248226175642056_close_5757,
      evh_2248226175642056_close,
      act_1040167445267876_register_5762,
      evh_1040167445267876_success_5761,
      act_1040167445267876_notification_5764,
      evh_1040167445267876_success_5763,
      act_1040167445267876_closeModal_5766,
      evh_1040167445267876_success_5765,
      act_1040167445267876_reloadSlickgrid_5768,
      evh_1040167445267876_success_5767,
      act_1040167445267876_request_5760,
      evh_1040167445267876_submit_5759,
      evh_1040167445267876_submit,
      act_1419464017721962_closeComponent_5770,
      evh_1419464017721962_clickPreventStop_5769,
      evh_1419464017721962_clickPreventStop,
      submit: act_1040167445267876_request_5760,
    }
  }
}

Vue.component("CreateMeterReadings", CreateMeterReadings);

</script>