"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcProductPrices = class UcProductPrices extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['items', 'currencies', 'ux'];
    }
    beforeCreate() {
    }
    sortItems(...args) {
        return _.orderBy(args[0], ['valid_from', 'price'], ['desc', 'desc']);
    }
    onProduct(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("product");
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_product_price', { parameters: { 'product_id': this.product.id } });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            items: null,
            currencies: ["HUF",
                "EUR",
                "USD",
            ],
            ux: {
                initialLoadSpinner: true,
            },
        });
    }
    get purchasePrices() {
        if (this.loaded) {
            return this.sortItems(_.filter(this.items, { is_purchase_price: true }));
        }
        return null;
    }
    get sellingPrices() {
        if (this.loaded) {
            return this.sortItems(_.filter(this.items, { is_purchase_price: false }));
        }
        return null;
    }
    evh_3343864469950044_close_5965_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.value;
        });
    }
    act_3343864469950044_showModal_5964_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ProductPrice",
                props: {
                    product: this.product,
                    is_purchase_price: false,
                },
            };
        });
    }
    evh_3343864469950044_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3343864469950044_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_8855792642156263_close_5969_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.ok;
        });
    }
    act_8855792642156263_showModal_5968_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ProductPrice",
                props: {
                    oid: $event.scope.item.id,
                    mode: "edit",
                    product: this.product,
                },
            };
        });
    }
    evh_8855792642156263_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8855792642156263_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_302930856768608_close_5973_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.value;
        });
    }
    act_302930856768608_showModal_5972_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ProductPrice",
                props: {
                    product: this.product,
                    is_purchase_price: true,
                },
            };
        });
    }
    evh_302930856768608_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_302930856768608_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_7972369138378646_close_5977_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.ok;
        });
    }
    act_7972369138378646_showModal_5976_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ProductPrice",
                props: {
                    oid: $event.scope.item.id,
                    mode: "edit",
                    product: this.product,
                },
            };
        });
    }
    evh_7972369138378646_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7972369138378646_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_3343864469950044_reloadComponentData_5966 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_3343864469950044_close_5965 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_reloadComponentData_5966,
            event: "close",
            when: this.evh_3343864469950044_close_5965_getWhen,
            displayName: "reloadComponentData",
        });
        const act_3343864469950044_showModal_5964 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_3343864469950044_showModal_5964_getActionArgs,
            events: [evh_3343864469950044_close_5965],
        });
        const evh_3343864469950044_clickPreventStop_5963 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_showModal_5964,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_3343864469950044_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3343864469950044_clickPreventStop_5963],
        });
        const act_8855792642156263_reloadComponentData_5970 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_8855792642156263_close_5969 = new core_1.actions.EventHandlerImpl({
            action: act_8855792642156263_reloadComponentData_5970,
            event: "close",
            when: this.evh_8855792642156263_close_5969_getWhen,
            displayName: "reloadComponentData",
        });
        const act_8855792642156263_showModal_5968 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8855792642156263_showModal_5968_getActionArgs,
            events: [evh_8855792642156263_close_5969],
        });
        const evh_8855792642156263_clickPreventStop_5967 = new core_1.actions.EventHandlerImpl({
            action: act_8855792642156263_showModal_5968,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_8855792642156263_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8855792642156263_clickPreventStop_5967],
        });
        const act_302930856768608_reloadComponentData_5974 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_302930856768608_close_5973 = new core_1.actions.EventHandlerImpl({
            action: act_302930856768608_reloadComponentData_5974,
            event: "close",
            when: this.evh_302930856768608_close_5973_getWhen,
            displayName: "reloadComponentData",
        });
        const act_302930856768608_showModal_5972 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_302930856768608_showModal_5972_getActionArgs,
            events: [evh_302930856768608_close_5973],
        });
        const evh_302930856768608_clickPreventStop_5971 = new core_1.actions.EventHandlerImpl({
            action: act_302930856768608_showModal_5972,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_302930856768608_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_302930856768608_clickPreventStop_5971],
        });
        const act_7972369138378646_reloadComponentData_5978 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7972369138378646_close_5977 = new core_1.actions.EventHandlerImpl({
            action: act_7972369138378646_reloadComponentData_5978,
            event: "close",
            when: this.evh_7972369138378646_close_5977_getWhen,
            displayName: "reloadComponentData",
        });
        const act_7972369138378646_showModal_5976 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7972369138378646_showModal_5976_getActionArgs,
            events: [evh_7972369138378646_close_5977],
        });
        const evh_7972369138378646_clickPreventStop_5975 = new core_1.actions.EventHandlerImpl({
            action: act_7972369138378646_showModal_5976,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_7972369138378646_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7972369138378646_clickPreventStop_5975],
        });
        return {
            act_3343864469950044_reloadComponentData_5966,
            evh_3343864469950044_close_5965,
            act_3343864469950044_showModal_5964,
            evh_3343864469950044_clickPreventStop_5963,
            evh_3343864469950044_clickPreventStop,
            act_8855792642156263_reloadComponentData_5970,
            evh_8855792642156263_close_5969,
            act_8855792642156263_showModal_5968,
            evh_8855792642156263_clickPreventStop_5967,
            evh_8855792642156263_clickPreventStop,
            act_302930856768608_reloadComponentData_5974,
            evh_302930856768608_close_5973,
            act_302930856768608_showModal_5972,
            evh_302930856768608_clickPreventStop_5971,
            evh_302930856768608_clickPreventStop,
            act_7972369138378646_reloadComponentData_5978,
            evh_7972369138378646_close_5977,
            act_7972369138378646_showModal_5976,
            evh_7972369138378646_clickPreventStop_5975,
            evh_7972369138378646_clickPreventStop,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcProductPrices.prototype, "product", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('product'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcProductPrices.prototype, "onProduct", null);
UcProductPrices = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcProductPrices);
exports.default = UcProductPrices;
vue_property_decorator_1.Vue.component("UcProductPrices", UcProductPrices);
