<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    direction="column"
  >
    <ItpBox
      direction="column"
      v-if="items && items.length"
    >
      <ItpBox
        :key="item.id"
        alignItems="start"
        class="border rounded rounded-lg p-2 mb-2 w-100"
        v-for="item in items"
        :style="b_6466705138206980_style"
      >
        <ItpText
          strong
          :text="item.payment_date | date"
          class="mr-3"
        >
        </ItpText>
        <ItpBox
          direction="column"
          class="mr-3"
        >
          <DIV>
            <ItpLink
              :text="item.payment_number"
              strong
              @click="evh_4592790460410433_click($event, {item})"
            >
            </ItpLink>
          </DIV>
          <UcAccountMiniCard
            :item="item.account"
          >
          </UcAccountMiniCard>
        </ItpBox>
        <ItpBox
          class="ml-auto"
        >
          <UcPaymentTransactionTypeSymbol
            :paymentType="item.payment_type"
            class="mx-2"
          >
          </UcPaymentTransactionTypeSymbol>
          <ItpText
            strong
            class="ml-auto"
          >
            {{ item.amount | number }} {{ item.currency }}

          </ItpText>
        </ItpBox>
      </ItpBox>
    </ItpBox>
    <ItpBox
      class="px-2 mb-2 w-100"
      v-if="items && items.length > 1"
    >
      <ItpText>
        {{ 'Total'|pgettext('InvoicePayments') }}
      </ItpText>
      <ItpText
        strong
        class="ml-auto"
      >
        {{ invoice.totals.paid | number }} {{ invoice.currency }}

      </ItpText>
    </ItpBox>
    <ItpBox
      direction="row"
      alignItems="center"
    >
      <DIV
        class="form-check px-0"
      >
        <ItpFormCheckbox
          name="no_payment_required"
          v-model="invoice.no_payment_required"
          is-switch
          class="py-1"
          @change="evh_322074727583344_change($event, {})"
        >
          {{ 'No payment required'|pgettext('Invoice') }}
        </ItpFormCheckbox>
        <ItpFormCheckbox
          name="uncollectible"
          v-model="invoice.uncollectible"
          is-switch
          class="py-1"
          @change="evh_5807883900791941_change($event, {})"
        >
          {{ 'Uncollectible'|pgettext('Invoice') }}
        </ItpFormCheckbox>
      </DIV>
      <b-dropdown
        right
        variant="light"
        class="border, ml-auto"
      >
        <template
          slot="button-content"
        >
          <ItpIcon
            fa="bars"
            class="mx-1"
          >
          </ItpIcon>
          <ItpText
            :text="'New payment'|pgettext('InvoicePayments')"
          >
          </ItpText>
        </template>
        <b-dropdown-item-button
          @click.prevent="evh_1984986731969919_clickPrevent($event, {})"
        >
          <ItpText
            :text="'New cash payment...'|pgettext('InvoicePayments')"
          >
          </ItpText>
        </b-dropdown-item-button>
        <b-dropdown-item-button
          @click.prevent="evh_298505142589676_clickPrevent($event, {})"
        >
          <ItpText
            :text="'New banking payment...'|pgettext('InvoicePayments')"
          >
          </ItpText>
        </b-dropdown-item-button>
        <ItpDropdownDivider>
        </ItpDropdownDivider>
        <b-dropdown-item-button
          @click.prevent="evh_5738488038990219_clickPrevent($event, {})"
        >
          <ItpText
            :text="'New reconciliation...'|pgettext('InvoicePayments')"
          >
          </ItpText>
        </b-dropdown-item-button>
        <b-dropdown-item-button
          @click.prevent="evh_7481595298497917_clickPrevent($event, {})"
        >
          <ItpText
            :text="'Bad debt...'|pgettext('InvoicePayments')"
          >
          </ItpText>
        </b-dropdown-item-button>
      </b-dropdown>
    </ItpBox>
  </ItpBox>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoicePayments extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  @Prop({
    default: null,
  })
  reload!: any;

  @Watch('reload')
  onReload(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("reload")
  }

  triggerButtonID!: any;

  async $$load_triggerButtonID() {
    return this.$fn.randomElementId()
  }

  ux!: any;
  b_6466705138206980_style!: any;
  dataMembers = ['triggerButtonID', 'ux', 'b_6466705138206980_style'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        triggerButtonID: null,
        ux: null,
        b_6466705138206980_style: {
          "min-width": "150px",
        }
        ,
      },
    }
  }

  get items() {
    if (this.loaded) {

      return this.invoice.payments.data
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_4592790460410433_showModal_5442_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_4592790460410433_showModal_5442, alias=OpenPayment
    return {
      name: "Payment",
      props: {
        oid: $event.scope.item.payment_id,
      }
      ,
    }
  }

  async evh_4592790460410433_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4592790460410433_click.executeFromDOM(this, event, scope);
  }

  async act_322074727583344_resource_5444_getActionArgs($event: actions.ActionEvent): Promise<actions.ResourceActionArgs> {
    // parameterResolver name=act_322074727583344_resource_5444, alias=undefined
    return {
      method: "patch",
      target: this.invoice,
      data: {
        no_payment_required: $event.data.checked ? 'true' : null,
      }
      ,
    }
  }

  async evh_322074727583344_change(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_322074727583344_change.executeFromDOM(this, event, scope);
  }

  async act_5807883900791941_resource_5446_getActionArgs($event: actions.ActionEvent): Promise<actions.ResourceActionArgs> {
    // parameterResolver name=act_5807883900791941_resource_5446, alias=undefined
    return {
      method: "patch",
      target: this.invoice,
      data: {
        uncollectible: $event.data.checked ? 'true' : null,
      }
      ,
    }
  }

  async evh_5807883900791941_change(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5807883900791941_change.executeFromDOM(this, event, scope);
  }

  async act_1984986731969919_emit_5450_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1984986731969919_emit_5450, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_1984986731969919_close_5449_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_1984986731969919_close_5449, alias=undefined
    return $event.data.ok
  }

  async act_1984986731969919_showModal_5448_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_1984986731969919_showModal_5448, alias=createCashPayment
    return {
      name: "Payment",
      props: {
        transactionType: this.invoice.invoice_kind == 'incoming' ? 'payment' : 'deposit',
        invoices: [this.invoice.id],
      }
      ,
    }
  }

  async evh_1984986731969919_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1984986731969919_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_298505142589676_emit_5454_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_298505142589676_emit_5454, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_298505142589676_close_5453_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_298505142589676_close_5453, alias=undefined
    return $event.data.ok
  }

  async act_298505142589676_showModal_5452_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_298505142589676_showModal_5452, alias=createBankingPayment
    return {
      name: "Payment",
      props: {
        transactionType: this.invoice.invoice_kind == 'incoming' ? 'debit' : 'credit',
        invoices: [this.invoice.id],
      }
      ,
    }
  }

  async evh_298505142589676_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_298505142589676_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_5738488038990219_emit_5458_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5738488038990219_emit_5458, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_5738488038990219_close_5457_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_5738488038990219_close_5457, alias=undefined
    return $event.data.ok
  }

  async act_5738488038990219_showModal_5456_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_5738488038990219_showModal_5456, alias=createReconciliation
    return {
      name: "Payment",
      props: {
        transactionType: "reconciliation_of_invoices",
        invoices: [this.invoice.id],
      }
      ,
    }
  }

  async evh_5738488038990219_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5738488038990219_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_7481595298497917_emit_5462_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7481595298497917_emit_5462, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_7481595298497917_close_5461_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_7481595298497917_close_5461, alias=undefined
    return $event.data.ok
  }

  async act_7481595298497917_showModal_5460_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7481595298497917_showModal_5460, alias=createBadDebt
    return {
      name: "Payment",
      props: {
        transactionType: "bad_debt_journal",
        invoices: [this.invoice.id],
      }
      ,
    }
  }

  async evh_7481595298497917_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7481595298497917_clickPrevent.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5440: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5439: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_4592790460410433_showModal_5442: actions.ShowModalAction;
    evh_4592790460410433_click_5441: actions.EventHandlerImpl;
    evh_4592790460410433_click: actions.EventHandlerGroup;
    act_322074727583344_resource_5444: actions.ResourceAction;
    evh_322074727583344_change_5443: actions.EventHandlerImpl;
    evh_322074727583344_change: actions.EventHandlerGroup;
    act_5807883900791941_resource_5446: actions.ResourceAction;
    evh_5807883900791941_change_5445: actions.EventHandlerImpl;
    evh_5807883900791941_change: actions.EventHandlerGroup;
    act_1984986731969919_emit_5450: actions.EmitAction;
    evh_1984986731969919_close_5449: actions.EventHandlerImpl;
    act_1984986731969919_showModal_5448: actions.ShowModalAction;
    evh_1984986731969919_clickPrevent_5447: actions.EventHandlerImpl;
    evh_1984986731969919_clickPrevent: actions.EventHandlerGroup;
    act_298505142589676_emit_5454: actions.EmitAction;
    evh_298505142589676_close_5453: actions.EventHandlerImpl;
    act_298505142589676_showModal_5452: actions.ShowModalAction;
    evh_298505142589676_clickPrevent_5451: actions.EventHandlerImpl;
    evh_298505142589676_clickPrevent: actions.EventHandlerGroup;
    act_5738488038990219_emit_5458: actions.EmitAction;
    evh_5738488038990219_close_5457: actions.EventHandlerImpl;
    act_5738488038990219_showModal_5456: actions.ShowModalAction;
    evh_5738488038990219_clickPrevent_5455: actions.EventHandlerImpl;
    evh_5738488038990219_clickPrevent: actions.EventHandlerGroup;
    act_7481595298497917_emit_5462: actions.EmitAction;
    evh_7481595298497917_close_5461: actions.EventHandlerImpl;
    act_7481595298497917_showModal_5460: actions.ShowModalAction;
    evh_7481595298497917_clickPrevent_5459: actions.EventHandlerImpl;
    evh_7481595298497917_clickPrevent: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5440 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5439 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5440,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5439],
      }
    );
    const act_4592790460410433_showModal_5442 = new actions.ShowModalAction(
      {
        actionArgs: this.act_4592790460410433_showModal_5442_getActionArgs,
        displayName: "OpenPayment",
        events: [],
      }
    );
    const evh_4592790460410433_click_5441 = new actions.EventHandlerImpl(
      {
        action: act_4592790460410433_showModal_5442,
        event: "click",
        displayName: "OpenPayment",
      }
    );
    const evh_4592790460410433_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_4592790460410433_click_5441],
      }
    );
    const act_322074727583344_resource_5444 = new actions.ResourceAction(
      {
        actionArgs: this.act_322074727583344_resource_5444_getActionArgs,
        events: [],
      }
    );
    const evh_322074727583344_change_5443 = new actions.EventHandlerImpl(
      {
        action: act_322074727583344_resource_5444,
        event: "change",
        displayName: "resource",
      }
    );
    const evh_322074727583344_change = new actions.EventHandlerGroup(
      {
        handlers: [evh_322074727583344_change_5443],
      }
    );
    const act_5807883900791941_resource_5446 = new actions.ResourceAction(
      {
        actionArgs: this.act_5807883900791941_resource_5446_getActionArgs,
        events: [],
      }
    );
    const evh_5807883900791941_change_5445 = new actions.EventHandlerImpl(
      {
        action: act_5807883900791941_resource_5446,
        event: "change",
        displayName: "resource",
      }
    );
    const evh_5807883900791941_change = new actions.EventHandlerGroup(
      {
        handlers: [evh_5807883900791941_change_5445],
      }
    );
    const act_1984986731969919_emit_5450 = new actions.EmitAction(
      {
        actionArgs: this.act_1984986731969919_emit_5450_getActionArgs,
        events: [],
      }
    );
    const evh_1984986731969919_close_5449 = new actions.EventHandlerImpl(
      {
        action: act_1984986731969919_emit_5450,
        event: "close",
        when: this.evh_1984986731969919_close_5449_getWhen,
        displayName: "emit",
      }
    );
    const act_1984986731969919_showModal_5448 = new actions.ShowModalAction(
      {
        actionArgs: this.act_1984986731969919_showModal_5448_getActionArgs,
        displayName: "createCashPayment",
        events: [evh_1984986731969919_close_5449],
      }
    );
    const evh_1984986731969919_clickPrevent_5447 = new actions.EventHandlerImpl(
      {
        action: act_1984986731969919_showModal_5448,
        event: "click.prevent",
        displayName: "createCashPayment",
      }
    );
    const evh_1984986731969919_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_1984986731969919_clickPrevent_5447],
      }
    );
    const act_298505142589676_emit_5454 = new actions.EmitAction(
      {
        actionArgs: this.act_298505142589676_emit_5454_getActionArgs,
        events: [],
      }
    );
    const evh_298505142589676_close_5453 = new actions.EventHandlerImpl(
      {
        action: act_298505142589676_emit_5454,
        event: "close",
        when: this.evh_298505142589676_close_5453_getWhen,
        displayName: "emit",
      }
    );
    const act_298505142589676_showModal_5452 = new actions.ShowModalAction(
      {
        actionArgs: this.act_298505142589676_showModal_5452_getActionArgs,
        displayName: "createBankingPayment",
        events: [evh_298505142589676_close_5453],
      }
    );
    const evh_298505142589676_clickPrevent_5451 = new actions.EventHandlerImpl(
      {
        action: act_298505142589676_showModal_5452,
        event: "click.prevent",
        displayName: "createBankingPayment",
      }
    );
    const evh_298505142589676_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_298505142589676_clickPrevent_5451],
      }
    );
    const act_5738488038990219_emit_5458 = new actions.EmitAction(
      {
        actionArgs: this.act_5738488038990219_emit_5458_getActionArgs,
        events: [],
      }
    );
    const evh_5738488038990219_close_5457 = new actions.EventHandlerImpl(
      {
        action: act_5738488038990219_emit_5458,
        event: "close",
        when: this.evh_5738488038990219_close_5457_getWhen,
        displayName: "emit",
      }
    );
    const act_5738488038990219_showModal_5456 = new actions.ShowModalAction(
      {
        actionArgs: this.act_5738488038990219_showModal_5456_getActionArgs,
        displayName: "createReconciliation",
        events: [evh_5738488038990219_close_5457],
      }
    );
    const evh_5738488038990219_clickPrevent_5455 = new actions.EventHandlerImpl(
      {
        action: act_5738488038990219_showModal_5456,
        event: "click.prevent",
        displayName: "createReconciliation",
      }
    );
    const evh_5738488038990219_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_5738488038990219_clickPrevent_5455],
      }
    );
    const act_7481595298497917_emit_5462 = new actions.EmitAction(
      {
        actionArgs: this.act_7481595298497917_emit_5462_getActionArgs,
        events: [],
      }
    );
    const evh_7481595298497917_close_5461 = new actions.EventHandlerImpl(
      {
        action: act_7481595298497917_emit_5462,
        event: "close",
        when: this.evh_7481595298497917_close_5461_getWhen,
        displayName: "emit",
      }
    );
    const act_7481595298497917_showModal_5460 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7481595298497917_showModal_5460_getActionArgs,
        displayName: "createBadDebt",
        events: [evh_7481595298497917_close_5461],
      }
    );
    const evh_7481595298497917_clickPrevent_5459 = new actions.EventHandlerImpl(
      {
        action: act_7481595298497917_showModal_5460,
        event: "click.prevent",
        displayName: "createBadDebt",
      }
    );
    const evh_7481595298497917_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_7481595298497917_clickPrevent_5459],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5440,
      evh_7315092382398562_reload_5439,
      evh_7315092382398562_reload,
      act_4592790460410433_showModal_5442,
      evh_4592790460410433_click_5441,
      evh_4592790460410433_click,
      act_322074727583344_resource_5444,
      evh_322074727583344_change_5443,
      evh_322074727583344_change,
      act_5807883900791941_resource_5446,
      evh_5807883900791941_change_5445,
      evh_5807883900791941_change,
      act_1984986731969919_emit_5450,
      evh_1984986731969919_close_5449,
      act_1984986731969919_showModal_5448,
      evh_1984986731969919_clickPrevent_5447,
      evh_1984986731969919_clickPrevent,
      act_298505142589676_emit_5454,
      evh_298505142589676_close_5453,
      act_298505142589676_showModal_5452,
      evh_298505142589676_clickPrevent_5451,
      evh_298505142589676_clickPrevent,
      act_5738488038990219_emit_5458,
      evh_5738488038990219_close_5457,
      act_5738488038990219_showModal_5456,
      evh_5738488038990219_clickPrevent_5455,
      evh_5738488038990219_clickPrevent,
      act_7481595298497917_emit_5462,
      evh_7481595298497917_close_5461,
      act_7481595298497917_showModal_5460,
      evh_7481595298497917_clickPrevent_5459,
      evh_7481595298497917_clickPrevent,
      OpenPayment: act_4592790460410433_showModal_5442,
      createCashPayment: act_1984986731969919_showModal_5448,
      createBankingPayment: act_298505142589676_showModal_5452,
      createReconciliation: act_5738488038990219_showModal_5456,
      createBadDebt: act_7481595298497917_showModal_5460,
    }
  }
}

Vue.component("UcInvoicePayments", UcInvoicePayments);

</script>