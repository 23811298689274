<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="CompleteInvoices"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpAlert
      variant="info"
      show
    >
      <p>
        <ItpIcon
          fa="info-circle"
        >
        </ItpIcon>
        <ItpText
          wrap
        >
          Szinkronizálás után a számla már nem módosítható.
        </ItpText>
      </p>
    </ItpAlert>
    <ItpForm
      :id="formId"
      name="confirmSyncInvoice"
      ref="mainForm"
      @submit="evh_4186377094414664_submit($event, {})"
    >
      <ItpFormGroup
        name="copies"
        :label="'Print'|pgettext('InvoiceSync')"
        :labelAlign="labelAlign"
        label-cols-md="3"
        content-cols-md="3"
      >
        <ItpFormCheckbox
          name="copies"
          v-model="payload.print"
          required
          size="md"
        >
        </ItpFormCheckbox>
      </ItpFormGroup>
      <ItpFormGroup
        name="print_copies"
        :label="'Copies'|pgettext('InvoiceSync')"
        :labelAlign="labelAlign"
        label-cols-md="3"
        content-cols-md="3"
      >
        <ItpFormInput
          type="number"
          name="print_copies"
          v-model="payload.print_copies"
          required
          :disabled="!payload.print"
        >
        </ItpFormInput>
      </ItpFormGroup>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Complete invoices'|pgettext('CompleteInvoices|Submit')"
      :disabled="!forms.confirmSyncInvoice || !forms.confirmSyncInvoice.submittable"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.submit.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Cancel'|gettext"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class CompleteInvoices extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Array,
  })
  invoices!: array;

  @Watch('invoices')
  onInvoices(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoices")
  }

  @Prop({
    type: String,
    default: function() {
      return this.$fn.randomElementId()
    },
  })
  formId!: string;

  @Watch('formId')
  onForm_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("formId")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  labelAlign!: any;
  payload!: any;

  async $$load_payload() {
    return {
      invoices: _.map(this.invoices, p => p.id),
      print: true,
      print_copies: 1,
      target: "remote",
    }
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['labelAlign', 'payload', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        labelAlign: "right",
        payload: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("CompleteInvoices|Title", "Complete Invoices"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_5474_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_5474, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_4186377094414664_closeModal_5480_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_4186377094414664_closeModal_5480, alias=undefined
    return {
      result: {
        ok: true,
        value: $event.data.response.data.sync_job,
      }
      ,
    }
  }

  async act_4186377094414664_request_5478_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_4186377094414664_request_5478, alias=submit
    return {
      operation: "sync_invoice",
      data: this.payload,
    }
  }

  async evh_4186377094414664_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4186377094414664_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_5474: actions.CloseModalAction;
    evh_7315092382398562_close_5473: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_5476: actions.CloseComponentAction;
    evh_2248226175642056_close_5475: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_4186377094414664_closeModal_5480: actions.CloseModalAction;
    evh_4186377094414664_success_5479: actions.EventHandlerImpl;
    act_4186377094414664_request_5478: actions.RequestAction;
    evh_4186377094414664_submit_5477: actions.EventHandlerImpl;
    evh_4186377094414664_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_5482: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_5481: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_5474 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_5474_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_5473 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_5474,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_5473],
      }
    );
    const act_2248226175642056_closeComponent_5476 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_5475 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_5476,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_5475],
      }
    );
    const act_4186377094414664_closeModal_5480 = new actions.CloseModalAction(
      {
        actionArgs: this.act_4186377094414664_closeModal_5480_getActionArgs,
        events: [],
      }
    );
    const evh_4186377094414664_success_5479 = new actions.EventHandlerImpl(
      {
        action: act_4186377094414664_closeModal_5480,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_4186377094414664_request_5478 = new actions.RequestAction(
      {
        actionArgs: this.act_4186377094414664_request_5478_getActionArgs,
        displayName: "submit",
        events: [evh_4186377094414664_success_5479],
      }
    );
    const evh_4186377094414664_submit_5477 = new actions.EventHandlerImpl(
      {
        action: act_4186377094414664_request_5478,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_4186377094414664_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_4186377094414664_submit_5477],
      }
    );
    const act_1419464017721962_closeComponent_5482 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_5481 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_5482,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_5481],
      }
    );
    return {
      act_7315092382398562_closeModal_5474,
      evh_7315092382398562_close_5473,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_5476,
      evh_2248226175642056_close_5475,
      evh_2248226175642056_close,
      act_4186377094414664_closeModal_5480,
      evh_4186377094414664_success_5479,
      act_4186377094414664_request_5478,
      evh_4186377094414664_submit_5477,
      evh_4186377094414664_submit,
      act_1419464017721962_closeComponent_5482,
      evh_1419464017721962_clickPreventStop_5481,
      evh_1419464017721962_clickPreventStop,
      submit: act_4186377094414664_request_5478,
    }
  }
}

Vue.component("CompleteInvoices", CompleteInvoices);

</script>