<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <BListGroup
    flush
  >
    <BListGroupItem
      :key="service.id"
      v-for="service in filterItems(services)"
    >
      <ItpBox
        direction="column"
      >
        <ItpBox>
          <ItpText
            strong
            :text="service.service.name"
          >
          </ItpText>
          <ItpText
            :text="service.service.invoice_group_id"
            class="px-3"
            v-if="service.service.invoice_group_id"
          >
          </ItpText>
          <ItpText
            class="px-3"
          >
            <ItpText>{{ service.valid_from|date }}</ItpText>
<ItpText v-if="service.valid_to"> - {{ service.valid_to|date }}</ItpText>

          </ItpText>
        </ItpBox>
        <ItpBox
          v-if="service.meter"
        >
          <ItpText
            muted
            :text="'Meter'|gettext"
            class="pr-3"
          >
          </ItpText>
          <UcCompactMeterDisplay
            :payload="service.meter"
          >
          </UcCompactMeterDisplay>
        </ItpBox>
        <ItpBox>
          <ItpText
            pre
            v-if="service.notes"
          >
            {{ service.notes }}
          </ItpText>
        </ItpBox>
      </ItpBox>
    </BListGroupItem>
  </BListGroup>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractRentalServices extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  filterItems(...args: any[]) {
    const [services] = args;
    return _.filter(services, p => !p.inactive)
  }

  @Prop({
    required: true,
    type: Object,
  })
  rental!: object;

  @Watch('rental')
  onRental(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("rental")
  }

  services!: any;

  async $$load_services() {
    return this.$fn.fetch('list_rental_service', { parameters: { rental_id: this.rental.id } })
  }

  ux!: any;
  dataMembers = ['services', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        services: null,
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6614: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6613: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6614 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6613 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6614,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6613],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6614,
      evh_7315092382398562_reload_6613,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcContractRentalServices", UcContractRentalServices);

</script>