<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Meter"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <DIV
    class="page--v2 d-flex flex-column"
    v-if="loaded"
  >
    <MaterialTabs
      :tabManager="tabManager"
    >
      <ItpBox
        alignItems="center"
        class="mr-3"
        slot="tabs-start"
      >
        <b-breadcrumb
          class="m-0 bg-white"
        >
          <b-breadcrumb-item
            :text="'Meters'|gettext"
            :to="b_2285343489561581_to"
          >
          </b-breadcrumb-item>
          <b-breadcrumb-item
            :text="item.snapshot('serial_number')"
            active
          >
          </b-breadcrumb-item>
        </b-breadcrumb>
        <ItpButton
          variant="light"
          icon="fa-sync-alt"
          :tooltip="'Reload'|gettext"
          :spinning="actions.reload.isRunning"
          size="sm"
          class="mr-2"
          @click.prevent.stop="evh_2095089031469157_clickPreventStop($event, {})"
        >
        </ItpButton>
        <BtnDeleteResource
          :resource="item"
          no-text
          class="mr-2"
          @success="evh_9004123373732307_success($event, {})"
        >
        </BtnDeleteResource>
        <ItpButton
          icon="material-symbols:history"
          :tooltip="'Invoice history'|pgettext('Invoice')"
          size="sm"
          @click.prevent.stop="evh_397195341670858_clickPreventStop($event, {})"
        >
        </ItpButton>
      </ItpBox>
      <b-tab
        :title="'General'|gettext"
        class="container float-left"
      >
        <ItpRow
          v-if="tabManager.visited(0)"
        >
          <ItpCol
            w="6"
          >
            <UcMeterGeneralPanel
              :meter="item"
              :reload="lastLoaded"
              class="pb-4"
              @reload="evh_2679161326024595_reload($event, {})"
              @saved="evh_2679161326024595_saved($event, {})"
            >
            </UcMeterGeneralPanel>
            <UcMeterRentalServices
              :meter="item"
              :reload="lastLoaded"
              v-if="item"
              @reload="evh_7987652045662005_reload($event, {})"
            >
            </UcMeterRentalServices>
          </ItpCol>
          <ItpCol
            w="6"
          >
            <UcCommentsList
              :parent="item"
              :reload="lastLoaded"
              class="pb-4"
            >
            </UcCommentsList>
            <UcAttachmentsList
              :parent="item"
              :reload="lastLoaded"
              class="pb-4"
            >
            </UcAttachmentsList>
          </ItpCol>
        </ItpRow>
      </b-tab>
      <b-tab
        :title="'Readings'|pgettext('Meter')"
        class="container-fluid h-100"
      >
        <UcMeterReadings
          :meter="item"
          :reload="lastLoaded"
          class="h-100"
          v-if="tabManager.visited(1)"
        >
        </UcMeterReadings>
      </b-tab>
      <b-tab
        :title="'Auto Readings'|pgettext('Meter')"
        class="container-fluid h-100"
      >
        <UcMeterCharts
          :meter="item"
          :reload="lastLoaded"
          class="h-100"
          v-if="tabManager.visited(2)"
        >
        </UcMeterCharts>
      </b-tab>
      <b-tab
        :title="'Invoices'|pgettext('Meter')"
        class="container-fluid h-100"
      >
        <UcMeterInvoices
          :meter="item"
          :reload="lastLoaded"
          class="h-100"
          v-if="tabManager.visited(3)"
        >
        </UcMeterInvoices>
      </b-tab>
    </MaterialTabs>
  </DIV>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "meter",
    path: "/meters/:id",
    component: "Meter",
    params: [{
      name: "id",
      prop: "meterId",
    }
      ,
    ]

  }
)

@Component()
export default class Meter extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  meterId!: string;

  @Watch('meterId')
  onMeter_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("meterId")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  tabManager!: any;

  async $$load_tabManager() {
    return new this.$app.utils.LazyTabManager()
  }

  item!: any;

  async $$load_item() {
    return this.$fn.fetch('read_meter', { asResource: true, parameters: { id: this.meterId } })
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2285343489561581_to!: any;
  dataMembers = ['tabManager', 'item', 'ux', 'b_7315092382398562_modalBindings', 'b_2285343489561581_to'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        tabManager: null,
        item: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2285343489561581_to: {
          name: "app.meters",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: [this.item.snapshot('serial_number')
          ,
        this.$fn.gettext("Meter")
          ,
        ]
        ,
        icon: this.item ? this.$config.meterTypes[this.item.meter_type].icon : this.$config.meter.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3520_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3520, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2095089031469157_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2095089031469157_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_9004123373732307_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_9004123373732307_success.executeFromDOM(this, event, scope);
  }

  async act_397195341670858_showModal_3526_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_397195341670858_showModal_3526, alias=undefined
    return {
      name: "entityEvents",
      props: {
        entityId: this.item.id,
      }
      ,
    }
  }

  async evh_397195341670858_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_397195341670858_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_2679161326024595_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2679161326024595_reload.executeFromDOM(this, event, scope);
  }

  async act_2679161326024595_setData_3530_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2679161326024595_setData_3530, alias=undefined
    return {
      path: "item",
      value: $event.data,
      resolve: false,
      noTouchLastLoaded: true,
      noTakeSnapshot: false,
    }
  }

  async evh_2679161326024595_saved(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2679161326024595_saved.executeFromDOM(this, event, scope);
  }

  async evh_7987652045662005_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7987652045662005_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3520: actions.CloseModalAction;
    evh_7315092382398562_close_3519: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2095089031469157_reloadComponentData_3522: actions.ReloadComponentDataAction;
    evh_2095089031469157_clickPreventStop_3521: actions.EventHandlerImpl;
    evh_2095089031469157_clickPreventStop: actions.EventHandlerGroup;
    act_9004123373732307_closeComponent_3524: actions.CloseComponentAction;
    evh_9004123373732307_success_3523: actions.EventHandlerImpl;
    evh_9004123373732307_success: actions.EventHandlerGroup;
    act_397195341670858_showModal_3526: actions.ShowModalAction;
    evh_397195341670858_clickPreventStop_3525: actions.EventHandlerImpl;
    evh_397195341670858_clickPreventStop: actions.EventHandlerGroup;
    act_2679161326024595_reloadComponentData_3528: actions.ReloadComponentDataAction;
    evh_2679161326024595_reload_3527: actions.EventHandlerImpl;
    evh_2679161326024595_reload: actions.EventHandlerGroup;
    act_2679161326024595_setData_3530: actions.SetDataAction;
    evh_2679161326024595_saved_3529: actions.EventHandlerImpl;
    evh_2679161326024595_saved: actions.EventHandlerGroup;
    act_7987652045662005_reloadComponentData_3532: actions.ReloadComponentDataAction;
    evh_7987652045662005_reload_3531: actions.EventHandlerImpl;
    evh_7987652045662005_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3520 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3520_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3519 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3520,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3519],
      }
    );
    const act_2095089031469157_reloadComponentData_3522 = new actions.ReloadComponentDataAction(
      {
        displayName: "reload",
        events: [],
      }
    );
    const evh_2095089031469157_clickPreventStop_3521 = new actions.EventHandlerImpl(
      {
        action: act_2095089031469157_reloadComponentData_3522,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_2095089031469157_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2095089031469157_clickPreventStop_3521],
      }
    );
    const act_9004123373732307_closeComponent_3524 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_9004123373732307_success_3523 = new actions.EventHandlerImpl(
      {
        action: act_9004123373732307_closeComponent_3524,
        event: "success",
        displayName: "closeComponent",
      }
    );
    const evh_9004123373732307_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_9004123373732307_success_3523],
      }
    );
    const act_397195341670858_showModal_3526 = new actions.ShowModalAction(
      {
        actionArgs: this.act_397195341670858_showModal_3526_getActionArgs,
        events: [],
      }
    );
    const evh_397195341670858_clickPreventStop_3525 = new actions.EventHandlerImpl(
      {
        action: act_397195341670858_showModal_3526,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_397195341670858_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_397195341670858_clickPreventStop_3525],
      }
    );
    const act_2679161326024595_reloadComponentData_3528 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_2679161326024595_reload_3527 = new actions.EventHandlerImpl(
      {
        action: act_2679161326024595_reloadComponentData_3528,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_2679161326024595_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_2679161326024595_reload_3527],
      }
    );
    const act_2679161326024595_setData_3530 = new actions.SetDataAction(
      {
        actionArgs: this.act_2679161326024595_setData_3530_getActionArgs,
        events: [],
      }
    );
    const evh_2679161326024595_saved_3529 = new actions.EventHandlerImpl(
      {
        action: act_2679161326024595_setData_3530,
        event: "saved",
        displayName: "setData",
      }
    );
    const evh_2679161326024595_saved = new actions.EventHandlerGroup(
      {
        handlers: [evh_2679161326024595_saved_3529],
      }
    );
    const act_7987652045662005_reloadComponentData_3532 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7987652045662005_reload_3531 = new actions.EventHandlerImpl(
      {
        action: act_7987652045662005_reloadComponentData_3532,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7987652045662005_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7987652045662005_reload_3531],
      }
    );
    return {
      act_7315092382398562_closeModal_3520,
      evh_7315092382398562_close_3519,
      evh_7315092382398562_close,
      act_2095089031469157_reloadComponentData_3522,
      evh_2095089031469157_clickPreventStop_3521,
      evh_2095089031469157_clickPreventStop,
      act_9004123373732307_closeComponent_3524,
      evh_9004123373732307_success_3523,
      evh_9004123373732307_success,
      act_397195341670858_showModal_3526,
      evh_397195341670858_clickPreventStop_3525,
      evh_397195341670858_clickPreventStop,
      act_2679161326024595_reloadComponentData_3528,
      evh_2679161326024595_reload_3527,
      evh_2679161326024595_reload,
      act_2679161326024595_setData_3530,
      evh_2679161326024595_saved_3529,
      evh_2679161326024595_saved,
      act_7987652045662005_reloadComponentData_3532,
      evh_7987652045662005_reload_3531,
      evh_7987652045662005_reload,
      reload: act_2095089031469157_reloadComponentData_3522,
    }
  }
}

Vue.component("Meter", Meter);

</script>