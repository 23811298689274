<template>
<ItpModal
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="ReplaceMeter"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      name="replaceMeter"
      :id="formId"
      ref="form"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpAlert
        show
      >
        <DIV>
          Az óracsere művelet a következő lépéseket végzi el automatikusan:
        </DIV>
        <ul>
          <li
            v-for="step in workflowStepsInfo.steps"
          >
            {{ step }}
          </li>
        </ul>
      </ItpAlert>
      <HR>
      </HR>
      <ItpFormGroup
        :labelAlign="labelAlign"
        label-cols-md="3"
        content-cols-md="3"
        name="replacement_time"
        :label="'Replacement time'|pgettext('ReplaceMeterModal')"
        required
      >
        <ItpFormDatetime
          type="datetime"
          name="replacement_time"
          v-model="item.replacement_time"
          required
          :readonly="isReadOnly"
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <HR>
      </HR>
      <DIV
        class="form-group"
      >
        <ItpText
          strong
          muted
          :text="'Old device'|pgettext('ReplaceMeterModal')"
        >
        </ItpText>
      </DIV>
      <ItpFormGroup
        :labelAlign="labelAlign"
        label-cols-md="3"
        content-cols-md="9"
        name="format"
        :label="'Meter'|pgettext('ReplaceMeterModal')"
        required
      >
        <ItpFormSelect2
          name="old_meter"
          required
          :readonly="isReadOnly"
          v-model="item.old_meter.id"
          :dataSource="b_4963030162390099_dataSource"
          valueField="id"
          textField="serial_number"
          use-option-template-for-selected-option
          optionTemplate="<UcSuggestMeterOption :option=option></UcSuggestMeterOption>"
          @optionSelected="evh_4963030162390099_optionSelected($event, {})"
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        :labelAlign="labelAlign"
        label-cols-md="3"
        content-cols-md="9"
        name="old_reading_value"
        :label="'Reading value'|pgettext('ReplaceMeterModal')"
        required
      >
        <ItpBox>
          <ItpFormInput
            type="number"
            class="col-3"
            name="old_reading_value"
            v-model="item.old_meter.reading_value"
            required
            :readonly="isReadOnly"
          >
          </ItpFormInput>
          <ItpFormSelect2
            class="col-4"
            name="old_reading_unit"
            v-model="item.old_meter.unit"
            :options="oldMeterReadingUnitOptions"
            required
            :readonly="isReadOnly"
          >
          </ItpFormSelect2>
        </ItpBox>
      </ItpFormGroup>
      <ItpFormGroup
        :labelAlign="labelAlign"
        name="old_reading_image"
        :label="'Photo'|pgettext('ReplaceMeter')"
        label-cols-md="3"
        content-cols-md="9"
      >
        <ItpFormFile
          v-model="old_reading_image"
          name="old_reading_image"
          :placeholder="'The photo of the gauge if available.'|pgettext('ReplaceMeter')"
          lang="hu"
          @changed="evh_3855552110905499_changed($event, {})"
        >
        </ItpFormFile>
      </ItpFormGroup>
      <ItpFormGroup
        :labelAlign="labelAlign"
        name="old_notes"
        :label="'Notes'|pgettext('ReplaceMeterModal')"
        label-cols-md="3"
        content-cols-md="9"
      >
        <ItpFormTextarea
          name="old_notes"
          v-model="item.old_meter.notes"
          :readonly="isReadOnly"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
      <HR>
      </HR>
      <DIV
        class="form-group"
      >
        <ItpText
          strong
          muted
          :text="'New device'|pgettext('ReplaceMeterModal')"
        >
        </ItpText>
      </DIV>
      <ItpFormGroup
        :labelAlign="labelAlign"
        label-cols-md="3"
        content-cols-md="9"
        name="new_serial"
        :label="'Device'|pgettext('ReplaceMeterModal')"
      >
        <ItpFormSelect2
          name="new_meter"
          required
          :readonly="isReadOnly"
          v-model="item.new_meter.serial"
          :dataSource="b_4246589045129675_dataSource"
          valueField="serial_number"
          textField="serial_number"
          taggable
          clearable
          optionTemplate="<UcSuggestMeterOption :option=option></UcSuggestMeterOption>"
          @optionSelected="evh_4246589045129675_optionSelected($event, {})"
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        :labelAlign="labelAlign"
        label-cols-md="3"
        content-cols-md="9"
        name="new_serial"
        :label="'Serial number'|pgettext('ReplaceMeterModal')"
        required
      >
        <ItpFormInput
          name="new_serial"
          v-model="item.new_meter.serial"
          required
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        :labelAlign="labelAlign"
        label-cols-md="3"
        content-cols-md="9"
        name="new_deveui"
        :label="'DevEUI'|pgettext('ReplaceMeterModal')"
      >
        <ItpFormInput
          name="new_deveui"
          v-model="item.new_meter.deveui"
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        :labelAlign="labelAlign"
        label-cols-md="3"
        content-cols-md="9"
        name="new_tag_id"
        :label="'Location Code'|pgettext('ReplaceMeterModal')"
      >
        <ItpFormInput
          name="new_tag_id"
          v-model="item.new_meter.tag_id"
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        :labelAlign="labelAlign"
        label-cols-md="3"
        content-cols-md="9"
        name="new_reading_value"
        :label="'Reading value'|pgettext('ReplaceMeterModal')"
        required
      >
        <ItpBox>
          <ItpFormInput
            type="number"
            class="col-3"
            name="new_reading_value"
            v-model="item.new_meter.reading_value"
            required
            :readonly="isReadOnly"
          >
          </ItpFormInput>
          <ItpFormSelect2
            class="col-4"
            name="new_reading_unit"
            v-model="item.new_meter.unit"
            :options="oldMeterReadingUnitOptions"
            required
            :readonly="isReadOnly"
          >
          </ItpFormSelect2>
        </ItpBox>
      </ItpFormGroup>
      <ItpFormGroup
        :labelAlign="labelAlign"
        name="new_reading_image"
        :label="'Photo'|pgettext('ReplaceMeter')"
        label-cols-md="3"
        content-cols-md="9"
      >
        <ItpFormFile
          v-model="new_reading_image"
          name="new_reading_image"
          :placeholder="'The photo of the gauge if available.'|pgettext('ReplaceMeter')"
          lang="hu"
        >
        </ItpFormFile>
      </ItpFormGroup>
      <ItpFormGroup
        :labelAlign="labelAlign"
        name="new_notes"
        :label="'Notes'|pgettext('ReplaceMeterModal')"
        label-cols-md="3"
        content-cols-md="9"
      >
        <ItpFormTextarea
          name="new_notes"
          v-model="item.new_meter.notes"
          :readonly="isReadOnly"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
      <HR>
      </HR>
      <DIV
        class="row mb-3"
      >
        <DIV
          class="col-md-9 offset-md-3"
        >
          <ItpButton
            :form="formId"
            :text="'Replace Meter'|pgettext('ReplaceMeterModal')"
            type="submit"
            variant="primary"
            :icon="$config.meterReplacement.icon"
            :spinning="actions.submit.isRunning"
            :disabled="!!(!forms.replaceMeter || !forms.replaceMeter.submittable || actions.submit.isRunning || responseData)"
          >
          </ItpButton>
        </DIV>
      </DIV>
      <ItpAlert
        type="success"
        show
        v-if="responseData"
      >
        <H4
          class="alert-heading"
        >
          Sikeres óracsere
        </H4>
        <ul>
          <li v-for="step in responseData.value.result">
  {{ step.message }}
</li>

        </ul>
        <DIV
          class="text-center"
        >
          <ItpButton
            :text="'Close'|gettext"
            variant="primary"
            :disabled="actions.submit.isRunning"
            @click.prevent.stop="evh_353388685162594_clickPreventStop($event, {})"
          >
          </ItpButton>
        </DIV>
      </ItpAlert>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_1548630417156826_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpModal>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class ReplaceMeter extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "modal",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;
  labelAlign!: any;
  item!: any;

  async $$load_item() {
    return this.$fn.schemaDefaults('ReplaceMeter')
  }

  responseData!: any;
  workflowStepsInfo!: any;
  units!: any;

  async $$load_units() {
    return this.$fn.fetch('get_meter_type_units')
  }

  selectedOldMeter!: any;
  old_reading_image!: any;
  new_reading_image!: any;
  ux!: any;
  b_4963030162390099_dataSource!: any;
  b_4246589045129675_dataSource!: any;
  dataMembers = ['formId', 'labelAlign', 'item', 'responseData', 'workflowStepsInfo', 'units', 'selectedOldMeter', 'old_reading_image', 'new_reading_image', 'ux', 'b_4963030162390099_dataSource', 'b_4246589045129675_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        labelAlign: "right",
        item: null,
        responseData: null,
        workflowStepsInfo: {
          steps: ["rögzíti a leszerelt mérőóra állását"
            ,
            "inaktiválja a leszerelt órát"
            ,
            "inaktiválja a kapcsolódó bérleménynél beállított mért szolgáltatást"
            ,
            "létrehoz egy új mérőórát a megadott sorozatszámmal"
            ,
            "rögzíti az induló mérőállást"
            ,
            "a kapcsolódó bérleménynél a leszerelt mérőóra helyett beállít egy mért szolgáltatást az új mérőórával"
            ,
          ]
          ,
        }
        ,
        units: null,
        selectedOldMeter: null,
        old_reading_image: null,
        new_reading_image: null,
        ux: null,
        b_4963030162390099_dataSource: {
          name: "suggest_meter",
          parameters: {
            exclude_types: ["pulse"
              ,
            ]
            ,
          }
          ,
        }
        ,
        b_4246589045129675_dataSource: {
          name: "suggest_meter",
          parameters: {
            exclude_types: ["pulse"
              ,
            ]
            ,
          }
          ,
        }
        ,
      },
    }
  }

  get isReadOnly() {
    if (this.loaded) {

      return !!(this.responseData || (this.actions.submit && this.actions.submit.isRunning))
    }
    return null;
  }

  get oldMeterReadingUnitOptions() {
    if (this.loaded) {

      return this.selectedOldMeter ? this.units[this.selectedOldMeter.meter_type] : []
    }
    return null;
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("ReplaceMeterModal.Title", "Replace Meter"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3598_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3598, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_setData_3604_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1040167445267876_setData_3604, alias=undefined
    return {
      path: "responseData",
      value: $event.data.response.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_1040167445267876_request_3602_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1040167445267876_request_3602, alias=submit
    return {
      operation: "create_meter_operation",
      data: this.item,
      parameters: {
        op: "replacement",
      }
      ,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_4963030162390099_setData_3606_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4963030162390099_setData_3606, alias=undefined
    return {
      path: "item.old_meter.unit",
      value: $event.data.option.unit,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_4963030162390099_setData_3608_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4963030162390099_setData_3608, alias=undefined
    return {
      path: "selectedOldMeter",
      value: $event.data.option,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_4963030162390099_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4963030162390099_optionSelected.executeFromDOM(this, event, scope);
  }

  async act_3855552110905499_setData_3610_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_3855552110905499_setData_3610, alias=undefined
    return {
      path: "item.old_meter.image",
      value: this.$fn.imageData($event.value),
      resolve: true,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_3855552110905499_changed(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3855552110905499_changed.executeFromDOM(this, event, scope);
  }

  async act_4246589045129675_setData_3612_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4246589045129675_setData_3612, alias=undefined
    return {
      path: "item.new_meter.serial",
      value: $event.data.option.serial_number,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_4246589045129675_setData_3612_getWhen($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4246589045129675_setData_3612, alias=undefined
    return $event.data.option
  }

  async act_4246589045129675_setData_3614_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4246589045129675_setData_3614, alias=undefined
    return {
      path: "item.new_meter.id",
      value: $event.data.option.id,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_4246589045129675_setData_3614_getWhen($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4246589045129675_setData_3614, alias=undefined
    return $event.data.option
  }

  async act_4246589045129675_setData_3616_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4246589045129675_setData_3616, alias=undefined
    return {
      path: "item.new_meter.unit",
      value: $event.data.option.unit,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_4246589045129675_setData_3616_getWhen($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4246589045129675_setData_3616, alias=undefined
    return $event.data.option
  }

  async evh_4246589045129675_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4246589045129675_optionSelected.executeFromDOM(this, event, scope);
  }

  async evh_353388685162594_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_353388685162594_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_1548630417156826_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3598: actions.CloseModalAction;
    evh_7315092382398562_close_3597: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_3600: actions.CloseComponentAction;
    evh_2248226175642056_close_3599: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_setData_3604: actions.SetDataAction;
    evh_1040167445267876_success_3603: actions.EventHandlerImpl;
    act_1040167445267876_request_3602: actions.RequestAction;
    evh_1040167445267876_submit_3601: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_4963030162390099_setData_3606: actions.SetDataAction;
    evh_4963030162390099_optionSelected_3605: actions.EventHandlerImpl;
    act_4963030162390099_setData_3608: actions.SetDataAction;
    evh_4963030162390099_optionSelected_3607: actions.EventHandlerImpl;
    evh_4963030162390099_optionSelected: actions.EventHandlerGroup;
    act_3855552110905499_setData_3610: actions.SetDataAction;
    evh_3855552110905499_changed_3609: actions.EventHandlerImpl;
    evh_3855552110905499_changed: actions.EventHandlerGroup;
    act_4246589045129675_setData_3612: actions.SetDataAction;
    evh_4246589045129675_optionSelected_3611: actions.EventHandlerImpl;
    act_4246589045129675_setData_3614: actions.SetDataAction;
    evh_4246589045129675_optionSelected_3613: actions.EventHandlerImpl;
    act_4246589045129675_setData_3616: actions.SetDataAction;
    evh_4246589045129675_optionSelected_3615: actions.EventHandlerImpl;
    evh_4246589045129675_optionSelected: actions.EventHandlerGroup;
    act_353388685162594_closeComponent_3618: actions.CloseComponentAction;
    evh_353388685162594_clickPreventStop_3617: actions.EventHandlerImpl;
    evh_353388685162594_clickPreventStop: actions.EventHandlerGroup;
    act_1548630417156826_closeComponent_3620: actions.CloseComponentAction;
    evh_1548630417156826_clickPreventStop_3619: actions.EventHandlerImpl;
    evh_1548630417156826_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3598 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3598_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3597 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3598,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3597],
      }
    );
    const act_2248226175642056_closeComponent_3600 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_3599 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_3600,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_3599],
      }
    );
    const act_1040167445267876_setData_3604 = new actions.SetDataAction(
      {
        actionArgs: this.act_1040167445267876_setData_3604_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_3603 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_setData_3604,
        event: "success",
        displayName: "setData",
      }
    );
    const act_1040167445267876_request_3602 = new actions.RequestAction(
      {
        actionArgs: this.act_1040167445267876_request_3602_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_success_3603],
      }
    );
    const evh_1040167445267876_submit_3601 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_request_3602,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_3601],
      }
    );
    const act_4963030162390099_setData_3606 = new actions.SetDataAction(
      {
        actionArgs: this.act_4963030162390099_setData_3606_getActionArgs,
        events: [],
      }
    );
    const evh_4963030162390099_optionSelected_3605 = new actions.EventHandlerImpl(
      {
        action: act_4963030162390099_setData_3606,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const act_4963030162390099_setData_3608 = new actions.SetDataAction(
      {
        actionArgs: this.act_4963030162390099_setData_3608_getActionArgs,
        events: [],
      }
    );
    const evh_4963030162390099_optionSelected_3607 = new actions.EventHandlerImpl(
      {
        action: act_4963030162390099_setData_3608,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const evh_4963030162390099_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_4963030162390099_optionSelected_3605, evh_4963030162390099_optionSelected_3607],
      }
    );
    const act_3855552110905499_setData_3610 = new actions.SetDataAction(
      {
        actionArgs: this.act_3855552110905499_setData_3610_getActionArgs,
        events: [],
      }
    );
    const evh_3855552110905499_changed_3609 = new actions.EventHandlerImpl(
      {
        action: act_3855552110905499_setData_3610,
        event: "changed",
        displayName: "setData",
      }
    );
    const evh_3855552110905499_changed = new actions.EventHandlerGroup(
      {
        handlers: [evh_3855552110905499_changed_3609],
      }
    );
    const act_4246589045129675_setData_3612 = new actions.SetDataAction(
      {
        actionArgs: this.act_4246589045129675_setData_3612_getActionArgs,
        when: this.act_4246589045129675_setData_3612_getWhen,
        events: [],
      }
    );
    const evh_4246589045129675_optionSelected_3611 = new actions.EventHandlerImpl(
      {
        action: act_4246589045129675_setData_3612,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const act_4246589045129675_setData_3614 = new actions.SetDataAction(
      {
        actionArgs: this.act_4246589045129675_setData_3614_getActionArgs,
        when: this.act_4246589045129675_setData_3614_getWhen,
        events: [],
      }
    );
    const evh_4246589045129675_optionSelected_3613 = new actions.EventHandlerImpl(
      {
        action: act_4246589045129675_setData_3614,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const act_4246589045129675_setData_3616 = new actions.SetDataAction(
      {
        actionArgs: this.act_4246589045129675_setData_3616_getActionArgs,
        when: this.act_4246589045129675_setData_3616_getWhen,
        events: [],
      }
    );
    const evh_4246589045129675_optionSelected_3615 = new actions.EventHandlerImpl(
      {
        action: act_4246589045129675_setData_3616,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const evh_4246589045129675_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_4246589045129675_optionSelected_3611, evh_4246589045129675_optionSelected_3613, evh_4246589045129675_optionSelected_3615],
      }
    );
    const act_353388685162594_closeComponent_3618 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_353388685162594_clickPreventStop_3617 = new actions.EventHandlerImpl(
      {
        action: act_353388685162594_closeComponent_3618,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_353388685162594_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_353388685162594_clickPreventStop_3617],
      }
    );
    const act_1548630417156826_closeComponent_3620 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1548630417156826_clickPreventStop_3619 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_closeComponent_3620,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1548630417156826_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_clickPreventStop_3619],
      }
    );
    return {
      act_7315092382398562_closeModal_3598,
      evh_7315092382398562_close_3597,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_3600,
      evh_2248226175642056_close_3599,
      evh_2248226175642056_close,
      act_1040167445267876_setData_3604,
      evh_1040167445267876_success_3603,
      act_1040167445267876_request_3602,
      evh_1040167445267876_submit_3601,
      evh_1040167445267876_submit,
      act_4963030162390099_setData_3606,
      evh_4963030162390099_optionSelected_3605,
      act_4963030162390099_setData_3608,
      evh_4963030162390099_optionSelected_3607,
      evh_4963030162390099_optionSelected,
      act_3855552110905499_setData_3610,
      evh_3855552110905499_changed_3609,
      evh_3855552110905499_changed,
      act_4246589045129675_setData_3612,
      evh_4246589045129675_optionSelected_3611,
      act_4246589045129675_setData_3614,
      evh_4246589045129675_optionSelected_3613,
      act_4246589045129675_setData_3616,
      evh_4246589045129675_optionSelected_3615,
      evh_4246589045129675_optionSelected,
      act_353388685162594_closeComponent_3618,
      evh_353388685162594_clickPreventStop_3617,
      evh_353388685162594_clickPreventStop,
      act_1548630417156826_closeComponent_3620,
      evh_1548630417156826_clickPreventStop_3619,
      evh_1548630417156826_clickPreventStop,
      submit: act_1040167445267876_request_3602,
    }
  }
}

Vue.component("ReplaceMeter", ReplaceMeter);

</script>