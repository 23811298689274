"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let ContractStatusTransition = class ContractStatusTransition extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['options', 'formId', 'labelAlign', 'item', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    getCurrentOptions(...args) {
        switch (args[0]) {
            case 'approved':
                return {
                    title: this.$fn.pgettext("Contract", "Approve"),
                    date: this.$fn.pgettext("Contract", "Approval date"),
                    dname: 'approval_date',
                    displayDate: true
                };
            case 'signed':
                return {
                    title: this.$fn.pgettext("Contract", "Sign"),
                    date: this.$fn.pgettext("Contract", "Signature date"),
                    dname: 'signature_date',
                    displayDate: true
                };
            case 'rescinded':
                return {
                    title: this.$fn.pgettext("Contract", "Rescind"),
                    date: this.$fn.pgettext("Contract", "Rescission date"),
                    dname: 'rescission_date',
                    displayDate: true
                };
            case 'active':
                return {
                    title: this.$fn.pgettext("Contract", "Activate"),
                    date: this.$fn.pgettext("Contract", "Valid from"),
                    dname: 'valid_from',
                    displayDate: true
                };
            case 'terminated':
                return {
                    title: this.$fn.pgettext("Contract", "Terminate"),
                    date: this.$fn.pgettext("Contract", "Termination date"),
                    effective_date: this.$fn.pgettext("Contract", "Termination effective date"),
                    dname: 'termination_date',
                    displayDate: true
                };
            case 'ended':
                return {
                    title: this.$fn.pgettext("Contract", "End"),
                    date: this.$fn.pgettext("Contract", "Ending date"),
                    dname: 'end_date',
                    displayDate: true
                };
            case 'archived':
                return {
                    title: this.$fn.pgettext("Contract", "Archive"),
                    date: this.$fn.pgettext("Contract", "Archive date"),
                    dname: 'archive_date',
                    displayDate: true
                };
            case 'unarchive':
                return {
                    title: this.$fn.pgettext("Contract", "Unarchive"),
                    displayDate: false,
                    notesRequired: true,
                    notesHelpText: this.$fn.pgettext('Contract', 'The reason for unarchiving must be justified.')
                };
        }
    }
    defaultData(...args) {
        const [status, contract] = args;
        switch (status) {
            case 'active': {
                return { date: contract.lifecycle.valid_from || this.$fn.today() };
            }
            case 'unarchive': {
                return { notes: null };
            }
            case 'terminated':
            case 'ended':
            case 'archived': {
                return { date: contract.lifecycle.valid_to || this.$fn.today() };
            }
        }
    }
    onContract(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contract");
    }
    onStatus(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("status");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_options() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getCurrentOptions(this.status);
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return _.extend({
                id: this.contract.id,
                rev: this.contract.revision_number,
                status: this.status,
                date: this.$fn.today(),
                effective_date: null,
                notes: null,
                $etag: this.contract.etag
            }, this.defaultData(this.status, this.contract));
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            options: null,
            formId: null,
            labelAlign: "right",
            item: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "md",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: ([this.contract.contract_number, this.options.title]),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_6684_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeModal_6690_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                    value: $event.data.response.data,
                },
            };
        });
    }
    act_1040167445267876_request_6688_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "update_contract_status",
                data: _.set(this.item, this.options.dname, this.item.date),
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_6684 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_6684_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_6683 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_6684,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_6683],
        });
        const act_2248226175642056_closeComponent_6686 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_6685 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_6686,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_6685],
        });
        const act_1040167445267876_closeModal_6690 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_6690_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_6689 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_6690,
            event: "success",
            displayName: "closeModal",
        });
        const act_1040167445267876_request_6688 = new core_1.actions.RequestAction({
            actionArgs: this.act_1040167445267876_request_6688_getActionArgs,
            displayName: "submit",
            events: [evh_1040167445267876_success_6689],
        });
        const evh_1040167445267876_submit_6687 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_request_6688,
            event: "submit",
            displayName: "submit",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_6687],
        });
        const act_1419464017721962_closeComponent_6692 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_6691 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_6692,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_6691],
        });
        return {
            act_7315092382398562_closeModal_6684,
            evh_7315092382398562_close_6683,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_6686,
            evh_2248226175642056_close_6685,
            evh_2248226175642056_close,
            act_1040167445267876_closeModal_6690,
            evh_1040167445267876_success_6689,
            act_1040167445267876_request_6688,
            evh_1040167445267876_submit_6687,
            evh_1040167445267876_submit,
            act_1419464017721962_closeComponent_6692,
            evh_1419464017721962_clickPreventStop_6691,
            evh_1419464017721962_clickPreventStop,
            submit: act_1040167445267876_request_6688,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], ContractStatusTransition.prototype, "contract", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('contract'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ContractStatusTransition.prototype, "onContract", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], ContractStatusTransition.prototype, "status", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('status'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ContractStatusTransition.prototype, "onStatus", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], ContractStatusTransition.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ContractStatusTransition.prototype, "onSize", null);
ContractStatusTransition = __decorate([
    (0, vue_property_decorator_1.Component)()
], ContractStatusTransition);
exports.default = ContractStatusTransition;
vue_property_decorator_1.Vue.component("ContractStatusTransition", ContractStatusTransition);
