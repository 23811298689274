"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let CreateContract = class CreateContract extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['contract_type', 'contract', 'contractTypes', 'formName', 'formId', 'labelAlign', 'currentTab', 'categories', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
        this.$$cache_contract_type = new core_1.ComponentValueCache(this, "contract_type", "CreateContract.contractType");
    }
    mapDefaultContract(...args) {
        const data = args[0];
        return _.extend(data, { counterparty: {}, rental: {} });
    }
    onRental_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("rentalId");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_contract() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('read_contract', {
                parameters: {
                    id: 'defaults',
                    type: this.$data.contract_type,
                    rental: this.rentalId
                }
            });
        });
    }
    $$load_contractTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.getEnumValuesFromSchema('ContractTypes');
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_categories() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_contract_category');
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            contract_type: "rental",
            contract: null,
            contractTypes: null,
            formName: "contract",
            formId: null,
            labelAlign: "left",
            currentTab: 0,
            categories: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "lg",
            },
        });
    }
    get companyTranslation() {
        if (this.loaded) {
            return ['rental', 'rental_agreement', 'parking'].indexOf(this.contract.contract_type) > -1 ?
                this.$fn.pgettext('Contract', 'Lessor') :
                this.$fn.pgettext('Contract', 'Company');
        }
        return null;
    }
    get counterpartyTranslation() {
        if (this.loaded) {
            return ['rental', 'rental_agreement', 'parking'].indexOf(this.contract.contract_type) > -1 ?
                this.$fn.pgettext('Contract', 'Tenant') :
                this.$fn.pgettext('Contract', 'Counterparty');
        }
        return null;
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.gettext("Create Contract"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_2792_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_7789867021953041_setData_2798_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "contract",
                value: this.mapDefaultContract($event.data.response.data),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_7789867021953041_request_2796_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "read_contract",
                parameters: {
                    id: "defaults",
                    type: $event.data.value,
                },
            };
        });
    }
    evh_7789867021953041_optionSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7789867021953041_optionSelected.executeFromDOM(this, event, scope);
        });
    }
    act_2140198529698299_setData_2800_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "currentTab",
                value: this.currentTab - 1,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2140198529698299_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2140198529698299_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_8790374038998522_setData_2802_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "currentTab",
                value: this.currentTab + 1,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_8790374038998522_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8790374038998522_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_309864017738714_reloadSlickgrid_2806_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "contracts",
            };
        });
    }
    act_309864017738714_closeComponent_2808_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    close: true,
                },
            };
        });
    }
    act_309864017738714_navigate_2810_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                location: {
                    name: "app.contract",
                    params: {
                        id: $event.data.id,
                    },
                },
            };
        });
    }
    act_309864017738714_crud_2804_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "contract",
                op: "upsert",
                data: this.contract,
            };
        });
    }
    evh_309864017738714_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_309864017738714_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_1589624209624921_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1589624209624921_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2792 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2792_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2791 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2792,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2791],
        });
        const act_2248226175642056_closeComponent_2794 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_2793 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_2794,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_2793],
        });
        const act_7789867021953041_setData_2798 = new core_1.actions.SetDataAction({
            actionArgs: this.act_7789867021953041_setData_2798_getActionArgs,
            events: [],
        });
        const evh_7789867021953041_success_2797 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_setData_2798,
            event: "success",
            displayName: "setData",
        });
        const act_7789867021953041_request_2796 = new core_1.actions.RequestAction({
            actionArgs: this.act_7789867021953041_request_2796_getActionArgs,
            events: [evh_7789867021953041_success_2797],
        });
        const evh_7789867021953041_optionSelected_2795 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_request_2796,
            event: "optionSelected",
            displayName: "request",
        });
        const evh_7789867021953041_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7789867021953041_optionSelected_2795],
        });
        const act_2140198529698299_setData_2800 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2140198529698299_setData_2800_getActionArgs,
            events: [],
        });
        const evh_2140198529698299_clickPreventStop_2799 = new core_1.actions.EventHandlerImpl({
            action: act_2140198529698299_setData_2800,
            event: "click.prevent.stop",
            displayName: "setData",
        });
        const evh_2140198529698299_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2140198529698299_clickPreventStop_2799],
        });
        const act_8790374038998522_setData_2802 = new core_1.actions.SetDataAction({
            actionArgs: this.act_8790374038998522_setData_2802_getActionArgs,
            events: [],
        });
        const evh_8790374038998522_clickPreventStop_2801 = new core_1.actions.EventHandlerImpl({
            action: act_8790374038998522_setData_2802,
            event: "click.prevent.stop",
            displayName: "setData",
        });
        const evh_8790374038998522_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8790374038998522_clickPreventStop_2801],
        });
        const act_309864017738714_reloadSlickgrid_2806 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_309864017738714_reloadSlickgrid_2806_getActionArgs,
            events: [],
        });
        const evh_309864017738714_success_2805 = new core_1.actions.EventHandlerImpl({
            action: act_309864017738714_reloadSlickgrid_2806,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_309864017738714_closeComponent_2808 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_309864017738714_closeComponent_2808_getActionArgs,
            events: [],
        });
        const evh_309864017738714_success_2807 = new core_1.actions.EventHandlerImpl({
            action: act_309864017738714_closeComponent_2808,
            event: "success",
            displayName: "closeComponent",
        });
        const act_309864017738714_navigate_2810 = new core_1.actions.NavigateAction({
            actionArgs: this.act_309864017738714_navigate_2810_getActionArgs,
            events: [],
        });
        const evh_309864017738714_success_2809 = new core_1.actions.EventHandlerImpl({
            action: act_309864017738714_navigate_2810,
            event: "success",
            displayName: "navigate",
        });
        const act_309864017738714_crud_2804 = new core_1.actions.CRUDAction({
            actionArgs: this.act_309864017738714_crud_2804_getActionArgs,
            displayName: "save",
            events: [evh_309864017738714_success_2805, evh_309864017738714_success_2807, evh_309864017738714_success_2809],
        });
        const evh_309864017738714_clickPreventStop_2803 = new core_1.actions.EventHandlerImpl({
            action: act_309864017738714_crud_2804,
            event: "click.prevent.stop",
            displayName: "save",
        });
        const evh_309864017738714_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_309864017738714_clickPreventStop_2803],
        });
        const act_1589624209624921_closeModal_2812 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_1589624209624921_clickPreventStop_2811 = new core_1.actions.EventHandlerImpl({
            action: act_1589624209624921_closeModal_2812,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        const evh_1589624209624921_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1589624209624921_clickPreventStop_2811],
        });
        return {
            act_7315092382398562_closeModal_2792,
            evh_7315092382398562_close_2791,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_2794,
            evh_2248226175642056_close_2793,
            evh_2248226175642056_close,
            act_7789867021953041_setData_2798,
            evh_7789867021953041_success_2797,
            act_7789867021953041_request_2796,
            evh_7789867021953041_optionSelected_2795,
            evh_7789867021953041_optionSelected,
            act_2140198529698299_setData_2800,
            evh_2140198529698299_clickPreventStop_2799,
            evh_2140198529698299_clickPreventStop,
            act_8790374038998522_setData_2802,
            evh_8790374038998522_clickPreventStop_2801,
            evh_8790374038998522_clickPreventStop,
            act_309864017738714_reloadSlickgrid_2806,
            evh_309864017738714_success_2805,
            act_309864017738714_closeComponent_2808,
            evh_309864017738714_success_2807,
            act_309864017738714_navigate_2810,
            evh_309864017738714_success_2809,
            act_309864017738714_crud_2804,
            evh_309864017738714_clickPreventStop_2803,
            evh_309864017738714_clickPreventStop,
            act_1589624209624921_closeModal_2812,
            evh_1589624209624921_clickPreventStop_2811,
            evh_1589624209624921_clickPreventStop,
            save: act_309864017738714_crud_2804,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], CreateContract.prototype, "rentalId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('rentalId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], CreateContract.prototype, "onRental_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "lg",
    }),
    __metadata("design:type", String)
], CreateContract.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], CreateContract.prototype, "onSize", null);
CreateContract = __decorate([
    (0, vue_property_decorator_1.Component)()
], CreateContract);
exports.default = CreateContract;
vue_property_decorator_1.Vue.component("CreateContract", CreateContract);
