"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcContractEvents = class UcContractEvents extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['items', 'ux'];
    }
    beforeCreate() {
    }
    onContract(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contract");
    }
    onReadonly(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("readonly");
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_contract_protocol', {
                parameters: {
                    contract_id: this.contract.id,
                    contract_revision_id: this.contract.revision_id
                }
            })
                .then(d => _.orderBy(d, ['handover_date'], ['desc']));
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            items: null,
            ux: null,
        });
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_4440454779651433_emit_6520_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_4440454779651433_close_6519_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.modalResult && $event.data.modalResult.ok;
        });
    }
    act_4440454779651433_showModal_6518_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "HandoverProtocol",
                props: {
                    contractId: this.contract.id,
                    revisionId: this.contract.revision_id,
                    handoverType: "moving_in",
                },
            };
        });
    }
    evh_4440454779651433_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4440454779651433_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_8643180786190723_emit_6524_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_8643180786190723_close_6523_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.modalResult && $event.data.modalResult.ok;
        });
    }
    act_8643180786190723_showModal_6522_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "HandoverProtocol",
                props: {
                    contractId: this.contract.id,
                    revisionId: this.contract.revision_id,
                    handoverType: "moving_out",
                },
            };
        });
    }
    evh_8643180786190723_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8643180786190723_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_2002045350107722_close_6527_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.modalResult && $event.data.modalResult.ok;
        });
    }
    act_2002045350107722_showModal_6526_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "HandoverProtocol",
                props: {
                    oid: $event.scope.item.id,
                },
            };
        });
    }
    evh_2002045350107722_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2002045350107722_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_6516 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_6515 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_6516,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_6515],
        });
        const act_4440454779651433_emit_6520 = new core_1.actions.EmitAction({
            actionArgs: this.act_4440454779651433_emit_6520_getActionArgs,
            displayName: "emitReload",
            events: [],
        });
        const evh_4440454779651433_close_6519 = new core_1.actions.EventHandlerImpl({
            action: act_4440454779651433_emit_6520,
            event: "close",
            when: this.evh_4440454779651433_close_6519_getWhen,
            displayName: "emitReload",
        });
        const act_4440454779651433_showModal_6518 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_4440454779651433_showModal_6518_getActionArgs,
            events: [evh_4440454779651433_close_6519],
        });
        const evh_4440454779651433_clickPreventStop_6517 = new core_1.actions.EventHandlerImpl({
            action: act_4440454779651433_showModal_6518,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_4440454779651433_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4440454779651433_clickPreventStop_6517],
        });
        const act_8643180786190723_emit_6524 = new core_1.actions.EmitAction({
            actionArgs: this.act_8643180786190723_emit_6524_getActionArgs,
            displayName: "emitReload",
            events: [],
        });
        const evh_8643180786190723_close_6523 = new core_1.actions.EventHandlerImpl({
            action: act_8643180786190723_emit_6524,
            event: "close",
            when: this.evh_8643180786190723_close_6523_getWhen,
            displayName: "emitReload",
        });
        const act_8643180786190723_showModal_6522 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8643180786190723_showModal_6522_getActionArgs,
            events: [evh_8643180786190723_close_6523],
        });
        const evh_8643180786190723_clickPreventStop_6521 = new core_1.actions.EventHandlerImpl({
            action: act_8643180786190723_showModal_6522,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_8643180786190723_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8643180786190723_clickPreventStop_6521],
        });
        const act_2002045350107722_reloadComponentData_6528 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_2002045350107722_close_6527 = new core_1.actions.EventHandlerImpl({
            action: act_2002045350107722_reloadComponentData_6528,
            event: "close",
            when: this.evh_2002045350107722_close_6527_getWhen,
            displayName: "reloadComponentData",
        });
        const act_2002045350107722_showModal_6526 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2002045350107722_showModal_6526_getActionArgs,
            events: [evh_2002045350107722_close_6527],
        });
        const evh_2002045350107722_clickPreventStop_6525 = new core_1.actions.EventHandlerImpl({
            action: act_2002045350107722_showModal_6526,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_2002045350107722_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2002045350107722_clickPreventStop_6525],
        });
        return {
            act_7315092382398562_reloadComponentData_6516,
            evh_7315092382398562_reload_6515,
            evh_7315092382398562_reload,
            act_4440454779651433_emit_6520,
            evh_4440454779651433_close_6519,
            act_4440454779651433_showModal_6518,
            evh_4440454779651433_clickPreventStop_6517,
            evh_4440454779651433_clickPreventStop,
            act_8643180786190723_emit_6524,
            evh_8643180786190723_close_6523,
            act_8643180786190723_showModal_6522,
            evh_8643180786190723_clickPreventStop_6521,
            evh_8643180786190723_clickPreventStop,
            act_2002045350107722_reloadComponentData_6528,
            evh_2002045350107722_close_6527,
            act_2002045350107722_showModal_6526,
            evh_2002045350107722_clickPreventStop_6525,
            evh_2002045350107722_clickPreventStop,
            emitReload: act_8643180786190723_emit_6524,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcContractEvents.prototype, "contract", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('contract'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcContractEvents.prototype, "onContract", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], UcContractEvents.prototype, "readonly", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('readonly'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcContractEvents.prototype, "onReadonly", null);
UcContractEvents = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcContractEvents);
exports.default = UcContractEvents;
vue_property_decorator_1.Vue.component("UcContractEvents", UcContractEvents);
