"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let InvoiceRegisterEntry = class InvoiceRegisterEntry extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['container', 'formId', 'formName', 'createTitle', 'defaultTitle', 'registerObj', 'resource', 'editor', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    onRegister(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("register");
    }
    onCompany_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("companyId");
    }
    onInvoice(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_container() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                self: this.$fn.urlFor('create_invoice_register_entry'),
                object: 'list',
                data: []
            };
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_registerObj() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.register || this.$fn.fetch('read_register', { parameters: { id: 'current', register_type: 'incoming_invoice' } });
        });
    }
    $$load_resource() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.oid
                ? this.$fn.fetch('read_invoice_register_entry', { parameters: { id: this.oid }, asResource: true })
                : this.$fn.schemaDefaults('CreateInvoiceRegisterEntryCommand', this.invoice ?
                    {
                        id: null,
                        register_name: this.registerObj.id,
                        invoice_kind: this.invoice.invoice_kind,
                        invoice_type: this.invoice.invoice_type,
                        received_date: this.$fn.today(),
                        categories: this.invoice.tags,
                        description: this.invoice.description || this.invoice.notes,
                        company_id: this.invoice.company.id,
                        company: this.invoice.company,
                        client_id: this.invoice.client.id,
                        client: this.invoice.client,
                        invoice_id: this.invoice.id,
                        reference_invoice_number: this.invoice.invoice_number,
                        invoice_number: this.invoice.invoice_number,
                        invoice_date: this.invoice.invoice_date,
                        due_date: this.invoice.due_date,
                        fulfillment_date: this.invoice.fulfillment_date,
                        payment_method: this.invoice.payment_method,
                        notes: this.invoice.notes,
                        amount: {
                            amount: this.invoice.totals.total,
                            currency: this.invoice.currency
                        }
                    } :
                    {
                        id: null,
                        register_name: this.registerObj.id,
                        invoice_kind: 'incoming',
                        invoice_type: 'normal',
                        company_id: this.companyId,
                        received_date: this.$fn.today(),
                        payment_method: 'bank_transfer',
                        amount: {
                            amount: 0,
                            currency: 'HUF'
                        }
                    });
        });
    }
    $$load_editor() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.resource.editor();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            container: null,
            formId: null,
            formName: "invoiceRegisterEntry",
            createTitle: this.$fn.pgettext("InvoiceRegisterEntry", "New Registry Entry"),
            defaultTitle: this.$fn.pgettext("InvoiceRegisterEntry", "Edit Invoice Registry Information"),
            registerObj: null,
            resource: null,
            editor: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "lg",
            },
        });
    }
    get isDeletable() {
        if (this.loaded) {
            return this.oid != null;
        }
        return null;
    }
    get creatingTitle() {
        if (this.loaded) {
            return this.$fn.pgettext("InvoiceRegisterEntry", "Create new entry");
        }
        return null;
    }
    get editingTitle() {
        if (this.loaded) {
            return '#' + this.resource.entry_number;
        }
        return null;
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.resource.isNew ? this.creatingTitle : this.editingTitle,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_3276_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    evh_8564662037462133_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
        });
    }
    act_8564662037462133_reloadSlickgrid_3284_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "invoiceRegister",
            };
        });
    }
    evh_8564662037462133_deleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_emit_3288_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "saved",
                value: $event.data,
            };
        });
    }
    act_1040167445267876_reloadSlickgrid_3290_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "invoiceRegister",
            };
        });
    }
    act_1040167445267876_rest_3286_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "save",
                container: this.container,
                resource: this.editor,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    evh_5534025912102772_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5534025912102772_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_5534025912102772_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5534025912102772_close.executeFromDOM(this, event, scope);
        });
    }
    act_5534025912102772_emit_3296_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "saved",
                value: $event.data,
            };
        });
    }
    evh_5534025912102772_changed(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5534025912102772_changed.executeFromDOM(this, event, scope);
        });
    }
    act_1548630417156826_script_3298_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            (this.setData('resource', this.resource.clone({
                id: null,
                rev: null,
                register_name: this.resource.register ?
                    this.resource.register.id :
                    this.registerObj.id,
                invoice_number: null,
                entry_number: null,
                received_date: this.$fn.today(),
                links: null,
                etag: null,
                amount: { amount: 0, currency: this.resource.amount.currency }
            })),
                this.setData('editor', this.resource.editor()));
        });
    }
    act_1548630417156826_script_3298_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_1548630417156826_script_3298_getActionArgs_value($event),
            };
        });
    }
    evh_1548630417156826_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_click.executeFromDOM(this, event, scope);
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_3276 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_3276_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_3275 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_3276,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_3275],
        });
        const act_2248226175642056_closeComponent_3278 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_3277 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_3278,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_3277],
        });
        const act_8564662037462133_reloadComponentData_3280 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_8564662037462133_reload_3279 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadComponentData_3280,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_8564662037462133_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_reload_3279],
        });
        const act_8564662037462133_closeComponent_3282 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_8564662037462133_deleted_3281 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_closeComponent_3282,
            event: "deleted",
            displayName: "closeComponent",
        });
        const act_8564662037462133_reloadSlickgrid_3284 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8564662037462133_reloadSlickgrid_3284_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_deleted_3283 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadSlickgrid_3284,
            event: "deleted",
            displayName: "reloadSlickgrid",
        });
        const evh_8564662037462133_deleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_deleted_3281, evh_8564662037462133_deleted_3283],
        });
        const act_1040167445267876_emit_3288 = new core_1.actions.EmitAction({
            actionArgs: this.act_1040167445267876_emit_3288_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_saved_3287 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_emit_3288,
            event: "saved",
            displayName: "emit",
        });
        const act_1040167445267876_reloadSlickgrid_3290 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1040167445267876_reloadSlickgrid_3290_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_saved_3289 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_reloadSlickgrid_3290,
            event: "saved",
            displayName: "reloadSlickgrid",
        });
        const act_1040167445267876_rest_3286 = new core_1.actions.RestAction({
            actionArgs: this.act_1040167445267876_rest_3286_getActionArgs,
            displayName: "save",
            events: [evh_1040167445267876_saved_3287, evh_1040167445267876_saved_3289],
        });
        const evh_1040167445267876_submit_3285 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_rest_3286,
            event: "submit",
            displayName: "save",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_3285],
        });
        const act_5534025912102772_reloadComponentData_3292 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_5534025912102772_reload_3291 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_reloadComponentData_3292,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_5534025912102772_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5534025912102772_reload_3291],
        });
        const act_5534025912102772_closeComponent_3294 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_5534025912102772_close_3293 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_closeComponent_3294,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_5534025912102772_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5534025912102772_close_3293],
        });
        const act_5534025912102772_emit_3296 = new core_1.actions.EmitAction({
            actionArgs: this.act_5534025912102772_emit_3296_getActionArgs,
            events: [],
        });
        const evh_5534025912102772_changed_3295 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_emit_3296,
            event: "changed",
            displayName: "emit",
        });
        const evh_5534025912102772_changed = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5534025912102772_changed_3295],
        });
        const act_1548630417156826_script_3298 = new core_1.actions.ScriptAction({
            actionArgs: this.act_1548630417156826_script_3298_getActionArgs,
            events: [],
        });
        const evh_1548630417156826_click_3297 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_script_3298,
            event: "click",
            displayName: "script",
        });
        const evh_1548630417156826_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_click_3297],
        });
        const act_1419464017721962_closeComponent_3300 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_3299 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_3300,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_3299],
        });
        return {
            act_7315092382398562_closeModal_3276,
            evh_7315092382398562_close_3275,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_3278,
            evh_2248226175642056_close_3277,
            evh_2248226175642056_close,
            act_8564662037462133_reloadComponentData_3280,
            evh_8564662037462133_reload_3279,
            evh_8564662037462133_reload,
            act_8564662037462133_closeComponent_3282,
            evh_8564662037462133_deleted_3281,
            act_8564662037462133_reloadSlickgrid_3284,
            evh_8564662037462133_deleted_3283,
            evh_8564662037462133_deleted,
            act_1040167445267876_emit_3288,
            evh_1040167445267876_saved_3287,
            act_1040167445267876_reloadSlickgrid_3290,
            evh_1040167445267876_saved_3289,
            act_1040167445267876_rest_3286,
            evh_1040167445267876_submit_3285,
            evh_1040167445267876_submit,
            act_5534025912102772_reloadComponentData_3292,
            evh_5534025912102772_reload_3291,
            evh_5534025912102772_reload,
            act_5534025912102772_closeComponent_3294,
            evh_5534025912102772_close_3293,
            evh_5534025912102772_close,
            act_5534025912102772_emit_3296,
            evh_5534025912102772_changed_3295,
            evh_5534025912102772_changed,
            act_1548630417156826_script_3298,
            evh_1548630417156826_click_3297,
            evh_1548630417156826_click,
            act_1419464017721962_closeComponent_3300,
            evh_1419464017721962_clickPreventStop_3299,
            evh_1419464017721962_clickPreventStop,
            save: act_1040167445267876_rest_3286,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], InvoiceRegisterEntry.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceRegisterEntry.prototype, "onOid", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], InvoiceRegisterEntry.prototype, "register", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('register'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceRegisterEntry.prototype, "onRegister", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], InvoiceRegisterEntry.prototype, "companyId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('companyId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceRegisterEntry.prototype, "onCompany_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], InvoiceRegisterEntry.prototype, "invoice", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoice'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceRegisterEntry.prototype, "onInvoice", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "lg",
    }),
    __metadata("design:type", String)
], InvoiceRegisterEntry.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceRegisterEntry.prototype, "onSize", null);
InvoiceRegisterEntry = __decorate([
    (0, vue_property_decorator_1.Component)()
], InvoiceRegisterEntry);
exports.default = InvoiceRegisterEntry;
vue_property_decorator_1.Vue.component("InvoiceRegisterEntry", InvoiceRegisterEntry);
