"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "settings.expenseTypes",
    path: "expense-types",
    component: "ExpenseTypes"
});
let ExpenseTypes = class ExpenseTypes extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['objectType', 'items', 'ux', 'b_7315092382398562_modalBindings', 'b_8564662037462133_columns'];
    }
    beforeCreate() {
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_object_by_type', { parameters: { object_type: this.objectType } });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            objectType: "expense-type",
            items: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_8564662037462133_columns: [{
                    name: "code",
                    header: this.$fn.gettext("Code"),
                    width: "160px",
                    required: true,
                },
                {
                    name: "name",
                    header: this.$fn.gettext("Name"),
                    width: "140px",
                    required: true,
                },
                {
                    name: "description",
                    header: this.$fn.gettext("Description"),
                    width: "320px",
                },
            ],
        });
    }
    get document() {
        if (this.loaded) {
            return;
        }
        return null;
    }
    act_7315092382398562_closeModal_4116_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_8564662037462133_script_4118_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('object_by_type').upsert(Object.assign(Object.assign({}, $event.data.row), { object_type: this.objectType })));
        });
    }
    act_8564662037462133_script_4118_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_8564662037462133_script_4118_getActionArgs_value($event),
            };
        });
    }
    evh_8564662037462133_save(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_save.executeFromDOM(this, event, scope);
        });
    }
    act_8564662037462133_script_4120_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            $event.data.waitFor(this.$fn.crud('object_by_type').delete(Object.assign(Object.assign({}, $event.data.row), { object_type: this.objectType })));
        });
    }
    act_8564662037462133_script_4120_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_8564662037462133_script_4120_getActionArgs_value($event),
            };
        });
    }
    evh_8564662037462133_delete(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_delete.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_4116 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_4116_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_4115 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_4116,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_4115],
        });
        const act_8564662037462133_script_4118 = new core_1.actions.ScriptAction({
            actionArgs: this.act_8564662037462133_script_4118_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_save_4117 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_script_4118,
            event: "save",
            displayName: "script",
        });
        const evh_8564662037462133_save = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_save_4117],
        });
        const act_8564662037462133_script_4120 = new core_1.actions.ScriptAction({
            actionArgs: this.act_8564662037462133_script_4120_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_delete_4119 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_script_4120,
            event: "delete",
            displayName: "script",
        });
        const evh_8564662037462133_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_delete_4119],
        });
        return {
            act_7315092382398562_closeModal_4116,
            evh_7315092382398562_close_4115,
            evh_7315092382398562_close,
            act_8564662037462133_script_4118,
            evh_8564662037462133_save_4117,
            evh_8564662037462133_save,
            act_8564662037462133_script_4120,
            evh_8564662037462133_delete_4119,
            evh_8564662037462133_delete,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], ExpenseTypes.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ExpenseTypes.prototype, "onSize", null);
ExpenseTypes = __decorate([
    (0, vue_property_decorator_1.Component)()
], ExpenseTypes);
exports.default = ExpenseTypes;
vue_property_decorator_1.Vue.component("ExpenseTypes", ExpenseTypes);
