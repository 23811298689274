"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let FieldsetContractInvoicing = class FieldsetContractInvoicing extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['currencies', 'billing_frequency', 'billingFrequencyOptions', 'contractPaymentTermTypes', 'ux'];
    }
    beforeCreate() {
    }
    updateBillingCycle(...args) {
        this.contract.invoicing.billing_cycle = `${this.billing_frequency.value} ${this.billing_frequency.unit}`;
    }
    onContract(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contract");
    }
    onLabel_align(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("labelAlign");
    }
    $$load_currencies() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_currency');
        });
    }
    $$load_billing_frequency() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.contract.invoicing.billing_cycle != null ?
                {
                    value: this.contract.invoicing.billing_cycle.split(" ")[0],
                    unit: this.contract.invoicing.billing_cycle.split(" ")[1]
                } : {
                value: null,
                unit: null
            };
        });
    }
    $$load_contractPaymentTermTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.getEnumValuesFromSchema('ContractPaymentTermTypes');
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            currencies: null,
            billing_frequency: null,
            billingFrequencyOptions: [{
                    value: "day",
                    text: this.$fn.gettext("day"),
                },
                {
                    value: "month",
                    text: this.$fn.gettext("month"),
                },
            ],
            contractPaymentTermTypes: null,
            ux: null,
        });
    }
    act_8120114320850587_script_6558_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.updateBillingCycle();
        });
    }
    act_8120114320850587_script_6558_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_8120114320850587_script_6558_getActionArgs_value($event),
            };
        });
    }
    evh_8120114320850587_input(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8120114320850587_input.executeFromDOM(this, event, scope);
        });
    }
    act_7882194489015218_script_6560_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.updateBillingCycle();
        });
    }
    act_7882194489015218_script_6560_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_7882194489015218_script_6560_getActionArgs_value($event),
            };
        });
    }
    evh_7882194489015218_input(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7882194489015218_input.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_8120114320850587_script_6558 = new core_1.actions.ScriptAction({
            actionArgs: this.act_8120114320850587_script_6558_getActionArgs,
            events: [],
        });
        const evh_8120114320850587_input_6557 = new core_1.actions.EventHandlerImpl({
            action: act_8120114320850587_script_6558,
            event: "input",
            displayName: "script",
        });
        const evh_8120114320850587_input = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8120114320850587_input_6557],
        });
        const act_7882194489015218_script_6560 = new core_1.actions.ScriptAction({
            actionArgs: this.act_7882194489015218_script_6560_getActionArgs,
            events: [],
        });
        const evh_7882194489015218_input_6559 = new core_1.actions.EventHandlerImpl({
            action: act_7882194489015218_script_6560,
            event: "input",
            displayName: "script",
        });
        const evh_7882194489015218_input = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7882194489015218_input_6559],
        });
        return {
            act_8120114320850587_script_6558,
            evh_8120114320850587_input_6557,
            evh_8120114320850587_input,
            act_7882194489015218_script_6560,
            evh_7882194489015218_input_6559,
            evh_7882194489015218_input,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], FieldsetContractInvoicing.prototype, "contract", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('contract'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FieldsetContractInvoicing.prototype, "onContract", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "right",
    }),
    __metadata("design:type", String)
], FieldsetContractInvoicing.prototype, "labelAlign", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('labelAlign'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FieldsetContractInvoicing.prototype, "onLabel_align", null);
FieldsetContractInvoicing = __decorate([
    (0, vue_property_decorator_1.Component)()
], FieldsetContractInvoicing);
exports.default = FieldsetContractInvoicing;
vue_property_decorator_1.Vue.component("FieldsetContractInvoicing", FieldsetContractInvoicing);
