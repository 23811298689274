"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let HandoverProtocol = class HandoverProtocol extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['item', 'formName', 'formId', 'labelAlign', 'handoverTypes', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    onContract_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contractId");
    }
    onRevision_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("revisionId");
    }
    onHandover_type(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("handoverType");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.oid ?
                this.$fn.fetch('read_contract_protocol', {
                    asResource: true,
                    parameters: { id: this.oid }
                }) :
                this.$fn.fetch('get_contract_protocol_template', {
                    asResource: true,
                    parameters: {
                        contract_id: this.contractId,
                        revision_id: this.revisionId,
                        handover_type: this.handoverType,
                    }
                });
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_handoverTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.getEnumValuesFromSchema('HandoverType');
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            formName: "handoverProtocol",
            formId: null,
            labelAlign: "left",
            handoverTypes: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "lg",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: [this.$fn.pgettext("Contract", "Handover protocol"),
                    this.$fn.pgettext("HandoverType", this.item.handover_type, "Sentence"),
                ],
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_6484_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    evh_8564662037462133_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
        });
    }
    act_8564662037462133_closeModal_6490_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    evh_8564662037462133_deleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_script_6494_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.item.reset($event.data);
        });
    }
    act_1040167445267876_script_6494_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_1040167445267876_script_6494_getActionArgs_value($event),
            };
        });
    }
    evh_1040167445267876_success_6493_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data;
        });
    }
    act_1040167445267876_crud_6492_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "contract_protocol",
                op: "upsert",
                data: this.item,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    evh_5534025912102772_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5534025912102772_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_5534025912102772_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5534025912102772_close.executeFromDOM(this, event, scope);
        });
    }
    act_5534025912102772_emit_6500_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "saved",
                value: $event.data,
            };
        });
    }
    evh_5534025912102772_changed(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5534025912102772_changed.executeFromDOM(this, event, scope);
        });
    }
    act_1548630417156826_closeComponent_6504_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    act_1548630417156826_crud_6502_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "contract_protocol",
                op: "upsert",
                data: Object.assign(Object.assign({}, this.item), { protocol_status: 'cancelled' }),
            };
        });
    }
    act_1548630417156826_crud_6502_getConfirm($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                header: "Jegyőkönyv érvénytelenítése",
                message: "A jegyzőkönyv érvénytelenítés után már nem módosítható.",
                btnOk: "Érvénytelenítés",
                severity: "warning",
            };
        });
    }
    evh_1548630417156826_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_1419464017721962_closeComponent_6508_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    act_1419464017721962_crud_6506_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "contract_protocol",
                op: "upsert",
                data: Object.assign(Object.assign({}, this.item), { protocol_status: 'completed' }),
            };
        });
    }
    act_1419464017721962_crud_6506_getConfirm($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                header: "Jegyőkönyv véglegesítése",
                message: "A jegyzőkönyv véglegesítés után már nem módosítható.",
                btnOk: "Véglegesítés",
                severity: "warning",
            };
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_2186679009640457_script_6512_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.item.reset($event.data);
        });
    }
    act_2186679009640457_script_6512_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_2186679009640457_script_6512_getActionArgs_value($event),
            };
        });
    }
    evh_2186679009640457_success_6511_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data;
        });
    }
    act_2186679009640457_crud_6510_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "contract_protocol",
                op: "upsert",
                data: this.item,
            };
        });
    }
    evh_2186679009640457_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_5566617329548203_closeComponent_6514_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    evh_5566617329548203_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5566617329548203_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_6484 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_6484_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_6483 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_6484,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_6483],
        });
        const act_2248226175642056_closeModal_6486 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_2248226175642056_close_6485 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeModal_6486,
            event: "close",
            displayName: "closeModal",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_6485],
        });
        const act_8564662037462133_reloadComponentData_6488 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_8564662037462133_reload_6487 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadComponentData_6488,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_8564662037462133_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_reload_6487],
        });
        const act_8564662037462133_closeModal_6490 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_8564662037462133_closeModal_6490_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_deleted_6489 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_closeModal_6490,
            event: "deleted",
            displayName: "closeModal",
        });
        const evh_8564662037462133_deleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_deleted_6489],
        });
        const act_1040167445267876_script_6494 = new core_1.actions.ScriptAction({
            actionArgs: this.act_1040167445267876_script_6494_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_6493 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_script_6494,
            event: "success",
            when: this.evh_1040167445267876_success_6493_getWhen,
            displayName: "script",
        });
        const act_1040167445267876_crud_6492 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1040167445267876_crud_6492_getActionArgs,
            displayName: "save",
            events: [evh_1040167445267876_success_6493],
        });
        const evh_1040167445267876_submit_6491 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_crud_6492,
            event: "submit",
            displayName: "save",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_6491],
        });
        const act_5534025912102772_reloadComponentData_6496 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_5534025912102772_reload_6495 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_reloadComponentData_6496,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_5534025912102772_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5534025912102772_reload_6495],
        });
        const act_5534025912102772_closeComponent_6498 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_5534025912102772_close_6497 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_closeComponent_6498,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_5534025912102772_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5534025912102772_close_6497],
        });
        const act_5534025912102772_emit_6500 = new core_1.actions.EmitAction({
            actionArgs: this.act_5534025912102772_emit_6500_getActionArgs,
            events: [],
        });
        const evh_5534025912102772_changed_6499 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_emit_6500,
            event: "changed",
            displayName: "emit",
        });
        const evh_5534025912102772_changed = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5534025912102772_changed_6499],
        });
        const act_1548630417156826_closeComponent_6504 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1548630417156826_closeComponent_6504_getActionArgs,
            events: [],
        });
        const evh_1548630417156826_success_6503 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeComponent_6504,
            event: "success",
            displayName: "closeComponent",
        });
        const act_1548630417156826_crud_6502 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1548630417156826_crud_6502_getActionArgs,
            confirm: this.act_1548630417156826_crud_6502_getConfirm,
            displayName: "cancel",
            events: [evh_1548630417156826_success_6503],
        });
        const evh_1548630417156826_clickPreventStop_6501 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_crud_6502,
            event: "click.prevent.stop",
            displayName: "cancel",
        });
        const evh_1548630417156826_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_clickPreventStop_6501],
        });
        const act_1419464017721962_closeComponent_6508 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1419464017721962_closeComponent_6508_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_success_6507 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_6508,
            event: "success",
            displayName: "closeComponent",
        });
        const act_1419464017721962_crud_6506 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1419464017721962_crud_6506_getActionArgs,
            confirm: this.act_1419464017721962_crud_6506_getConfirm,
            displayName: "complete",
            events: [evh_1419464017721962_success_6507],
        });
        const evh_1419464017721962_clickPreventStop_6505 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_crud_6506,
            event: "click.prevent.stop",
            displayName: "complete",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_6505],
        });
        const act_2186679009640457_script_6512 = new core_1.actions.ScriptAction({
            actionArgs: this.act_2186679009640457_script_6512_getActionArgs,
            events: [],
        });
        const evh_2186679009640457_success_6511 = new core_1.actions.EventHandlerImpl({
            action: act_2186679009640457_script_6512,
            event: "success",
            when: this.evh_2186679009640457_success_6511_getWhen,
            displayName: "script",
        });
        const act_2186679009640457_crud_6510 = new core_1.actions.CRUDAction({
            actionArgs: this.act_2186679009640457_crud_6510_getActionArgs,
            displayName: "save",
            events: [evh_2186679009640457_success_6511],
        });
        const evh_2186679009640457_clickPreventStop_6509 = new core_1.actions.EventHandlerImpl({
            action: act_2186679009640457_crud_6510,
            event: "click.prevent.stop",
            displayName: "save",
        });
        const evh_2186679009640457_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2186679009640457_clickPreventStop_6509],
        });
        const act_5566617329548203_closeComponent_6514 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_5566617329548203_closeComponent_6514_getActionArgs,
            events: [],
        });
        const evh_5566617329548203_clickPreventStop_6513 = new core_1.actions.EventHandlerImpl({
            action: act_5566617329548203_closeComponent_6514,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_5566617329548203_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5566617329548203_clickPreventStop_6513],
        });
        return {
            act_7315092382398562_closeModal_6484,
            evh_7315092382398562_close_6483,
            evh_7315092382398562_close,
            act_2248226175642056_closeModal_6486,
            evh_2248226175642056_close_6485,
            evh_2248226175642056_close,
            act_8564662037462133_reloadComponentData_6488,
            evh_8564662037462133_reload_6487,
            evh_8564662037462133_reload,
            act_8564662037462133_closeModal_6490,
            evh_8564662037462133_deleted_6489,
            evh_8564662037462133_deleted,
            act_1040167445267876_script_6494,
            evh_1040167445267876_success_6493,
            act_1040167445267876_crud_6492,
            evh_1040167445267876_submit_6491,
            evh_1040167445267876_submit,
            act_5534025912102772_reloadComponentData_6496,
            evh_5534025912102772_reload_6495,
            evh_5534025912102772_reload,
            act_5534025912102772_closeComponent_6498,
            evh_5534025912102772_close_6497,
            evh_5534025912102772_close,
            act_5534025912102772_emit_6500,
            evh_5534025912102772_changed_6499,
            evh_5534025912102772_changed,
            act_1548630417156826_closeComponent_6504,
            evh_1548630417156826_success_6503,
            act_1548630417156826_crud_6502,
            evh_1548630417156826_clickPreventStop_6501,
            evh_1548630417156826_clickPreventStop,
            act_1419464017721962_closeComponent_6508,
            evh_1419464017721962_success_6507,
            act_1419464017721962_crud_6506,
            evh_1419464017721962_clickPreventStop_6505,
            evh_1419464017721962_clickPreventStop,
            act_2186679009640457_script_6512,
            evh_2186679009640457_success_6511,
            act_2186679009640457_crud_6510,
            evh_2186679009640457_clickPreventStop_6509,
            evh_2186679009640457_clickPreventStop,
            act_5566617329548203_closeComponent_6514,
            evh_5566617329548203_clickPreventStop_6513,
            evh_5566617329548203_clickPreventStop,
            save: act_2186679009640457_crud_6510,
            cancel: act_1548630417156826_crud_6502,
            complete: act_1419464017721962_crud_6506,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], HandoverProtocol.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], HandoverProtocol.prototype, "onOid", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], HandoverProtocol.prototype, "contractId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('contractId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], HandoverProtocol.prototype, "onContract_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], HandoverProtocol.prototype, "revisionId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('revisionId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], HandoverProtocol.prototype, "onRevision_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], HandoverProtocol.prototype, "handoverType", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('handoverType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], HandoverProtocol.prototype, "onHandover_type", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "lg",
    }),
    __metadata("design:type", String)
], HandoverProtocol.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], HandoverProtocol.prototype, "onSize", null);
HandoverProtocol = __decorate([
    (0, vue_property_decorator_1.Component)()
], HandoverProtocol);
exports.default = HandoverProtocol;
vue_property_decorator_1.Vue.component("HandoverProtocol", HandoverProtocol);
