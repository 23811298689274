"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let SendMails = class SendMails extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['selectedTemplate', 'templates', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onItems(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("items");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_templates() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_template')
                .then(data => _.filter(data, { template_type: 'mjml' }));
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            selectedTemplate: null,
            templates: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.gettext("Send emails"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_2982_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_register_2988_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "resultLength",
                value: $event.data.response.data ? $event.data.response.data.length : 0,
            };
        });
    }
    act_1040167445267876_notification_2990_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                message: this.$fn.ngettext("One email has been successfully enqueued.", "${n} emails have been successfully enqueued.", $event.data.resultLength),
            };
        });
    }
    act_1040167445267876_notification_2990_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.resultLength > 0;
        });
    }
    act_1040167445267876_reloadSlickgrid_2992_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "all",
            };
        });
    }
    act_1040167445267876_closeComponent_2994_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    close: true,
                },
            };
        });
    }
    act_1040167445267876_request_2986_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "create_job",
                data: _.chain(this.items)
                    .filter(item => item.customer_email)
                    .map(item => ({
                    job_type: 'sendmail:dunning_letter',
                    job_data: {
                        id: item.id,
                        template: this.selectedTemplate,
                        mail_to: item.customer_email
                    }
                }))
                    .value(),
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2982 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2982_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2981 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2982,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2981],
        });
        const act_2248226175642056_closeComponent_2984 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_2983 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_2984,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_2983],
        });
        const act_1040167445267876_register_2988 = new core_1.actions.RegisterAction({
            actionArgs: this.act_1040167445267876_register_2988_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_2987 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_register_2988,
            event: "success",
            displayName: "register",
        });
        const act_1040167445267876_notification_2990 = new core_1.actions.NotificationAction({
            actionArgs: this.act_1040167445267876_notification_2990_getActionArgs,
            when: this.act_1040167445267876_notification_2990_getWhen,
            events: [],
        });
        const evh_1040167445267876_success_2989 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_notification_2990,
            event: "success",
            displayName: "notification",
        });
        const act_1040167445267876_reloadSlickgrid_2992 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1040167445267876_reloadSlickgrid_2992_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_2991 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_reloadSlickgrid_2992,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_1040167445267876_closeComponent_2994 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1040167445267876_closeComponent_2994_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_2993 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeComponent_2994,
            event: "success",
            displayName: "closeComponent",
        });
        const act_1040167445267876_request_2986 = new core_1.actions.RequestAction({
            actionArgs: this.act_1040167445267876_request_2986_getActionArgs,
            displayName: "submit",
            events: [evh_1040167445267876_success_2987, evh_1040167445267876_success_2989, evh_1040167445267876_success_2991, evh_1040167445267876_success_2993],
        });
        const evh_1040167445267876_submit_2985 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_request_2986,
            event: "submit",
            displayName: "submit",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_2985],
        });
        const act_1419464017721962_closeComponent_2996 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_2995 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_2996,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_2995],
        });
        return {
            act_7315092382398562_closeModal_2982,
            evh_7315092382398562_close_2981,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_2984,
            evh_2248226175642056_close_2983,
            evh_2248226175642056_close,
            act_1040167445267876_register_2988,
            evh_1040167445267876_success_2987,
            act_1040167445267876_notification_2990,
            evh_1040167445267876_success_2989,
            act_1040167445267876_reloadSlickgrid_2992,
            evh_1040167445267876_success_2991,
            act_1040167445267876_closeComponent_2994,
            evh_1040167445267876_success_2993,
            act_1040167445267876_request_2986,
            evh_1040167445267876_submit_2985,
            evh_1040167445267876_submit,
            act_1419464017721962_closeComponent_2996,
            evh_1419464017721962_clickPreventStop_2995,
            evh_1419464017721962_clickPreventStop,
            submit: act_1040167445267876_request_2986,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        default: null,
    }),
    __metadata("design:type", typeof (_a = typeof list !== "undefined" && list) === "function" ? _a : Object)
], SendMails.prototype, "items", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('items'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], SendMails.prototype, "onItems", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "sm",
    }),
    __metadata("design:type", String)
], SendMails.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], SendMails.prototype, "onSize", null);
SendMails = __decorate([
    (0, vue_property_decorator_1.Component)()
], SendMails);
exports.default = SendMails;
vue_property_decorator_1.Vue.component("SendMails", SendMails);
