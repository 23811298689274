"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let InvoiceLineMeterReadingSelector = class InvoiceLineMeterReadingSelector extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['services', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    getPayload(...args) {
        const [data] = args;
        const fields = this.target && this.targetConfig[this.target].fieldsToSubmit;
        return fields ? _.pick(data, fields) : data;
    }
    isServiceStale(...args) {
        const [service] = args;
        return service.valid_to && this.$fn.parseISODate(service.valid_to) < this.today;
    }
    onCheckChanged(...args) {
        const [service] = args;
        const selection = _
            .chain(service.readings)
            .filter({ selected: true })
            .reverse()
            .value();
        const consumptions = [];
        selection.forEach((item, index) => {
            const nextItem = selection[index + 1];
            if (nextItem == null) {
                return;
            }
            consumptions.push({
                $key: item.id + nextItem.id,
                meter: service.meter,
                from_date: item.reading_date,
                from_amount: item.value,
                from_unit: item.unit,
                to_date: nextItem.reading_date,
                to_amount: nextItem.value,
                to_unit: nextItem.unit,
                billing_multiplier: service.meter_factor,
                diff: nextItem.value - item.value,
            });
        });
        service.consumptions = consumptions;
    }
    onRental(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("rental");
    }
    onMultiple(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("multiple");
    }
    onService_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("serviceId");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_services() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('query_meter_readings', {
                parameters: {
                    rental: this.rental,
                    service_id: this.serviceId
                },
                asResource: true
            })
                .then(data => _
                .chain(data.services)
                .sortBy(['valid_from', 'inactive'], ['desc', 'asc'])
                .map(svc => (svc.readings = _.reverse(svc.readings).map((p, i) => (p.selected = (i < 2), p)),
                this.onCheckChanged(svc),
                svc))
                .value());
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            services: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "lg",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("InvoiceLineMeterReadingSelector|Title", "Choose readings"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_5332_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1969262983629788_closeModal_5336_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    value: $event.scope.cons,
                },
            };
        });
    }
    evh_1969262983629788_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1969262983629788_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_7391797828980767_script_5338_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.onCheckChanged($event.scope.item);
        });
    }
    act_7391797828980767_script_5338_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_7391797828980767_script_5338_getActionArgs_value($event),
            };
        });
    }
    evh_7391797828980767_change(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7391797828980767_change.executeFromDOM(this, event, scope);
        });
    }
    act_1548630417156826_closeModal_5340_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                },
            };
        });
    }
    evh_1548630417156826_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_5332 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_5332_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_5331 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_5332,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_5331],
        });
        const act_2248226175642056_closeComponent_5334 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_5333 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_5334,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_5333],
        });
        const act_1969262983629788_closeModal_5336 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1969262983629788_closeModal_5336_getActionArgs,
            events: [],
        });
        const evh_1969262983629788_clickPreventStop_5335 = new core_1.actions.EventHandlerImpl({
            action: act_1969262983629788_closeModal_5336,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        const evh_1969262983629788_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1969262983629788_clickPreventStop_5335],
        });
        const act_7391797828980767_script_5338 = new core_1.actions.ScriptAction({
            actionArgs: this.act_7391797828980767_script_5338_getActionArgs,
            events: [],
        });
        const evh_7391797828980767_change_5337 = new core_1.actions.EventHandlerImpl({
            action: act_7391797828980767_script_5338,
            event: "change",
            displayName: "script",
        });
        const evh_7391797828980767_change = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7391797828980767_change_5337],
        });
        const act_1548630417156826_closeModal_5340 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1548630417156826_closeModal_5340_getActionArgs,
            events: [],
        });
        const evh_1548630417156826_clickPreventStop_5339 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeModal_5340,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        const evh_1548630417156826_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_clickPreventStop_5339],
        });
        return {
            act_7315092382398562_closeModal_5332,
            evh_7315092382398562_close_5331,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_5334,
            evh_2248226175642056_close_5333,
            evh_2248226175642056_close,
            act_1969262983629788_closeModal_5336,
            evh_1969262983629788_clickPreventStop_5335,
            evh_1969262983629788_clickPreventStop,
            act_7391797828980767_script_5338,
            evh_7391797828980767_change_5337,
            evh_7391797828980767_change,
            act_1548630417156826_closeModal_5340,
            evh_1548630417156826_clickPreventStop_5339,
            evh_1548630417156826_clickPreventStop,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], InvoiceLineMeterReadingSelector.prototype, "rental", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('rental'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceLineMeterReadingSelector.prototype, "onRental", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], InvoiceLineMeterReadingSelector.prototype, "multiple", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('multiple'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceLineMeterReadingSelector.prototype, "onMultiple", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], InvoiceLineMeterReadingSelector.prototype, "serviceId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('serviceId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceLineMeterReadingSelector.prototype, "onService_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "lg",
    }),
    __metadata("design:type", String)
], InvoiceLineMeterReadingSelector.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceLineMeterReadingSelector.prototype, "onSize", null);
InvoiceLineMeterReadingSelector = __decorate([
    (0, vue_property_decorator_1.Component)()
], InvoiceLineMeterReadingSelector);
exports.default = InvoiceLineMeterReadingSelector;
vue_property_decorator_1.Vue.component("InvoiceLineMeterReadingSelector", InvoiceLineMeterReadingSelector);
