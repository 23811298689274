<template>
<ItpDataTable
  tableId="MeterReadings"
  editable
  :paging="b_7315092382398562_paging"
  :value="_.orderBy(items, ['reading_date', 'reading_time', 'created_at'], ['desc', 'desc', 'desc'])"
  :rowTemplate="schemaDefaults('CreateMeterReading', {
    meter_id: meter.id,
    unit: meter.unit,
    reading_date: new Date().toLocaleDateString(),
    reading_time: new Date().toLocaleTimeString()
  })"
  prepend-new-row
  :columns="b_7315092382398562_columns"
  @save="evh_7315092382398562_save($event, {})"
  @delete="evh_7315092382398562_delete($event, {})"
>
</ItpDataTable>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcMeterReadings extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  meter!: object;

  @Watch('meter')
  onMeter(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("meter")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_meter_reading', { parameters: { 'meter-id': this.meter.id } })
  }

  ux!: any;
  b_7315092382398562_paging!: any;
  b_7315092382398562_columns!: any;
  dataMembers = ['items', 'ux', 'b_7315092382398562_paging', 'b_7315092382398562_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        ux: {
          initialLoadSpinner: true,
        }
        ,
        b_7315092382398562_paging: false,
        b_7315092382398562_columns: [{
          name: "locked",
          type: "lock",
        }
          ,
        {
          name: "inactive",
          header: this.$fn.gettext("Inactive"),
          width: "30px",
          textAlign: "center",
          formatter: {
            name: "checkbox",
          }
          ,
          editor: {
            name: "checkbox",
          }
          ,
        }
          ,
        {
          name: "value",
          type: "number",
          header: this.$fn.gettext("Value"),
          width: "110px",
          validator: ["required"
            ,
            "numeric"
            ,
          ]
          ,
        }
          ,
        {
          name: "unit",
          header: this.$fn.gettext("Unit"),
          width: "110px",
          required: true,
        }
          ,
        {
          name: "reading_type",
          header: this.$fn.gettext("Reading Type"),
          width: "120px",
          required: true,
          default: "reading",
        }
          ,
        {
          name: "reading_date",
          type: "date",
          header: this.$fn.gettext("Reading Date"),
          required: true,
          width: "120px",
        }
          ,
        {
          name: "reading_time",
          type: "time",
          header: this.$fn.gettext("Reading Time"),
          required: true,
          width: "120px",
        }
          ,
        {
          name: "notes",
          type: "textarea",
          header: this.$fn.gettext("Notes"),
          width: "200px",
        }
          ,
        ]
        ,
      },
    }
  }

  async act_7315092382398562_script_6872_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_7315092382398562_script_6872, alias=undefined
    $event.data.waitFor(this.$fn.crud('meter_reading').upsert($event.data.row))
  }

  async act_7315092382398562_script_6872_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_7315092382398562_script_6872, alias=undefined
    return {
      value: () => this.act_7315092382398562_script_6872_getActionArgs_value($event),
    }
  }

  async evh_7315092382398562_save(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_save.executeFromDOM(this, event, scope);
  }

  async act_7315092382398562_script_6874_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_7315092382398562_script_6874, alias=undefined
    $event.data.waitFor(this.$fn.crud('meter_reading').delete($event.data.row))
  }

  async act_7315092382398562_script_6874_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_7315092382398562_script_6874, alias=undefined
    return {
      value: () => this.act_7315092382398562_script_6874_getActionArgs_value($event),
    }
  }

  async evh_7315092382398562_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_delete.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_script_6872: actions.ScriptAction;
    evh_7315092382398562_save_6871: actions.EventHandlerImpl;
    evh_7315092382398562_save: actions.EventHandlerGroup;
    act_7315092382398562_script_6874: actions.ScriptAction;
    evh_7315092382398562_delete_6873: actions.EventHandlerImpl;
    evh_7315092382398562_delete: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_script_6872 = new actions.ScriptAction(
      {
        actionArgs: this.act_7315092382398562_script_6872_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_save_6871 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_script_6872,
        event: "save",
        displayName: "script",
      }
    );
    const evh_7315092382398562_save = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_save_6871],
      }
    );
    const act_7315092382398562_script_6874 = new actions.ScriptAction(
      {
        actionArgs: this.act_7315092382398562_script_6874_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_delete_6873 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_script_6874,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_7315092382398562_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_delete_6873],
      }
    );
    return {
      act_7315092382398562_script_6872,
      evh_7315092382398562_save_6871,
      evh_7315092382398562_save,
      act_7315092382398562_script_6874,
      evh_7315092382398562_delete_6873,
      evh_7315092382398562_delete,
    }
  }
}

Vue.component("UcMeterReadings", UcMeterReadings);

</script>