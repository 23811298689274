"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcInvoicePayments = class UcInvoicePayments extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['triggerButtonID', 'ux', 'b_6466705138206980_style'];
    }
    beforeCreate() {
    }
    onInvoice(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice");
    }
    onReload(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("reload");
    }
    $$load_triggerButtonID() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            triggerButtonID: null,
            ux: null,
            b_6466705138206980_style: {
                "min-width": "150px",
            },
        });
    }
    get items() {
        if (this.loaded) {
            return this.invoice.payments.data;
        }
        return null;
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_4592790460410433_showModal_5442_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    oid: $event.scope.item.payment_id,
                },
            };
        });
    }
    evh_4592790460410433_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4592790460410433_click.executeFromDOM(this, event, scope);
        });
    }
    act_322074727583344_resource_5444_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "patch",
                target: this.invoice,
                data: {
                    no_payment_required: $event.data.checked ? 'true' : null,
                },
            };
        });
    }
    evh_322074727583344_change(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_322074727583344_change.executeFromDOM(this, event, scope);
        });
    }
    act_5807883900791941_resource_5446_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "patch",
                target: this.invoice,
                data: {
                    uncollectible: $event.data.checked ? 'true' : null,
                },
            };
        });
    }
    evh_5807883900791941_change(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5807883900791941_change.executeFromDOM(this, event, scope);
        });
    }
    act_1984986731969919_emit_5450_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_1984986731969919_close_5449_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.ok;
        });
    }
    act_1984986731969919_showModal_5448_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionType: this.invoice.invoice_kind == 'incoming' ? 'payment' : 'deposit',
                    invoices: [this.invoice.id],
                },
            };
        });
    }
    evh_1984986731969919_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1984986731969919_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_298505142589676_emit_5454_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_298505142589676_close_5453_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.ok;
        });
    }
    act_298505142589676_showModal_5452_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionType: this.invoice.invoice_kind == 'incoming' ? 'debit' : 'credit',
                    invoices: [this.invoice.id],
                },
            };
        });
    }
    evh_298505142589676_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_298505142589676_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_5738488038990219_emit_5458_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_5738488038990219_close_5457_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.ok;
        });
    }
    act_5738488038990219_showModal_5456_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionType: "reconciliation_of_invoices",
                    invoices: [this.invoice.id],
                },
            };
        });
    }
    evh_5738488038990219_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5738488038990219_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_7481595298497917_emit_5462_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_7481595298497917_close_5461_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.ok;
        });
    }
    act_7481595298497917_showModal_5460_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionType: "bad_debt_journal",
                    invoices: [this.invoice.id],
                },
            };
        });
    }
    evh_7481595298497917_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7481595298497917_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_5440 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_5439 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_5440,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_5439],
        });
        const act_4592790460410433_showModal_5442 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_4592790460410433_showModal_5442_getActionArgs,
            displayName: "OpenPayment",
            events: [],
        });
        const evh_4592790460410433_click_5441 = new core_1.actions.EventHandlerImpl({
            action: act_4592790460410433_showModal_5442,
            event: "click",
            displayName: "OpenPayment",
        });
        const evh_4592790460410433_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4592790460410433_click_5441],
        });
        const act_322074727583344_resource_5444 = new core_1.actions.ResourceAction({
            actionArgs: this.act_322074727583344_resource_5444_getActionArgs,
            events: [],
        });
        const evh_322074727583344_change_5443 = new core_1.actions.EventHandlerImpl({
            action: act_322074727583344_resource_5444,
            event: "change",
            displayName: "resource",
        });
        const evh_322074727583344_change = new core_1.actions.EventHandlerGroup({
            handlers: [evh_322074727583344_change_5443],
        });
        const act_5807883900791941_resource_5446 = new core_1.actions.ResourceAction({
            actionArgs: this.act_5807883900791941_resource_5446_getActionArgs,
            events: [],
        });
        const evh_5807883900791941_change_5445 = new core_1.actions.EventHandlerImpl({
            action: act_5807883900791941_resource_5446,
            event: "change",
            displayName: "resource",
        });
        const evh_5807883900791941_change = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5807883900791941_change_5445],
        });
        const act_1984986731969919_emit_5450 = new core_1.actions.EmitAction({
            actionArgs: this.act_1984986731969919_emit_5450_getActionArgs,
            events: [],
        });
        const evh_1984986731969919_close_5449 = new core_1.actions.EventHandlerImpl({
            action: act_1984986731969919_emit_5450,
            event: "close",
            when: this.evh_1984986731969919_close_5449_getWhen,
            displayName: "emit",
        });
        const act_1984986731969919_showModal_5448 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_1984986731969919_showModal_5448_getActionArgs,
            displayName: "createCashPayment",
            events: [evh_1984986731969919_close_5449],
        });
        const evh_1984986731969919_clickPrevent_5447 = new core_1.actions.EventHandlerImpl({
            action: act_1984986731969919_showModal_5448,
            event: "click.prevent",
            displayName: "createCashPayment",
        });
        const evh_1984986731969919_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1984986731969919_clickPrevent_5447],
        });
        const act_298505142589676_emit_5454 = new core_1.actions.EmitAction({
            actionArgs: this.act_298505142589676_emit_5454_getActionArgs,
            events: [],
        });
        const evh_298505142589676_close_5453 = new core_1.actions.EventHandlerImpl({
            action: act_298505142589676_emit_5454,
            event: "close",
            when: this.evh_298505142589676_close_5453_getWhen,
            displayName: "emit",
        });
        const act_298505142589676_showModal_5452 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_298505142589676_showModal_5452_getActionArgs,
            displayName: "createBankingPayment",
            events: [evh_298505142589676_close_5453],
        });
        const evh_298505142589676_clickPrevent_5451 = new core_1.actions.EventHandlerImpl({
            action: act_298505142589676_showModal_5452,
            event: "click.prevent",
            displayName: "createBankingPayment",
        });
        const evh_298505142589676_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_298505142589676_clickPrevent_5451],
        });
        const act_5738488038990219_emit_5458 = new core_1.actions.EmitAction({
            actionArgs: this.act_5738488038990219_emit_5458_getActionArgs,
            events: [],
        });
        const evh_5738488038990219_close_5457 = new core_1.actions.EventHandlerImpl({
            action: act_5738488038990219_emit_5458,
            event: "close",
            when: this.evh_5738488038990219_close_5457_getWhen,
            displayName: "emit",
        });
        const act_5738488038990219_showModal_5456 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5738488038990219_showModal_5456_getActionArgs,
            displayName: "createReconciliation",
            events: [evh_5738488038990219_close_5457],
        });
        const evh_5738488038990219_clickPrevent_5455 = new core_1.actions.EventHandlerImpl({
            action: act_5738488038990219_showModal_5456,
            event: "click.prevent",
            displayName: "createReconciliation",
        });
        const evh_5738488038990219_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5738488038990219_clickPrevent_5455],
        });
        const act_7481595298497917_emit_5462 = new core_1.actions.EmitAction({
            actionArgs: this.act_7481595298497917_emit_5462_getActionArgs,
            events: [],
        });
        const evh_7481595298497917_close_5461 = new core_1.actions.EventHandlerImpl({
            action: act_7481595298497917_emit_5462,
            event: "close",
            when: this.evh_7481595298497917_close_5461_getWhen,
            displayName: "emit",
        });
        const act_7481595298497917_showModal_5460 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7481595298497917_showModal_5460_getActionArgs,
            displayName: "createBadDebt",
            events: [evh_7481595298497917_close_5461],
        });
        const evh_7481595298497917_clickPrevent_5459 = new core_1.actions.EventHandlerImpl({
            action: act_7481595298497917_showModal_5460,
            event: "click.prevent",
            displayName: "createBadDebt",
        });
        const evh_7481595298497917_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7481595298497917_clickPrevent_5459],
        });
        return {
            act_7315092382398562_reloadComponentData_5440,
            evh_7315092382398562_reload_5439,
            evh_7315092382398562_reload,
            act_4592790460410433_showModal_5442,
            evh_4592790460410433_click_5441,
            evh_4592790460410433_click,
            act_322074727583344_resource_5444,
            evh_322074727583344_change_5443,
            evh_322074727583344_change,
            act_5807883900791941_resource_5446,
            evh_5807883900791941_change_5445,
            evh_5807883900791941_change,
            act_1984986731969919_emit_5450,
            evh_1984986731969919_close_5449,
            act_1984986731969919_showModal_5448,
            evh_1984986731969919_clickPrevent_5447,
            evh_1984986731969919_clickPrevent,
            act_298505142589676_emit_5454,
            evh_298505142589676_close_5453,
            act_298505142589676_showModal_5452,
            evh_298505142589676_clickPrevent_5451,
            evh_298505142589676_clickPrevent,
            act_5738488038990219_emit_5458,
            evh_5738488038990219_close_5457,
            act_5738488038990219_showModal_5456,
            evh_5738488038990219_clickPrevent_5455,
            evh_5738488038990219_clickPrevent,
            act_7481595298497917_emit_5462,
            evh_7481595298497917_close_5461,
            act_7481595298497917_showModal_5460,
            evh_7481595298497917_clickPrevent_5459,
            evh_7481595298497917_clickPrevent,
            OpenPayment: act_4592790460410433_showModal_5442,
            createCashPayment: act_1984986731969919_showModal_5448,
            createBankingPayment: act_298505142589676_showModal_5452,
            createReconciliation: act_5738488038990219_showModal_5456,
            createBadDebt: act_7481595298497917_showModal_5460,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcInvoicePayments.prototype, "invoice", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoice'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoicePayments.prototype, "onInvoice", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        default: null,
    }),
    __metadata("design:type", Object)
], UcInvoicePayments.prototype, "reload", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('reload'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoicePayments.prototype, "onReload", null);
UcInvoicePayments = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcInvoicePayments);
exports.default = UcInvoicePayments;
vue_property_decorator_1.Vue.component("UcInvoicePayments", UcInvoicePayments);
