<template>
<div>
  <legend
    class="text-muted"
  >
    {{ 'Advances'|pgettext('Contract') }}
  </legend>
  <ItpFormGroup
    name="rental_fee_advances_initial_months"
    :label="'Initial'|pgettext('Contract')"
    :appendText="'months'|pgettext('InputAddon')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      type="number"
      name="rental_fee_advances_initial_months"
      v-model="contract.invoicing.rental_fee_advances_initial_months"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="rental_fee_advances_reserve_months"
    :label="'Reserve'|pgettext('Contract')"
    :appendText="'months'|pgettext('InputAddon')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      type="number"
      name="rental_fee_advances_reserve_months"
      v-model="contract.invoicing.rental_fee_advances_reserve_months"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <hr>
  </hr>
  <legend>
    {{ 'Billing'|pgettext('Contract') }}
  </legend>
  <ItpFormGroup
    name="billing_currency"
    :label="'Billing currency'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="3"
    :labelAlign="labelAlign"
  >
    <ItpFormSelect2
      name="billing_currency"
      valueField="code"
      textField="code"
      required
      v-model="contract.invoicing.billing_currency"
      :options="currencies"
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    name="billing_frequency"
    :label="'Billing frequency'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="5"
    :labelAlign="labelAlign"
    contentClass="input-group"
    v-if="loaded"
  >
    <ItpFormInput
      type="number"
      name="billing_frequency_value"
      v-model="billing_frequency.value"
      @input="evh_8120114320850587_input($event, {})"
    >
    </ItpFormInput>
    <DIV
      class="input-group-append w-50"
    >
      <ItpFormSelect
        name="billing_frequency_unit"
        v-model="billing_frequency.unit"
        :options="billingFrequencyOptions"
        @input="evh_7882194489015218_input($event, {})"
      >
      </ItpFormSelect>
    </DIV>
  </ItpFormGroup>
  <ItpFormGroup
    name="invoice_notes"
    :label="'Notes on invoice'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
  >
    <ItpFormTextarea
      name="invoice_notes_value"
      v-model="contract.invoicing.invoice_notes"
    >
    </ItpFormTextarea>
  </ItpFormGroup>
  <ItpFormGroup
    name="payment_terms"
    :label="'Payment period'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    contentClass="input-group"
    v-if="loaded && contract"
  >
    <ItpFormInput
      type="number"
      name="payment_terms_value"
      v-model="contract.invoicing.payment_terms.value"
    >
    </ItpFormInput>
    <DIV
      class="input-group-append w-50"
    >
      <ItpFormSelect
        name="payment_terms_type"
        v-model="contract.invoicing.payment_terms.type"
        :options="contractPaymentTermTypes"
      >
      </ItpFormSelect>
    </DIV>
  </ItpFormGroup>
</div>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetContractInvoicing extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  updateBillingCycle(...args: any[]) {
    this.contract.invoicing.billing_cycle = `${this.billing_frequency.value} ${this.billing_frequency.unit}`
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  currencies!: any;

  async $$load_currencies() {
    return this.$fn.fetch('list_currency')
  }

  billing_frequency!: any;

  async $$load_billing_frequency() {
    return this.contract.invoicing.billing_cycle != null ?
      {
        value: this.contract.invoicing.billing_cycle.split(" ")[0],
        unit: this.contract.invoicing.billing_cycle.split(" ")[1]
      } : {
        value: null,
        unit: null
      }
  }

  billingFrequencyOptions!: any;
  contractPaymentTermTypes!: any;

  async $$load_contractPaymentTermTypes() {
    return this.$fn.getEnumValuesFromSchema('ContractPaymentTermTypes')
  }

  ux!: any;
  dataMembers = ['currencies', 'billing_frequency', 'billingFrequencyOptions', 'contractPaymentTermTypes', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        currencies: null,
        billing_frequency: null,
        billingFrequencyOptions: [{
          value: "day",
          text: this.$fn.gettext("day"),
        }
          ,
        {
          value: "month",
          text: this.$fn.gettext("month"),
        }
          ,
        ]
        ,
        contractPaymentTermTypes: null,
        ux: null,
      },
    }
  }

  async act_8120114320850587_script_6558_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_8120114320850587_script_6558, alias=undefined
    this.updateBillingCycle()
  }

  async act_8120114320850587_script_6558_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_8120114320850587_script_6558, alias=undefined
    return {
      value: () => this.act_8120114320850587_script_6558_getActionArgs_value($event),
    }
  }

  async evh_8120114320850587_input(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8120114320850587_input.executeFromDOM(this, event, scope);
  }

  async act_7882194489015218_script_6560_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_7882194489015218_script_6560, alias=undefined
    this.updateBillingCycle()
  }

  async act_7882194489015218_script_6560_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_7882194489015218_script_6560, alias=undefined
    return {
      value: () => this.act_7882194489015218_script_6560_getActionArgs_value($event),
    }
  }

  async evh_7882194489015218_input(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7882194489015218_input.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_8120114320850587_script_6558: actions.ScriptAction;
    evh_8120114320850587_input_6557: actions.EventHandlerImpl;
    evh_8120114320850587_input: actions.EventHandlerGroup;
    act_7882194489015218_script_6560: actions.ScriptAction;
    evh_7882194489015218_input_6559: actions.EventHandlerImpl;
    evh_7882194489015218_input: actions.EventHandlerGroup;
  }

  getActions() {
    const act_8120114320850587_script_6558 = new actions.ScriptAction(
      {
        actionArgs: this.act_8120114320850587_script_6558_getActionArgs,
        events: [],
      }
    );
    const evh_8120114320850587_input_6557 = new actions.EventHandlerImpl(
      {
        action: act_8120114320850587_script_6558,
        event: "input",
        displayName: "script",
      }
    );
    const evh_8120114320850587_input = new actions.EventHandlerGroup(
      {
        handlers: [evh_8120114320850587_input_6557],
      }
    );
    const act_7882194489015218_script_6560 = new actions.ScriptAction(
      {
        actionArgs: this.act_7882194489015218_script_6560_getActionArgs,
        events: [],
      }
    );
    const evh_7882194489015218_input_6559 = new actions.EventHandlerImpl(
      {
        action: act_7882194489015218_script_6560,
        event: "input",
        displayName: "script",
      }
    );
    const evh_7882194489015218_input = new actions.EventHandlerGroup(
      {
        handlers: [evh_7882194489015218_input_6559],
      }
    );
    return {
      act_8120114320850587_script_6558,
      evh_8120114320850587_input_6557,
      evh_8120114320850587_input,
      act_7882194489015218_script_6560,
      evh_7882194489015218_input_6559,
      evh_7882194489015218_input,
    }
  }
}

Vue.component("FieldsetContractInvoicing", FieldsetContractInvoicing);

</script>