"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let CreateMeterReadings = class CreateMeterReadings extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['notes', 'irentWriteback', 'skipMetersNotFound', 'irentWritebackSkipMetersNotFound', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onItems(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("items");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            notes: null,
            irentWriteback: false,
            skipMetersNotFound: false,
            irentWritebackSkipMetersNotFound: false,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "md",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("CreateMeterReadings", "Create Meter Readings"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_5756_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_register_5762_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "resultLength",
                value: $event.data.response.data ? $event.data.response.data.length : 0,
            };
        });
    }
    act_1040167445267876_notification_5764_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                message: this.$fn.ngettext("One meter reading created.", "${n} meter readings created.", $event.data.resultLength),
            };
        });
    }
    act_1040167445267876_notification_5764_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.resultLength > 0;
        });
    }
    act_1040167445267876_closeModal_5766_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    value: $event.data.response.data,
                },
            };
        });
    }
    act_1040167445267876_reloadSlickgrid_5768_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "all",
            };
        });
    }
    act_1040167445267876_request_5760_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "create_meter_reading",
                data: this.items
                    .filter(p => !!p.meter_id)
                    .map(p => ({
                    meter_id: p.meter_id,
                    value: p.value,
                    unit: p.unit,
                    notes: this.notes,
                    reading_type: 'automated',
                    reading_date: this.$fn.formatDate(this.$fn.parseISODate(p.timestamp), 'yyyy-MM-dd'),
                    reading_time: this.$fn.formatDate(this.$fn.parseISODate(p.timestamp), 'hh:mm'),
                })),
                parameters: {
                    "irent-writeback": this.irentWriteback,
                    "skip-meters-not-found": this.skipMetersNotFound,
                    "irent-writeback-skip-meters-not-found": this.irentWritebackSkipMetersNotFound,
                },
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_5756 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_5756_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_5755 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_5756,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_5755],
        });
        const act_2248226175642056_closeComponent_5758 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_5757 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_5758,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_5757],
        });
        const act_1040167445267876_register_5762 = new core_1.actions.RegisterAction({
            actionArgs: this.act_1040167445267876_register_5762_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_5761 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_register_5762,
            event: "success",
            displayName: "register",
        });
        const act_1040167445267876_notification_5764 = new core_1.actions.NotificationAction({
            actionArgs: this.act_1040167445267876_notification_5764_getActionArgs,
            when: this.act_1040167445267876_notification_5764_getWhen,
            events: [],
        });
        const evh_1040167445267876_success_5763 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_notification_5764,
            event: "success",
            displayName: "notification",
        });
        const act_1040167445267876_closeModal_5766 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_5766_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_5765 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_5766,
            event: "success",
            displayName: "closeModal",
        });
        const act_1040167445267876_reloadSlickgrid_5768 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1040167445267876_reloadSlickgrid_5768_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_5767 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_reloadSlickgrid_5768,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_1040167445267876_request_5760 = new core_1.actions.RequestAction({
            actionArgs: this.act_1040167445267876_request_5760_getActionArgs,
            displayName: "submit",
            events: [evh_1040167445267876_success_5761, evh_1040167445267876_success_5763, evh_1040167445267876_success_5765, evh_1040167445267876_success_5767],
        });
        const evh_1040167445267876_submit_5759 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_request_5760,
            event: "submit",
            displayName: "submit",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_5759],
        });
        const act_1419464017721962_closeComponent_5770 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_5769 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_5770,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_5769],
        });
        return {
            act_7315092382398562_closeModal_5756,
            evh_7315092382398562_close_5755,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_5758,
            evh_2248226175642056_close_5757,
            evh_2248226175642056_close,
            act_1040167445267876_register_5762,
            evh_1040167445267876_success_5761,
            act_1040167445267876_notification_5764,
            evh_1040167445267876_success_5763,
            act_1040167445267876_closeModal_5766,
            evh_1040167445267876_success_5765,
            act_1040167445267876_reloadSlickgrid_5768,
            evh_1040167445267876_success_5767,
            act_1040167445267876_request_5760,
            evh_1040167445267876_submit_5759,
            evh_1040167445267876_submit,
            act_1419464017721962_closeComponent_5770,
            evh_1419464017721962_clickPreventStop_5769,
            evh_1419464017721962_clickPreventStop,
            submit: act_1040167445267876_request_5760,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        default: null,
    }),
    __metadata("design:type", typeof (_a = typeof list !== "undefined" && list) === "function" ? _a : Object)
], CreateMeterReadings.prototype, "items", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('items'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], CreateMeterReadings.prototype, "onItems", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], CreateMeterReadings.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], CreateMeterReadings.prototype, "onSize", null);
CreateMeterReadings = __decorate([
    (0, vue_property_decorator_1.Component)()
], CreateMeterReadings);
exports.default = CreateMeterReadings;
vue_property_decorator_1.Vue.component("CreateMeterReadings", CreateMeterReadings);
