<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'Attachments'|gettext }}
      </strong>
      <ItpButton
        :text="'Upload file...'|pgettext('Attachments')"
        variant="light"
        size="sm"
        class="ml-auto"
        v-if="!isReadOnly"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup>
      <b-list-group-item
        v-if="!items || !items.length"
      >
        <span>
          {{ 'No attachments have been added yet.'|gettext }}
        </span>
      </b-list-group-item>
      <b-list-group-item
        :key="item.id"
        class="d-flex flex-column"
        v-for="item in _.orderBy(items, ['created_at'], ['desc'])"
      >
        <ItpBox
          direction="row"
          spacing="pb-1"
        >
          <div>
            <UcFileIcon
              :filename="item.file_name"
            >
            </UcFileIcon>
          </div>
          <ItpBox
            spacing="pl-3 w-100"
            direction="column"
          >
            <ItpBox
              spacing="pb-1"
            >
              <ItpLink
                :text="item.file_name"
                :href="item.download_url"
                :tooltip="item.file_name"
                :downloadFileName="item.file_name"
                download-link-only
                use-auth
              >
              </ItpLink>
              <ItpText
                class="px-3"
              >
                {{ item.file_size|filesize }}
              </ItpText>
              <b-dropdown
                size="sm"
                toggle-class="btn-default-borderless"
                no-caret
                class="ml-auto mb-auto"
                v-if="!item.locked"
              >
                <ItpIcon
                  fa="ellipsis-v"
                  class="mx-1"
                  slot="button-content"
                >
                </ItpIcon>
                <b-dropdown-item-button
                  @click.prevent.stop="evh_8513850661379123_clickPreventStop($event, {item})"
                >
                  <ItpText
                    :text="'Edit'|pgettext('Command')"
                  >
                  </ItpText>
                </b-dropdown-item-button>
                <b-dropdown-item-button
                  @click.prevent.stop="evh_3861238174616472_clickPreventStop($event, {item})"
                >
                  <ItpText
                    :text="'Delete'|pgettext('Command')"
                  >
                  </ItpText>
                </b-dropdown-item-button>
              </b-dropdown>
            </ItpBox>
            <ItpBox
              spacing="pb-2"
              v-if="item.description"
            >
              <ItpText
                pre
                :text="item.description"
              >
              </ItpText>
            </ItpBox>
            <ItpBox
              alignItems="center"
            >
              <small
                class="text-muted text-nowrap"
              >
                {{ (item.created_at || item.created_at)|datetime }}
              </small>
            </ItpBox>
          </ItpBox>
        </ItpBox>
      </b-list-group-item>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcAttachmentsList extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  parent_id!: string;

  @Watch('parent_id')
  onParent_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("parent_id")
  }

  @Prop({
    type: Object,
  })
  parent!: object;

  @Watch('parent')
  onParent(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("parent")
  }

  @Prop({
    type: Boolean,
  })
  readonly!: boolean;

  @Watch('readonly')
  onReadonly(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("readonly")
  }

  container!: any;

  async $$load_container() {
    return this.$fn.fetch('list_attachment', { parameters: { 'owner-id': this.parent_id || this.parent.id }, asResourceContainer: true })
  }

  ux!: any;
  dataMembers = ['container', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        container: null,
        ux: null,
      },
    }
  }

  get items() {
    if (this.loaded) {

      return this.container.data
    }
    return null;
  }

  get isReadOnly() {
    if (this.loaded) {

      return this.readonly || (this.parent && this.parent.isReadOnly)
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_showModal_2598_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_2598, alias=undefined
    return {
      name: "WAttachmentModal",
      props: {
        object_id: this.parent_id || this.parent.id,
      }
      ,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_8513850661379123_showModal_2602_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8513850661379123_showModal_2602, alias=undefined
    return {
      name: "WAttachmentModal",
      props: {
        object_id: this.parent_id || this.parent.id,
        attachment: _.cloneDeep($event.scope.item),
      }
      ,
    }
  }

  async evh_8513850661379123_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8513850661379123_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_3861238174616472_request_2606_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_3861238174616472_request_2606, alias=undefined
    return {
      operation: "delete_attachment",
      data: $event.scope.item,
    }
  }

  async act_3861238174616472_request_2606_getConfirm($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_3861238174616472_request_2606, alias=undefined
    return {
      message: this.$fn.gettext("Please confirm delete."),
      btnOk: this.$fn.gettext("Delete"),
    }
  }

  async evh_3861238174616472_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3861238174616472_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_2596: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_2595: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8320016629450276_reloadComponentData_2600: actions.ReloadComponentDataAction;
    evh_8320016629450276_close_2599: actions.EventHandlerImpl;
    act_8320016629450276_showModal_2598: actions.ShowModalAction;
    evh_8320016629450276_clickPreventStop_2597: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
    act_8513850661379123_reloadComponentData_2604: actions.ReloadComponentDataAction;
    evh_8513850661379123_close_2603: actions.EventHandlerImpl;
    act_8513850661379123_showModal_2602: actions.ShowModalAction;
    evh_8513850661379123_clickPreventStop_2601: actions.EventHandlerImpl;
    evh_8513850661379123_clickPreventStop: actions.EventHandlerGroup;
    act_3861238174616472_reloadComponentData_2608: actions.ReloadComponentDataAction;
    evh_3861238174616472_success_2607: actions.EventHandlerImpl;
    act_3861238174616472_request_2606: actions.RequestAction;
    evh_3861238174616472_clickPreventStop_2605: actions.EventHandlerImpl;
    evh_3861238174616472_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_2596 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_2595 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_2596,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_2595],
      }
    );
    const act_8320016629450276_reloadComponentData_2600 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8320016629450276_close_2599 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_reloadComponentData_2600,
        event: "close",
        displayName: "reloadComponentData",
      }
    );
    const act_8320016629450276_showModal_2598 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_2598_getActionArgs,
        events: [evh_8320016629450276_close_2599],
      }
    );
    const evh_8320016629450276_clickPreventStop_2597 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_2598,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_2597],
      }
    );
    const act_8513850661379123_reloadComponentData_2604 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8513850661379123_close_2603 = new actions.EventHandlerImpl(
      {
        action: act_8513850661379123_reloadComponentData_2604,
        event: "close",
        displayName: "reloadComponentData",
      }
    );
    const act_8513850661379123_showModal_2602 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8513850661379123_showModal_2602_getActionArgs,
        events: [evh_8513850661379123_close_2603],
      }
    );
    const evh_8513850661379123_clickPreventStop_2601 = new actions.EventHandlerImpl(
      {
        action: act_8513850661379123_showModal_2602,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8513850661379123_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8513850661379123_clickPreventStop_2601],
      }
    );
    const act_3861238174616472_reloadComponentData_2608 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_3861238174616472_success_2607 = new actions.EventHandlerImpl(
      {
        action: act_3861238174616472_reloadComponentData_2608,
        event: "success",
        displayName: "reloadComponentData",
      }
    );
    const act_3861238174616472_request_2606 = new actions.RequestAction(
      {
        actionArgs: this.act_3861238174616472_request_2606_getActionArgs,
        confirm: this.act_3861238174616472_request_2606_getConfirm,
        events: [evh_3861238174616472_success_2607],
      }
    );
    const evh_3861238174616472_clickPreventStop_2605 = new actions.EventHandlerImpl(
      {
        action: act_3861238174616472_request_2606,
        event: "click.prevent.stop",
        displayName: "request",
      }
    );
    const evh_3861238174616472_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_3861238174616472_clickPreventStop_2605],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_2596,
      evh_7315092382398562_reload_2595,
      evh_7315092382398562_reload,
      act_8320016629450276_reloadComponentData_2600,
      evh_8320016629450276_close_2599,
      act_8320016629450276_showModal_2598,
      evh_8320016629450276_clickPreventStop_2597,
      evh_8320016629450276_clickPreventStop,
      act_8513850661379123_reloadComponentData_2604,
      evh_8513850661379123_close_2603,
      act_8513850661379123_showModal_2602,
      evh_8513850661379123_clickPreventStop_2601,
      evh_8513850661379123_clickPreventStop,
      act_3861238174616472_reloadComponentData_2608,
      evh_3861238174616472_success_2607,
      act_3861238174616472_request_2606,
      evh_3861238174616472_clickPreventStop_2605,
      evh_3861238174616472_clickPreventStop,
    }
  }
}

Vue.component("UcAttachmentsList", UcAttachmentsList);

</script>