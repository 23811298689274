"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcClientInvoices = class UcClientInvoices extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['ux', 'b_7315092382398562_columnDefaults', 'b_7315092382398562_columns'];
    }
    beforeCreate() {
    }
    getQueryParameters(...args) {
        return { client_id: this.clientId };
    }
    onClient_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("clientId");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
            b_7315092382398562_columnDefaults: {
                sortable: true,
                filterable: true,
                resizable: true,
            },
            b_7315092382398562_columns: [{
                    field: "invoice_kind",
                    name: this.$fn.pgettext("Invoice", "Invoice kind"),
                },
                {
                    field: "invoice_type",
                    name: this.$fn.pgettext("Invoice", "Invoice type"),
                },
                {
                    field: "invoice_number",
                    name: this.$fn.pgettext("Invoice", "Invoice number"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "invoice",
                        params: {
                            id: "invoice_id",
                        },
                    },
                },
                {
                    field: "invoice_date",
                    name: this.$fn.pgettext("Invoice", "Invoice date"),
                    type: "date",
                },
                {
                    field: "fulfillment_date",
                    name: this.$fn.pgettext("Invoice", "Fulfillment date"),
                    type: "date",
                },
                {
                    field: "description",
                    name: this.$fn.pgettext("InvoiceLine", "Description"),
                },
                {
                    field: "quantity",
                    name: this.$fn.pgettext("InvoiceLine", "Quantity"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "unit",
                    name: this.$fn.pgettext("InvoiceLine", "Unit"),
                },
                {
                    field: "unit_price",
                    name: this.$fn.pgettext("InvoiceLine", "Price"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "discount",
                    name: this.$fn.pgettext("InvoiceLine", "Discount"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "net_amount",
                    name: this.$fn.pgettext("InvoiceLine", "Net"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "vat_amount",
                    name: this.$fn.pgettext("InvoiceLine", "VAT"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "amount",
                    name: this.$fn.pgettext("InvoiceLine", "Amount"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "tax_code",
                    name: this.$fn.pgettext("InvoiceLine", "Tax Code"),
                },
                {
                    field: "currency",
                    name: this.$fn.pgettext("Invoice", "Currency"),
                },
                {
                    field: "billing_period_start",
                    name: this.$fn.pgettext("Invoice", "Period start"),
                    type: "date",
                },
                {
                    field: "billing_period_end",
                    name: this.$fn.pgettext("Invoice", "Period end"),
                    type: "date",
                },
            ],
        });
    }
    act_7315092382398562_setData_5098_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: this.$refs.grid.getSelectedItems(),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_7315092382398562_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_setData_5098 = new core_1.actions.SetDataAction({
            actionArgs: this.act_7315092382398562_setData_5098_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_selectedRowsChanged_5097 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_setData_5098,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        const evh_7315092382398562_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_selectedRowsChanged_5097],
        });
        return {
            act_7315092382398562_setData_5098,
            evh_7315092382398562_selectedRowsChanged_5097,
            evh_7315092382398562_selectedRowsChanged,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], UcClientInvoices.prototype, "clientId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('clientId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcClientInvoices.prototype, "onClient_id", null);
UcClientInvoices = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcClientInvoices);
exports.default = UcClientInvoices;
vue_property_decorator_1.Vue.component("UcClientInvoices", UcClientInvoices);
