"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcClientPayments = class UcClientPayments extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['paymentMethods', 'ux', 'b_7315092382398562_columnDefaults', 'b_7315092382398562_columns'];
    }
    beforeCreate() {
    }
    getQueryParameters(...args) {
        return { client: this.clientId };
    }
    onClient_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("clientId");
    }
    $$load_paymentMethods() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_payment_method')
                .then(data => _.reduce(data, (m, v, k) => _.set(m, v.id, this.$fn.pgettext('PaymentMethod', v.name))));
        });
    }
    $$load_b_7315092382398562_columns() {
        return __awaiter(this, void 0, void 0, function* () {
            return [{
                    field: "payment_date",
                    name: this.$fn.pgettext("PaymentBrowserView", "Date"),
                    type: "date",
                },
                {
                    field: "payment_number",
                    name: this.$fn.pgettext("PaymentBrowserView", "Number"),
                    type: "command",
                    formatter: [{
                            name: "chain",
                            formatters: [{
                                    name: "link",
                                    href: "#",
                                },
                                {
                                    name: "cellclass",
                                    value: (row) => ({
                                        'font-weight-bold': true,
                                        'bg-yellow-200': !row.locked
                                    }),
                                },
                            ],
                        },
                    ],
                },
                {
                    field: "payment_method",
                    name: this.$fn.pgettext("PaymentBrowserView", "Method"),
                    formatter: {
                        name: "mapvalue",
                        mapping: this.paymentMethods,
                    },
                },
                {
                    field: "account_name",
                    name: this.$fn.pgettext("PaymentBrowserView", "Account"),
                },
                {
                    field: "payment_category",
                    name: this.$fn.pgettext("PaymentBrowserView", "Title"),
                },
                {
                    field: "description",
                    name: this.$fn.pgettext("PaymentBrowserView", "Description"),
                },
                {
                    field: "opening_balance",
                    name: this.$fn.pgettext("PaymentBrowserView", "Opening balance"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                    noFooterTotals: true,
                },
                {
                    field: "amount_in",
                    name: this.$fn.pgettext("PaymentBrowserView", "In"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "amount_out",
                    name: this.$fn.pgettext("PaymentBrowserView", "Out"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "closing_balance",
                    name: this.$fn.pgettext("PaymentBrowserView", "Closing balance"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                    noFooterTotals: true,
                },
                {
                    field: "currency",
                    name: this.$fn.pgettext("PaymentBrowserView", "Currency"),
                },
                {
                    field: "exchange_rate",
                    name: this.$fn.pgettext("PaymentBrowserView", "Exchange Rate"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                    noFooterTotals: true,
                },
                {
                    field: "reference_number",
                    name: this.$fn.pgettext("PaymentBrowserView", "Ref Nr."),
                },
                {
                    field: "file_number",
                    name: this.$fn.pgettext("PaymentBrowserView", "File Nr."),
                },
                {
                    field: "invoices",
                    name: this.$fn.pgettext("PaymentBrowserView", "Invoices"),
                    formatter: [{
                            name: "chain",
                            separator: ", ",
                            formatterContext: "value",
                            formatters: [{
                                    name: "getvalue",
                                    opts: {
                                        field: "invoice_number",
                                        source: "value",
                                    },
                                },
                                {
                                    name: "routeLink",
                                    to: "invoice",
                                    navigateOnIconClick: true,
                                    params: {
                                        id: "id",
                                    },
                                },
                            ],
                        },
                    ],
                },
            ];
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            paymentMethods: null,
            ux: null,
            b_7315092382398562_columnDefaults: {
                sortable: true,
                filterable: true,
                resizable: true,
            },
            b_7315092382398562_columns: null,
        });
    }
    act_7315092382398562_setData_5110_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: this.$refs.grid.getSelectedItems(),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_7315092382398562_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    act_7315092382398562_showModal_5112_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    modal: true,
                    oid: $event.data.data.id,
                },
            };
        });
    }
    evh_7315092382398562_command_5111_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.column.id === 'payment_number';
        });
    }
    act_7315092382398562_showModal_5114_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Account",
                props: {
                    oid: $event.data.data.account_id,
                },
            };
        });
    }
    evh_7315092382398562_command_5113_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.column.id === 'account_name';
        });
    }
    evh_7315092382398562_command(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_command.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_setData_5110 = new core_1.actions.SetDataAction({
            actionArgs: this.act_7315092382398562_setData_5110_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_selectedRowsChanged_5109 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_setData_5110,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        const evh_7315092382398562_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_selectedRowsChanged_5109],
        });
        const act_7315092382398562_showModal_5112 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7315092382398562_showModal_5112_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_command_5111 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_showModal_5112,
            event: "command",
            when: this.evh_7315092382398562_command_5111_getWhen,
            displayName: "showModal",
        });
        const act_7315092382398562_showModal_5114 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7315092382398562_showModal_5114_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_command_5113 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_showModal_5114,
            event: "command",
            when: this.evh_7315092382398562_command_5113_getWhen,
            displayName: "showModal",
        });
        const evh_7315092382398562_command = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_command_5111, evh_7315092382398562_command_5113],
        });
        return {
            act_7315092382398562_setData_5110,
            evh_7315092382398562_selectedRowsChanged_5109,
            evh_7315092382398562_selectedRowsChanged,
            act_7315092382398562_showModal_5112,
            evh_7315092382398562_command_5111,
            act_7315092382398562_showModal_5114,
            evh_7315092382398562_command_5113,
            evh_7315092382398562_command,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], UcClientPayments.prototype, "clientId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('clientId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcClientPayments.prototype, "onClient_id", null);
UcClientPayments = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcClientPayments);
exports.default = UcClientPayments;
vue_property_decorator_1.Vue.component("UcClientPayments", UcClientPayments);
