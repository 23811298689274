<template>
<ItpBox
  alignItems="center"
  class="items-spacing w-100"
  v-if="loaded"
>
  <ItpBox
    direction="column"
  >
    <ItpBox>
      <ItpText
        muted
        class="mb-0"
      >
        {{ resourceTitle }}
      </ItpText>
    </ItpBox>
    <ItpText
      tag="h4"
      :text="resourceKey"
      class="mb-0"
    >
    </ItpText>
  </ItpBox>
  <DIV
    class="btn-toolbar ml-auto"
  >
    <ItpButton
      icon="fa-sync-alt"
      :tooltip="'Reload'|gettext"
      :spinning="actions.reload.isRunning"
      variant="light"
      class="ml-auto mr-2"
      v-if="!isNew"
      @click.prevent.stop="evh_1040167445267876_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      variant="light"
      icon="icon-park-outline:preview-open"
      :href="protocol.preview_url"
      :tooltip="'Open preview'|pgettext('ContractProtocol')"
      target="_blank"
      use-auth
      show-spinner
      class="ml-auto mr-2"
      v-if="protocol.preview_url"
    >
    </ItpButton>
    <b-dropdown
      right
      variant="light"
      class="border"
      v-if="!isNew"
    >
      <ItpIcon
        fa="bars"
        class="mx-1"
        slot="button-content"
      >
      </ItpIcon>
      <BtnDeleteResource
        no-icon
        dropdown-item
        :resource="protocol"
        :tooltip="'Delete protocol'|pgettext('Payment')"
        :disabled="protocol.isNew || protocol.protocol_status != 'draft'"
        v-if="!isNew"
        @success="evh_4942415580156499_success($event, {})"
      >
      </BtnDeleteResource>
    </b-dropdown>
  </DIV>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractProtocolHeader extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  protocol!: object;

  @Watch('protocol')
  onProtocol(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("protocol")
  }

  messages!: any;
  ux!: any;
  dataMembers = ['messages', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          handoverTypes: {
            moving_in: this.$fn.pgettext("HandoverType", "Moving-in protocol"),
            moving_out: this.$fn.pgettext("HandoverType", "Moving-out protocol"),
          }
          ,
          newTransaction: this.$fn.pgettext("ContractProtocol", "New protocol"),
          editTransaction: this.$fn.pgettext("ContractProtocol", "Edit protocol"),
        }
        ,
        ux: null,
      },
    }
  }

  get isNew() {
    if (this.loaded) {

      return this.protocol.isNew
    }
    return null;
  }

  get resourceTitle() {
    if (this.loaded) {

      return this.messages.handoverTypes[this.protocol.handover_type]
    }
    return null;
  }

  get resourceKey() {
    if (this.loaded) {

      return this.isNew ? this.messages.newTransaction : this.messages.editTransaction
    }
    return null;
  }

  async act_1040167445267876_emit_6530_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1040167445267876_emit_6530, alias=reload
    return {
      event: "reload",
    }
  }

  async evh_1040167445267876_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_4942415580156499_emit_6532_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_4942415580156499_emit_6532, alias=undefined
    return {
      event: "deleted",
    }
  }

  async evh_4942415580156499_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4942415580156499_success.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_1040167445267876_emit_6530: actions.EmitAction;
    evh_1040167445267876_clickPreventStop_6529: actions.EventHandlerImpl;
    evh_1040167445267876_clickPreventStop: actions.EventHandlerGroup;
    act_4942415580156499_emit_6532: actions.EmitAction;
    evh_4942415580156499_success_6531: actions.EventHandlerImpl;
    evh_4942415580156499_success: actions.EventHandlerGroup;
  }

  getActions() {
    const act_1040167445267876_emit_6530 = new actions.EmitAction(
      {
        actionArgs: this.act_1040167445267876_emit_6530_getActionArgs,
        displayName: "reload",
        events: [],
      }
    );
    const evh_1040167445267876_clickPreventStop_6529 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_emit_6530,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_1040167445267876_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_clickPreventStop_6529],
      }
    );
    const act_4942415580156499_emit_6532 = new actions.EmitAction(
      {
        actionArgs: this.act_4942415580156499_emit_6532_getActionArgs,
        events: [],
      }
    );
    const evh_4942415580156499_success_6531 = new actions.EventHandlerImpl(
      {
        action: act_4942415580156499_emit_6532,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_4942415580156499_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_4942415580156499_success_6531],
      }
    );
    return {
      act_1040167445267876_emit_6530,
      evh_1040167445267876_clickPreventStop_6529,
      evh_1040167445267876_clickPreventStop,
      act_4942415580156499_emit_6532,
      evh_4942415580156499_success_6531,
      evh_4942415580156499_success,
      reload: act_1040167445267876_emit_6530,
    }
  }
}

Vue.component("UcContractProtocolHeader", UcContractProtocolHeader);

</script>