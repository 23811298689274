<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Contract"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <DIV
    class="page--v2 d-flex flex-column"
    v-if="loaded"
  >
    <MaterialTabs
      :tabManager="tabManager"
    >
      <ItpBox
        alignItems="center"
        class="mr-3"
        slot="tabs-start"
      >
        <b-breadcrumb
          class="m-0 bg-white"
        >
          <b-breadcrumb-item
            :text="'Meters'|gettext"
            :to="b_2285343489561581_to"
          >
          </b-breadcrumb-item>
          <b-breadcrumb-item
            :text="item.snapshot('contract_number')"
            active
          >
          </b-breadcrumb-item>
        </b-breadcrumb>
        <ItpButton
          variant="light"
          icon="fa-sync-alt"
          :tooltip="'Reload'|gettext"
          :spinning="actions.reload.isRunning"
          size="sm"
          class="mr-2"
          @click.prevent.stop="evh_2095089031469157_clickPreventStop($event, {})"
        >
        </ItpButton>
        <BtnDeleteResource
          :resource="item"
          no-text
          :dialogHeader="'Delete contract'|pgettext('Contract/DialogTitle')"
          class="mr-2"
          @success="evh_9004123373732307_success($event, {})"
        >
        </BtnDeleteResource>
        <UcContractRevisionDropdown
          :contract="item"
        >
        </UcContractRevisionDropdown>
      </ItpBox>
      <b-tab
        :title="'General'|gettext"
        class="container float-left"
      >
        <ItpRow
          v-if="tabManager.visited(0)"
        >
          <ItpCol
            w="6"
          >
            <UcContractGeneralInfo
              :contract="item"
              :reload="lastLoaded"
              :readonly="readonly"
              class="pb-5"
              @reload="evh_2679161326024595_reload($event, {})"
            >
            </UcContractGeneralInfo>
            <UcContractServices
              :contract="item"
              :reload="lastLoaded"
              :readonly="readonly"
              class="pb-5"
              @reload="evh_7987652045662005_reload($event, {})"
            >
            </UcContractServices>
            <UcContractEvents
              :contract="item"
              :reload="lastLoaded"
              :readonly="readonly"
              class="pb-5"
              @reload="evh_6490121676898844_reload($event, {})"
            >
            </UcContractEvents>
          </ItpCol>
          <ItpCol
            w="6"
          >
            <UcContractLifecycle
              :contract="item"
              :reload="lastLoaded"
              :readonly="readonly"
              class="pb-5"
              @reload="evh_2789477278097085_reload($event, {})"
            >
            </UcContractLifecycle>
            <UcContractInvoicing
              :contract="item"
              :reload="lastLoaded"
              :readonly="readonly"
              class="pb-5"
              @reload="evh_6182083303232630_reload($event, {})"
            >
            </UcContractInvoicing>
            <UcCommentsList
              :parentId="item.revision_id"
              :parent="item"
              :reload="lastLoaded"
              class="pb-5"
            >
            </UcCommentsList>
            <UcAttachmentsList
              :parent_id="item.revision_id"
              :parent="item"
              :reload="lastLoaded"
              :readonly="readonly"
              class="pb-5"
              @reload="evh_8856043737157701_reload($event, {})"
            >
            </UcAttachmentsList>
          </ItpCol>
        </ItpRow>
      </b-tab>
      <b-tab
        :title="'Invoices'|pgettext('Contract')"
        class="container-fluid h-100"
      >
        <UcContractInvoices
          :contractId="item.id"
          :reload="lastLoaded"
          class="h-100"
          v-if="tabManager.visited(1)"
        >
        </UcContractInvoices>
      </b-tab>
    </MaterialTabs>
  </DIV>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "contract",
    path: "/contracts/:id",
    component: "Contract",
    params: [{
      name: "id",
      prop: "contractId",
    }
      ,
    {
      name: "rev",
      prop: "revision_number",
    }
      ,
    ]

  }
)

@Component()
export default class Contract extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  contractId!: string;

  @Watch('contractId')
  onContract_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contractId")
  }

  @Prop({
    type: String,
  })
  revision_number!: string;

  @Watch('revision_number')
  onRevision_number(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("revision_number")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  tabManager!: any;

  async $$load_tabManager() {
    return new this.$app.utils.LazyTabManager()
  }

  item!: any;

  async $$load_item() {
    return this.$fn.fetch('read_contract', { asResource: true, parameters: { id: this.contractId, rev: this.revision_number } })
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2285343489561581_to!: any;
  dataMembers = ['tabManager', 'item', 'ux', 'b_7315092382398562_modalBindings', 'b_2285343489561581_to'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        tabManager: null,
        item: null,
        ux: {
          initialLoadSpinner: true,
        }
        ,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2285343489561581_to: {
          name: "app.meters",
        }
        ,
      },
    }
  }

  get readonly() {
    if (this.loaded) {

      return this.item.locked
    }
    return null;
  }

  get document() {
    if (this.loaded) {

      return {
        title: [this.item.snapshot('contract_number')
          ,
        this.$fn.gettext("Contract")
          ,
        ]
        ,
        icon: this.$config.contract.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2716_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2716, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2095089031469157_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2095089031469157_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_9004123373732307_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_9004123373732307_success.executeFromDOM(this, event, scope);
  }

  async evh_2679161326024595_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2679161326024595_reload.executeFromDOM(this, event, scope);
  }

  async evh_7987652045662005_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7987652045662005_reload.executeFromDOM(this, event, scope);
  }

  async evh_6490121676898844_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6490121676898844_reload.executeFromDOM(this, event, scope);
  }

  async evh_2789477278097085_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2789477278097085_reload.executeFromDOM(this, event, scope);
  }

  async evh_6182083303232630_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6182083303232630_reload.executeFromDOM(this, event, scope);
  }

  async evh_8856043737157701_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8856043737157701_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2716: actions.CloseModalAction;
    evh_7315092382398562_close_2715: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2095089031469157_reloadComponentData_2718: actions.ReloadComponentDataAction;
    evh_2095089031469157_clickPreventStop_2717: actions.EventHandlerImpl;
    evh_2095089031469157_clickPreventStop: actions.EventHandlerGroup;
    act_9004123373732307_closeComponent_2720: actions.CloseComponentAction;
    evh_9004123373732307_success_2719: actions.EventHandlerImpl;
    evh_9004123373732307_success: actions.EventHandlerGroup;
    act_2679161326024595_reloadComponentData_2722: actions.ReloadComponentDataAction;
    evh_2679161326024595_reload_2721: actions.EventHandlerImpl;
    evh_2679161326024595_reload: actions.EventHandlerGroup;
    act_7987652045662005_reloadComponentData_2724: actions.ReloadComponentDataAction;
    evh_7987652045662005_reload_2723: actions.EventHandlerImpl;
    evh_7987652045662005_reload: actions.EventHandlerGroup;
    act_6490121676898844_reloadComponentData_2726: actions.ReloadComponentDataAction;
    evh_6490121676898844_reload_2725: actions.EventHandlerImpl;
    evh_6490121676898844_reload: actions.EventHandlerGroup;
    act_2789477278097085_reloadComponentData_2728: actions.ReloadComponentDataAction;
    evh_2789477278097085_reload_2727: actions.EventHandlerImpl;
    evh_2789477278097085_reload: actions.EventHandlerGroup;
    act_6182083303232630_reloadComponentData_2730: actions.ReloadComponentDataAction;
    evh_6182083303232630_reload_2729: actions.EventHandlerImpl;
    evh_6182083303232630_reload: actions.EventHandlerGroup;
    act_8856043737157701_reloadComponentData_2732: actions.ReloadComponentDataAction;
    evh_8856043737157701_reload_2731: actions.EventHandlerImpl;
    evh_8856043737157701_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2716 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2716_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2715 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2716,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2715],
      }
    );
    const act_2095089031469157_reloadComponentData_2718 = new actions.ReloadComponentDataAction(
      {
        displayName: "reload",
        events: [],
      }
    );
    const evh_2095089031469157_clickPreventStop_2717 = new actions.EventHandlerImpl(
      {
        action: act_2095089031469157_reloadComponentData_2718,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_2095089031469157_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2095089031469157_clickPreventStop_2717],
      }
    );
    const act_9004123373732307_closeComponent_2720 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_9004123373732307_success_2719 = new actions.EventHandlerImpl(
      {
        action: act_9004123373732307_closeComponent_2720,
        event: "success",
        displayName: "closeComponent",
      }
    );
    const evh_9004123373732307_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_9004123373732307_success_2719],
      }
    );
    const act_2679161326024595_reloadComponentData_2722 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_2679161326024595_reload_2721 = new actions.EventHandlerImpl(
      {
        action: act_2679161326024595_reloadComponentData_2722,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_2679161326024595_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_2679161326024595_reload_2721],
      }
    );
    const act_7987652045662005_reloadComponentData_2724 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7987652045662005_reload_2723 = new actions.EventHandlerImpl(
      {
        action: act_7987652045662005_reloadComponentData_2724,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7987652045662005_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7987652045662005_reload_2723],
      }
    );
    const act_6490121676898844_reloadComponentData_2726 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_6490121676898844_reload_2725 = new actions.EventHandlerImpl(
      {
        action: act_6490121676898844_reloadComponentData_2726,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_6490121676898844_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_6490121676898844_reload_2725],
      }
    );
    const act_2789477278097085_reloadComponentData_2728 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_2789477278097085_reload_2727 = new actions.EventHandlerImpl(
      {
        action: act_2789477278097085_reloadComponentData_2728,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_2789477278097085_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_2789477278097085_reload_2727],
      }
    );
    const act_6182083303232630_reloadComponentData_2730 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_6182083303232630_reload_2729 = new actions.EventHandlerImpl(
      {
        action: act_6182083303232630_reloadComponentData_2730,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_6182083303232630_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_6182083303232630_reload_2729],
      }
    );
    const act_8856043737157701_reloadComponentData_2732 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8856043737157701_reload_2731 = new actions.EventHandlerImpl(
      {
        action: act_8856043737157701_reloadComponentData_2732,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_8856043737157701_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8856043737157701_reload_2731],
      }
    );
    return {
      act_7315092382398562_closeModal_2716,
      evh_7315092382398562_close_2715,
      evh_7315092382398562_close,
      act_2095089031469157_reloadComponentData_2718,
      evh_2095089031469157_clickPreventStop_2717,
      evh_2095089031469157_clickPreventStop,
      act_9004123373732307_closeComponent_2720,
      evh_9004123373732307_success_2719,
      evh_9004123373732307_success,
      act_2679161326024595_reloadComponentData_2722,
      evh_2679161326024595_reload_2721,
      evh_2679161326024595_reload,
      act_7987652045662005_reloadComponentData_2724,
      evh_7987652045662005_reload_2723,
      evh_7987652045662005_reload,
      act_6490121676898844_reloadComponentData_2726,
      evh_6490121676898844_reload_2725,
      evh_6490121676898844_reload,
      act_2789477278097085_reloadComponentData_2728,
      evh_2789477278097085_reload_2727,
      evh_2789477278097085_reload,
      act_6182083303232630_reloadComponentData_2730,
      evh_6182083303232630_reload_2729,
      evh_6182083303232630_reload,
      act_8856043737157701_reloadComponentData_2732,
      evh_8856043737157701_reload_2731,
      evh_8856043737157701_reload,
      reload: act_2095089031469157_reloadComponentData_2718,
    }
  }
}

Vue.component("Contract", Contract);

</script>