<template>
<ItpPage
  no-primary-button
  :closeButtonText="'Close'|gettext"
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="CreateMeter"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :name="formName"
      schema="CreateMeter"
      :id="formId"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <FieldsetMeterGeneralData
        :meter="item"
      >
      </FieldsetMeterGeneralData>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Save'|pgettext('Button')"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.create.isRunning"
      :disabled="!forms[formName] || !forms[formName].submittable"
    >
    </ItpButton>
    <ItpButton
      :text="'Save and create another'|pgettext('LetterRegisterEntry/Command')"
      :disabled="!forms[formName] || !forms[formName].submittable || actions.create && actions.create.isRunning"
      variant="light"
      icon="mdi:forward"
      class="mr-2"
      @click="evh_1419464017721962_click($event, {})"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      :disabled="actions.create.isRunning"
      @click.prevent.stop="evh_2186679009640457_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class CreateMeter extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return this.$fn.schemaDefaults('CreateMeter', { multiplier: 1 })
  }

  formName!: any;
  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  submitAndClose!: any;
  container!: any;

  async $$load_container() {
    return {
      self: this.$fn.urlFor('create_meter'),
      object: 'list',
      data: []
    }
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['item', 'formName', 'formId', 'submitAndClose', 'container', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        formName: "meter",
        formId: null,
        submitAndClose: true,
        container: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          noPrimaryButton: true,
          closeButtonText: this.$fn.gettext("Close"),
          size: "md",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Create Meter"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3486_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3486, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_3492_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_3492, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_1040167445267876_navigate_3494_getActionArgs($event: actions.ActionEvent): Promise<actions.NavigateActionArgs> {
    // parameterResolver name=act_1040167445267876_navigate_3494, alias=undefined
    return {
      location: {
        name: "app.meter",
        params: {
          id: $event.data.response.data.id,
        }
        ,
      }
      ,
    }
  }

  async act_1040167445267876_reloadSlickgrid_3496_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_3496, alias=undefined
    return {
      grid: "meters",
    }
  }

  async act_1040167445267876_rest_3490_getActionArgs($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_1040167445267876_rest_3490, alias=create
    return {
      method: "save",
      container: this.container,
      resource: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_script_3500_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_1419464017721962_script_3500, alias=undefined
    (
      this.setData('item', this.item.clone({
        id: null,
        rev: null,
        serial_number: null,
        links: null,
        etag: null,
        locked: null,
      })),
      this.setData('editor', this.item.editor())
    )
  }

  async act_1419464017721962_script_3500_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_1419464017721962_script_3500, alias=undefined
    return {
      value: () => this.act_1419464017721962_script_3500_getActionArgs_value($event),
    }
  }

  async act_1419464017721962_reloadSlickgrid_3502_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1419464017721962_reloadSlickgrid_3502, alias=undefined
    return {
      grid: "meters",
    }
  }

  async act_1419464017721962_rest_3498_getActionArgs($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_1419464017721962_rest_3498, alias=create2
    return {
      method: "save",
      container: this.container,
      resource: this.item,
    }
  }

  async evh_1419464017721962_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_click.executeFromDOM(this, event, scope);
  }

  async evh_2186679009640457_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3486: actions.CloseModalAction;
    evh_7315092382398562_close_3485: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_3488: actions.CloseComponentAction;
    evh_2248226175642056_close_3487: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_3492: actions.CloseModalAction;
    evh_1040167445267876_saved_3491: actions.EventHandlerImpl;
    act_1040167445267876_navigate_3494: actions.NavigateAction;
    evh_1040167445267876_saved_3493: actions.EventHandlerImpl;
    act_1040167445267876_reloadSlickgrid_3496: actions.ReloadSlickgridAction;
    evh_1040167445267876_saved_3495: actions.EventHandlerImpl;
    act_1040167445267876_rest_3490: actions.RestAction;
    evh_1040167445267876_submit_3489: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_script_3500: actions.ScriptAction;
    evh_1419464017721962_saved_3499: actions.EventHandlerImpl;
    act_1419464017721962_reloadSlickgrid_3502: actions.ReloadSlickgridAction;
    evh_1419464017721962_saved_3501: actions.EventHandlerImpl;
    act_1419464017721962_rest_3498: actions.RestAction;
    evh_1419464017721962_click_3497: actions.EventHandlerImpl;
    evh_1419464017721962_click: actions.EventHandlerGroup;
    act_2186679009640457_closeComponent_3504: actions.CloseComponentAction;
    evh_2186679009640457_clickPreventStop_3503: actions.EventHandlerImpl;
    evh_2186679009640457_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3486 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3486_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3485 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3486,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3485],
      }
    );
    const act_2248226175642056_closeComponent_3488 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_3487 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_3488,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_3487],
      }
    );
    const act_1040167445267876_closeModal_3492 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_3492_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_saved_3491 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_3492,
        event: "saved",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_navigate_3494 = new actions.NavigateAction(
      {
        actionArgs: this.act_1040167445267876_navigate_3494_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_saved_3493 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_navigate_3494,
        event: "saved",
        displayName: "navigate",
      }
    );
    const act_1040167445267876_reloadSlickgrid_3496 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1040167445267876_reloadSlickgrid_3496_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_saved_3495 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadSlickgrid_3496,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_1040167445267876_rest_3490 = new actions.RestAction(
      {
        actionArgs: this.act_1040167445267876_rest_3490_getActionArgs,
        displayName: "create",
        events: [evh_1040167445267876_saved_3491, evh_1040167445267876_saved_3493, evh_1040167445267876_saved_3495],
      }
    );
    const evh_1040167445267876_submit_3489 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_rest_3490,
        event: "submit",
        displayName: "create",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_3489],
      }
    );
    const act_1419464017721962_script_3500 = new actions.ScriptAction(
      {
        actionArgs: this.act_1419464017721962_script_3500_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_saved_3499 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_script_3500,
        event: "saved",
        displayName: "script",
      }
    );
    const act_1419464017721962_reloadSlickgrid_3502 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1419464017721962_reloadSlickgrid_3502_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_saved_3501 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_reloadSlickgrid_3502,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_1419464017721962_rest_3498 = new actions.RestAction(
      {
        actionArgs: this.act_1419464017721962_rest_3498_getActionArgs,
        displayName: "create2",
        events: [evh_1419464017721962_saved_3499, evh_1419464017721962_saved_3501],
      }
    );
    const evh_1419464017721962_click_3497 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_rest_3498,
        event: "click",
        displayName: "create2",
      }
    );
    const evh_1419464017721962_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_click_3497],
      }
    );
    const act_2186679009640457_closeComponent_3504 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2186679009640457_clickPreventStop_3503 = new actions.EventHandlerImpl(
      {
        action: act_2186679009640457_closeComponent_3504,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_2186679009640457_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2186679009640457_clickPreventStop_3503],
      }
    );
    return {
      act_7315092382398562_closeModal_3486,
      evh_7315092382398562_close_3485,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_3488,
      evh_2248226175642056_close_3487,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_3492,
      evh_1040167445267876_saved_3491,
      act_1040167445267876_navigate_3494,
      evh_1040167445267876_saved_3493,
      act_1040167445267876_reloadSlickgrid_3496,
      evh_1040167445267876_saved_3495,
      act_1040167445267876_rest_3490,
      evh_1040167445267876_submit_3489,
      evh_1040167445267876_submit,
      act_1419464017721962_script_3500,
      evh_1419464017721962_saved_3499,
      act_1419464017721962_reloadSlickgrid_3502,
      evh_1419464017721962_saved_3501,
      act_1419464017721962_rest_3498,
      evh_1419464017721962_click_3497,
      evh_1419464017721962_click,
      act_2186679009640457_closeComponent_3504,
      evh_2186679009640457_clickPreventStop_3503,
      evh_2186679009640457_clickPreventStop,
      create: act_1040167445267876_rest_3490,
      create2: act_1419464017721962_rest_3498,
    }
  }
}

Vue.component("CreateMeter", CreateMeter);

</script>