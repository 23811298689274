"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let CreateInvoice = class CreateInvoice extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['titles', 'formId', 'item', 'formContext', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onInvoice_kind(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoiceKind");
    }
    onInvoice_type(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoiceType");
    }
    onInvoice_register_entry(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoiceRegisterEntry");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('get_invoice_template', {
                parameters: {
                    invoice_kind: this.invoiceKind,
                    invoice_type: this.invoiceType,
                    invoice_register_entry: this.invoiceRegisterEntry
                }
            }).then(data => new this.$fn.Resource(data, {
                billing_period: data.billing_period || { start: null, end: null }
            }));
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            titles: {
                incoming: this.$fn.pgettext("Invoice", "Create Incoming Invoice"),
                outgoing: this.$fn.pgettext("Invoice", "Create Outgoing Invoice"),
            },
            formId: null,
            item: null,
            formContext: {},
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "lg",
            },
        });
    }
    get incomingInvoice() {
        if (this.loaded) {
            return this.invoiceKind === 'incoming';
        }
        return null;
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.titles[this.invoiceKind],
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_3066_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_reloadSlickgrid_3072_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "invoices",
            };
        });
    }
    evh_2248226175642056_deleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_deleted.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeModal_3076_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    close: true,
                },
            };
        });
    }
    act_1040167445267876_navigate_3078_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                location: {
                    name: "app.invoice",
                    params: {
                        id: $event.data.id,
                    },
                },
            };
        });
    }
    act_1040167445267876_crud_3074_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "invoice",
                op: "upsert",
                data: this.item,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    act_1419464017721962_closeModal_3080_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    close: true,
                },
            };
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_3066 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_3066_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_3065 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_3066,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_3065],
        });
        const act_2248226175642056_closeModal_3068 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_2248226175642056_close_3067 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeModal_3068,
            event: "close",
            displayName: "closeModal",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_3067],
        });
        const act_2248226175642056_closeModal_3070 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_2248226175642056_deleted_3069 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeModal_3070,
            event: "deleted",
            displayName: "closeModal",
        });
        const act_2248226175642056_reloadSlickgrid_3072 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_2248226175642056_reloadSlickgrid_3072_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_deleted_3071 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_reloadSlickgrid_3072,
            event: "deleted",
            displayName: "reloadSlickgrid",
        });
        const evh_2248226175642056_deleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_deleted_3069, evh_2248226175642056_deleted_3071],
        });
        const act_1040167445267876_closeModal_3076 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_3076_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_3075 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_3076,
            event: "success",
            displayName: "closeModal",
        });
        const act_1040167445267876_navigate_3078 = new core_1.actions.NavigateAction({
            actionArgs: this.act_1040167445267876_navigate_3078_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_3077 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_navigate_3078,
            event: "success",
            displayName: "navigate",
        });
        const act_1040167445267876_crud_3074 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1040167445267876_crud_3074_getActionArgs,
            displayName: "submit",
            events: [evh_1040167445267876_success_3075, evh_1040167445267876_success_3077],
        });
        const evh_1040167445267876_submit_3073 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_crud_3074,
            event: "submit",
            displayName: "submit",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_3073],
        });
        const act_1419464017721962_closeModal_3080 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1419464017721962_closeModal_3080_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_3079 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeModal_3080,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_3079],
        });
        return {
            act_7315092382398562_closeModal_3066,
            evh_7315092382398562_close_3065,
            evh_7315092382398562_close,
            act_2248226175642056_closeModal_3068,
            evh_2248226175642056_close_3067,
            evh_2248226175642056_close,
            act_2248226175642056_closeModal_3070,
            evh_2248226175642056_deleted_3069,
            act_2248226175642056_reloadSlickgrid_3072,
            evh_2248226175642056_deleted_3071,
            evh_2248226175642056_deleted,
            act_1040167445267876_closeModal_3076,
            evh_1040167445267876_success_3075,
            act_1040167445267876_navigate_3078,
            evh_1040167445267876_success_3077,
            act_1040167445267876_crud_3074,
            evh_1040167445267876_submit_3073,
            evh_1040167445267876_submit,
            act_1419464017721962_closeModal_3080,
            evh_1419464017721962_clickPreventStop_3079,
            evh_1419464017721962_clickPreventStop,
            submit: act_1040167445267876_crud_3074,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "outgoing",
    }),
    __metadata("design:type", String)
], CreateInvoice.prototype, "invoiceKind", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoiceKind'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], CreateInvoice.prototype, "onInvoice_kind", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "normal",
    }),
    __metadata("design:type", String)
], CreateInvoice.prototype, "invoiceType", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoiceType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], CreateInvoice.prototype, "onInvoice_type", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], CreateInvoice.prototype, "invoiceRegisterEntry", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoiceRegisterEntry'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], CreateInvoice.prototype, "onInvoice_register_entry", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "lg",
    }),
    __metadata("design:type", String)
], CreateInvoice.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], CreateInvoice.prototype, "onSize", null);
CreateInvoice = __decorate([
    (0, vue_property_decorator_1.Component)()
], CreateInvoice);
exports.default = CreateInvoice;
vue_property_decorator_1.Vue.component("CreateInvoice", CreateInvoice);
