"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcHeader = class UcHeader extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['ux', 'b_2248226175642056_class'];
    }
    beforeCreate() {
    }
    onModal(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("modal");
    }
    onCard(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("card");
    }
    onDialog_name(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("dialogName");
    }
    onTitle(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("title");
    }
    onColor(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("color");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
            b_2248226175642056_class: {
                "items-spacing": true,
                "w-100": true,
                "mr-3": true,
            },
        });
    }
    get borderClass() {
        if (this.loaded) {
            return this.color ? 'uc-border border-top-' + this.color : '';
        }
        return null;
    }
    act_5962862420439144_emit_2532_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "close",
            };
        });
    }
    evh_5962862420439144_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_5962862420439144_emit_2532 = new core_1.actions.EmitAction({
            actionArgs: this.act_5962862420439144_emit_2532_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_click_2531 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_emit_2532,
            event: "click",
            displayName: "emit",
        });
        const evh_5962862420439144_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_click_2531],
        });
        return {
            act_5962862420439144_emit_2532,
            evh_5962862420439144_click_2531,
            evh_5962862420439144_click,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], UcHeader.prototype, "modal", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('modal'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcHeader.prototype, "onModal", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], UcHeader.prototype, "card", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('card'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcHeader.prototype, "onCard", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], UcHeader.prototype, "dialogName", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('dialogName'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcHeader.prototype, "onDialog_name", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], UcHeader.prototype, "title", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('title'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcHeader.prototype, "onTitle", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], UcHeader.prototype, "color", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('color'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcHeader.prototype, "onColor", null);
UcHeader = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcHeader);
exports.default = UcHeader;
vue_property_decorator_1.Vue.component("UcHeader", UcHeader);
